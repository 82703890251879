import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  table: {
    '& .MuiTableCell-root': {
      borderBottom: `1px solid #ddd`,
      color: '#000',
      cursor: 'pointer',
      fontWeight: 500,
      // minWidth: 120,

      background: '#FAFAFA !important',
      [theme.breakpoints.only('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
        fontSize: 12,
        minWidth: 'auto',
      },
    },

    '& .MuiTableHead-root .MuiTableCell-root': {
      background: '#2C8DFF !important',
      fontSize: 14,
      color: '#fff',
      paddingTop: 20,
      paddingBottom: 20,
      fontWeight: 700,
      [theme.breakpoints.only('sm')]: {
        fontSize: 12,
      },
      '@media screen and (max-width: 450px) and (orientation: portrait)': {
        fontSize: 14,
      },
    },
  },

  container: {
    borderRadius: 10,
    // padding: 20,
    boxShadow: '3px 3px 5px #eee',
  },
}));

const RecentTransactionsTable = ({ columns, rows, type }) => {
  const classes = useStyles();
  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column, k) => (
              <TableCell style={{ color: '#fff' }} key={k}>
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, kk) => (
            <TableRow key={kk}>
              <TableCell style={{ color: '#000', borderColor: kk === rows.length - 1 ? '#ffffff00' : '#BABABA55' }}>
                {row.date}
              </TableCell>
              <TableCell style={{ color: '#000', borderColor: kk === rows.length - 1 ? '#ffffff00' : '#BABABA55' }}>
                {row.id}
              </TableCell>
              <TableCell style={{ color: '#000', borderColor: kk === rows.length - 1 ? '#ffffff00' : '#BABABA55' }}>
                {row.name}
              </TableCell>
              <TableCell style={{ color: '#000', borderColor: kk === rows.length - 1 ? '#ffffff00' : '#BABABA55' }}>
                {row.typeOfProperty}
              </TableCell>
              <TableCell
                style={{
                  color: row.order === 'Sell' ? '#E92C2C' : '#13CF8F',
                  borderColor: kk === rows.length - 1 ? '#ffffff00' : '#BABABA55',
                }}
              >
                {row.order}
              </TableCell>
              <TableCell style={{ color: '#000', borderColor: kk === rows.length - 1 ? '#ffffff00' : '#BABABA55' }}>
                $ {row.deposit}
              </TableCell>
              <TableCell style={{ color: '#000', borderColor: kk === rows.length - 1 ? '#ffffff00' : '#BABABA55' }}>
                $ {row.received}
              </TableCell>
              <TableCell
                style={{
                  color: row.totalProfitLoss < 0 ? '#E92C2C' : '#13CF8F',
                  fontWeight: 700,
                  borderColor: kk === rows.length - 1 ? '#ffffff00' : '#BABABA55',
                }}
              >
                {`${row.totalProfitLoss > 0 ? '+ $' : '- $'} ${Math.abs(row.totalProfitLoss)}`}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default RecentTransactionsTable;
