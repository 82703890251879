import { useStyles } from './style';
import ListingTable from '../../components/ProductDetail/ListingTable';
import { useEffect, useState, useCallback } from 'react';
// import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { AreaChart, Area, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import FilledButton from 'components/Buttons/FilledButton';
import OutlinedButton from 'components/Buttons/OutlinedButton';
import OfferTable from 'components/ProductDetail/OfferTable';
import ActivityTable from 'components/ProductDetail/ActivityTable';
import HowMuch from 'components/ProductDetail/HowMuch';
import FinancialDetails from 'components/ProductDetail/FinancialDetails';
import AboutProperty from 'components/ProductDetail/AboutProperty';
import PropertyInfo from 'components/ProductDetail/PropertyInfo';
import Documents from 'components/ProductDetail/Documents';
import Location from 'components/ProductDetail/Location';
import DeveloperManager from 'components/ProductDetail/DeveloperManager';
import BuyModal from 'components/modal/buyModal/BuyModal';
import MakeModal from 'components/modal/makeModal/MakeModal';
import { useLocation } from 'react-router-dom';
import { numberToString } from 'utils';
import BidHistoryTable from 'components/ProductDetail/BidHistoryTable';
import TextTimer from 'components/Timer/TextTimer';
import AuctionModal from 'components/modal/auctionModal/AuctionModal';
import ShareModal from 'components/modal/shareModal/ShareModal';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useMoralisWeb3Api, useMoralis } from "react-moralis"
import { currentChain, metropolyContract } from "constants/config";
import { useNewMoralisObject, useMoralisQuery } from "react-moralis";
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';



const myData = [
  {
    id: 0,
    feet: 2990,
    img: '/assets/images/unsplash_IYfp2Ixe9nM.png',
    type: 'Rental Property',
    startPrice: 5200,
    estatePrice: 5200000,
    name: 'Beautiful 5-Bedroom Villa with an Incredible View',
    location: 'Los Angeles, United States of America',
    homeState: 'Rented',
    beds: 5,
    avgPrice: 500,
    propertyPrice: 5200000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    country: "United States of America",
    state1: {
      owners: 800,
      nfts: 1000,
      views: 5.8,
      fav: 280,
    },
    state: {
      expected: 49,
      yeld: 7.79,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_01.png',
    propertyType: 0,
    howmuch: {
      netDividend: 7.79,
      totalAvg: 9.79,
      rentalIncom: 7790,
      capital: 2000,
      totalReturn: 9790,
      totalExpect: 29790,
    },
    financial: {
      listPrice: 4100000,
      transactionCosts: 400000,
      acquisitionCost: 4500000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 4500,
      grossYield: 12.79,
      grossRentPerYear: 655080,
      propertyInsureance: 20722,
      annualIncome: 405080,
    },
    holders: 800,
    minimum_amount: 5200,
    listingData: [
      {
        price: 5200,
        ethereum: 2.88,
        expiration: 7,
        from: 'Gohard',
      },
    ],
    offerData: [
      {
        price: 5100,
        ethereum: 2.83,
        different: 1.9,
        expiration: 4,
        from: 'SuperNova',
      },

      {
        price: 5000,
        ethereum: 2.77,
        different: 3.8,
        expiration: 8,
        from: 'MikelovesNFT',
      },
      {
        price: 4900,
        ethereum: 2.72,
        different: 5.7,
        expiration: 9,
        from: 'June24x',
      },
      {
        price: 4800,
        ethereum: 2.66,
        different: 7.7,
        expiration: 10,
        from: 'KevinCranel',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '5,000',
        date: 2,
        from: 'MoneyMaster_',
        to: 'Gohard',
      },
      {
        event: 'Mint',
        price: '4,500 x 1,000',
        date: 2,
        from: 'NullAddress',
        to: 'Multiple Accounts',
      },
    ],
  },

  {
    id: 1,
    feet: 590,
    img: '/assets/images/unsplash_IYfp2Ixe9nM (1).png',
    type: 'Rental Property',
    startPrice: 280,
    estatePrice: 280000,
    name: 'Cozy 1-Bedroom Apartment in the City Center',
    location: 'London, United Kingdom',
    homeState: 'Rented',
    beds: 1,
    avgPrice: 240,
    propertyPrice: 280000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    country: "United Kingdom",
    state1: {
      owners: 350,
      nfts: 1000,
      views: 3.2,
      fav: 130,
    },
    state: {
      expected: 32,
      yeld: 4.48,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_02.png',
    propertyType: 0,
    howmuch: {
      netDividend: 4.48,
      totalAvg: 6.48,
      rentalIncom: 4480,
      capital: 2000,
      totalReturn: 6480,
      totalExpect: 26480,
    },
    financial: {
      listPrice: 182000,
      transactionCosts: 18000,
      acquisitionCost: 200000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 200,
      grossYield: 9.48,
      grossRentPerYear: 26544,
      propertyInsureance: 4710,
      annualIncome: 12544,
    },
    holders: 350,
    minimum_amount: 280,
    listingData: [
      {
        price: 280,
        ethereum: 0.16,
        expiration: 6,
        from: 'MoneyMaster_',
      },
    ],
    offerData: [
      {
        price: 260,
        ethereum: 0.14,
        different: 7.2,
        expiration: 4,
        from: 'MikelovesNFT',
      },

      {
        price: 240,
        ethereum: 0.13,
        different: 14.3,
        expiration: 8,
        from: 'Gohard',
      },
      {
        price: 220,
        ethereum: 0.12,
        different: 21.4,
        expiration: 9,
        from: 'June27x',
      },
      {
        price: 200,
        ethereum: 0.11,
        different: 28.6,
        expiration: 10,
        from: 'KevinCranel',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '240',
        date: 2,
        from: 'SuperNova',
        to: 'MoneyMaster_',
      },
      {
        event: 'Mint',
        price: '$200 x 1,000',
        date: 1,
        from: 'NullAddress',
        to: 'Multiple Accounts',
      },
    ],
  },

  {
    id: 2,
    feet: 1800,
    img: '/assets/images/unsplash_IYfp2Ixe9nM (2).png',
    type: 'Holiday Home',
    startPrice: 860,
    estatePrice: 860000,
    name: 'Authentic 3-Bedroom Penthouse with a Private Terrace',
    location: 'Berlin, Germany',
    homeState: 'Vacant',
    beds: 3,
    avgPrice: 830,
    propertyPrice: 860000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    country: "Germany",
    state1: {
      owners: 650,
      nfts: 1000,
      views: 2.2,
      fav: 130,
    },
    state: {
      expected: 37,
      yeld: 5.32,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_02.png',
    propertyType: 0,
    howmuch: {
      netDividend: 5.32,
      totalAvg: 7.32,
      rentalIncom: 5320,
      capital: 2000,
      totalReturn: 7320,
      totalExpect: 27320,
    },
    financial: {
      listPrice: 728000,
      transactionCosts: 72000,
      acquisitionCost: 800000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 800,
      grossYield: 10.32,
      grossRentPerYear: 88560,
      propertyInsureance: 11937,
      annualIncome: 45752,
    },
    holders: 650,
    minimum_amount: 860,
    listingData: [
      {
        price: 860,
        ethereum: 0.48,
        expiration: 7,
        from: 'Gohard',
      },
    ],
    offerData: [
      {
        price: 850,
        ethereum: 0.47,
        different: 1.2,
        expiration: 4,
        from: 'MoneyMaster_',
      },

      {
        price: 830,
        ethereum: 0.46,
        different: 3.5,
        expiration: 8,
        from: 'KevinCranel',
      },
      {
        price: 800,
        ethereum: 0.44,
        different: 7.0,
        expiration: 9,
        from: 'SuperNova',
      },
      {
        price: 700,
        ethereum: 0.43,
        different: 9.3,
        expiration: 10,
        from: 'MikelovesNFT',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '820',
        date: 2,
        from: 'June27x',
        to: 'Gohard',
      },
      {
        event: 'Mint',
        price: '$800  x 1,000',
        date: 1,
        from: 'Null address',
        to: 'Multiple Accounts',
      },
    ],
  },

  {
    id: 3,
    feet: 1793,
    img: '/assets/images/image_02.png',
    type: 'Holiday Home',
    startPrice: 725,
    estatePrice: 725000,
    name: 'Modern 3-Bedroom Family Home',
    location: 'House, Stockholm, Sweden',
    homeState: 'Vacant',
    beds: 3,
    avgPrice: 600,
    propertyPrice: 725000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    country: "Sweden",
    state1: {
      owners: 600,
      nfts: 1000,
      views: 5.0,
      fav: 230,
    },
    state: {
      expected: 51,
      yeld: 8.28,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_03.png',
    propertyType: 0,
    howmuch: {
      netDividend: 8.28,
      totalAvg: 10.28,
      rentalIncom: 8280,
      capital: 2000,
      totalReturn: 10280,
      totalExpect: 30280,
    },
    financial: {
      listPrice: 500500,
      transactionCosts: 49500,
      acquisitionCost: 550000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 495,
      grossYield: 13.28,
      grossRentPerYear: 96280,
      propertyInsureance: 2550,
      annualIncome: 60030,
    },
    holders: 600,
    minimum_amount: 725,
    listingData: [
      {
        price: 725,
        ethereum: 0.4,
        expiration: 6,
        from: 'MoneyMaster_',
      },
    ],
    offerData: [
      {
        price: 700,
        ethereum: 0.39,
        different: 3.4,
        expiration: 4,
        from: 'MikelovesNFT',
      },

      {
        price: 600,
        ethereum: 0.33,
        different: 17.2,
        expiration: 8,
        from: 'June27x',
      },
      {
        price: 500,
        ethereum: 0.28,
        different: 31,
        expiration: 9,
        from: 'KevinCranel',
      },
      {
        price: 400,
        ethereum: 0.22,
        different: 44.8,
        expiration: 10,
        from: 'MIkelovesNFT',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '725',
        date: 2,
        from: 'June27x',
        to: 'MoneyMaster_',
      },
      {
        event: 'Sale',
        price: '700',
        date: 2,
        from: 'SuperNova',
        to: 'June27x',
      },
      {
        event: 'Sale',
        price: '600',
        date: 2,
        from: 'Gohard',
        to: 'SuperNova',
      },
      {
        event: 'Mint',
        price: '$550 x 1,000',
        date: 1,
        from: 'Null address',
        to: 'Multiple Accounts',
      },
    ],
  },

  {
    id: 4,
    feet: 2990,
    img: '/assets/images/image_03.png',
    type: 'Rental Property',
    startPrice: 7000,
    estatePrice: 7000000,
    name: 'Luxury 5-Bedroom Villa with a Pool',
    location: 'San Francisco, United States of America',
    homeState: 'Rented',
    beds: 5,
    avgPrice: 6600,
    propertyPrice: 7000000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    country: "United States of America",
    state1: {
      owners: 400,
      nfts: 1000,
      views: 10.5,
      fav: 800,
    },
    state: {
      expected: 37,
      yeld: 5.3,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_04.png',
    propertyType: 0,
    howmuch: {
      netDividend: 5.3,
      totalAvg: 7.3,
      rentalIncom: 5300,
      capital: 2000,
      totalReturn: 7300,
      totalExpect: 27300,
    },
    financial: {
      listPrice: 5096000,
      transactionCosts: 504000,
      acquisitionCost: 5600000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 5600,
      grossYield: 10.3,
      grossRentPerYear: 72100,
      propertyInsureance: 97650,
      annualIncome: 371000,
    },
    holders: 400,
    minimum_amount: 7000,
    listingData: [
      {
        price: 7000,
        ethereum: 3.88,
        expiration: 8,
        from: 'MikeloveNFT',
      },
    ],
    offerData: [
      {
        price: 6800,
        ethereum: 3.78,
        different: 2.9,
        expiration: 4,
        from: 'SuperNova',
      },

      {
        price: 6600,
        ethereum: 3.67,
        different: 5.7,
        expiration: 8,
        from: 'June27x',
      },
      {
        price: 6400,
        ethereum: 3.58,
        different: 8.6,
        expiration: 9,
        from: 'Gohard',
      },
      {
        price: 6200,
        ethereum: 3.44,
        different: 11.4,
        expiration: 10,
        from: 'SuperNova',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '7000',
        date: 2,
        from: 'KevinCranel',
        to: 'MikelovesNFT',
      },
      {
        event: 'Sale',
        price: '6400',
        date: 2,
        from: 'SuperNova',
        to: 'KevinCranel',
      },
      {
        event: 'Sale',
        price: '5800',
        date: 2,
        from: 'Gohard',
        to: 'SuperNova',
      },
      {
        event: 'Mint',
        price: '$5,600 x 1,000',
        date: 1,
        from: 'Null address',
        to: 'Multiple Accounts',
      },
    ],
  },

  {
    id: 5,
    feet: 2380,
    img: '/assets/images/image_04.png',
    type: 'Rental Property',
    startPrice: 3500,
    estatePrice: 3500000,
    name: '4-Bedroom House with a Beautiful Garden',
    location: 'Munich, Germany',
    homeState: 'Rented',
    beds: 4,
    avgPrice: 3300,
    propertyPrice: 3500000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    state1: {
      owners: 410,
      nfts: 1000,
      views: 8.4,
      fav: 2200,
    },
    state: {
      expected: 46,
      yeld: 7.2,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_05.png',
    propertyType: 0,
    howmuch: {
      netDividend: 7.2,
      totalAvg: 9.2,
      rentalIncom: 7200,
      capital: 2000,
      totalReturn: 9200,
      totalExpect: 29200,
    },
    financial: {
      listPrice: 27300000,
      transactionCosts: 270000,
      acquisitionCost: 3000000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 3000,
      grossYield: 12.2,
      grossRentPerYear: 427700,
      propertyInsureance: 25550,
      annualIncome: 252000,
    },
    holders: 410,
    minimum_amount: 3500,
    listingData: [
      {
        price: 3500,
        ethereum: 1.94,
        expiration: 5,
        from: 'SuperNova',
      },
    ],
    offerData: [
      {
        price: 3400,
        ethereum: 1.88,
        different: 5.2,
        expiration: 4,
        from: 'KevinCranel',
      },

      {
        price: 3300,
        ethereum: 1.83,
        different: 7.2,
        expiration: 8,
        from: 'MoneyMaster_',
      },
      {
        price: 3200,
        ethereum: 1.78,
        different: 8.6,
        expiration: 9,
        from: 'SuperNova',
      },
      {
        price: 3100,
        ethereum: 1.72,
        different: 11.9,
        expiration: 10,
        from: 'MikelovesNFT',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '3500',
        date: 2,
        from: 'June27x',
        to: 'SuperNova',
      },

      {
        event: 'Mint',
        price: '$3,000 x 1,000',
        date: 1,
        from: 'Null address',
        to: 'Multiple Accounts',
      },
    ],
  },

  {
    id: 6,
    feet: 590,
    img: '/assets/images/image_05.png',
    type: 'Rental Property',
    startPrice: 215,
    estatePrice: 215000,
    name: '1-Bedroom Studio Apartment Within Walking Distance of the City Center',
    location: 'Manchester, United Kingdom',
    homeState: 'Rented',
    beds: 1,
    avgPrice: 180,
    propertyPrice: 215000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    country: "United Kingdom",
    state1: {
      owners: 600,
      nfts: 1000,
      views: 0.45,
      fav: 80,
    },
    state: {
      expected: 38,
      yeld: 5.66,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_06.png',
    propertyType: 0,
    howmuch: {
      netDividend: 5.66,
      totalAvg: 10.66,
      rentalIncom: 5660,
      capital: 2000,
      totalReturn: 7660,
      totalExpect: 27660,
    },
    financial: {
      listPrice: 118300,
      transactionCosts: 11700,
      acquisitionCost: 130000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 130,
      grossYield: 10.66,
      grossRentPerYear: 22919,
      propertyInsureance: 2728,
      annualIncome: 12169,
    },
    holders: 600,
    minimum_amount: 215,
    listingData: [
      {
        price: 215,
        ethereum: 0.12,
        expiration: 3,
        from: 'MikelovesNFT',
      },
    ],
    offerData: [
      {
        price: 200,
        ethereum: 0.11,
        different: 7.0,
        expiration: 4,
        from: 'KevinCranel',
      },

      {
        price: 180,
        ethereum: 0.1,
        different: 16.2,
        expiration: 8,
        from: 'June27x',
      },
      {
        price: 150,
        ethereum: 0.08,
        different: 30.2,
        expiration: 9,
        from: 'MoneyMaster_',
      },
      {
        price: 140,
        ethereum: 0.06,
        different: 34.9,
        expiration: 10,
        from: 'SuperNova',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '215',
        date: 2,
        from: 'Gohard',
        to: 'MikelovesNFT',
      },

      {
        event: 'Mint',
        price: '$130 x 1,000',
        date: 1,
        from: 'Null address',
        to: 'Multiple Accounts',
      },
    ],
  },

  {
    id: 7,
    feet: 1120,
    img: '/assets/images/image_06.png',
    type: 'Rental Property',
    startPrice: 560,
    estatePrice: 560000,
    name: 'Luxury 2-Bedroom Condo in the Heart of the City',
    location: 'Rome, Italy',
    homeState: 'Vacant',
    beds: 2,
    avgPrice: 450,
    propertyPrice: 560000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    country: "Italy",
    state1: {
      owners: 450,
      nfts: 1000,
      views: 1.5,
      fav: 400,
    },
    state: {
      expected: 30,
      yeld: 3.9,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_07.png',
    propertyType: 0,
    howmuch: {
      netDividend: 3.9,
      totalAvg: 5.9,
      rentalIncom: 3900,
      capital: 2000,
      totalReturn: 5900,
      totalExpect: 25900,
    },
    financial: {
      listPrice: 364000,
      transactionCosts: 36000,
      acquisitionCost: 400000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 400,
      grossYield: 8.9,
      grossRentPerYear: 49840,
      propertyInsureance: 10556,
      annualIncome: 21840,
    },
    holders: 450,
    minimum_amount: 560,
    listingData: [
      {
        price: 560,
        ethereum: 0.31,
        expiration: 3,
        from: 'KevinCranel',
      },
    ],
    offerData: [
      {
        price: 500,
        ethereum: 0.28,
        different: 10.7,
        expiration: 4,
        from: 'June27x',
      },

      {
        price: 450,
        ethereum: 0.25,
        different: 19.6,
        expiration: 8,
        from: 'MoneyMaster_',
      },
      {
        price: 400,
        ethereum: 0.22,
        different: 28.6,
        expiration: 9,
        from: 'MikelovesNFT',
      },
      {
        price: 300,
        ethereum: 0.17,
        different: 46.4,
        expiration: 10,
        from: 'SuperNova',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '500',
        date: 2,
        from: 'SuperNova',
        to: 'KevinCranel',
      },

      {
        event: 'Mint',
        price: '$400 x 1,000',
        date: 1,
        from: 'Null address',
        to: 'Multiple Accounts',
      },
    ],
  },

  {
    id: 8,
    feet: 1793,
    img: '/assets/images/image_07.png',
    type: 'Rental Property',
    startPrice: 2400,
    estatePrice: 2400000,
    name: 'Rare 3-Bedroom Penthouse with a Patio',
    location: 'Barcelona, Spain',
    homeState: 'Rented',
    beds: 3,
    avgPrice: 2200,
    propertyPrice: 2400000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    country: "Spain",
    state1: {
      owners: 550,
      nfts: 1000,
      views: 7.8,
      fav: 360,
    },
    state: {
      expected: 51,
      yeld: 8.12,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_08.png',
    propertyType: 0,
    howmuch: {
      netDividend: 8.12,
      totalAvg: 10.12,
      rentalIncom: 8120,
      capital: 2000,
      totalReturn: 10120,
      totalExpect: 30120,
    },
    financial: {
      listPrice: 1820000,
      transactionCosts: 180000,
      acquisitionCost: 2000000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 2000,
      grossYield: 13.12,
      grossRentPerYear: 31488,
      propertyInsureance: 9792,
      annualIncome: 194880,
    },
    holders: 560,
    minimum_amount: 2400,
    listingData: [
      {
        price: 2400,
        ethereum: 1.5,
        expiration: 8,
        from: 'June27x',
      },
    ],
    offerData: [
      {
        price: 2300,
        ethereum: 1.44,
        different: 4.1,
        expiration: 4,
        from: 'MikelovesNFT',
      },

      {
        price: 2200,
        ethereum: 1.38,
        different: 8.3,
        expiration: 8,
        from: 'SuperNova',
      },
      {
        price: 2100,
        ethereum: 1.31,
        different: 12.5,
        expiration: 9,
        from: 'KevinCranel',
      },
      {
        price: 2000,
        ethereum: 1.25,
        different: 16.7,
        expiration: 10,
        from: 'MikelovesNFT',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '2200',
        date: 2,
        from: 'Gohard',
        to: 'MoneyMaster_',
      },

      {
        event: 'Mint',
        price: '$2,000 x 1,000',
        date: 1,
        from: 'Null address',
        to: 'Multiple Accounts',
      },
    ],
  },

  {
    id: 9,
    feet: 1195,
    img: '/assets/images/image_08.png',
    type: 'Holiday Home',
    startPrice: 2400,
    estatePrice: 2400000,
    propertyPrice: 2400000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    name: 'Beautiful 4-Bedroom House Near the Alps',
    location: 'Swiss Alps, Switzerland',
    homeState: 'Upcoming',
    beds: 4,
    country: "Switzerland",
    state1: {
      nfts: 1000,
      views: 20.1,
      fav: 1200,
    },
    state: {
      expected: 54,
      yeld: 8.79,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_09.png',
    propertyType: 1,
    minted: 300,
    howmuch: {
      netDividend: 8.79,
      totalAvg: 10.79,
      rentalIncom: 8790,
      capital: 2000,
      totalReturn: 10790,
      totalExpect: 30790,
    },
    financial: {
      listPrice: 2184000,
      transactionCosts: 216000,
      acquisitionCost: 2400000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 2400,
      grossYield: 13.79,
      grossRentPerYear: 330960,
      propertyInsureance: 4164,
      annualIncome: 210960,
    },
    holders: -1,
    minimum_amount: 2400,
  },

  {
    id: 10,
    feet: 1800,
    img: '/assets/images/image_09.png',
    type: 'Holiday Home',
    startPrice: 800,
    estatePrice: 800000,
    name: 'Beautiful 3-Bedroom Holiday House with a Pool',
    location: 'Dubai Hills, Dubai',
    homeState: 'Upcoming',
    beds: 3,
    propertyPrice: 800000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    country: "Dubai",
    state1: {
      nfts: 1000,
      views: 7.5,
      fav: 360,
    },
    state: {
      expected: 46,
      yeld: 7.5,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_10.png',
    propertyType: 1,
    minted: 600,
    howmuch: {
      netDividend: 7.5,
      totalAvg: 9.5,
      rentalIncom: 7500,
      capital: 2000,
      totalReturn: 9500,
      totalExpect: 29500,
    },
    financial: {
      listPrice: 728000,
      transactionCosts: 7200,
      acquisitionCost: 800000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 800,
      grossYield: 12.5,
      grossRentPerYear: 100000,
      propertyInsureance: 5000,
      annualIncome: 60000,
    },
    holders: -1,
    minimum_amount: 800,
  },

  {
    id: 11,
    feet: 500,
    img: '/assets/images/image_10.png',
    type: 'Rental Property',
    startPrice: 400,
    estatePrice: 400000,
    propertyPrice: 400000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    name: 'Chic 1-Bedroom Apartment with a Beautiful View',
    location: 'Paris, France',
    homeState: 'Upcoming',
    beds: 1,
    country: "France",
    state1: {
      nfts: 1000,
      views: 12.2,
      fav: 680,
    },
    state: {
      expected: 42,
      yeld: 6.32,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_11.png',
    propertyType: 1,
    minted: 750,
    howmuch: {
      netDividend: 6.32,
      totalAvg: 8.32,
      rentalIncom: 6320,
      capital: 2000,
      totalReturn: 8320,
      totalExpect: 28320,
    },
    financial: {
      listPrice: 364000,
      transactionCosts: 36000,
      acquisitionCost: 400000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 400,
      grossYield: 11.32,
      grossRentPerYear: 45280,
      propertyInsureance: 4152,
      annualIncome: 25280,
    },
    holders: -1,
    minimum_amount: 400,
  },

  {
    id: 12,
    feet: 1598,
    img: '/assets/images/image_11.png',
    type: 'Rental Property',
    startPrice: 8000,
    estatePrice: 8000,
    propertyPrice: 1000000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    name: 'Beautiful 3-Bedroom House in a quiet Neighbourhood',
    location: 'Miami, United States of America',
    country: "United States of America",
    homeState: 'Vacant',
    beds: 3,
    avgPrice: 7000,
    deadline: 1663305078,
    state1: {
      owners: 655,
      nfts: 1000,
      views: 6.5,
      fav: 85,
    },
    state: {
      expected: 57,
      yeld: 9.44,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_12.png',
    propertyType: 2,
    howmuch: {
      netDividend: 9.44,
      totalAvg: 11.44,
      rentalIncom: 9440,
      capital: 2000,
      totalReturn: 11440,
      totalExpect: 31440,
    },
    financial: {
      listPrice: 819000,
      transactionCosts: 81000,
      acquisitionCost: 900000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 900,
      grossYield: 16.0,
      grossRentPerYear: 160000,
      propertyInsureance: 9600,
      annualIncome: 94400,
    },
    holders: 655,
    minimum_amount: 1000,
    bidsData: [
      {
        price: 8000,
        ethereum: 4.44,
        different: 20,
        expiration: 4,
        from: 'KevinCranel',
      },

      {
        price: 7000,
        ethereum: 3.88,
        different: 30,
        expiration: 8,
        from: 'MikelovesNFT',
      },
      {
        price: 6000,
        ethereum: 3.33,
        different: 40,
        expiration: 9,
        from: 'MoneyMaster_',
      },
      {
        price: 5000,
        ethereum: 2.78,
        different: 50,
        expiration: 10,
        from: 'SuperNova',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '-',
        date: 2,
        from: 'Gohard',
        to: 'Metropoly Auction',
      },
      {
        event: 'Mint',
        price: '$9,000 x 1,000',
        date: 1,
        from: 'Null address',
        to: 'Multiple Accounts',
      },
    ],
  },
  {
    id: 13,
    feet: 1120,
    img: '/assets/images/image_12.png',
    type: 'Rental Property',
    startPrice: 24000,
    estatePrice: 800000,
    propertyPrice: 5500000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    name: 'Comfortable 2-Bedroom Ensuite Apartment',
    location: 'Dubai Hills, Dubai',
    country: "Dubai",
    homeState: 'Vacant',
    beds: 2,
    avgPrice: 23000,
    deadline: 1663391478,
    state1: {
      owners: 500,
      nfts: 1000,
      views: 2.2,
      fav: 67,
    },
    state: {
      expected: 40,
      yeld: 7.5,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_13.png',
    propertyType: 2,
    howmuch: {
      netDividend: 6,
      totalAvg: 8,
      rentalIncom: 6000,
      capital: 2000,
      totalReturn: 8000,
      totalExpect: 28000,
    },
    financial: {
      listPrice: 364000,
      transactionCosts: 3600,
      acquisitionCost: 400000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 400,
      grossYield: 11.0,
      grossRentPerYear: 60500,
      propertyInsureance: 6325,
      annualIncome: 33000,
    },
    holders: 500,
    minimum_amount: 550,
    bidsData: [
      {
        price: 24000,
        ethereum: 13.33,
        different: 14.5,
        expiration: 4,
        from: 'Gohard',
      },

      {
        price: 23000,
        ethereum: 12.78,
        different: 16.4,
        expiration: 8,
        from: 'June27x',
      },
      {
        price: 22000,
        ethereum: 12.22,
        different: 20,
        expiration: 9,
        from: 'SuperNova',
      },
      {
        price: 21000,
        ethereum: 11.67,
        different: 23.6,
        expiration: 10,
        from: 'MoneyMaster_',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '-',
        date: 2,
        from: 'KevinCranel',
        to: 'Metropoly Auction',
      },
      {
        event: 'Mint',
        price: '$20,000 x 1,000',
        date: 1,
        from: 'Null address',
        to: 'Multiple Accounts',
      },
    ],
  },
  {
    id: 14,
    feet: 680,
    img: '/assets/images/image_13.png',
    type: 'Rental Property',
    startPrice: 13500,
    estatePrice: 400000,
    propertyPrice: 150000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    name: 'Fancy 1-Bedroom Apartment in the city Center',
    location: 'Athens, Greece',
    homeState: 'Vacant',
    country: "Greece",
    beds: 1,
    avgPrice: 11000,
    deadline: 1663477878,
    state1: {
      owners: 700,
      nfts: 1000,
      views: 50,
      fav: 67,
    },
    state: {
      expected: 34,
      yeld: 4.8,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_14.png',
    propertyType: 2,
    howmuch: {
      netDividend: 4.8,
      totalAvg: 6.8,
      rentalIncom: 4800,
      capital: 2000,
      totalReturn: 6800,
      totalExpect: 26800,
    },
    financial: {
      listPrice: 81900,
      transactionCosts: 8100,
      acquisitionCost: 90000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 90,
      grossYield: 9.8,
      grossRentPerYear: 1470,
      propertyInsureance: 2355,
      annualIncome: 7200,
    },
    holders: 700,
    minimum_amount: 150,
    bidsData: [
      {
        price: 13500,
        ethereum: 7.5,
        different: 10,
        expiration: 4,
        from: 'MikelovesNFT',
      },

      {
        price: 11000,
        ethereum: 6.11,
        different: 26,
        expiration: 8,
        from: 'KevinCranel',
      },
      {
        price: 10000,
        ethereum: 5.56,
        different: 33,
        expiration: 9,
        from: 'SuperNova_',
      },
      {
        price: 5000,
        ethereum: 5.0,
        different: 40,
        expiration: 10,
        from: 'Gohard',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '-',
        date: 2,
        from: 'June27x',
        to: 'Metropoly Auction',
      },
      {
        event: 'Mint',
        price: '$9,000 x 1,000',
        date: 1,
        from: 'Null address',
        to: 'Multiple Accounts',
      },
    ],
  },
  {
    id: 15,
    feet: 3000,
    img: '/assets/inbox/img_01.png',
    type: 'Rental Property',
    startPrice: 2600,
    estatePrice: 2600000,
    name: 'Stunning 5-Bedroom Villa Next to the Beach',
    location: 'Ibiza, Spain',
    homeState: 'Vacant',
    beds: 5,
    avgPrice: 2400,
    propertyPrice: 2600000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    country: "Spain",
    state1: {
      owners: 300,
      nfts: 1000,
      views: 3.3,
      fav: 1500,
    },
    state: {
      expected: 74,
      yeld: 12.79,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_14.png',
    propertyType: 0,
    howmuch: {
      netDividend: 4.8,
      totalAvg: 6.8,
      rentalIncom: 4800,
      capital: 2000,
      totalReturn: 6800,
      totalExpect: 26800,
    },
    financial: {
      listPrice: 81900,
      transactionCosts: 8100,
      acquisitionCost: 90000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 90,
      grossYield: 9.8,
      grossRentPerYear: 1470,
      propertyInsureance: 2355,
      annualIncome: 7200,
    },
    holders: 700,
    minimum_amount: 2600,
    listingData: [
      {
        price: 2600,
        ethereum: 1.44,
        expiration: 3,
        from: 'MikelovesNFT',
      },
    ],
    offerData: [
      {
        price: 2500,
        ethereum: 1.39,
        different: 3.8,
        expiration: 4,
        from: 'June27x',
      },

      {
        price: 2400,
        ethereum: 1.33,
        different: 7.7,
        expiration: 8,
        from: 'MoneyMaster_',
      },
      {
        price: 2300,
        ethereum: 1.28,
        different: 11.5,
        expiration: 9,
        from: 'KevinCranel',
      },
      {
        price: 2200,
        ethereum: 1.22,
        different: 15.4,
        expiration: 10,
        from: 'SuperNova',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '2000',
        date: 2,
        from: 'Gohard',
        to: 'MikelovesNFT',
      },
      {
        event: 'Mint',
        price: '$1,800 x 1,000',
        date: 1,
        from: 'Null address',
        to: 'Multiple Accounts',
      },
    ],
  },

  {
    id: 16,
    feet: 1793,
    img: '/assets/inbox/img_02.png',
    type: 'Rental Property',
    startPrice: 840,
    estatePrice: 840000,
    name: 'Rare 3-Bedroom Penthouse with a patio',
    location: 'Rome, Italy',
    homeState: 'Vacant',
    country: "Italy",
    beds: 3,
    avgPrice: 780,
    propertyPrice: 840000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    state1: {
      owners: 600,
      nfts: 1000,
      views: 3.7,
      fav: 1200,
    },
    state: {
      expected: 57,
      yeld: 9.42,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_14.png',
    propertyType: 0,
    howmuch: {
      netDividend: 9.42,
      totalAvg: 11.42,
      rentalIncom: 9420,
      capital: 2000,
      totalReturn: 11420,
      totalExpect: 31420,
    },
    financial: {
      listPrice: 637000,
      transactionCosts: 63000,
      acquisitionCost: 700000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 700,
      grossYield: 16.0,
      grossRentPerYear: 134400,
      propertyInsureance: 8232,
      annualIncome: 79128,
    },
    holders: 600,
    minimum_amount: 840,
    listingData: [
      {
        price: 840,
        ethereum: 0.47,
        expiration: 8,
        from: 'MoneyMaster_',
      },
    ],
    offerData: [
      {
        price: 800,
        ethereum: 0.44,
        different: 5.0,
        expiration: 4,
        from: 'Gohard',
      },

      {
        price: 780,
        ethereum: 0.43,
        different: 7.1,
        expiration: 8,
        from: 'June27x',
      },
      {
        price: 760,
        ethereum: 0.42,
        different: 9.5,
        expiration: 9,
        from: 'MikelovesNFT',
      },
      {
        price: 740,
        ethereum: 0.41,
        different: 11.9,
        expiration: 10,
        from: 'KevinCranel',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '800',
        date: 2,
        from: 'SuperNova',
        to: 'MoneyMaster_',
      },
      {
        event: 'Mint',
        price: '$700 x 1,000',
        date: 1,
        from: 'Null address',
        to: 'Multiple Accounts',
      },
    ],
  },
  {
    id: 17,
    feet: 1793,
    img: '/assets/inbox/img_03.png',
    type: 'Rental Property',
    startPrice: 7000,
    estatePrice: 7000000,
    name: 'Luxury 5-Bedroom Villa with a Pool',
    location: 'San Francisco, United States of America',
    homeState: 'Rented',
    country: "United States of America",
    beds: 2,
    avgPrice: 6600,
    propertyPrice: 7000000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    state1: {
      owners: 400,
      nfts: 1000,
      views: 10.5,
      fav: 800,
    },
    state: {
      expected: 37,
      yeld: 5.3,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_14.png',
    propertyType: 0,
    howmuch: {
      netDividend: 5.3,
      totalAvg: 7.3,
      rentalIncom: 5300,
      capital: 2000,
      totalReturn: 7300,
      totalExpect: 27300,
    },
    financial: {
      listPrice: 509600,
      transactionCosts: 50400,
      acquisitionCost: 5600000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 5600,
      grossYield: 10.3,
      grossRentPerYear: 721000,
      propertyInsureance: 97650,
      annualIncome: 371000,
    },
    holders: 400,
    minimum_amount: 7000,
    listingData: [
      {
        price: 7000,
        ethereum: 3.88,
        expiration: 8,
        from: 'MikeloveNFT',
      },
    ],
    offerData: [
      {
        price: 6800,
        ethereum: 3.78,
        different: 2.9,
        expiration: 4,
        from: 'SuperNova',
      },

      {
        price: 6600,
        ethereum: 3.67,
        different: 5.7,
        expiration: 8,
        from: 'June27x',
      },
      {
        price: 6400,
        ethereum: 3.58,
        different: 8.6,
        expiration: 9,
        from: 'Gohard',
      },
      {
        price: 6200,
        ethereum: 3.44,
        different: 11.4,
        expiration: 10,
        from: 'SuperNova',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '7000',
        date: 2,
        from: 'KevinCranel',
        to: 'MikelovesNFT',
      },
      {
        event: 'Sale',
        price: '6400',
        date: 2,
        from: 'SuperNova',
        to: 'KevinCranel',
      },
      {
        event: 'Sale',
        price: '5800',
        date: 2,
        from: 'Gohard',
        to: 'SuperNova',
      },
      {
        event: 'Mint',
        price: '$5,600 x 1,000',
        date: 1,
        from: 'Null address',
        to: 'Multiple Accounts',
      },
    ],
  },
  {
    id: 18,
    feet: 1800,
    img: '/assets/inbox/img_04.png',
    type: 'Rental Property',
    startPrice: 1600,
    estatePrice: 160000,
    name: 'Stunning 3-Bedroom Villa Next to the Beach',
    location: 'Dubai, United Arab Emirates',
    country: "United Arab Emirates",
    homeState: 'Rented',
    beds: 3,
    avgPrice: 1400,
    propertyPrice: 160000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    state1: {
      owners: 650,
      nfts: 1000,
      views: 2.2,
      fav: 130,
    },
    state: {
      expected: 66,
      yeld: 11.2,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_14.png',
    propertyType: 0,
    howmuch: {
      netDividend: 11.2,
      totalAvg: 13.2,
      rentalIncom: 11200,
      capital: 2000,
      totalReturn: 13200,
      totalExpect: 33200,
    },
    financial: {
      listPrice: 823000,
      transactionCosts: 72000,
      acquisitionCost: 900000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 900,
      grossYield: 20,
      grossRentPerYear: 300000,
      propertyInsureance: 15800,
      annualIncome: 179200,
    },
    holders: 650,
    minimum_amount: 1600,
    listingData: [
      {
        price: 1600,
        ethereum: 0.88,
        expiration: 7,
        from: 'Gohard',
      },
    ],
    offerData: [
      {
        price: 1500,
        ethereum: 0.83,
        different: 1.2,
        expiration: 4,
        from: 'MoneyMaster_',
      },

      {
        price: 1400,
        ethereum: 0.77,
        different: 3.5,
        expiration: 8,
        from: 'KevinCranel',
      },
      {
        price: 1300,
        ethereum: 0.72,
        different: 7.0,
        expiration: 9,
        from: 'SuperNova',
      },
      {
        price: 1200,
        ethereum: 0.66,
        different: 9.3,
        expiration: 10,
        from: 'MikelovesNFT',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '900',
        date: 2,
        from: 'June27x',
        to: 'Gohard',
      },
      {
        event: 'Mint',
        price: '$900 x 1,000',
        date: 1,
        from: 'Null address',
        to: 'Multiple Accounts',
      },
    ],
  },
  {
    id: 19,
    feet: 1800,
    img: '/assets/inbox/img_05.png',
    type: 'Holiday Home',
    startPrice: 880,
    estatePrice: 880000,
    name: '4-Bedroom Family House with a Garden',
    location: 'Paris, France',
    country: "France",
    homeState: 'Vacant',
    beds: 4,
    avgPrice: 830,
    propertyPrice: 880000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    state1: {
      owners: 650,
      nfts: 1000,
      views: 2.2,
      fav: 130,
    },
    state: {
      expected: 52,
      yeld: 8.4,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_14.png',
    propertyType: 0,
    howmuch: {
      netDividend: 8.4,
      totalAvg: 10.4,
      rentalIncom: 8400,
      capital: 2000,
      totalReturn: 10400,
      totalExpect: 30400,
    },
    financial: {
      listPrice: 528000,
      transactionCosts: 72000,
      acquisitionCost: 600000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 600,
      grossYield: 20,
      grossRentPerYear: 16000,
      propertyInsureance: 33980,
      annualIncome: 73920,
    },
    holders: 650,
    minimum_amount: 860,
    listingData: [
      {
        price: 880,
        ethereum: 0.49,
        expiration: 7,
        from: 'Gohard',
      },
    ],
    offerData: [
      {
        price: 850,
        ethereum: 0.47,
        different: 1.2,
        expiration: 4,
        from: 'MoneyMaster_',
      },

      {
        price: 830,
        ethereum: 0.46,
        different: 3.5,
        expiration: 8,
        from: 'KevinCranel',
      },
      {
        price: 800,
        ethereum: 0.44,
        different: 7.0,
        expiration: 9,
        from: 'SuperNova',
      },
      {
        price: 780,
        ethereum: 0.43,
        different: 9.3,
        expiration: 10,
        from: 'MikelovesNFT',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '600',
        date: 2,
        from: 'June27x',
        to: 'Gohard',
      },
      {
        event: 'Mint',
        price: '$600 x 1,000',
        date: 1,
        from: 'Null address',
        to: 'Multiple Accounts',
      },
    ],
  },
  {
    id: 20,
    feet: 1793,
    img: '/assets/inbox/img_06.png',
    type: 'Rental Property',
    startPrice: 7000,
    estatePrice: 7000000,
    name: 'Luxury 5-Bedroom Villa with a Pool',
    location: 'San Francisco, United States of America',
    country: "United States of America",
    homeState: 'Rented',
    beds: 2,
    avgPrice: 6600,
    propertyPrice: 7000000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    state1: {
      owners: 400,
      nfts: 1000,
      views: 10.5,
      fav: 800,
    },
    state: {
      expected: 37,
      yeld: 5.3,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_14.png',
    propertyType: 0,
    howmuch: {
      netDividend: 5.3,
      totalAvg: 7.3,
      rentalIncom: 5300,
      capital: 2000,
      totalReturn: 7300,
      totalExpect: 27300,
    },
    financial: {
      listPrice: 509600,
      transactionCosts: 50400,
      acquisitionCost: 5600000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 5600,
      grossYield: 10.3,
      grossRentPerYear: 721000,
      propertyInsureance: 97650,
      annualIncome: 371000,
    },
    holders: 400,
    minimum_amount: 7000,
    listingData: [
      {
        price: 7000,
        ethereum: 3.88,
        expiration: 8,
        from: 'MikeloveNFT',
      },
    ],
    offerData: [
      {
        price: 6800,
        ethereum: 3.78,
        different: 2.9,
        expiration: 4,
        from: 'SuperNova',
      },

      {
        price: 6600,
        ethereum: 3.67,
        different: 5.7,
        expiration: 8,
        from: 'June27x',
      },
      {
        price: 6400,
        ethereum: 3.58,
        different: 8.6,
        expiration: 9,
        from: 'Gohard',
      },
      {
        price: 6200,
        ethereum: 3.44,
        different: 11.4,
        expiration: 10,
        from: 'SuperNova',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '7000',
        date: 2,
        from: 'KevinCranel',
        to: 'MikelovesNFT',
      },
      {
        event: 'Sale',
        price: '6400',
        date: 2,
        from: 'SuperNova',
        to: 'KevinCranel',
      },
      {
        event: 'Sale',
        price: '5800',
        date: 2,
        from: 'Gohard',
        to: 'SuperNova',
      },
      {
        event: 'Mint',
        price: '$5,600 x 1,000',
        date: 1,
        from: 'Null address',
        to: 'Multiple Accounts',
      },
    ],
  },
  {
    id: 21,
    feet: 1793,
    img: '/assets/inbox/img_08.png',
    type: 'Rental Property',
    startPrice: 5700,
    estatePrice: 5700000,
    name: 'Beautiful 5-Bedroom Villa near the beach',
    location: 'Cannes, France',
    homeState: 'Rented',
    country: "France",
    beds: 5,
    avgPrice: 5400,
    propertyPrice: 5700000,
    developer: "Titan Real Estate Corp.",
    propertyManager: "Ben & Chill",
    state1: {
      owners: 520,
      nfts: 1000,
      views: 11.2,
      fav: 6200,
    },
    state: {
      expected: 36,
      yeld: 5.2,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_9.png',
    propertyType: 0,
    howmuch: {
      netDividend: 5.2,
      totalAvg: 7.2,
      rentalIncom: 5200,
      capital: 2000,
      totalReturn: 7200,
      totalExpect: 27200,
    },
    financial: {
      listPrice: 4550000,
      transactionCosts: 450000,
      acquisitionCost: 5000000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 5000,
      grossYield: 10.2,
      grossRentPerYear: 581400,
      propertyInsureance: 81510,
      annualIncome: 296,
    },
    holders: 520,
    minimum_amount: 5700,
    listingData: [
      {
        price: 5700,
        ethereum: 3.17,
        expiration: 4,
        from: 'SuperNova',
      },
    ],
    offerData: [
      {
        price: 5500,
        ethereum: 3.06,
        different: 3.5,
        expiration: 4,
        from: 'MoneyMaster_',
      },

      {
        price: 5400,
        ethereum: 3.0,
        different: 5.3,
        expiration: 8,
        from: 'June27x',
      },
      {
        price: 5300,
        ethereum: 2.94,
        different: 7,
        expiration: 9,
        from: 'MikelovesNFT',
      },
      {
        price: 5200,
        ethereum: 2.89,
        different: 8.8,
        expiration: 10,
        from: 'KevinCranel',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '7000',
        date: 2,
        from: 'Gohard',
        to: 'SuperNova',
      },
      {
        event: 'Mint',
        price: '$5,000 x 1,000',
        date: 1,
        from: 'Null address',
        to: 'Multiple Accounts',
      },
    ],
  },
  {
    id: 22,
    feet: 2990,
    img: '/assets/images/unsplash_IYfp2Ixe9nM.png',
    type: 'Rental Property',
    startPrice: 5200,
    estatePrice: 5200000,
    name: 'Beautiful 5-Bedroom Villa with an Incredible View',
    location: 'Los Angeles, United States of America',
    homeState: 'Rented',
    beds: 5,
    avgPrice: 500,
    propertyPrice: 5200000,
    developer: "Titan Real Estate Corp.",
    country: "United States of America",
    propertyManager: "Ben & Chill",
    state1: {
      owners: 800,
      nfts: 1000,
      views: 5.8,
      fav: 280,
    },
    state: {
      expected: 49,
      yeld: 7.79,
      frequency: 'Monthly',
    },
    map: '/assets/map/map_01.png',
    propertyType: 0,
    howmuch: {
      netDividend: 7.79,
      totalAvg: 9.79,
      rentalIncom: 7790,
      capital: 2000,
      totalReturn: 9790,
      totalExpect: 29790,
    },
    financial: {
      listPrice: 4100000,
      transactionCosts: 400000,
      acquisitionCost: 4500000,
      nftNumber: 1000,
      nftValue: '1/1000',
      mintPrice: 4500,
      grossYield: 12.79,
      grossRentPerYear: 655080,
      propertyInsureance: 20722,
      annualIncome: 405080,
    },
    holders: 800,
    minimum_amount: 5200,
    listingData: [
      {
        price: 5200,
        ethereum: 2.88,
        expiration: 7,
        from: 'Gohard',
      },
    ],
    offerData: [
      {
        price: 5100,
        ethereum: 2.83,
        different: 1.9,
        expiration: 4,
        from: 'SuperNova',
      },

      {
        price: 5000,
        ethereum: 2.77,
        different: 3.8,
        expiration: 8,
        from: 'MikelovesNFT',
      },
      {
        price: 4900,
        ethereum: 2.72,
        different: 5.7,
        expiration: 9,
        from: 'June24x',
      },
      {
        price: 4800,
        ethereum: 2.66,
        different: 7.7,
        expiration: 10,
        from: 'KevinCranel',
      },
    ],
    activityData: [
      {
        event: 'Sale',
        price: '5,000',
        date: 2,
        from: 'MoneyMaster_',
        to: 'Gohard',
      },
      {
        event: 'Mint',
        price: '4,500 x 1,000',
        date: 2,
        from: 'NullAddress',
        to: 'Multiple Accounts',
      },
    ],
  },
];

const data = [
  {
    name: 'Jan',
    avg: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Feb',
    avg: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Mar',
    avg: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Apr',
    avg: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'May',
    avg: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Jun',
    avg: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Jul',
    avg: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Aug',
    avg: 3590,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'sep',
    avg: 3790,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Oct',
    avg: 3890,
    pv: 4300,
    amt: 2100,
  },
];

// const data = [ 'Jan',  'Feb', 'Mar',  'Apr', 'May',  'Jun',  'Jul', 'Aug', 'sep', 'Oct',];
const ProductDetail = () => {
  const classes = useStyles();
  const location = useLocation();
  const tokenID = location.pathname.split('/').pop();
  const [totalViews, setTotalViews] = useState(0);
  const [mintCount, setMintCount] = useState(1);
  const decreaseHandle = () => {
    getEthPrice();
    if (mintCount > 1) {
      setMintCount(mintCount - 1);
    }
  };
  const increaseHandle = () => {
    getEthPrice();
    if (mintCount < 10) {
      setMintCount(mintCount + 1);
    }
  };
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showMake, setShowMake] = useState(false);
  const [showAuction, setShowAucition] = useState(false);
  const [showShare, setShowShare] = useState(false);

  const [isFavorite, setIsFavorite] = useState(false);

  const { save } = useNewMoralisObject("Views");
  const Web3Api = useMoralisWeb3Api()
  const { isWeb3Enabled, enableWeb3, isInitialized, initialize, isInitializing, isWeb3EnableLoading, isAuthenticated } = useMoralis();
  const [propertyData, setPropertyData] = useState<any>(myData[tokenID])

  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);
  const { fetch } = useMoralisQuery(
    "Views",
    (query) => query.equalTo("tokenId", tokenID),
    [],
    { autoFetch: false }
  );

  const saveObject = async () => {
    const data = {
      tokenId: tokenID,
      views: 1,

    };

    save(data, {
      onSuccess: (views) => {
        // Execute any logic that should take place after the object is saved.

        setTotalViews(1);

      },
      onError: (error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Moralis.Error with an error code and message.
        alert("Failed to create new object, with error code: " + error.message);
      },
    });
  };
  const fetchViews = () => {
    fetch({
      onSuccess: (views) => {
        // The object was retrieved successfully.
        console.log(views)
        if (views.length > 0) {
          views[0].increment("views");
          views[0].save();
          setTotalViews(views[0].get("views"));

        } else {
          saveObject();
        }
      },
      onError: (error) => {
        // The object was not retrieved successfully.
        // error is a Moralis.Error with an error code and message.
      },
    });
  };

  const [loading, setLoading] = useState(false);


  const getProperty = useCallback(async () => {
    setLoading(true)
    await Web3Api.token.getTokenIdOwners({
      chain: currentChain,
      address: metropolyContract,
      token_id: tokenID
    }).then(async (total) => {
      await Web3Api.token.getTokenIdMetadata({
        chain: currentChain,
        address: metropolyContract,
        token_id: tokenID
      }).then(async (data) => {
        let property: any = {};
        let metadata = JSON.parse(data.metadata)
        property = {
          id: data.token_id,
          img: metadata.image,
          name: metadata.name,
          description: metadata.description,
          homeState: "Rented",
          avgPrice: 500,
          amount: data.amount,
          amenities: [],
          state1: {
            owners: total.total,
            nfts: data.amount,
            views: 5.8,
            fav: 280,
          },
          state: {
          },
          map: '/assets/map/map_01.png',
          propertyType: 0,
          howmuch: {
            netDividend: 7.79,
            totalAvg: 9.79,
            rentalIncom: 7790,
            capital: 2000,
            totalReturn: 9790,
            totalExpect: 29790,
          },
          financial: {
            listPrice: 4100000,
            transactionCosts: 400000,
            acquisitionCost: 4500000,
            nftNumber: 1000,
            nftValue: '1/1000',
            mintPrice: 4500,
            grossYield: 12.79,
            grossRentPerYear: 655080,
            propertyInsureance: 20722,
            annualIncome: 405080,
          },
          holders: 800,
          minimum_amount: 5200,
          listingData: [
            {
              price: 5200,
              ethereum: 2.88,
              expiration: 7,
              from: 'Gohard',
            },
          ],
          offerData: [
            {
              price: 5100,
              ethereum: 2.83,
              different: 1.9,
              expiration: 4,
              from: 'SuperNova',
            },

            {
              price: 5000,
              ethereum: 2.77,
              different: 3.8,
              expiration: 8,
              from: 'MikelovesNFT',
            },
            {
              price: 4900,
              ethereum: 2.72,
              different: 5.7,
              expiration: 9,
              from: 'June24x',
            },
            {
              price: 4800,
              ethereum: 2.66,
              different: 7.7,
              expiration: 10,
              from: 'KevinCranel',
            },
          ],
          activityData: [
            {
              event: 'Sale',
              price: '5,000',
              date: 2,
              from: 'MoneyMaster_',
              to: 'Gohard',
            },
            {
              event: 'Mint',
              price: '4,500 x 1,000',
              date: 1,
              from: 'NullAddress',
              to: 'Multiple Accounts',
            },
          ],

        }

        for (let i = 0; i < metadata.attributes.length; i++) {
          switch (metadata.attributes[i].trait_type) {
            case "Property Price": {
              property.propertyPrice = metadata.attributes[i].value * 1000
              let price = parseInt(metadata.attributes[i].value.replaceAll(',', ''));
              property.startPrice = price
              break;
            }
            case "Property Type": {
              property.type = metadata.attributes[i].value
              break;
            }
            case "Bedroom": {
              property.beds = metadata.attributes[i].value
              break;
            }
            case "SQFT": {
              property.feet = metadata.attributes[i].value
              break;
            }
            case "Dividend Yield": {
              property.howmuch.netDividend = metadata.attributes[i].value
              property.state.yeld = metadata.attributes[i].value
              break;
            }
            case "Expected Annual Appreciation": {
              property.state.expected = metadata.attributes[i].value
              break;
            }
            case "Total Average Annualized Return": {
              property.howmuch.totalAvg = metadata.attributes[i].value
              break;
            }
            case "Total Return after 5 years": {
              property.howmuch.totalReturn = metadata.attributes[i].value
              break;
            }
            case "Payment": {
              property.payment = metadata.attributes[i].value
              break;
            }
            case "Dividends Frequency": {
              property.state.frequency = metadata.attributes[i].value
              break;
            }
            case "Location": {
              property.location = metadata.attributes[i].value
              break;
            }
            case "Developer": {
              property.developer = metadata.attributes[i].value
              break;
            }
            case "Property Manager": {
              property.propertyManager = metadata.attributes[i].value
              break;
            }
            case "Amenities": {
              property.amenities.push({
                value: metadata.attributes[i].value
              })
              break;
            }
            default: { }
          }
        }
        if (property) {
          // setPropertyData(property)
          setPropertyData(myData[tokenID])
          setLoading(false)
        }
        // setIsFetching(true)
        return;
      }).then(async () => {
        let res = await fetchViews()
        // if(fetchViews){

        // }
      })
    }).catch((e) => {

      setPropertyData(myData[tokenID])
      setLoading(false)
    })
  }, [])



  useEffect(() => {
    data[6].avg = propertyData?.avgPrice || 0;
    getEthPrice();
  }, [tokenID]);

  const [ethPrice, setEthPrice] = useState(1);
  const getEthPrice = useCallback(async () => {
    // const resultInUSD = await Web3Api.token.getTokenPrice({
    //   address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
    // })

    await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')
      .then(function (response) {
        // handle success
        setEthPrice(response.data.USD);

      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }, [Web3Api.token, ethPrice])


  useEffect(() => {
    (async () => {
      try {
        if (isInitializing || isWeb3EnableLoading)
        return () => { };
        if (!isInitialized && !isInitializing) {
          initialize({
            appId: "F254vsO3ffYAne6sff288HKXOjBrUvqhzhDAjxpn",
            serverUrl: "https://bzgdtzqlihki.usemoralis.com:2053/server"
          });
        }
        if (isInitialized && !isWeb3Enabled && !isWeb3EnableLoading) {
          // try {

          //   const wc = window as WindowChain;
          //   const provider = wc.ethereum;
          //   if (window.localStorage.walletconnect) {
          //     console.log("walletconnect")
          //   } else
          //     if (provider) {
          //       await enableWeb3()
          //     }
          // } catch (e) {
          //   console.log(e)
          // }

          try {
            const wc = window as WindowChain;
            if (window.localStorage.walletconnect) {
              await enableWeb3({
                provider: "walletconnect"
              })
            } else
              if (wc.ethereum) {
                await enableWeb3()

              }
          } catch (e) {
            console.log(e)

          }
        }
        if (isInitialized && isWeb3Enabled) {
          await getProperty();
          await getEthPrice();
          console.log("enabled")
        }

      } catch (error) {
        await getProperty();
        console.log("error", error);
      }
    }
    )()
  }, [isAuthenticated])

  return (
    <>
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={`${classes.wrapper} ${classes.height30}`}>
            <div className={classes.left}>
              {
                !loading ?
                  <>
                    <img
                      className={classes.productImg}
                      src={propertyData?.img}
                      alt=""
                    />
                    <div className="topLeft">
                      <span>{propertyData?.type}</span>
                    </div>
                    <div className="topRight" onClick={() => setIsFavorite(!isFavorite)}>
                      <span>
                        {isFavorite ? (
                          <img src="/assets/images/favorite_icon_03.svg" alt="" />
                        ) : (
                          <img src="/assets/images/favorite_icon_01.svg" alt="" />
                        )}
                      </span>
                    </div>
                    <div className="bottomRight">
                      <span>{numberToString(propertyData?.feet || 0)} sqft</span>
                      <span>
                        <img src="/assets/images/bed_icon.svg" alt="" />
                        <p>
                          {propertyData?.beds} {propertyData?.beds === 1 ? 'Bed' : 'Beds'}
                        </p>
                      </span>
                    </div></>
                  : <>
                    <Skeleton
                      className={classes.productImg}
                      variant="rectangular" height={430} />
                  </>
              }
              {propertyData?.propertyType === 2 && (
                <div className="timer-div">
                  <p>Auction Ends in</p>
                  <TextTimer deadLine={propertyData?.deadline} setTimeEnd={() => { }} />
                </div>
              )}
            </div>
            <div className={classes.right}>
              <div className={classes.row}>
                {
                  !loading ? <>
                    <div className={classes.room}>
                      <img src={'/assets/images/bed_icon.svg'} alt="" />
                      {propertyData?.beds} Beds | {numberToString(propertyData?.feet || 0)} sqft
                    </div>
                    <div className="share">
                      <button onClick={() => setShowShare(true)}>
                        <img src="/assets/images/share_icon.svg" alt="" />
                      </button>
                    </div>
                  </>
                    : <>
                      <Skeleton className={classes.room} height={50} width={130} />
                    </>
                }
              </div>
              {
                !loading ?
                  <>
                    <h1 className={classes.title}>{propertyData?.name}</h1>
                  </>
                  :
                  <>
                    <h1 className={classes.title}>
                      <Skeleton variant="text" sx={{
                        fontSize: '2vw',
                      }} width={400} height={50} />
                    </h1>
                  </>
              }
              <div className={classes.row}>

                {
                  !loading ? <>
                    {propertyData?.propertyType === 1 ? (
                      <>
                        <ul>
                          <li>
                            <img src="/assets/images/cube_icon.svg" alt="" />
                            {propertyData?.state1?.nfts} Total NFTs
                          </li>

                          <li>
                            <img src="/assets/images/eye_icon.svg" alt="" />
                            {totalViews}k Views
                          </li>

                          <li>
                            <img src="/assets/images/favorite_icon_02.svg" alt="" />
                            {propertyData?.state1?.fav} Favorites
                          </li>
                        </ul>
                      </>
                    ) : (
                      <>
                        <ul>
                          <li>
                            <img src="/assets/images/users_icon.svg" alt="" />
                            {propertyData?.state1?.owners} Owners
                          </li>

                          <li>
                            <img src="/assets/images/cube_icon.svg" alt="" />
                            {propertyData?.state1?.nfts} Total NFTs
                          </li>

                          <li>
                            <img src="/assets/images/eye_icon.svg" alt="" />
                            {totalViews} Views
                          </li>

                          <li>
                            <img src="/assets/images/favorite_icon_02.svg" alt="" />
                            {propertyData?.state1?.fav} Favorites
                          </li>
                        </ul>
                      </>
                    )}
                  </> : <>

                    <Skeleton className={classes.title} variant="text" sx={{
                      fontSize: '2vw',
                    }} width={500} height={30} />
                  </>
                }
              </div>
              <div className={classes.row} style={{ marginBottom: 0 }}>
                {
                  !loading ? <>
                    <p className="location">
                      <img src="/assets/images/tag.svg" alt="" />
                      {propertyData?.location}
                    </p>
                  </> : <>

                    <Skeleton className={"location"} variant="text" sx={{
                      fontSize: '2vw',
                    }} width={200} height={30} />
                  </>
                }
              </div>
              {
                !loading ? <></> : <></>
              }
              {
                !loading ? <>
                  <div className={classes.state}>
                    <ul>
                      <li>
                        <p>Property Price</p>
                        <h5>
                          {numberToString(propertyData?.propertyPrice || 0)} USD{' '}
                          <img src="/assets/images/apartment_icon.svg" alt="" />
                        </h5>
                      </li>

                      <li>
                        <p>Dividend Yield</p>
                        <h5>
                          {propertyData?.howmuch.netDividend}% <img src="/assets/images/chat_icon.svg" alt="" />
                        </h5>
                      </li>

                      <li>
                        <p>5 YR Expected return</p>
                        <h5>
                          {propertyData?.state.expected}% <img src="/assets/images/poker_icon.svg" alt="" />
                        </h5>
                      </li>

                      <li>
                        <p>Value of One NFT</p>
                        <h5>
                          10/1000 <img src="/assets/images/tree_icon.svg" alt="" />
                        </h5>
                      </li>
                    </ul>
                  </div>
                </> : <>

                  <div className={classes.state}>
                    <ul>
                      <li>
                        <p>Property Price</p>
                        <h5>
                          <Skeleton className={classes.title} variant="text" sx={{
                            fontSize: '2vw',
                          }} width={200} height={30} />
                        </h5>
                      </li>

                      <li>
                        <p>Dividend Yield</p>
                        <h5>
                          <Skeleton className={classes.title} variant="text" sx={{
                            fontSize: '2vw',
                          }} width={200} height={30} />
                        </h5>
                      </li>

                      <li>
                        <p>5 YR expected return</p>
                        <h5>
                          <Skeleton className={classes.title} variant="text" sx={{
                            fontSize: '2vw',
                          }} width={200} height={30} />
                        </h5>
                      </li>

                      <li>
                        <p>Value of One NFT</p>
                        <h5>
                          <Skeleton className={classes.title} variant="text" sx={{
                            fontSize: '2vw',
                          }} width={200} height={30} />
                        </h5>
                      </li>
                    </ul>
                  </div>
                </>
              }

            </div>
          </div>
          {!loading ? <>
            <div className={classes.wrapper} style={{ flexDirection: isMobileOrTablet ? 'column-reverse' : 'row' }}>
              <div className={classes.left}>
                {propertyData?.propertyType === 1 ? (
                  <>
                    <div className={`${classes.row}`}>
                      <h3>Timeline</h3>
                    </div>
                    <div className="time-line">
                      <div className="node">
                        <div className="circle"></div>
                        <div className="text">
                          <h5>Latest Closing Date </h5>
                          <p>October 15th, 2022</p>
                        </div>
                      </div>
                      <div className="node">
                        <div className="circle"></div>
                        <div className="text">
                          <h5>NFT Distribution</h5>
                          <p>Immediately after all NFTs have been minted</p>
                        </div>
                      </div>
                      <div className="node">
                        <div className="circle"></div>
                        <div className="text">
                          <h5>Expected First Rental Payment</h5>
                          <p>December 1st, 2022</p>
                        </div>
                      </div>
                      <div className="desc">
                        Each step may occur earlier, but not later than, the aforementioned dates.
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={`${classes.row} `}>
                      <h3 className={`${classes.borderBottom}`}>Price history</h3>
                    </div>

                    <div className="chart-div">
                      <p>
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <ellipse cx="4.96777" cy="5.22634" rx="4.5" ry="4.49538" fill="#2892F3" />
                        </svg>
                        All Time Avg. Price <strong>USD {numberToString(propertyData?.startPrice || 0)}</strong>{' '}
                      </p>
                      <div className="chart">
                        <ResponsiveContainer width="100%" height="100%">
                          <AreaChart
                            width={500}
                            height={400}
                            data={data}
                            margin={{
                              top: 10,
                              right: 0,
                              left: 0,
                              bottom: 0,
                            }}
                          >
                            <defs>
                              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#2892F3" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#2892F3" stopOpacity={0} />
                              </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                            {isMobileOrTablet ? (
                              <XAxis
                                interval={0}
                                dataKey="name"
                                tickCount={10}
                                ticks={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'sep', 'Oct']}
                              />
                            ) : (
                              <XAxis
                                interval={'preserveStartEnd'}
                                dataKey="name"
                                tickCount={10}
                                ticks={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'sep', 'Oct']}
                              />
                            )}
                            {/* <XAxis dataKey="name" ticks = {[ 'Jan',  'Feb', 'Mar',  'Apr', 'May',  'Jun',  'Jul', 'Aug', 'sep', 'Oct',]}/> */}
                            {/* <YAxis /> */}
                            {!isMobileOrTablet && <Tooltip />}
                            <Area type="linear" dataKey="avg" stroke="#2892F3" fillOpacity={1} fill="url(#colorUv)" />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className={classes.right}>
                {propertyData?.propertyType === 1 ? (
                  <>
                    <div className="progress">
                      <h5>{propertyData?.minted} of 1000 NFTs Minted</h5>
                      <div className="progressBar">
                        <div className="track" style={{ width: `${(propertyData?.minted / 1000) * 100}%` }}></div>
                      </div>
                    </div>
                    <p className={classes.smalTxt}>Price in USD</p>
                    <div className={classes.row}>
                      <span className="price">
                        <h1>$ {numberToString(propertyData?.startPrice * mintCount)}</h1>
                        <h5>({((propertyData?.startPrice * mintCount) / 1750).toFixed(2)} ETH)</h5>
                      </span>

                      <span className="count">
                        <button onClick={decreaseHandle}>-</button>
                        <input type="number" value={mintCount} onChange={e => setMintCount(parseFloat(e.target.value))} />
                        <button onClick={increaseHandle}>+</button>
                      </span>
                    </div>
                  </>
                ) : propertyData?.propertyType === 2 ? (
                  <>
                    <div className={classes.row}>
                      <div className="row">
                        <img src="/assets/images/place_icon_01.svg" alt="" />
                        <span className="col">
                          <p className={classes.smalTxt}>Price in USD</p>
                          <h1>$ {numberToString(propertyData?.startPrice)}</h1>
                        </span>
                      </div>
                    </div>
                    <div className={classes.row}>
                      <div className="row">
                        <img src="/assets/logo_icon.png" alt="" />
                        <span className="col">
                          <h2>Current Real Estate Value $10,000</h2>
                          <p className={classes.smalTxt}>
                            The highest bid is 20% below market price. You can find additional information here
                          </p>
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <p className={classes.current}>Current Price</p>
                    <div className={classes.row}>
                      <span className="price">
                        <h1>$ {numberToString(propertyData?.startPrice * mintCount)}</h1>
                        <h5>({((propertyData?.startPrice * mintCount) / ethPrice).toFixed(2)} ETH)</h5>
                      </span>

                      <span className="count">
                        <button onClick={decreaseHandle}>-</button>
                        <input type="number" value={mintCount} onChange={e => setMintCount(parseFloat(e.target.value))} />
                        <button onClick={increaseHandle}>+</button>
                      </span>
                    </div>
                  </>
                )}

                <div className={classes.row}>
                  {propertyData?.propertyType === 1 ? (
                    <div className="btns">
                      <FilledButton
                        label={'MINT NOW'}
                        iconPosition="start"
                        icon={<i className="fas fa-wallet"></i>}
                        handleClick={() => setShowBuyModal(true)}
                      />
                    </div>
                  ) : propertyData?.propertyType === 2 ? (
                    <div className="btns">
                      <FilledButton
                        label={'Place Bid'}
                        iconPosition="start"
                        icon={<img src="/assets/images/Auction 2 1.png" alt="" />}
                        handleClick={() => setShowAucition(true)}
                      />
                    </div>
                  ) : (
                    <div className="btns">
                      <FilledButton
                        label={'Buy Now'}
                        iconPosition="start"
                        icon={<i className="fas fa-wallet"></i>}
                        handleClick={() => setShowBuyModal(true)}
                      />
                      <OutlinedButton
                        label={'Make Offer'}
                        iconPosition="start"
                        icon={<i className="fas fa-tag"></i>}
                        handleClick={() => setShowMake(true)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {propertyData?.propertyType === 0 && (
              <>
                <div className={classes.wrapper} style={{ padding: isMobileOrTablet ? '0px 20px' : '0px 20px' }}>
                  <ListingTable listings={propertyData?.listingData} />
                </div>

                <div className={classes.wrapper} style={{ padding: isMobileOrTablet ? '0px 20px' : '0px 20px' }}>
                  <OfferTable listings={propertyData?.offerData} />
                </div>

                <div className={classes.wrapper} style={{ padding: isMobileOrTablet ? '0px 20px' : '0px 20px' }}>
                  <ActivityTable listings={propertyData?.activityData} />
                </div>
              </>
            )}
            {propertyData?.propertyType === 2 && (
              <>
                <div className={classes.wrapper} style={{ padding: isMobileOrTablet ? '0px 20px' : '0px 20px' }}>
                  <BidHistoryTable listings={propertyData?.bidsData} />
                </div>

                <div className={classes.wrapper} style={{ padding: isMobileOrTablet ? '0px 20px' : '0px 20px' }}>
                  <ActivityTable listings={propertyData?.activityData} />
                </div>
              </>
            )}

            <div className={classes.wrapper} style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 0 }}>
              <HowMuch data={propertyData} setShowBuy={setShowBuyModal} />
            </div>
            {/* <div className={classes.wrapper} style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 0 }}>
            <PropertyInfo data={myData[tokenID]} />
          </div> */}
            <div className={classes.wrapper}>
              {propertyData ?
                <AboutProperty propertyData={propertyData.amenities} productDataDesc={propertyData.description} />
                :
                <></>}
            </div>
            <div className={classes.wrapper}>
              <FinancialDetails data={propertyData} />
            </div>

            <div className={classes.wrapper} style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 0 }}>
              <PropertyInfo data={propertyData} />
            </div>

            <div className={classes.wrapper} style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Documents />
            </div>

            <div className={classes.wrapper}>
              <Location map={propertyData?.map} />
            </div>
            <div className={classes.wrapper} style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 0 }}>
              <DeveloperManager data={propertyData} />
            </div>
          </> : <></>
          }

        </div>
      </div>
      <BuyModal
        showModal={showBuyModal}
        setShowModal={setShowBuyModal}
        item={propertyData}
        price={propertyData?.startPrice * mintCount}
      />
      <MakeModal showModal={showMake} setShowModal={setShowMake} />
      <AuctionModal showModal={showAuction} setShowModal={setShowAucition} />
      <ShareModal showModal={showShare} setShowModal={setShowShare} />
    </>
  );
};

ProductDetail.propTypes = {};

ProductDetail.defaultProps = {};

export default ProductDetail;
