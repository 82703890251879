import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/Layout/Container';
import { useHistory } from 'react-router-dom';
import OutlinedButton from 'components/Buttons/OutlinedButton';
import FilledButton from 'components/Buttons/FilledButton';
import DiscoverNewProperties from 'components/Dashboard/DiscoverNewProperties';
import MetropolyThusFar from 'components/Dashboard/MetropolyThusFar';
import Leaderboards from 'components/Dashboard/Leaderboards';
import PropertyDay from 'components/Dashboard/PropertyDay';
import TrendingNFTs from 'components/Dashboard/TrendingNFTs';
const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
      paddingTop: 76,
      textAlign: 'center',
    },
  },

  section: {
    position: 'relative',
    paddingTop: theme.spacing(0),
  },
  topSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
  },
  video: {
    width: '100%',
  },
  wrapper: {
    // height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: 70,
    // minHeight: '17vw',
    padding: 70,
    backgroundImage: `url("assets/images/home_banner.png")`,
    backgroundSize: 'cover',
    // backgroundSize: '100% 100%',
    borderRadius: 20,
    '@media screen and (max-width: 678px) and (orientation: portrait)': {
      flexDirection: 'column',
      padding: '30px 20px',
      backgroundSize: 'cover',
      backgroundPosition: '70%',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& h1': {
      color: '#fff',
      fontSize: 38,
      fontWeight: 700,
      fontFamily: "'Outfit', sans-serif",
      '@media screen and (max-width: 678px) and (orientation: portrait)': {
        fontSize: 30,
      },
    },
    '& p': {
      color: '#fff',
      fontSize: 20,
      lineHeight: 1.8,
      opacity: 0.7,
      fontFamily: "'Outfit', sans-serif",
      '@media screen and (max-width: 678px) and (orientation: portrait)': {
        fontSize: 10,
        opacity: 0.8,
        marginTop: 10,
      },
    },
    '& .btns': {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 30,
      '@media screen and (max-width: 678px) and (orientation: portrait)': {
        marginTop: 10,
      },
      '& button': {
        marginRight: 20,
        fontFamily: "'Outfit', sans-serif",
        width: 200,
        height: 40,
        '@media screen and (max-width: 678px) and (orientation: portrait)': {
          fontSize: 12,
          padding: '8px 10px',
          margin: 10,
          width: 120,
        },
      },
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const goToPage = (path: string) => {
    history.push(path);
  };

  return (
    <>
      <Container className={classes.root}>
        <div className={classes.topSection}>
          <div className={classes.wrapper}>
            <h1>Buy Your First Real Estate in Seconds</h1>
            <p>The world’s first NFT marketplace backed by real-world properties</p>
            <div className="btns">
              <a href='https://presale.metropoly.io/'>
              <FilledButton color="white" label="JOIN PRESALE NOW"  />
              </a>
              <OutlinedButton color="white" label="How it works" handleClick={() => goToPage('/how_works')} />
            </div>
          </div>
        </div>
        <div className={classes.section}>
          <DiscoverNewProperties />
        </div>

        <div className={classes.section}>
          <MetropolyThusFar />
        </div>

        <div className={classes.section}>
          <Leaderboards />
        </div>
        <div className={classes.section}>
          <PropertyDay />
        </div>

        <div className={classes.section}>
          <TrendingNFTs />
        </div>
      </Container>
    </>
  );
};

export default Home;
