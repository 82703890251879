import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/Layout/Container';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 15,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
      paddingTop: 0,
      textAlign: 'center',
    },
  },

  section: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 20,
    '& p': {
      color: '#000',
      fontSize: 14,
      width: '100%',
      marginBottom: 20,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 12,
        textAlign: 'left',
      },
    },
    '& h2': {
      color: '#000',
      fontSize: 20,
      width: '100%',
      marginBottom: 20,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 18,
        textAlign: 'left',
      },
    },
  },
  topSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(7),
  },
  video: {
    width: '100%',
  },
  banner: {
    height: '100%',
    minHeight: '16vw',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 70,
    padding: 70,
    backgroundImage: `url("assets/images/home_banner.png")`,
    backgroundSize: '100% 100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      paddingTop: 100,
      backgroundSize: 'cover',
      backgroundPosition: '90%',
    },
    '& h1': {
      color: '#fff',
      fontSize: 40,
      fontWeight: 700,
      fontFamily: "'Outfit', sans-serif",
      width: '100%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 30,
      },
    },
  },
}));

const TermsOfService = () => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.root}>
        <div className={classes.topSection}>
          <div className={classes.banner}>
            <h1>Terms Of Service</h1>
          </div>
        </div>
        <div className={classes.section}>
          <p>
            These website terms of use set out the terms between you and us under which you may access our website
            https://metropoly.io (“our site”). These website terms of use apply to all users of, and visitors to, our
            site. By using our site, you are accepting and agreeing to be bound by these website terms of use, our
            Cookie Notice and our Privacy Policy. If you do not agree to the terms of these documents, please refrain
            from using our site immediately. Our site is operated by Metropoly Ltd. (“we or us”). We reserve the right
            to modify or amend these website terms of use, our site or any content on our site from time to time,
            including for security, legal or regulatory reasons, or to reflect updates or changes to the services or
            functionality of our site. You are advised to check our site and these website terms of use periodically to
            ensure that you are aware of and are complying with the current version. Changes are binding on users of our
            site and will take effect immediately from posting of the revised documentation on our site. You agree to be
            bound by such variation and your continued use of our site shall constitute your acceptance of such
            variation. If you elect to become a seller or investor with us, you must comply with our Seller Terms or
            Investor Terms, as applicable. These website terms of use should be read alongside and shall apply in
            addition to our Seller Terms or our Investor Terms (all made available during our registration process).{' '}
          </p>
          <h2>Prohibited Uses</h2>
          <p>
            You may use our site only for lawful purposes. You may not: use our site in any way that breaches any
            applicable local, national or international law or regulation; use our site in any way that is unlawful or
            fraudulent, or has any unlawful or fraudulent purpose or effect;{' '}
          </p>
          <p>
            use our site for the purpose of harming or attempting to harm minors in any way; use our site to send,
            knowingly receive, upload, download, use or re-use any material which does not comply with our content
            standards as set out below; use our site to transmit, or procure the sending of, any unsolicited or
            unauthorized advertising or promotional material or any other form of similar solicitation (spam); or use
            our site to knowingly transmit any data, send or upload any material that contains viruses, Trojan horses,
            worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code
            designed to adversely affect the operation of any computer software or hardware;{' '}
          </p>
          <p>
            use our site in any manner that could, or could reasonably be expected to, damage or overburden any of our
            servers, or any network connected to any of our servers; use our site in any manner that would, or could
            reasonably be expected to, interfere with any other party’s use of the Website or which is, or which is
            likely to be, in contravention of any applicable laws or any party’s rights or legal entitlements under any
            applicable laws;{' '}
          </p>
          <p>
            use any robot, spider, screen scraper, data aggregation tool or other automatic device, routine or process
            (Automated Process) to process, monitor, copy or extract any web pages on our site, or any of the
            information, content or data contained within or accessible through our site, without our prior written
            permission; use any Automated Process to aggregate or combine information, content or data contained within
            or accessible through our site with information, content or data accessible via or sourced from any third
            party;{' '}
          </p>
          <p>
            use any Automated Process to interfere or attempt to interfere with the proper working of the Website or any
            transaction or process being conducted on or through our site; post upload to, transmit, distribute, store,
            create or otherwise publish or make available through our site any content that is false, misleading,
            erroneous, fraudulent or is otherwise likely to cause confusion to users of our site or content which is
            unlawful, libellous, defamatory, offensive to any religious or moral groups, obscene, pornographic,
            indecent, lewd, suggestive, harassing, threatening, invasive of privacy or publicity rights, abusive,
            inflammatory, or otherwise objectionable; take any action that imposes an unreasonable or disproportionately
            large load on the infrastructure of or bandwidth connecting to our site; attempt to gain unauthorized access
            to our site, the server on which our site is stored or any server, computer or database connected to our
            site; attempt to attack our site via a denial-of-service attack or a distributed denial-of-service attack;{' '}
          </p>
          <p>
            reverse engineer, reverse assemble, decompile, or otherwise attempt to discover source code or other
            arithmetic formula or processes in respect of the software underlying the infrastructure and processes
            associated with our site; or copy, reproduce, alter, modify, create derivative works from, or publicly
            display, any part of any content from our site without our prior written permission.{' '}
          </p>
          <p>
            Your use of our site in violation of these website terms of use may result in, among other things,
            termination or suspension of your rights to use our site and we may, in our sole discretion, report a breach
            of any such obligation which is also a breach of the applicable law to the relevant law enforcement
            authorities and will co-operate with those authorities by disclosing your identity to them. In the event of
            such a breach, your right to use our site will cease immediately.{' '}
          </p>
          <h2>Content Standards</h2>
          <p>
            These content standards apply to all material which you contribute to our site (contributions), and to any
            interactive services associated with it. You must comply with the spirit and the letter of the following
            standards. The standards apply to each part of any contribution as well as to its whole. Contributions must:
            be accurate (where they state facts); be genuinely held (where they state opinions); and comply with
            applicable law in any country from which they are posted Contributions must not: contain any material which
            is defamatory of any person; contain any material which is obscene, offensive, hateful or inflammatory;
            promote sexually explicit material; promote violence; promote discrimination based on race, sex, religion,
            nationality, disability, sexual orientation or age; infringe any copyright, database right or trade mark of
            any other person; be likely to deceive any person; be made in breach of any legal duty owed to a third
            party, such as a contractual duty or a duty of confidence; promote any illegal activity; be threatening,
            abuse or invade another’s privacy, or cause annoyance, inconvenience or needless anxiety; be likely to
            harass, upset, embarrass, alarm or annoy any other person; be used to impersonate any person, or to
            misrepresent your identity or affiliation with any person; give the impression that they emanate from us, if
            this is not the case; or advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or
            computer misuse. {' '}
          </p>
          <h2>Disclaimer and Risk Warning</h2>
          <p>
            Our site is a marketplace that allows you to invest directly in
            a property provided by third parties. As a marketplace, we do not own or sell the properties or investment
            opportunities which are displayed on our site. You acknowledge and agree that no content published on our
            site constitutes a recommendation that any particular investment, portfolio of investments, transaction, or
            investment strategy is suitable or advisable for you or any other specific person. You further understand
            that neither we nor any of the information providers, their affiliates nor any third parties will advise you
            personally concerning the nature, potential, advisability, value or suitability of any particular
            investment, portfolio of investments, transaction, investment strategy, or other matter. The content and
            material available on our site are for informational purposes only and are not intended to address your
            particular requirements. In particular, the content and material available on our site does not constitute
            any form of advice or recommendation by us, should not be regarded as an offer, solicitation, invitation or
            recommendation to buy or sell investments, securities or any other financial services, other than what is
            permitted under our DFSA license to operate a Property Investment Crowdfunding Platform, and is not intended
            to be relied upon by you in making (or refraining from making) any specific investment or other decisions.
            We recommend that you seek independent advice from a financial advisor before making any such decision. The
            value of investments and the income derived from them can fluctuate, and you may not necessarily recover the
            amount you invested. Past performance of an investment is not necessarily a guide to its future performance.
            It may be difficult for you to sell or value certain investments or to obtain reliable information about
            their value or the extent of the risks to which they are exposed.{' '}
          </p>
          <p>
            You acknowledge and agree that prices, descriptions and other financial data made available on our site is
            compiled from third party sources believed to be reliable, and where possible such data is verified by us as
            far as is commercially possible. Notwithstanding this, you acknowledge and agree that the calculations,
            profiles and data available on our site are made using such third-party data, and that neither such data nor
            such calculations are guaranteed by these sources, by us, nor by the information providers or any other
            person or entity and may not be complete or accurate. You acknowledge and agree that returns advertised by
            us on our site are indicative only and are subject to economic, business factors and other factors outside
            of our control and we do not guarantee that the returns advertised will be available to you at any point in
            time.{' '}
          </p>
          <p>
            Nothing included on our site constitutes an offer or solicitation to sell, or distribution of, investments
            and related services to anyone in any jurisdiction. From time to time, reference may be made on our site to
            prior investments or data we have published. These references may be selective or, may be partial. As
            markets change continuously, previously published information and data may not be current and should not be
            relied upon.{' '}
          </p>
          <h2>Disclaimers of Warranties</h2>
          <p>
            Our site is provided “as is,” without any express or implied warranties of any kind. We and our affiliates,
            agents, and licensors cannot and do not warrant the accuracy, reliability, quality or completeness of the
            information provided on our site nor that the services provided via our site will be timely, non-infringing,
            of merchantable quality or fit for a particular purpose, and we hereby disclaim any and all such express or
            implied warranties, except those that cannot be lawfully excluded. Although we make every effort to ensure
            that the services offered on our site are available consistently, the nature of the Internet means that we
            cannot guarantee our site’s availability. We do not warrant that the provision of our site or any part of it
            will be available, uninterrupted or error free, that defects in our site will be corrected, or that our site
            will be free of viruses or other harmful components. We will not be liable for any loss, damage, expense,
            costs, delays or other liability whatsoever (including without limitation any financial losses) which you
            may incur as a result of using our site, or as a result of the site or any part of it being unavailable at
            any time for whatever reason. This does not affect our liability for death or personal injury arising from
            our negligence, nor our liability for fraud or fraudulent misrepresentation, or any other liability which
            cannot be excluded or limited under applicable law.{' '}
          </p>
          <p>
            You are solely responsible for obtaining telecommunications access to our site and for any fees (such as
            internet service provider or airtime charges) relating to such access. Similarly, you are solely responsible
            for all equipment necessary for you to access our site and for implementing and maintaining antivirus
            software on your computer.{' '}
          </p>
          <p>
            Information transmitted via our site will pass over public telecommunications networks. We accept no
            liability if communications sent via our site are intercepted by third parties, delayed or incorrectly
            delivered or not delivered.{' '}
          </p>
          <h2>Means of Access</h2>
          <p>
            Our site is generally intended to be viewed by a conventional web browser with a screen resolution of 1024
            by 768 pixels or greater. Although you may use other means to access our site, be aware that our site may
            not appear accurately through other access methods. You must not access our site through devices or services
            that are designed to provide high-speed, automated, repeated access. Certain parts of our site are protected
            by passwords or require a login. You must not obtain or attempt to obtain unauthorized access to such parts
            of our site, or to any other protected materials or information, through any means not intentionally made
            available by us for your specific use.{' '}
          </p>
          <h2> Eligibility and Registration</h2>
          <p>
            In order to access our site, you must be a minimum of eighteen (18) years old (measured according to the
            Gregorian calendar) and have full legal capacity. You warrant, represent and undertake that you possess the
            legal right and experience, knowledge and ability to access and use our site in accordance with these
            website terms of use. In order to access our services and certain restricted parts of our site you are
            required to register as either an investor or seller. Please note that an application to register as both an
            investor and a business may be rejected. To become an investor or seller you will be required to follow a
            registration process. Firstly, you will be required to submit some basic details about yourself (including
            your name and email address) and shall be prompted to choose a unique username and password and may be asked
            to provide answers to certain security questions. These are the methods used by us to identify you and so
            you must always keep them secure. You will then be required at this point to submit detailed information
            about either yourself or the property you wish to sell, as applicable. We will also require you to provide
            proof of identity and proof of your current address, (or that of the relevant business’ directors, partners,
            or representatives as appropriate) to comply with our anti-money laundering obligations. Applications to
            become sellers and investors on our site will be subject to an approval process and may be accepted or
            rejected by us in our sole discretion. You warrant, represent, and undertake that the information you
            provide about yourself during the registration process is accurate, current and complete. Please see our
            Privacy Policy for further information on our processing of your personal data.{' '}
          </p>
          <h2>Your Login Details</h2>
          <p>
            Each time you log in to our site you will need to enter your email address and password and you may be
            required to answer one or more security questions. Your username and password are unique to you and are not
            transferable.{' '}
          </p>
          <p>
            You are responsible for all information and activity on our site by anyone using your username and password.
            Accordingly, you must take steps to protect the confidentiality of your username and password and you must
            notify us immediately if you become aware of any disclosure, loss, theft, or unauthorized use of the same.
            Notwithstanding the above, you: may not transfer or resell your username or password to any third party; and
            agree to notify us at contact@metropoly.io if you become aware of any possible unauthorized use(s) of your
            username or password or any possible breach of security, including loss, theft, or unauthorized disclosure
            of your username or password.{' '}
          </p>
          <p>
            If you authorize an employee, sub-contractor or agent to use your login details you will be responsible for
            their activity on our site. We reserve the right not to act on your instructions where we suspect that the
            person logged into your account is not you or we suspect illegal or fraudulent activity or unauthorized use.{' '}
          </p>
          <h2>Links to and from other sites</h2>
          <p>
            You may, through hypertext or other computer links, gain access from our site to websites operated by
            persons other than us. Such hyperlinks are provided for your reference and convenience only and are the
            exclusive responsibility of such website’s owners or operators. You acknowledge and agree that we do not
            endorse and are not responsible for the content or operation of such websites, and that we do not make any
            representations or warranties in relation to such websites, including without limitation that the content of
            such websites does not infringe the rights of any third parties or any applicable law. We exclude to the
            fullest extent permitted by law all liability that may arise in connection with or as a result of such
            external website material causing any damage, costs, injury or financial loss of any kind.{' '}
            Our site must not be framed on any other site, nor may you create a link to any part of our site. We reserve
            the right to withdraw linking permission without notice. We assume no responsibility for the use of, or
            inability to use, any third party software or other materials on the site and shall have no liability
            whatsoever to any person or entity for their use of, or inability to use, any such third party software or
            materials. </p>
          <h2>Limitation of liability and Indemnity</h2>
          <p> You use our site entirely at your own risk. Neither we nor
            any of our affiliates, agents, officers, employees, suppliers or licensors will be liable to you or anyone
            else for any: inaccuracy, delay, interruption in service, error or omission, regardless of cause or for any
            damages as a result; decision made or action taken or not taken by you in reliance on our site or its
            content; damages, including, without limitation, any direct, indirect, incidental, consequential, special,
            punitive, exemplary or similar damages, regardless of the theory of liability and even if advised of the
            possibility of such damages in advance;{' '}
            loss or injury resulting from use of the content of our site, in whole or part, whether caused by negligence
            or contingencies beyond our control in procuring, compiling, interpreting, reporting or delivering our site
            and its content or otherwise; losses, damages, liabilities, claims or expenses (including without limitation
            to legal costs and defense or settlement costs) whatsoever, whether direct, indirect or consequential,
            arising out of or referable to your use of our site or its content, howsoever caused, whether in contract,
            tort (including negligence), statute or otherwise; or loss resulting from a cause over which we do not have
            control.{' '}
          </p>
          <p>
            This does not affect our liability for death or personal injury arising from our negligence, nor our
            liability for fraud or fraudulent misrepresentation, or any other liability which cannot be excluded or
            limited under applicable law. Our total liability to you in connection with these website terms of use, and
            any other terms you may subscribe to in connection with your use of our site, shall not exceed AED 10,000.{' '}
          </p>
          <p>
            All exclusions and limitations of liability set out in these website terms of use shall apply to the fullest
            extent permissible under the applicable law. You shall indemnify us in full in respect of any liability
            incurred by us for any loss, cost, damage, or expense howsoever caused, or suffered by us as a result of
            your breach of these website terms of use. Where you use our site inappropriately or maliciously, you agree
            that you will indemnify us against any losses suffered by us or any third parties as a result of or in
            connection with such use.{' '}
          </p>
          <h2>Availability of the site</h2>
          <p>
            As electronic services are subject to interruption or breakdown, access to our site is offered on an “as is”
            and “as available” basis only.{' '}
          </p>
          <p>
            We reserve the right to limit the availability of our site to any person, geographic area or jurisdiction we
            so desire and/or to terminate your access to and use of our site, at any time and in our sole discretion. We
            may, at our sole discretion, impose limits or restrictions on the use you may make of our site. Further, for
            commercial, security, technical, maintenance, legal or regulatory reasons, or due to any breach of these
            website terms of use, we may withdraw our site or your access to our site, or suspend such access, at any
            time and without notice to you.</p>
          <h2>Intellectual property rights</h2>
          <p> All remarks, suggestions, ideas, materials or
            other information (excluding data) provided by you through our site will forever be our property. You
            acknowledge that you are responsible for any submissions provided through your username or password, and
            you, not us, have full responsibility for such submissions, including their accuracy, legality, reliability,
            appropriateness, originality and copyright. Notwithstanding the foregoing, we have the right to refuse to
            post, remove, edit or abridge any submission for any reason and to disclose any submission as we deem
            necessary, including without limitation to achieve compliance with any applicable law, regulation, legal
            process or governmental request, all in our sole discretion.{' '}
          </p>
          <p>
            We own all present and future copyright, registered and unregistered trademarks, design rights, unregistered
            designs, database rights and all other present and future intellectual property rights and rights in the
            nature of intellectual property rights existing in or in relation to our site.{' '}
          </p>
          <p>
            If and to the extent that any such intellectual property rights vest in you by operation of law or
            otherwise, you agree to do any and all such acts and execute any and all such documents, now or in the
            future, as we may reasonably request in order to assign such intellectual property rights back to us.{' '}
          </p>
          <p>
            You shall retain ownership of all copyright in data you upload or submit to our site. Without prejudice to
            our rights and obligations in relation to your personal data as set out in detail in our Privacy Policy,
            you grant us a world-wide, perpetual, exclusive, royalty-free, non-terminable license to use, copy,
            distribute, publish and transmit such data in any manner.{' '}
          </p>
          <p>
            We do not warrant or represent that the content of our site does not infringe the rights of any third party.
            We have the right to disclose your identity to any third party who is claiming that any material posted or
            uploaded by you to our site constitutes a violation of their intellectual property rights, or their right to
            privacy. We have the right to remove any material or posting you make on our site at any time.{' '}
          </p>
          <h2>Copyrights and trademarks</h2>
          <p>
            Our site and its content are our property or the property of our licensors and are protected by copyright,
            trademark, patent and other applicable laws.{' '}
          </p>
          <p>
            Except where necessary for and incidental to personally viewing our site through your web browser, or as
            permitted by these website terms of use, no part of our site may be reproduced, stored (for any period of
            time) in an electronic or any other retrieval system, modified, adapted, uploaded to a third party location,
            framed, performed in public, or transmitted, in any form by any process whatsoever, without our specific
            prior written consent.{' '}
          </p>
          <h2>Confidentiality</h2>
          <p>
            You undertake that you shall not at any time after you have been provided a unique user name by us, divulge
            or communicate to any person (except to professional representatives or advisers or as may be required by
            law or any legal or regulatory authority) any Confidential Information, and will use best endeavours to
            prevent the unauthorised publication or disclosure of any Confidential Information, and will only use such
            Confidential Information for the purposes of proposing, considering or making transactions through our site.
            Confidential Information for these purposes of this clause shall include all information in whatever form
            (including in visual, oral or electronic form) relating to us or any investor or seller (including all
            investor or business profiles) or an individual or a company that was previously an investor or a seller,
            which is provided or disclosed through our site (or to any employees or agents) in connection with the use
            of our site.{' '}
          </p>
          <h2>Suspension and termination</h2>
          <p>
            We will determine, in our sole discretion, whether there has been a breach of these website terms of use
            through your use of our site. Where a breach of these website terms of use has occurred, we may take such
            action as we deem appropriate. We may terminate either or both of your log-in details and your access to our
            site for any or no reason, at any time, with or without notice to you and without incurring any liability to
            you, however that liability may arise. We exclude liability for actions taken in response to breaches of
            these website terms of use. The responses described in this policy are not limited, and we may take any
            other action we reasonably deem appropriate. All restrictions and all disclaimers and exclusions and
            limitations of our liability will continue to apply during suspension and will survive any termination. Upon
            suspension or termination, you must not directly or indirectly access or use our site.{' '}
          </p>
          <h2>General terms</h2>
          <p>
            If we fail to insist on your strict performance of these website terms of use or if we fail to exercise any
            of our rights or remedies to which we are entitled under these website terms of use, or any other document
            referred to herein, this will not amount to a waiver of such rights or remedies, nor will it relieve you of
            your obligations under these terms and conditions. No full or partial waiver of any such right or remedy
            shall restrict us from exercising that right or remedy, or any other right or remedy, in the future.{' '}
          </p>
          <p>
            If any of these website terms of use or any provisions of any other document featured as part of your
            relationship with us are determined by a competent authority to be invalid, unlawful or unenforceable, to
            any extent, then such term or condition will be severed from the remaining terms and conditions which will
            continue to be valid, as far as permitted by British Virgin Island law.{' '}
          </p>
          <p>
            Nothing in these website terms of use is intended to or will be used to establish any partnership or joint
            venture between the parties, nor authorize any party to make any commitments for or on behalf of any other
            party.{' '}
          </p>
          <p>
            You should take your own tax advice. No warranty or representation is made in relation to your tax position,
            which will apply to you following the making of any investment. Any notice or other communication given by
            either party to the other in connection with these website terms of use shall be in writing and delivered by
            hand or sent by pre-paid first class post (or an equivalent next day delivery service) to that party’s
            registered address (if a company) or its principal place of business or residential address (if an
            individual), or sent by e-mail to the e-mail address notified to the other party. Our e-mail address for the
            service of notices is contact@metropoly.io Any notice or other communication will be deemed to have been
            received if delivered by hand, on signature of delivery receipt or at the time the notice is left at the
            proper address, or if sent by email, at 9.00 am on the next working day after it is sent or if sent by post
            (or other delivery service) on the second working day after posting. All correspondence and communication
            between you and us will be in the English language.{' '}
          </p>
          <p>
            Any dispute or claim arising out of or in connection with our site, these website terms of use or their
            subject matter or formation will be governed by British Virgin Island law and such disputes or claims will be
            subject to the non-exclusive jurisdiction of the British Virgin Island Courts.{' '}
          </p>
          <p>
            These website terms of use and any document expressly referred to in them constitute the whole agreement
            between us and supersede all previous discussions, correspondence, negotiations or agreement relating to
            this subject matter.{' '}
          </p>
          <h2>Changes to these website terms of use</h2>
          <p>
            We may revise these website terms of use at any time by amending this page. You are expected to check this
            page from time to time to take notice of any changes we make, as they are legally binding on you. Some of
            the provisions contained in these website terms of use may also be superseded by provisions or notices
            published elsewhere on our site.{' '}
          </p>
        </div>
      </Container>
    </>
  );
};

export default TermsOfService;
