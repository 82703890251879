import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import FilledButton from 'components/Buttons/FilledButton';
import TextTimer from 'components/Timer/TextTimer';
import { useCallback, useEffect, useState } from 'react';
import { numberToString } from 'utils';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useMoralisWeb3Api, useMoralis, useMoralisQuery, useNewMoralisObject } from "react-moralis"
import toast from 'react-hot-toast';

interface PropsType {
  product?: any;
  isUpcoming?: boolean;
  isAuction?: boolean;
  isMyProperty?: boolean;
  showOption?: any;
  loading?: boolean;
}

const useStyles = makeStyles(theme => ({
  productWrapper: {
    maxWidth: 454,
    cursor: 'pointer',
    border: '1px solid #bababa',
    // boxShadow: '3px 3px 5px #777',
    borderRadius: 18,
    overflow: 'hidden',
    marginBottom: 30,
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      maxWidth: '90vw',
    },
    '& .top': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: 20,
      position: 'relative',
      '& img': {
        width: '100%',
      },
      '& .topLeft': {
        position: 'absolute',
        top: 10,
        left: 10,
        '& span': {
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          background: '#fff',
          border: '2px solid #004AAD',
          padding: '5px 20px',
          borderRadius: 50,
          fontSize: 12,
          color: '#004AAD',
          fontWeight: 700,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 12,
          },
        },
      },
      '& .topRight': {
        position: 'absolute',
        top: 10,
        right: 10,
        '& span': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#fff',
          padding: 10,
          borderRadius: 50,

          '& img': {},
        },
      },
      '& .bottomRight': {
        position: 'absolute',
        bottom: 10,
        right: 10,
        '& span': {
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          background: '#fff',
          border: '2px solid #004AAD',
          padding: '5px 5px',
          borderRadius: 10,
          // fontSize: 10,
          fontSize: 10,
          color: '#004AAD',
          fontWeight: 700,
          marginBottom: 8,
          minWidth: 70,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 10,
            minWidth: 60,
          },
          '& p': {
            color: '#004AAD',
            fontWeight: 700,
            fontSize: 10,
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
              fontSize: 10,
            },
          },
          '& img': {
            width: 35,
          },
        },
      },
    },
    '& .price': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 5,
      '& span': {
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        background: '#13CF8F',
        border: '2px solid #ffffff55',
        borderLeft: 'none',
        padding: '8px 20px',
        borderRadius: '0 50px 50px 0',

        '& h3': {
          color: '#fff',
          fontSize: 18,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 16,
          },
        },
        '& p': {
          color: '#fff',
          fontSize: 12,
          fontWeight: 600,
          marginLeft: 7,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 10,
          },
        },
      },
    },
    '& .auction': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 5,
      '& span': {
        width: 'fit-content',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        background: '#13CF8F',
        border: '2px solid #ffffff55',
        borderLeft: 'none',
        padding: '8px 20px',
        borderRadius: '0 50px 50px 0',
        '& .timer': {
          color: '#fff',
          fontSize: 18,
          fontWeight: 600,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 16,
          },
        },
        '& h3': {
          color: '#fff',
          fontSize: 18,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 16,
          },
        },
        '& p': {
          color: '#fff',
          fontSize: 12,
          marginLeft: 7,
          fontWeight: 600,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 10,
          },
        },
      },
    },
    '& .title': {
      padding: '5px 20px',
      height: 'min(8vw, 120px)',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flexDirection: 'column',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        height: 'auto',
      },
      '& h1': {
        color: '#292A36',
        fontSize: 'min(1.8vw, 24px)',
        lineHeight: 1.1,
        fontWeight: 700,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 20,
          textAlign: 'left',
        },
      },
      '& p': {
        fontSize: 'min(1.2vw, 18px)',
        lineHeight: 1.7,
        color: '#6B7380',
        display: 'flex',
        alignItems: 'center',
        fontFamily: "'Antic Didone', serif",
        marginTop: 'auto',
        '& img': {
          marginRight: 7,
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: 14,
        },
      },
    },

    '& .state': {
      padding: '5px 20px',
      marginBottom: 20,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        marginBottom: 10,
      },
      '& ul': {
        background: '#F5F6F8',
        padding: '8px 8px',
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'row',
        width: '100%',
        listStyle: 'none',

        '@media screen and (max-width: 768px) and (orientation: portrait)': {},
        '& li': {
          lineHeight: 1.7,
          color: '#6B7380',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          fontFamily: "'Antic Didone', serif",
          // width: '100%',
          '& img': {
            marginRight: 7,
          },
          '& h4': {
            color: '#004AAD',
            fontSize: 14,
          },
          '& p': {
            color: '#000',
            fontSize: 10,
            textAlign: 'center',
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
              fontSize: 10,
            },
          },
        },
      },
    },
    '& .state1': {
      padding: '5px 20px',
      marginBottom: 20,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {},
      '& ul': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'row',
        width: '100%',
        listStyle: 'none',
        padding: 0,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {},
        '& li': {
          lineHeight: 1.7,
          color: '#6B7380',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          fontFamily: "'Antic Didone', serif",
          width: '100%',

          '& h4': {
            color: '#000',
            fontSize: 14,
            background: '#fff',
            padding: '8px 20px',
            width: '100%',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            textAlign: 'center',
            border: '1px #BABABA solid',
          },
          '& p': {
            color: '#000',
            fontSize: 10,
            textAlign: 'center',
            '@media screen and (max-width: 768px) and (orientation: portrait)': { fontSize: 10 },
          },
          '& .border-radius-left': {
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            borderRight: '1px solid #ffffff00',
          },
          '& .border-radius-right': {
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            borderLeft: '1px solid #ffffff00',
          },
        },
      },
    },
    '& .state2': {
      padding: '5px 0px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {},
      '& h3': {
        color: '#000',
        fontSize: 20,
      },
      '& p': {
        color: '#000',
        fontSize: 10,
        textAlign: 'center',
        '@media screen and (max-width: 768px) and (orientation: portrait)': { fontSize: 10 },
      },
      '& img': {
        marginRight: 10,
      },
      '& span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {},
      },
    },
    '& .progress': {
      padding: '5px 20px',
      marginBottom: 20,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexDirection: 'column',

      '@media screen and (max-width: 768px) and (orientation: portrait)': {},
      '& h3': {
        color: '#000',
        fontSize: 14,
      },
      '& .progressBar': {
        background: '#BABABA',
        height: 15,
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        overflow: 'hidden',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {},
        '& .track': {
          background: '#004AAD',
          height: 15,
        },
      },
    },
    '& .footer': {
      padding: '5px 20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        width: '100% !important',
      },
      '& span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
      },
      '& h4': {
        color: '#000',
        fontSize: 20,
        marginBottom: 10,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 22,
        },
      },
      '& p': {
        lineHeight: 1.7,
        fontSize: 14,
        color: '#6B7380',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 10,
        },
      },
    },
  },
}));

const PropertyCard1 = ({ product, isUpcoming, isAuction, isMyProperty, showOption, loading }: PropsType) => {
  const classes = useStyles();
  const history = useHistory();
  const { isAuthenticated, user } = useMoralis();
  const [userWallet, setUserWallet] = useState('');
  const [fav, setFav] = useState([]);
  const onOption = () => {
    showOption(true);
  };

  const { save } = useNewMoralisObject("Favorites");

  const saveObject = async () => {
    const data = {
      tokenId: product?.id.toString(),
      favoritesCount: 1,
      favorites: [user.get("ethAddress")]

    };

    save(data, {
      onSuccess: (favorites) => {
        // Execute any logic that should take place after the object is saved.
        console.log("New object created with objectId: " + favorites);
        setIsFavorite(true);
      },
      onError: (error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Moralis.Error with an error code and message.
        alert("Failed to create new object, with error code: " + error.message);
      },
    });
  };

  const handleFavorites = () => {

    if (fav.length > 0) {
      let arr = fav[0].get("favorites");
      if (!arr.includes(user.get("ethAddress"))) {
        arr.push(user.get("ethAddress"))
        try {
          fav[0].set("favorites", arr);
          fav[0].increment("favoritesCount");
          fav[0].save().then((data) => {
            console.log(data)
          });
        } catch (e) {
          console.log(e)
        }
      } else {
        toast.error("You already liked this NFT.")
      }
    }
    else
      saveObject();
  }

  const getFavorites = useMoralisQuery(
    "Favorites",
    (query) => query.equalTo("favorites", userWallet),
    [],
    { autoFetch: true }
  );

  const fetchFavorites = () => {
    if (userWallet !== '')
      getFavorites.fetch({
        onSuccess: (favorites) => {
          // The object was retrieved successfully.
          setFav(favorites)
        },
        onError: (error) => {
          // The object was not retrieved successfully.
          // error is a Moralis.Error with an error code and message.
        },
      });
  };
  const userFavorite = useMoralisQuery(
    "Favorites",
    (query) => query.equalTo("tokenId", product?.id.toString()),
    [],
    { autoFetch: true }
  );

  const userQuery = useCallback(() => {
    if (isAuthenticated)
      userFavorite.fetch({
        onSuccess: (favorites) => {
          // The object was retrieved successfully.
          console.log(favorites)
          setFav(favorites)
        },
        onError: (error) => {
          // The object was not retrieved successfully.
          // error is a Moralis.Error with an error code and message.
        },
      });
    else
      toast.error("You must connect your wallet.")
  }, [fetch]);


  useEffect(() => {
    if (isAuthenticated)
      setUserWallet(user.get("ethAddress"))
  }, [isAuthenticated])

  useEffect(() => {
    (async () => {
      try {
        if (isAuthenticated)
          fetchFavorites()
      } catch (error) {
        console.log("error", error);
      }
    }
    )()
  }, [userWallet])


  const onBuy = () => {
    history.push(`/detail/${product?.id}`);
  };
  // const [timeEnd, setTimeEnd] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  return (
    <div className={classes.productWrapper}>
      {loading ? <>
        <Skeleton height={300} width={350} />
      </>
        :
        <>
          <div className="top">
            <img src={product?.img || '/assets/images/unsplash_IYfp2Ixe9nM.png'} alt="" onClick={onBuy} />
            <div className="topLeft">
              <span>{product?.type}</span>
            </div>
            <div className="topRight" onClick={() =>
              fetchFavorites()
            }>
              <span>
                {product?.isFavorite ? (
                  <img src="/assets/images/favorite_icon_03.svg" alt="" />
                ) : isFavorite ? (
                  <img src="/assets/images/favorite_icon_03.svg" alt="" />
                ) : (
                  <img src="/assets/images/favorite_icon_01.svg" alt="" />
                )}
              </span>
            </div>
            <div className="bottomRight">
              <span>{product?.homeState}</span>
              <span>
                <img src="/assets/images/bed_icon.svg" alt="" />
                <p>
                  {product?.beds} {product?.beds === 1 ? 'Bed' : 'Beds'}
                </p>
              </span>
            </div>
          </div>
          {isAuction ? (
            <div className="auction">
              <span>
                <p>Auction Ends In</p>
                <TextTimer deadLine={1662829200} setTimeEnd={() => { }} />
              </span>
            </div>
          ) : (
            <div className="price">
              <span>
                <h3>{numberToString(product?.startPrice)} USD*</h3>
                <p>starting price</p>
              </span>
            </div>
          )}

          <div className="title">
            <h1>{product?.name}</h1>
            <p>
              <img src="assets/images/tag.svg" alt="" />
              {product?.location}
            </p>
          </div>
          <div className="state">
            <ul>
              <li>
                <h4>{product?.state?.expected}%</h4>
                <p>Expected Return</p>
                <img src="/assets/images/coins.svg" alt="" />
              </li>
              <li>
                <h4>{product?.state?.yeld}%</h4>
                <p>Dividend Yield</p>
                <img src="/assets/images/arrow_01.svg" alt="" />
              </li>
              <li>
                <h4>{product?.state?.frequency}</h4>
                <p>Dividend Frequency</p>
                <img src="/assets/images/calenter_01.svg" alt="" />
              </li>
            </ul>
          </div>
          {isMyProperty && (
            <div className="state1">
              <ul>
                <li>
                  <p>Profit/Loss</p>
                  <h4 className="border-radius-left" style={{ color: '#13CF8F' }}>
                    {product?.state?.profit}
                  </h4>
                </li>
                <li>
                  <p>NFTs Owned</p>
                  <h4>{product?.state?.owned}</h4>
                </li>
                <li>
                  <p>Current Value</p>
                  <h4 className="border-radius-right">{product?.state?.value}</h4>
                </li>
              </ul>
            </div>
          )}
          {isUpcoming && (
            <div className="progress">
              <h3>{product?.minted} of 1,000 NFTs Minted</h3>
              <div className="progressBar">
                <div className="track" style={{ width: `${(product?.minted / 1000) * 100}%` }}></div>
              </div>
            </div>
          )}
          {isAuction && (
            <div className="state">
              <ul>
                <li style={{ width: '100%' }}>
                  <p>The highest current bid is</p>
                  <h4 style={{ fontSize: 16 }}>{product?.currntBid}% below the market price</h4>
                </li>
              </ul>
            </div>
          )}
          <div className="footer">
            {isMyProperty ? (
              <>
                <div className="state2" style={{ width: 'auto' }}>
                  <img src="/assets/images/back_icon_02.svg" alt="" />
                  <span>
                    <p>Annual Rental Income</p>
                    <h3>{numberToString(product?.annualIncome || 0)} USD </h3>
                  </span>
                </div>
                <FilledButton label={'OPTIONS'} handleClick={onOption} />
              </>
            ) : (
              <>
                <span>
                  <p>Price of Real Estate* </p>
                  <h4>{numberToString(product?.estatePrice)} USD</h4>
                </span>
                <FilledButton label={isUpcoming ? 'MINT NOW' : isAuction ? 'BID NOW' : 'BUY NOW'} handleClick={onBuy} />
              </>
            )}
          </div>
        </>
      }
    </div>
  );
};

export default PropertyCard1;
