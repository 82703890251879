import React, { useState, useEffect } from 'react';
import { useStyles } from './style';
import Masonry from 'react-masonry-css';
import ProductCard1 from 'components/Cards/ProductCard1';
import Filter from 'components/Filter/Filter';
const myData = [
  {
    id: 0,
    img: '/assets/images/unsplash_IYfp2Ixe9nM.png',
    type: 'Rental Property',
    startPrice: 5200,
    estatePrice: 5200000,
    name: 'Beautiful 5-Bedroom Villa with an Incredible View',
    location: 'Los Angeles, United States of America',
    homeState: 'Rented',
    beds: 5,
    country: "United States of America",
    state: {
      expected: 49,
      yeld: 7.79,
      frequency: 'Monthly',
    },
  },
  {
    id: 1,
    img: '/assets/images/unsplash_IYfp2Ixe9nM (1).png',
    type: 'Rental Property',
    startPrice: 280,
    estatePrice: 280000,
    name: 'Cozy 1-Bedroom Apartment in the City Center',
    location: 'London, United Kingdom',
    homeState: 'Rented',
    beds: 1,
    country: "United Kingdom",
    state: {
      expected: 32,
      yeld: 4.48,
      frequency: 'Monthly',
    },
  },
  {
    id: 2,
    img: '/assets/images/unsplash_IYfp2Ixe9nM (2).png',
    type: 'Holiday Home',
    startPrice: 860,
    estatePrice: 860000,
    name: 'Authentic 3-Bedroom Penthouse with a Private Terrace',
    location: 'Berlin, Germany',
    homeState: 'Vacant',
    beds: 3,
    country: "Germany",
    state: {
      expected: 37,
      yeld: 5.32,
      frequency: 'Monthly',
    },
  },

  {
    id: 3,
    img: '/assets/images/image_02.png',
    type: 'Holiday Home',
    startPrice: 725,
    estatePrice: 725000,
    name: 'Modern 3-Bedroom Family Home',
    location: 'House, Stockholm, Sweden',
    homeState: 'Vacant',
    beds: 3,
    country: "Sweden",
    state: {
      expected: 51,
      yeld: 8.28,
      frequency: 'Monthly',
    },
  },
  {
    id: 4,
    img: '/assets/images/image_03.png',
    type: 'Rental Property',
    startPrice: 7000,
    estatePrice: 7000000,
    name: 'Luxury 5-Bedroom Villa with a Pool',
    location: 'San Francisco, USA',
    homeState: 'Rented',
    beds: 5,
    country: "United States of America",
    state: {
      expected: 37,
      yeld: 5.3,
      frequency: 'Monthly',
    },
  },
  {
    id: 5,
    img: '/assets/images/image_04.png',
    type: 'Rental Property',
    startPrice: 3500,
    estatePrice: 3500000,
    name: '4-Bedroom House with a Beautiful Garden',
    location: 'Munich, Germany',
    homeState: 'Rented',
    beds: 4,
    country: "Germany",
    state: {
      expected: 46,
      yeld: 7.2,
      frequency: 'Monthly',
    },
  },

  {
    id: 6,
    img: '/assets/images/image_05.png',
    type: 'Rental Property',
    startPrice: 215,
    estatePrice: 215000,
    name: '1-Bedroom Studio Apartment Near of the City Center',
    location: 'Manchester, United Kingdom',
    homeState: 'Rented',
    beds: 1,
    country: "United Kingdom",
    state: {
      expected: 38,
      yeld: 5.66,
      frequency: 'Monthly',
    },
  },

  {
    id: 7,
    img: '/assets/images/image_06.png',
    type: 'Rental Property',
    startPrice: 560,
    estatePrice: 560000,
    name: 'Luxury 2-Bedroom Condo in the Heart of the City',
    location: 'Rome, Italy',
    homeState: 'Vacant',
    beds: 2,
    country: "Italy",
    state: {
      expected: 30,
      yeld: 3.9,
      frequency: 'Monthly',
    },
  },
  {
    id: 8,
    img: '/assets/images/image_07.png',
    type: 'Rental Property',
    startPrice: 2400,
    estatePrice: 2400000,
    name: 'Rare 3-Bedroom Penthouse with a Patio',
    location: 'Barcelona, Spain',
    homeState: 'Rented',
    beds: 3,
    country: "Spain",
    state: {
      expected: 51,
      yeld: 8.12,
      frequency: 'Monthly',
    },
  },
];

interface FilteredData {
  id: number,
  img: string,
  type: string,
  startPrice: number,
  estatePrice: number,
  name: string,
  location: string,
  homeState: string,
  beds: number,
  state: {
    expected: number,
    yeld: number,
    frequency: string,
  },
}

const Marketplace = () => {

  const [fiteredData, setFilteredData] = useState<any>()
  const classes = useStyles();
  const breakpointColumnsObj = {
    // default: 4,
    3840: 7,
    3000: 6,
    2560: 5,
    2200: 4,
    1840: 3,
    1440: 3,
    1280: 2,
    768: 2,
    450: 1,
  };

  const [data, setData] = useState(myData);

  
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <span>
          <h1 className={classes.title}>Marketplace</h1>
          <p>Buy and Sell Real Estate NFTs Within Seconds</p>
        </span>
      </div>
      <Filter data={data} setData={setData}  myData={myData}/>

      <div className={classes.content}>
        <Masonry breakpointCols={breakpointColumnsObj} className={classes.masonry} columnClassName={classes.gridColumn}>
          {data.map((d, i) => (
            <ProductCard1 key={i} product={d} />
          ))}
        </Masonry>
      </div>
    </div>
  );
};

export default Marketplace;
