import { makeStyles } from '@material-ui/core/styles';

const series2 = [
  {
    img: '/assets/images/download_icon_01.svg',
    title: 'Download',
    value: 'Independent Property Report',
    link: "https://scarlet-elderly-harrier-31.mypinata.cloud/ipfs/QmTkzFNYgejA2jmi3W4AYqZ1GeWdCGCNQByVAQRiadqyjs"
  },
  {
    img: '/assets/images/download_icon_01.svg',
    title: 'Download',
    value: 'Independent Area Report',
    link: "https://scarlet-elderly-harrier-31.mypinata.cloud/ipfs/QmWqwdnLNHyXhn2s5v489HUrBvrAbkfoxbwk2Qz3g1vQBc"
  },
  {
    img: '/assets/images/download_icon_01.svg',
    title: 'Download',
    value: 'Memorandum & Disclaimer',
    link: "https://scarlet-elderly-harrier-31.mypinata.cloud/ipfs/QmZaDwgVPjRMMw6FT6HaG4R9oRU2uxzGG8ospYhRkMyEtp"
  },
  {
    img: '/assets/images/download_icon_01.svg',
    title: 'Download',
    value: 'Projections Report',
    link: "https://scarlet-elderly-harrier-31.mypinata.cloud/ipfs/QmSAuUAnMhfVg9ZLfvVMJz4DJBiTrzfmoePXxbyqnv7G46"
  },
];
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 50,
    background: '#2081E2',
    width: '100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      paddingBottom: 50,
    },
  },
  top: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 20,
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      justifyContent: 'center',
    },

    '& p': {
      color: '#fff',
      marginBottom: 10,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        marginBottom: 0,
      },
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 1440,
    paddingBottom: 10,

    [theme.breakpoints.down('xs')]: {
      // flexDirection: 'column',
      alignItems: 'center',
    },
    '& .qrcode': {
      margin: 10,
      '& img': {
        height: 150,
      },
    },
  },

  wrapper: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    // flexDirection: 'row',
    // alignItems: 'flex-start',
    // flexWrap: 'wrap',
    listStyle: 'none',
    width: '100%',
    maxWidth: 940,
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      alignItems: 'center',
    },
    '& li': {
      width: 250,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 20,
      background: '#fff',
      borderRadius: 15,
      padding: 10,
      margin: 10,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        width: 'calc(100% - 40px)',
        marginBottom: 5,
      },
      '& span': {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'flex-start',
        // width: '90%',
      },
      '& img': {},
      '& h5': {
        color: '#000',
        fontSize: 14,
        fontWeight: 500,
      },
      '& p': {
        color: '#2081E2',
        fontSize: 12,
        textAlign: 'center',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 10,
        },
      },
    },
  },
  header: {
    fontSize: 35,
    color: '#fff',
    textAlign: 'center',
    lineHeight: '50px',
    position: 'relative',
    marginBottom: 10,
    width: '100%',
    fontWeight: 600,
    '@media (max-width: 768px)': {
      fontSize: '1.5em',
    },
  },
}));

const Documents = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.top}>
          <h1 className={classes.header}>Documents</h1>
          <p>Confidential property documents</p>
        </div>
        <div className={classes.content}>
          <ul className={classes.wrapper}>
            {series2.map((d, k) => (
              <li key={k}>
                <span>
                  <a href={d.link} target='_blank' rel='noopener noreferrer'>
                    <h5>{d.title}</h5>
                    <p>{d.value}</p>
                  </a>
                </span>
                <a href={d.link} target='_blank' rel='noopener noreferrer'>
                  <img src={d.img} alt="" /></a>
              </li>
            ))}
          </ul>

        </div>
      </div>
    </>
  );
};

export default Documents;
