// @ts-ignore
// @ts-nocheck
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

const CustomChip = withStyles(theme => ({
  root: {
    color: theme.palette.primary.contrastText,
    height: theme.spacing(5),
    '& img': {
      '@media screen and (max-width: 640px) and (orientation: portrait)': {
        height: 20,
        width: 20,
        objectFit: 'cover',
      },
    },
    // marginLeft: 11,
    '& .MuiChip-avatar': {
      width: 32,
      height: 32,
      marginLeft: 2,
      marginRight: 3,
      borderRadius: 0,
      '@media screen and (max-width: 640px) and (orientation: portrait)': {
        height: 20,
        width: 20,
      },
    },
  },
  outlined: {
    borderWidth: 0,
    padding: '0.5rem 0.5rem',
    border: 'none',
    borderRadius: '10px',
    backgroundColor: '#2892F3',
    '&:hover': {
      backgroundColor: '#0867c0 !important',
    },
    '&:focus': {
      backgroundColor: theme.palette.secondary.dark + ' !important',
    },
  },
  label: {
    fontSize: 14,
    fontWeight: 'bold',
    '@media screen and (max-width: 640px) and (orientation: portrait)': {
      fontSize: 12,
      padding: 0,
    },
  },
}))(Chip);

const UserChip = ({ avatarUrl, displayName, balance }) => {
  return (
    <CustomChip
      avatar={<Avatar src={'/assets/images/wallet.svg'} />}
      label={<span>{displayName}</span>}
      variant="outlined"
      clickable
    />
  );
};

UserChip.propTypes = {};

UserChip.defaultProps = {};

export default UserChip;
