import './ChooseCountry.scss';
import Select from 'react-select';
import FormatsortOptionLabel from '../FormatsortOptionLabel';
const customStyles = {
  control: base => ({
    ...base,
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '50px',
    border: '1px solid #ddd !important',
    boxShadow: 'none',
    width: '100%',
    minWidth: 300,
    background: '#F7F9FA',
    '&:focus': {
      border: '0 !important',
    },
  }),

  menu: (provided, state) => ({
    ...provided,
    border: '1px solid #ffffff13',
    color: '#fff',
    padding: 0,
    background: '#fff',
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #aaa',
    padding: 3,
    color: '#fff',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#ddd',
    },
    ':active': {
      backgroundColor: '#555',
    },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

const ChooseCountry = () => {
  const options = [
    { value: 0, label: 'United States', customAbbreviation: '0' },
    { value: 1, label: 'United Kingdom', customAbbreviation: '1' },
    { value: 2, label: 'Germany', customAbbreviation: '2' },
    { value: 3, label: 'Spain', customAbbreviation: '3' },
  ];
  const handleSelected = e => { };
  return (
    <div className="ChooseCountry">
      <h3 className="">Choose Country</h3>
      <Select
        defaultValue={options[0]}
        formatOptionLabel={FormatsortOptionLabel}
        options={options}
        instanceId="chainSelect"
        className={`select-gray `}
        onChange={e => handleSelected(e)}
        isSearchable={false}
        isClearable={false}
        styles={customStyles}
      />
    </div>
  );
};
export default ChooseCountry;
