import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 20,
    marginBottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },

  content: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  productImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 20,
  },
  height30: {
    height: '28vw',
    // maxHeight: '70vh',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: '0px 20px',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0px 10px',
    },
  },
  left: {
    width: '55%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingRight: 20,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      alignItems: 'center',
      padding: 10,
      paddingBottom: 0,
    },
    '& .chart-div': {
      width: '100%',
      paddingTop: 0,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'column',
    },

    '& .chart': {
      width: '100%',
      height: 200,
      paddingTop: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      '& .recharts-responsive-container': {
        overflow: 'visible',
      },
      '& .recharts-surface': {
        overflow: 'visible',
      },
      '& .yAxis': {
        display: 'none',
      },
      '& text': {
        paddingLeft: 20,
        paddingRight: 20,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 10,
        },
      },
    },

    '& .time-line': {
      width: '100%',
      height: 200,
      paddingTop: 20,
      background: '#F5F5F5',
      border: '1px #BABABA  solid',
      borderRadius: 20,
      padding: 20,
      position: 'relative',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'column',
      '& .node': {
        width: '70%',
        display: 'flex',
        alignItems: 'flex-start',
        // marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        '&:before': {
          content: '""',
          width: 1,
          height: 50,
          borderRight: '1px #2892F3 solid',
        },
        '& .circle': {
          width: 20,
          height: 20,
          background: '#2892F3',
          borderRadius: 30,
          marginRight: 10,
          marginLeft: -10,
        },
        '& .text': {
          color: '#292A36',
          '& h5': {
            fontSize: 14,
            [theme.breakpoints.down('xs')]: {
              fontSize: 12,
            },
          },
          '& p': {
            fontSize: 12,
            [theme.breakpoints.down('xs')]: {
              fontSize: 10,
            },
          },
        },
      },
      '& .desc': {
        width: '36%',
        position: 'absolute',
        fontSize: 10,
        right: 10,
        bottom: 20,
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          bottom: 10,
          paddingLeft: 30,
          position: 'relative',
        },
      },
    },
    '& .timer-div': {
      bottom: 10,
      position: 'absolute',
      background: '#F9F9F9',
      padding: '5px 10px',
      left: 0,
      [theme.breakpoints.down('xs')]: {
        left: 20,
      },
      '& p': {
        color: '#6B7380',
        fontSize: 14,
        [theme.breakpoints.down('xs')]: {
          fontSize: 10,
        },
      },
      '& .timer': {
        fontSize: 20,
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
          fontSize: 14,
        },
      },
    },

    '& .topLeft': {
      position: 'absolute',
      top: 30,
      left: 30,
      display: 'none',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        display: 'flex',
      },
      '& span': {
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        background: '#fff',
        border: '2px solid #004AAD',
        padding: '5px 20px',
        borderRadius: 50,
        fontSize: '0.7vw',
        color: '#004AAD',
        fontWeight: 700,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 12,
        },
      },
    },
    '& .topRight': {
      position: 'absolute',
      top: 15,
      right: 30,
      // display: 'none',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        display: 'flex',
      },
      '& span': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        padding: 10,
        borderRadius: 50,

        '& img': {},
      },
    },
    '& .bottomRight': {
      position: 'absolute',
      bottom: 10,
      right: 30,
      display: 'none',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        display: 'block',
      },
      '& span': {
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        background: '#fff',
        border: '2px solid #004AAD',
        padding: '5px 5px',
        borderRadius: 10,
        // fontSize: 10,
        fontSize: '0.6vw',
        color: '#004AAD',
        fontWeight: 700,
        marginBottom: 8,
        minWidth: 70,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 10,
          minWidth: 60,
          lineHeight: 1,
        },
        '& p': {
          color: '#004AAD',
          fontWeight: 700,
          fontSize: '0.6vw',
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 10,
          },
        },
        '& img': {
          width: 35,
        },
      },
    },
  },
  right: {
    width: '45%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'column',
    paddingLeft: 20,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      alignItems: 'center',
      padding: 10,
      paddingBottom: 0,
      paddingTop: 0,
    },
    '& .progress': {
      // padding: '5px 10px',
      marginBottom: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexDirection: 'column',
      width: '100%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {},
      '& h5': {
        color: '#000',
        fontSize: 14,
      },
      '& .progressBar': {
        background: '#BABABA',
        height: 15,
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        overflow: 'hidden',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {},
        '& .track': {
          background: '#004AAD',
          height: 15,
        },
      },
    },
  },
  room: {
    padding: '10px 20px',
    border: '1px solid #004AAD',
    borderRadius: 25,
    color: '#004AAD',
    fontSize: '0.7vw',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
      fontSize: 12,
    },
    '& img': {
      marginRight: 7,
    },
  },
  borderBottom: {
    borderBottom: '1px #ddd solid',
  },

  icon: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(2),
  },
  title: {
    color: '#000',
    lineHeight: 1,
    marginBottom: 10,
    fontSize: '2vw',
    // fontSize: 'min(2vw, 32px)',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
      marginBottom: 10,
      paddingRight: 50,
    },
  },
  smalTxt: {
    color: '#6B7380',
    fontSize: 12,
    width: '100%',
  },
  current: {
    color: '#6B7380',
    fontSize: 12,
    width: '100%',
    marginTop: 30,
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
    '& ul': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      listStyle: 'none',
      padding: 0,
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
      '& li': {
        // width: '25%',
        display: 'flex',
        alignItems: 'center',

        // fontSize: 'min(0.8vw, 14px)',
        fontSize: '0.8vw',
        [theme.breakpoints.down('md')]: {
          fontSize: 10,
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: 8,
        },
        '& img': {
          width: '1vw',
          [theme.breakpoints.down('md')]: {
            width: 18,
          },
          marginRight: 7,
          [theme.breakpoints.down('xs')]: {
            marginBottom: 0,
            fontSize: 10,
            width: 15,
          },
        },
      },
    },
    '& .location': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '1vw',
      color: '#6B7380',
      fontFamily: "'Antic Didone', serif",
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
      '& img': {
        marginRight: 7,
      },
    },
    '& .price': {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: 20,
      marginTop: 20,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
      },
      '& h1': {
        marginRight: 7,
        marginBottom: 0,
        lineHeight: 1,
        fontSize: 'min(2.5vw, 40px)',
        [theme.breakpoints.down('xs')]: {
          fontSize: 30,
        },
      },
      '& h5': {
        fontSize: 'min(1vw, 18px)',
        marginRight: 7,
        marginBottom: 7,
        lineHeight: 1,
        color: '#BABABA',
        [theme.breakpoints.down('xs')]: {
          fontSize: 12,
        },
      },
    },
    '& .row': {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        marginBottom: 10,
      },
      '& img': {
        width: 60,
        marginRight: 10,
        [theme.breakpoints.down('xs')]: {
          width: 40,
        },
      },
      '& .col': {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',

        '& h1': {
          marginRight: 7,
          marginBottom: 0,
          lineHeight: 1,
          fontSize: 40,
          [theme.breakpoints.down('xs')]: {
            fontSize: 25,
          },
        },
        '& h2': {
          marginRight: 7,
          marginBottom: 0,
          lineHeight: 1,
          fontSize: 20,
          [theme.breakpoints.down('xs')]: {
            fontSize: 14,
          },
        },
      },
    },

    '& .count': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 20,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
      },
      '& button': {
        fontSize: 24,
        border: 'none',
        width: 45,
        height: 45,
        borderRadius: 7,
        background: '#F7F9FA',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        [theme.breakpoints.down('xs')]: {
          fontSize: 12,
          width: 35,
          height: 35,
        },
        '&:hover': {
          background: '#ddd',
        },
      },
      '& input': {
        width: 100,
        height: 43,
        border: '2px #F7F9FA solid',
        fontSize: 24,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
          fontSize: 12,
          width: 80,
          height: 33,
        },
      },
    },
    '& .btns': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: -10,
      '& button': {
        width: '100%',
        fontSize: 20,
        height: 60,
        marginTop: 10,
        '& img': {
          height: 'auto',
        },
      },
    },
    '& .share': {
      [theme.breakpoints.down('xs')]: {
        // display: 'none',
        marginLeft: 'auto',
        marginBottom: -70,
        zIndex: 1,
      },
      '& button': {
        border: 'none',
        background: '#ffffff00',
        cursor: 'pointer',
      },
    },
    '& h3': {
      width: '100%',
      paddingBottom: 18,
    },
  },
  state: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& ul': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'center',
      listStyle: 'none',
      padding: 0,
      '& li': {
        width: 'calc(50% - min(0.4vw, 8px))',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        fontSize: 12,
        border: '1px #BABABA solid',
        borderRadius: 10,
        padding: 20,
        marginTop: 'min(0.8vw, 13px)',
        minHeight: '7vw',
        [theme.breakpoints.down('md')]: {
          padding: 10,
        },
        [theme.breakpoints.down('xs')]: {
          width: 'calc(50% - 5px)',
          padding: 10,
          margin: 0,
          marginBottom: 10,
        },
        '& p': {
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 'min(0.8vw, 13px)',
          [theme.breakpoints.down('xs')]: {
            fontSize: 12,
            marginBottom: 20,
            fontWeight: 600,
          },
        },
        '& h5': {
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          // fontSize: 'min(1.2vw, 18px)',
          fontSize: '1.2vw',
          [theme.breakpoints.down('xs')]: {
            fontSize: 12,
          },
          '& img': {
            height: 30,
            [theme.breakpoints.down('xs')]: {
              height: 20,
            },
          },
        },
      },
    },
  },

  description: {
    paddingTop: theme.spacing(4),
  },
  btnGroup: {
    '& .MuiButton-outlined': {
      border: `1px solid ${theme.palette.surface[3]}`,
    },
  },
}));

export default useStyles;
