import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 50,
    width: '100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      paddingBottom: 0,
    },
  },
  overview: {
    textAlign: "center",
    '& h3': {
      color: '#000',
      fontSize: 35,
      fontWeight: 600,
      width: '90%',
      marginBottom: 20,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 12,
      },
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingBottom: 10,
    [theme.breakpoints.only('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },

  left: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '50%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      width: '100%',
      marginBottom: 30,
    },
    '& p': {
      color: '#000',
      fontSize: 14,
      width: '90%',
      marginBottom: 20,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 12,
      },
    },
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '50%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      width: '100%',
    },
    '& ul': {
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      listStyle: 'none',
      '& li': {
        width: '20%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 34,
        '& p': {
          color: '#000',
          fontSize: 12,
          width: '90%',
          textAlign: 'center',
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 10,
          },
        },
      },
    },
  },

  header: {
    fontSize: 18,
    color: '#000',
    lineHeight: '50px',
    position: 'relative',
    marginBottom: 30,
    fontWeight: 600,
    width: '90%',
    '@media (max-width: 768px)': {
      fontSize: '1.5em',
    },
  },

  panel: {
    width: 'calc(100% - 20px)',
    padding: 20,
    border: '1px #ddd solid',
    borderRadius: 20,
    '& .head': {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: '1px #ddd solid',
      paddingBottom: 10,
      '& img': {
        background: 'radial-gradient(115.53% 117.14% at 50.29% 0%, #FAFCFF 0%, #E5E8EC 100%)',
        width: 40,
        height: 40,
        padding: 10,
        borderRadius: 7,
        boxShadow: '0px 1.02618px 4.1047px rgba(0, 0, 0, 0.25)',
        marginRight: 10,
      },
      '& span': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: 10,
        paddingBottom: 10,
        '& p': {
          color: '#2892F3',
          textAlign: 'left',
          fontSize: 14,

          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 12,
          },
        },
        '& h5': {
          color: '#343434',
          textAlign: 'left',
          fontSize: 18,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {},
        },
        '& h2': {
          color: '#2892F3',
          textAlign: 'left',
          fontSize: 30,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {},
        },
      },
    },
  },

  smalTxt: {
    marginTop: 20,
    color: '#777',
    fontSize: 14,
  },
}));

const series = [
  {
    img: '/assets/images/amenities_icon_01.svg',
    title: "Children's Play Area",
    status: false,
  },
  {
    img: '/assets/images/amenities_icon_02.svg',
    title: 'Swimming Pool',
    status: false,
  },
  {
    img: '/assets/images/amenities_icon_03.svg',
    title: 'BBQ Area',
    status: false,
  },
  {
    img: '/assets/images/amenities_icon_04.svg',
    title: 'Gym',
    status: false,
  },
  {
    img: '/assets/images/amenities_icon_05.svg',
    title: 'Built-in Wardrobes',
    status: false,
  },
  {
    img: '/assets/images/amenities_icon_06.svg',
    title: 'Free Public Parking',
    status: false,
  },
  {
    img: '/assets/images/amenities_icon_07.svg',
    title: 'WiFi',
    status: false,
  },
  {
    img: '/assets/images/amenities_icon_08.svg',
    title: 'Bar',
    status: false,
  },
  {
    img: '/assets/images/amenities_icon_09.svg',
    title: 'Lounge',
    status: false,
  },
  {
    img: '/assets/images/amenities_icon_10.svg',
    title: 'Spa',
    status: false,
  },
  {
    img: '/assets/images/amenities_icon_11.svg',
    title: 'Babysitting',
    status: false,
  },
  {
    img: '/assets/images/amenities_icon_12.svg',
    title: 'Non-Smoking Areas',
    status: false,
  },
  {
    img: '/assets/images/amenities_icon_13.svg',
    title: 'Family Rooms',
    status: false,
  },
  {
    img: '/assets/images/amenities_icon_14.svg',
    title: 'Garden',
    status: false,
  },
  {
    img: '/assets/images/amenities_icon_15.svg',
    title: 'Interconnected Rooms',
    status: false,
  },
];
const AboutProperty = ({ propertyData, productDataDesc }) => {
  const classes = useStyles();

  const [amenities, setAmenities] = useState([])

  useEffect(() => {
    if (propertyData) {
      setAmenities([]);
      for (let x = 0; x < propertyData.length; x++) {
        for (let y = 0; y < series.length; y++) {
          if (propertyData[x].value === series[y].title) {
            setAmenities(amenities => [...amenities, series[y]])
          }
        }
      }
    }
  }, [propertyData])
  return (
    <>
      <div className={classes.root}>
        <div className={classes.overview}>
          <h3>Overview</h3>
        </div>
        <div className={classes.content}>
          <div className={classes.left}>
            <h1 className={classes.header}>About the Property</h1>
            <p style={{ whiteSpace: "pre-wrap" }}>
              {/* {productDataDesc ? productDataDesc.replace(/\\n/g, '\n') : <>Fetching...</>}
               */}
              Here you will find all available information regarding the specific property. For example, this might entail where the property is located, what makes it unique, and why it could interest you. Furthermore, Metropoly provides insights about market conditions and why our team believes that the property value will increase in the long term. We evaluate crucial aspects of the property, including the area, surrounding development, service charges, building quality, number of amenities, developer, and expected supply. <br /><br />

              All real estate offered on the Metropoly marketplace must pass a strict screening and selection process to provide users with the best opportunity to earn both rental yield and capital appreciation. In addition, we leverage data from leading third - party vendors to ensure that all of our internal research and analysis tools are accurate and reliable in current market conditions.
            </p>
          </div>
          <div className={classes.right}>
            <h1 className={classes.header}> </h1>
            <ul>
              {

                series.map((d, k) => {
                  return (
                    <li key={k}>
                      <img src={d.img} alt="" />
                      <p>{d.title}</p>
                    </li>
                  )
                })
                // propertyData
                //   ?
                //   amenities.map((d, k) => {
                //     return (
                //       <li key={k}>
                //         <img src={d.img} alt="" />
                //         <p>{d.title}</p>
                //       </li>
                //     )
                //   })
                //   : <>Fetching ...</>
              }
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutProperty;
