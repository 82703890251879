import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/Layout/Container';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 15,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
      paddingTop: 0,
      textAlign: 'center',
    },
  },

  section: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 20,
    '& h1': {
      color: '#000',
      fontSize: 30,
      width: '100%',
      marginBottom: 20,
      textTransform: 'uppercase',
      [theme.breakpoints.only('sm')]: {
        fontSize: 28,
      },
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 30,
      },
    },
    '& p': {
      color: '#000',
      fontSize: 14,
      width: '100%',
      marginBottom: 20,
      fontWeight: 500,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 12,
      },
    },
    '& h2': {
      color: '#000',
      fontSize: 20,
      width: '100%',
      marginBottom: 20,
      [theme.breakpoints.only('sm')]: {
        fontSize: 18,
      },
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 18,
      },
    },
    '& .img1': {
      width: '80%',
      padding: 50,
    },
    '& .feature': {
      width: '100%',
      padding: 20,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      // listStyle: 'none',
      '& li': {
        width: '45%',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
        fontWeight: 500,
        textAlign: 'left',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          width: '100%',
        },
        '& img': {
          marginRight: 10,
          height: 40,
        },
      },
    },
    '& .row': {
      width: '100%',
      padding: 20,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        flexDirection: 'column',
      },
      '& .col': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 20,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          marginBottom: 40,
        },
        '& img': {
          width: '100%',
          maxWidth: 540,
        },
        '& ul': {
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          // listStyleType: 'square',
          '& li': {
            width: '100%',
            // display: 'flex',
            // alignItems: 'center',
            textAlign: 'left',
            marginBottom: 10,
            // fontWeight: 500,
          },
        },
      },
      '& .w-50': {
        width: '45%',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          width: '100%',
        },
      },
      '& .w-60': {
        width: '60%',
      },
      '& .w-40': {
        width: '35%',
      },
    },
  },
  topSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(7),
  },
  text: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'linear-gradient(140.75deg, #FDFDFD 3.18%, rgba(253, 253, 253, 0.72) 98.85%)',
    boxShadow: '0px 2.66804px 38.0196px rgba(0, 0, 0, 0.1)',
    borderRadius: 10,
    width: '100%',
    padding: 20,
    marginBottom: theme.spacing(5),
    '& h3': {
      color: '#000',
      fontSize: 20,
      width: '100%',
      marginBottom: 20,
      textTransform: 'uppercase',
      [theme.breakpoints.only('sm')]: {
        fontSize: 18,
      },
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 18,
      },
    },

    '& p': {
      color: '#000',
      fontSize: 16,
      width: '100%',
      marginBottom: 20,
      [theme.breakpoints.only('sm')]: {
        fontSize: 14,
      },
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 14,
      },
    },
  },
  banner: {
    height: '100%',
    minHeight: '16vw',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 70,
    padding: 70,
    backgroundImage: `url("assets/images/home_banner.png")`,
    backgroundSize: '100% 100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      paddingTop: 100,
      backgroundSize: 'cover',
      backgroundPosition: '90%',
    },
    '& h1': {
      color: '#fff',
      fontSize: 40,
      fontWeight: 700,
      fontFamily: "'Outfit', sans-serif",
      width: '100%',
      textTransform: 'uppercase',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 30,
      },
    },
  },
}));

const WahtEstate = () => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.root}>
        <div className={classes.topSection}>
          <div className={classes.banner}>
            <h1>real estate nft</h1>
          </div>
        </div>

        <div className={classes.section}>
          <h1>What is a real estate nft?</h1>
          <div className={classes.text}>
            <h3>revolutionizing the real estate industry</h3>
            <p>
              Each Real Estate NFT we mint is fractionalized and 100% backed by an actual physical income-generating property. All properties have been inspected beforehand and are owned by Metropoly Ltd or a trusted and vetted partner company.
            </p>
          </div>

          <h1>BENEFITS</h1>
          <div className={classes.text}>
            <h3>PAssive income</h3>
            <p>
              Metropoly LTD will offer income-generating Real Estate NFTs, divided into Holiday Homes and Long-term Rentals. After deducting the costs (detailed in the financial breakdown), the income generated by the rents is paid monthly to the owners in proportion to their shares.
            </p>
          </div>
          <div className={classes.text}>
            <h3>APPRECIATION IN VALUE</h3>
            <p>
              As in real life, the value of a property usually increases over time. The same applies to Real Estate NFTs, but this is not guaranteed, and the opposite may even be the case. Therefore, for our Metropoly members, we provide value appreciation estimates based on the price development of the last years.
            </p>
          </div>

          <div className={classes.text}>
            <h3>DAO RIGHTS</h3>
            <p>
              Each owner of a Real Estate NFT has a voting right proportional to the number of fractions he holds and can vote to sell the property in the real world. This function will be included in the 2nd version of the Metropoly Marketplace in the dashboard section.
            </p>
          </div>
          <img src="/assets/whatEstate/img_01.svg" alt="" className="img1" />

          <h1>FeatureS</h1>
          <ul className="feature">
            <li>
              <img src="/assets/whatEstate/arrow.svg" alt="" />
              Buy/Mint Real Estate NFTs at Any Time
            </li>
            <li>
              <img src="/assets/whatEstate/arrow.svg" alt="" />
              Sell Real Estate NFTs at Any Time
            </li>
            <li>
              <img src="/assets/whatEstate/arrow.svg" alt="" />
              Borrow Against Real Estate NFTs
            </li>
            <li>
              <img src="/assets/whatEstate/arrow.svg" alt="" />
              Auction Real Estate NFTs
            </li>
          </ul>
          <div className="row">
            <div className="col w-50">
              <img src="/assets/whatEstate/img_02.svg" alt="" />
            </div>
            <div className="col w-50">
              <img src="/assets/whatEstate/img_03.svg" alt="" />
            </div>
          </div>

          <h1>QR Code and Deal Sheet</h1>
          <div className={classes.text}>
            <h3>ALL INFORMATION IS PUBLICLY AVAILABLE</h3>
            <p>
              Each Real Estate NFT comes with a QR Code redirecting to a Deal Sheet to provide digital authenticity. This sheet includes all the background information and contracts tied to the property. This file can be modified and updated at any time by Metropoly Ltd. The data highlighted are only expectations based on various third-party sources and no financial advice.
            </p>
          </div>

          <div className="row">
            <div className="col w-50">
              <h1>Don't worry about property management</h1>
              <p>
                We take care of everything by partnering up with professional local property management companies that
                will manage all of the real estate tied to the NFTs. All partner companies will be thoroughly vetted by
                the Metropoly team. The fees for this service are deducted directly from rents and are listed
                transparently in the financial breakdown.
              </p>

              <p>These are the main advantages of hiring a property manager:</p>
              <ul>
                <li>Dealing with tenants (contact, support, etc)</li>
                <li>Maintenance of the property
                </li>
                <li>Key account manager</li>
                <li>Advertising the property to find new tenants</li>
              </ul>
            </div>
            <div className="col w-50">
              <img src="/assets/whatEstate/img_04.svg" alt="" />
            </div>
          </div>
          <h1>Transfer physical ownership</h1>
          <div className="row">
            <div className="col w-50">
              <div className={classes.text}>
                <h3>Transfer ownership and move in</h3>
                <p>
                  We cannot transfer a property’s physical ownership for each transaction unless a user has purchased
                  all available fractions and wishes to transfer the ownership because this would exclude many people.
                  Accordingly, it would not be possible to build a global, decentralized real estate NFT marketplace
                  backed by physical properties. However, with our solution, we can ensure that everyone receives the
                  value of the real estate and can sell their shares without any problems at any given moment.
                </p>

                <p>
                  REAL ESTATE NFT HOLDERS DO NOT OWN THE PHYSICAL PROPERTY. Rather, the owner remains Metropoly or a
                  partner company unless a user purchases all fractions of a real estate property and subsequently
                  decides to burn the NFT and transfer ownership. Metropoly will provide professional assistance when it
                  comes to the property transfer.
                </p>

                <p>
                  In order to transfer property ownership, the respective country’s laws must be respected. Most of the
                  time, this includes immigration, visa, registration in the land register, notary appointments, and
                  taxes. Depending on a variety of factors, this process can take up to three months or longer.
                </p>
              </div>
            </div>
            <div className="col w-50">
              <img src="/assets/whatEstate/img_05.svg" alt="" />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default WahtEstate;
