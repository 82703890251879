import './shareModal.scss';
import Bounce from 'react-reveal/Bounce';
import { useEffect, useState } from 'react';
interface Props {
  showModal: boolean;
  setShowModal?: any;
}

const ShareModal: React.FC<Props> = ({ showModal, setShowModal }) => {
  const [isStart, setIsStart] = useState(false);
  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        setIsStart(true);
      }, 100);
    }
  }, [setIsStart, showModal]);
  const onClose = () => {
    setIsStart(false);
    setTimeout(() => {
      setShowModal(false);
    }, 800);
  };

  return (
    <div className={showModal === true ? 'shareModal active' : 'shareModal'}>
      <Bounce opposite when={isStart}>
        <div className="modelContent">
          <div className="connectWalletHeader">
            <h1 className="connectWalletTitle">Share</h1>
            <button className="connectModalCloseButton" onClick={onClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="connectWalletWrapper">
            <h3>Share this link via</h3>
            <div className="socials">
              <a href="https://telegram.org/" target="_blank" rel="noreferrer">
                <i className="fab fa-telegram"></i>
              </a>
              <a href="https://twitter.com" target="_blank" rel="noreferrer">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://instagram.com" target="_blank" rel="noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://discord.com" target="_blank" rel="noreferrer">
                <i className="fab fa-discord"></i>
              </a>
              <a href="/" target="_blank" rel="noreferrer">
                <i className="fas fa-phone"></i>
              </a>
              <a href="https://youtube.com" target="_blank" rel="noreferrer">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
            <h3>Or Copy link</h3>
            <div className="row">
              <span>
                <input type="text" placeholder="Metropoly.com/share-link" />
                <button>Copy</button>
              </span>
            </div>
          </div>
        </div>
      </Bounce>
    </div>
  );
};
export default ShareModal;
