import { useWeb3React } from '@web3-react/core';
import ConnectModal from 'components/connectModal/ConnectModal';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { getBalanceOfQUINT } from 'utils/contracts';
import './topbar.scss';
import UserDropDown from 'components/Menus/UserDropDown';
import WalletDropDown from 'components/Menus/WalletDropDown';
import { useMoralis } from 'react-moralis';
import toast from 'react-hot-toast';

const notificationData = [
  {
    img: '/assets/inbox/user_01.png',
    name: 'Jenny, Property Manager in Ibiza',
    netid: '5238',
    text: 'Monthly Statement Villa Ibiza',
    time: '16:00',
    message: [
      'Hey @KevinCranel,',
      'I hope that you are doing great well 😊',
      'Here is your monthly statement for the villa in Ibiza, Spain. I have good news for you.',
      'Currently rates are going up in the summer season.',
      'We have experienced more demand than expected. You will find all the related information in the attached statement. Here you can find the latest payouts.',
      'Best,',
      'Jenny',
    ],

    property: {
      img: '/assets/inbox/img_01.png',
      type: 'Holiday Home',
      startPrice: 2600,
      name: 'Stunning 5-Bedroom Villa Next to the Beach',
      location: 'Ibiza, Spain',
      homeState: 'Vacant',
      beds: 5,
      state: {
        expected: 74,
        yeld: 12.79,
        frequency: 'Monthly',
      },
    },
    book: 'MonthlyStatementJune2022.pdf',
  },
  {
    img: '/assets/logo_icon.png',
    name: 'Metropoly',
    text: 'New Real Estate NFTs in Dubai...',
    time: '18:00',

    message: [
      'Hey @KevinCranel,',
      'Great news! We just listed a new apartment in Dubai.',
      'As you may have heard, Dubai is one of the top 10 tourist destinations in the world, bringing endless opportunities for real estate NFT owners.',
      'You don’t want to miss out. Check it out now!',
      'Best,',
      'Metropoly',
    ],

    property: {
      img: '/assets/inbox/img_02.png',
      type: 'Rental Property',
      startPrice: 840,
      estatePrice: 840000,
      name: 'Rare 2-Bedroom Apartment in Downtown',
      location: 'Dubai, United Arab Emirates',
      homeState: 'Vacant',
      beds: 2,
      state: {
        expected: 57,
        yeld: 9.42,
        frequency: 'Monthly',
      },
    },
  },
  {
    img: '/assets/logo_icon.png',
    name: 'Metropoly',
    text: 'Congratulations! You’re Now a...',
    time: '08:00',
    message: [
      'Hey @KevinCranel,',
      'Congratulations! You have achieved silver status. Now, you can experience a world of alluring bonuses and rewards. Visit our website to learn more about silver membership perks and how to take advantage of them.',
      "Do you have higher ambitions? Learn how to achieve gold status and join Metropoly's exclusive circle to receive unparalleled benefits.",
      'Head over to our website to learn more!',
      'Best,',
      'Metropoly',
    ],
  },
  {
    img: '/assets/inbox/user_02.png',
    name: 'Patrick, Marketplace Man..',
    text: '23% Price Increase. The Floor ...',
    time: '11:00',
    message: [
      'Hey @KevinCranel,',
      'I hope that you are doing well! 😊',
      'Good news! Your villa in San Francisco now has a floor price of $7,500 USD after experiencing a 23% increase.',
      'It is expected that the floor price will increase by 10% during the coming months because there is high demand for villas in San Francisco, in large part due to the predicted price hike towards the end of September.',
      'Note that I have also created a list for you with interesting villas that are expected to go up. You can find them in the PDF attached below.',
      'Best,',
      'Patrick',
    ],

    property: {
      img: '/assets/inbox/img_03.png',
      type: 'Rental Property',
      startPrice: 7000,
      estatePrice: 7000000,
      name: 'Luxury 5-Bedroom Villa with a Pool',
      location: 'San Francisco,USA',
      homeState: 'Rented',
      beds: 5,
      state: {
        expected: 37,
        yeld: 5.3,
        frequency: 'Monthly',
      },
    },
    book: 'VillasJuly2022.pdf',
  },
  {
    img: '/assets/inbox/user_03.png',
    name: 'Darrell, Property Manager',
    text: 'Monthly Statement House Dubai',
    time: '08:30',
    message: [
      'Hey @KevinCranel,',
      'I hope that you are well.',
      'Attached, please find your monthly statement for the Dubai villa below.',
      'Fortunately, I have great news! The demand exceeded expectations, and rates are increasing as a result. The statement that is included has all of the necessary information and the latest payouts.',
      'Best,',
      'Darrell',
    ],
    property: {
      img: '/assets/inbox/img_04.png',
      type: 'Holiday Home',
      homeState: 'Vacant',
      startPrice: 1600,
      // estatePrice: 7000000,
      name: 'Stunning 3-Bedroom Villa Next to the Beach',
      location: 'Dubai, United Arab Emirates',
      beds: 5,
      state: {
        expected: 66,
        yeld: 11.2,
        frequency: 'Monthly',
      },
    },
    book: 'MonthlyStatementJune2022.pdf',
  },
  {
    img: '/assets/inbox/user_04.png',
    name: 'Nadia, Property Manager',
    text: 'Monthly Statement Villa Paris',
    time: '13:00',
    message: [
      'Hey @KevinCranel,',
      'I hope that you are doing well! 😊',
      'Here is your monthly statement for the villa in Paris, France.',
      'I am pleased to share that I have some good news! Demand was higher than anticipated; therefore, we increased the rate. The statement below contains all of the necessary details and payouts.',
      'Best,',
      'Nadia',
    ],
    property: {
      img: '/assets/inbox/img_05.png',
      type: 'Holiday Home',
      homeState: 'Vacant',
      startPrice: 600,
      // estatePrice: 7000000,
      name: '4-Bedroom Family House with a Garden',
      location: 'Paris, France',
      beds: 5,
      state: {
        expected: 51,
        yeld: 8.2,
        frequency: 'Monthly',
      },
    },
    book: 'MonthlyStatementJune2022.pdf',
  },
  {
    img: '/assets/logo_icon.png',
    name: 'Metropoly',
    text: 'New Marketplace Updates!',
    time: '09:30',
    message: [
      'Hey @KevinCranel,',
      'We updated the market while taking our communities concerns into consideration!',
      'Our website now has a number of additional features. One of those recent features is the new auction page on which users can bid to get real estate NFTs at the lowest possible price. This is just one of the amazing changes that we have incorporated.',
      'Head over to our website and browse through all the new marketplace updates!',
      'Check it out now!',
      'Best,',
      'Metropoly',
    ],
  },
  {
    img: '/assets/inbox/user_05.png',
    name: 'Eleanor, Property Manager',
    text: 'Rent Update in San Francisco',
    time: '09:90',
    message: [
      'Hey @KevinCranel,',
      'I hope that all is well with you!',
      'The rent in this area dropped 1.25% during the previous quarter due to construction work in the neighborhood. Please feel free to reach out to us if you have any questions!',
      'All the best,',
      'Eleanor',
    ],
    property: {
      img: '/assets/inbox/img_06.png',
      type: 'Rental Property',
      homeState: 'Rented',
      startPrice: 35000,
      // estatePrice: 7000000,
      name: 'Luxury 5-Bedroom Villa with a Pool',
      location: 'San Francisco, USA',
      beds: 5,
      state: {
        expected: 37,
        yeld: 5.3,
        frequency: 'Monthly',
      },
    },
  },
  {
    img: '/assets/logo_icon.png',
    name: 'Metropoly',
    text: 'Upcoming Real Estate Projects.. ',
    time: '12:00',
    message: [
      'Hey @KevinCranel,',
      'We have some exciting news for you!',
      "We have a variety of new real estate projects coming in the next few months. We are sure you don't want to miss out on them.",
      "Don't worry, we have got you covered! We've included a link with details on all the upcoming projects. Have a look at them.",
      'You can also visit our website and browse through our new projects.',
      'All the best,',
      'Metropoly',
    ],
  },
  {
    img: '/assets/inbox/user_06.png',
    name: 'Sophie, Customer Support',
    text: 'Request No. 2165 has been pr..',
    time: '12:30',
    message: [
      'Hey @KevinCranel,',
      'I hope that you are well.',
      'Your request No. 2165 has been received and will be processed by the Metropoly support team.',
      'You should receive a message from our team within three business days. If you have any additional information to add to the request, please feel free to reach out using the contact form and include your request number.',
      'All the best,',
      'Sophie',
    ],
  },
  {
    img: '/assets/logo_icon.png',
    name: 'Metropoly',
    text: 'New Marketplace Features...',
    time: '14:00',
    message: [
      'Hey @KevinCranel,',
      'We have some exciting news for you!',
      'After listening to requests from our community, we have added a number of new features to the Metropoly website, including credit card payments. Browse through these features by heading over to our website.',
      "Don't forget to fill out the website's feedback form to help improve our platform.",
      'All the best,',
      'Metropoly',
    ],
  },

  {
    img: '/assets/inbox/user_07.png',
    name: 'Alfeen, Marketplace Man..',
    text: '16% price increase in Stockholm',
    time: '17:00',
    message: [
      'Hi @KevinCranel,',
      'I hope that you are doing well! We have some exciting news for you!',
      'The price of your home in Stockholm, Sweden, has now increased by 16%. Furthermore, the increasing demand for real estate is expected to cause the floor price to rise by another 20% during the coming months. By the end of the year, there is likely to be an increase in demand for real estate in Sweden.',
      'Here is a list of Swedish properties that should increase in value by upwards of 30% during the coming months.',
      'Best,',
      'Alfeen',
    ],

    property: {
      img: '/assets/inbox/img_07.png',
      type: 'Rental Property',
      startPrice: 725,
      name: 'Modern 3-Bedroom Family Home',
      location: 'Stockholm, Sweden',
      homeState: 'Vacant',
      beds: 5,
      state: {
        expected: 51,
        yeld: 8.28,
        frequency: 'Monthly',
      },
    },
    book: 'SwedishHomeReport.pdf',
  },
  {
    img: '/assets/logo_icon.png',
    name: 'Metropoly',
    text: 'New Marketplace Updates',
    time: '13:20',
    message: [
      'Hey @KevinCranel,',
      'We updated the market while taking our community’s concerns into consideration!',
      'Our website now has a number of additional features, one of which is the new lend/borrow feature. Now you can lend or borrow money with the NFTs you own. Isn’t that amazing?',
      'Be sure to head over to our website and browse through all of the new updates! Check it out now!',
      'Best,',
      'Metropoly',
    ],
  },
];
type MenuType = {
  menuOpen?: boolean;
  setMenuOpen?(flag: boolean): void;
};
export default function Topbar({ menuOpen, setMenuOpen }: MenuType) {
  const [showConnectModal, setShowConnectModal] = useState(false);

  const { Moralis, user, isAuthenticated, authenticate, isInitialized, initialize, enableWeb3, isWeb3Enabled } = useMoralis();
  const [loginStatus, setLoginStatus] = useState(false);
  // const { connector, library, chainId, account, active } = useWeb3React();
  let [QUINTBalance, setQUINTBalance] = useState('0.00');
  useEffect(() => {
    // const isLoggedin = account && active && chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);

    setLoginStatus(isAuthenticated);

  }, [isAuthenticated, isInitialized, isWeb3Enabled]);

  let displayName = '';
  let userAvatar = '/assets/images/users/default-profile.png';

  const [showNotification, setShowNotification] = useState(false);
  const onShowNotification = () => {
    setShowNotification(!showNotification);
  };
  const ref = useRef(null);
  const handleOutsideClick = e => {
    setShowNotification(false);
  };
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handleOutsideClick(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref]);

  const history = useHistory();
  return (
    <div className="nav-background">
      <div className="topbar">
        <div className="logo">
          <HashLink to="/">
            <img src="/assets/logo.png" alt="" />
            <h1>Metropoly</h1>
          </HashLink>
        </div>
        <div className="btns">
          {!loginStatus ?
            <button className="setting" onClick={() => toast.error("Please connect your wallet.")}>
              <img src="/assets/images/setting_icon.svg" alt="" />
            </button>
            :
            <button className="setting" onClick={() => history.push('/settings')}>
              <img src="/assets/images/setting_icon.svg" alt="" />
            </button>
          }
          <div className="dropdown">

            {!loginStatus ?
              <button className="setting" onClick={() => toast.error("Please connect your wallet.")}>
                <img src="/assets/images/bell_icon.svg" alt="" />
              </button>

              :
              <button className="setting" onClick={onShowNotification}>
                <img src="/assets/images/bell_icon.svg" alt="" />
              </button>

            }
            <div ref={ref} className={`dropdown-menu postion-right ${showNotification ? 'active-down' : ''}`}>
              <div className="dropdown-body">
                <div className="nk-notification">
                  {notificationData.map((d, i) => (
                    <div
                      className="nk-notification-item dropdown-inner"
                      key={i}
                      onClick={() => {
                        history.push(`/inbox/${i}`);
                        setShowNotification(false);
                      }}
                    >
                      <div className="nk-notification-icon">
                        <img src={d.img} alt="" />
                      </div>
                      <div className="nk-notification-content">
                        <div className="nk-notification-text">
                          <h3>{d.name}</h3>
                          <p>{d.text}</p>
                        </div>
                        <div className="nk-notification-time">{d.time}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <!-- .nk-dropdown-body --> */}
              <div
                className="dropdown-foot center"
                onClick={() => {
                  setShowNotification(false);
                }}
              >
                <HashLink to="/inbox/0">Inbox</HashLink>
              </div>
            </div>
          </div>

          <div className="connectBtn">
            {isAuthenticated && user ? (
              <UserDropDown
                avatarUrl={userAvatar}
                displayName={displayName}
                balance={QUINTBalance}
                walletAddress={user.get("ethAddress")}
              />
            ) : (
              <WalletDropDown
                avatarUrl={userAvatar}
                displayName={displayName}
                balance={QUINTBalance}
                walletAddress={'Connect Wallet'}
              />

              // <FilledButton
              //   handleClick={() => {
              //     setShowConnectModal(true);
              //   }}
              //   label={
              //     <>
              //       <img src="/assets/images/wallet.svg" alt="" />
              //       {loginStatus ? truncateWalletString(account) : 'Connect Wallet'}
              //     </>
              //   }
              // ></FilledButton>
            )}
          </div>
        </div>
        <div className={menuOpen ? 'hamburger active' : 'hamburger'} onClick={() => setMenuOpen(!menuOpen)}>
          <span className="line1"></span>
          <span className="line2"></span>
          <span className="line3"></span>
        </div>
        <ConnectModal showConnectModal={showConnectModal} setShowConnectModal={setShowConnectModal} />
      </div>
    </div>
  );
}
