import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  table: {
    '& .MuiTableCell-root': {
      color: '#000',
      cursor: 'pointer',
      fontWeight: 600,
      minWidth: 120,
      [theme.breakpoints.only('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
        fontSize: 12,
        minWidth: 'auto',
      },
    },
    '& .MuiTableRow-root': {
      borderBottom: `1px solid #ddd`,
      borderRadius: 10,
      // marginBottom: 10,
      borderSpacing: '5em',
      borderCollapse: 'separate',
      '@media screen and (max-width: 450px) and (orientation: portrait)': {},
    },
    '& .MuiTableHead-root .MuiTableCell-root': {
      fontSize: 16,
      color: '#000',
      [theme.breakpoints.only('sm')]: {
        fontSize: 12,
        paddingLeft: 8,
      },
      '@media screen and (max-width: 450px) and (orientation: portrait)': {
        fontSize: 14,
      },
    },
  },
  cell: {
    display: 'flex !important',
    alignItems: 'center',
    fontWeight: 600,
    // justifyContent: 'center',
    // flexDirection: 'row',
    color: '#000',
    '& img': {
      width: 60,
      height: 60,
      borderRadius: 7,
      marginLeft: 10,
      marginRight: 10,
    },
    '& h5': {
      width: 'fit-content',
      marginLeft: 10,
      fontSize: 16,
      color: '#000',
      [theme.breakpoints.only('sm')]: {
        fontSize: 12,
      },
    },
    '& p': {
      width: 'fit-content',
      marginLeft: 10,
      fontSize: 12,
      color: '#000',
    },
    '& button': {
      border: 'none',
      fontSize: 20,
      color: '#222',
      fontWeight: 900,
      background: '#ffffff00',
    },
  },
  container: {
    borderRadius: 20,
    // padding: 20,
    border: `1px solid #ddd`,
    boxShadow: '3px 3px 15px #eee',
  },
}));

const PayoutTable = ({ columns, rows, type }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column, k) => (
              <TableCell style={{ color: '#000' }} key={k}>
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, kk) => (
            <TableRow key={kk}>
              <TableCell style={{ color: '#000' }}>
                <div className={classes.cell} onClick={() => history.push(`/detail/${row?.id}`)}>
                  <img src={row.img} alt="" />
                  <span>
                    <h5> {row.name}</h5>
                  </span>
                </div>
              </TableCell>
              <TableCell style={{ color: '#000' }}>{row.date}</TableCell>
              <TableCell style={{ color: '#000' }}>{row.price}</TableCell>
              <TableCell style={{ color: '#000' }}>{row.owned}</TableCell>
              <TableCell style={{ color: '#000' }}>{row.payout}</TableCell>
              <TableCell style={{ color: '#000' }}>
                <span style={{ color: row.status === 'Paid' ? '#13CF8F' : '#F9CA5D' }}>●</span> {row.status}
              </TableCell>
              <TableCell style={{ color: '#000' }}>
                <div className={classes.cell}>
                  <button>...</button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default PayoutTable;
