import { useStyles } from './style';
import Masonry from 'react-masonry-css';
import ProductCard1 from 'components/Cards/ProductCard1';
const myData = [
  {
    id: 0,
    img: '/assets/images/unsplash_IYfp2Ixe9nM.png',
    type: 'Rental Property',
    startPrice: 5200,
    estatePrice: 5200000,
    name: 'Beautiful 5-Bedroom Villa with an Incredible View',
    location: 'Los Angeles, United States of America',
    homeState: 'Rented',
    beds: 5,
    state: {
      expected: 49,
      yeld: 7.79,
      frequency: 'Monthly',
    },
    isFavorite: true,
  },
  {
    id: 1,
    img: '/assets/images/unsplash_IYfp2Ixe9nM (1).png',
    type: 'Rental Property',
    startPrice: 280,
    estatePrice: 280000,
    name: 'Cozy 1-Bedroom Apartment in the City Center',
    location: 'London, United Kingdom',
    homeState: 'Rented',
    beds: 1,
    state: {
      expected: 32,
      yeld: 4.48,
      frequency: 'Monthly',
    },
    isFavorite: true,
  },
];

const Favorites = () => {
  const classes = useStyles();
  const breakpointColumnsObj = {
    // default: 4,
    3840: 7,
    3000: 6,
    2560: 5,
    2200: 4,
    1840: 3,
    1440: 3,
    1280: 2,
    768: 2,
    450: 1,
  };

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <span>
          <h1 className={classes.title}>Favorites</h1>
          <p>All of your favorite real estate NFTs in one place.</p>
        </span>
      </div>
      <div className={classes.content}>
        <Masonry breakpointCols={breakpointColumnsObj} className={classes.masonry} columnClassName={classes.gridColumn}>
          {myData.map((d, i) => (
            <ProductCard1 key={i} product={d} />
          ))}
        </Masonry>
      </div>
    </div>
  );
};

export default Favorites;
