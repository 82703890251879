import { BrowserRouter as Switch, Route } from 'react-router-dom';
import Layout from 'components/Layout';
import Home from 'containers/Home';

import Inbox from 'containers/Inbox';
import Setting from 'containers/Setting';
import Marketplace from 'containers/Marketplace';
import ProductDetail from 'containers/ProductDetail';
import Upcoming from 'containers/Upcoming';
import Auctions from 'containers/Auctions';
import Mortgages from 'containers/Mortgages';
import Favorites from 'containers/Favorites';
import Profile from 'containers/Profile';
import MyProperties from 'containers/MyProperties';
import Overview from 'containers/Overview';
import Transactions from 'containers/Transactions';
import Payout from 'containers/Payout';
import HowItWorks from 'containers/FooterPages/HowItWorks';
import WhyMetropoly from 'containers/FooterPages/WhyMetropoly';
import WahtEstate from 'containers/FooterPages/WahtEstate';
import Calculator from 'containers/FooterPages/Calculator';
import KeyRisk from 'containers/FooterPages/KeyRisk';
import FAQ from 'containers/FooterPages/FAQ';
import AboutUs from 'containers/FooterPages/AboutUs';
import ContactUs from 'containers/FooterPages/ContactUs';
import CookiePolicy from 'containers/FooterPages/CookiePolicy';
import PrivacyPolicy from 'containers/FooterPages/PrivacyPolicy';
import TermsOfService from 'containers/FooterPages/TermsOfService';
import Voting from 'containers/Voting';
import NewProposal from 'containers/NewProposal';
import Report from 'containers/FooterPages/Report';
import ScrollToTop from 'utils/scrollToTop';

const Routes = () => (
  <>
    <Switch>
      <Layout>
        <ScrollToTop />
        <Route exact path="/" component={Home} />
        <Route exact path="/inbox" component={Inbox} />
        <Route exact path="/inbox/:inboxID" component={Inbox} />
        <Route exact path="/settings" component={Setting} />
        <Route exact path="/marketplace" component={Marketplace} />
        <Route exact path="/upcoming" component={Upcoming} />
        <Route exact path="/auctions" component={Auctions} />
        <Route exact path="/mortgages" component={Mortgages} />
        <Route path="/favorites" component={Favorites} />
        <Route path="/detail/:tokenID" component={ProductDetail} />
        <Route path="/profile" component={Profile} />
        <Route path="/my-properties" component={MyProperties} />
        <Route path="/overview" component={Overview} />
        <Route path="/transactions" component={Transactions} />
        <Route path="/payouts" component={Payout} />
        <Route path="/how_works" component={HowItWorks} />
        <Route path="/why-metropoly" component={WhyMetropoly} />
        <Route path="/what-is-estate" component={WahtEstate} />
        <Route path="/calculator" component={Calculator} />
        <Route path="/faq" component={FAQ} />
        <Route path="/key-risk" component={KeyRisk} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/cookie_policy" component={CookiePolicy} />
        <Route path="/privacy_policy" component={PrivacyPolicy} />
        <Route path="/terms_of_service" component={TermsOfService} />
        <Route path="/voting" component={Voting} />
        <Route path="/new-proposal" component={NewProposal} />
        <Route path="/report" component={Report} />

        {/* <Route exact path="/" render={() => <Redirect to="/home" />} /> */}
        {/* <PrivateRoute
          path="/home" // Home
          component={Home}
          layout={Layout}
        />
        <PrivateRoute
          path="/inbox" // Inbox
          component={Inbox}
          layout={Layout}
        />

        <PrivateRoute
          path="/settings" // Setting
          component={Setting}
          layout={Layout}
        />

        <PrivateRoute
          path="/marketplace" // Marketplace
          component={Marketplace}
          layout={Layout}
        />

        <PrivateRoute
          path="/upcoming" // Upcoming
          component={Upcoming}
          layout={Layout}
        />

        <PrivateRoute
          path="/auctions" // Auctions
          component={Auctions}
          layout={Layout}
        />

        <PrivateRoute
          path="/mortgages" // Mortgages
          component={Mortgages}
          layout={Layout}
        />

        <PrivateRoute
          path="/favorites" // Favorites
          component={Favorites}
          layout={Layout}
        />

        <PrivateRoute
          path="/detail/:tokenID" // ProductDetail
          component={ProductDetail}
          layout={Layout}
        />

        <PrivateRoute
          path="/profile" // Profile
          component={Profile}
          layout={Layout}
        />

        <PrivateRoute
          path="/my-properties" // MyProperties
          component={MyProperties}
          layout={Layout}
        />

        <PrivateRoute
          path="/overview" // Overview
          component={Overview}
          layout={Layout}
        />

        <PrivateRoute
          path="/transactions" // Transactions
          component={Transactions}
          layout={Layout}
        />

        <PrivateRoute
          path="/payouts" // Payout
          component={Payout}
          layout={Layout}
        />

        <PrivateRoute
          path="/how_works" //
          component={HowItWorks}
          layout={Layout}
        />
        <PrivateRoute
          path="/why-metropoly" //
          component={WhyMetropoly}
          layout={Layout}
        />
        <PrivateRoute
          path="/what-is-estate" //
          component={WahtEstate}
          layout={Layout}
        />

        <PrivateRoute
          path="/calculator" //
          component={Calculator}
          layout={Layout}
        />

        <PrivateRoute
          path="/key-risk" //
          component={KeyRisk}
          layout={Layout}
        />

        <PrivateRoute
          path="/faq" //
          component={FAQ}
          layout={Layout}
        />

        <PrivateRoute
          path="/about-us" //
          component={AboutUs}
          layout={Layout}
        />

        <PrivateRoute
          path="/contact-us" //
          component={ContactUs}
          layout={Layout}
        />

        <PrivateRoute
          path="/cookie_policy" //
          component={CookiePolicy}
          layout={Layout}
        />

        <PrivateRoute
          path="/privacy_policy" //
          component={PrivacyPolicy}
          layout={Layout}
        />

        <PrivateRoute
          path="/terms_of_service" //
          component={TermsOfService}
          layout={Layout}
        />

        <PrivateRoute
          path="/calculator" //
          component={Calculator}
          layout={Layout}
        />

        <PrivateRoute
          path="/voting" //
          component={Voting}
          layout={Layout}
        />
        <PrivateRoute
          path="/new-proposal" //
          component={NewProposal}
          layout={Layout}
        />
        <PrivateRoute
          path="/report" //
          component={Report}
          layout={Layout}
        /> */}
      </Layout>
    </Switch>
  </>
);

export default Routes;
