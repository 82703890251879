import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/Layout/Container';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 15,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
      paddingTop: 0,
    },
  },

  section: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 20,
    '& p': {
      color: '#000',
      fontSize: 14,
      width: '100%',
      marginBottom: 20,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 12,
      },
    },
    '& h2': {
      color: '#000',
      fontSize: 20,
      width: '100%',
      marginBottom: 20,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 18,
      },
    },
  },
  topSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(7),
  },

  banner: {
    height: '100%',
    minHeight: '16vw',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 70,
    padding: 70,
    backgroundImage: `url("assets/images/home_banner.png")`,
    backgroundSize: '100% 100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      paddingTop: 100,
      backgroundSize: 'cover',
      backgroundPosition: '90%',
    },
    '& h1': {
      color: '#fff',
      fontSize: 40,
      fontWeight: 700,
      fontFamily: "'Outfit', sans-serif",
      width: '100%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 30,
      },
    },
  },
}));

const KeyRisk = () => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.root}>
        <div className={classes.topSection}>
          <div className={classes.banner}>
            <h1>KEY RISK</h1>
          </div>
        </div>
        <div className={classes.section}>
          <p>
            Investments in property and NFTs carry a risk. Your capital may be at risk, and you may not receive the
            anticipated returns. Using credit or borrowed funds to invest in Real Estate NFTs carries a more significant
            threat and is not recommended under any circumstances.
          </p>
          <h2>Key Risks</h2>
          <p>
            Metropoly does not remove any risks you may experience should you acquire an NFT residential property
            directly and outright. Some additional threats are introduced by shared ownership and the timing of your
            exit. We encourage you to diversify your Metropoly investments across multiple properties to safeguard
            against excessive exposure to any property that could incur issues such as tenant default or
            property-specific issues that impact valuation.
          </p>
          <h2>Variable Income</h2>
          <p>
            While Metropoly provides gross rental income estimates based on third-party information, these are not
            guaranteed. It may be that lower rents are secured. Furthermore, rental income could cease entirely for
            specific periods. From time to time, vacancies can be expected to arise in the operation of real estate
            assets. Sizable vacancies may sometimes mean less cash is available for distribution to investors. As a
            result, investment in Real Estate NFTs is speculative, the property’s market value can fall, and rental
            income is guaranteed with our Rental Income Insurance but can decrease in value.
          </p>
          <p>
            Forecasts and past performance are not reliable indicators of future performance. The real estate market can
            experience a downturn that affects property valuation. The value of your Metropoly investment can go down
            and up, and historical performance is not a guide to future performance. Achievement of rental and capital
            returns will depend on various factors, including the property asset and the broader economy. A fall in the
            value of your investment may be due to several reasons, such as a fall in the underlying value of the
            property or a problem with the property that will need to be funded from future rental income. In addition,
            real estate investments can perform cyclically, and values may increase or decrease accordingly.
          </p>
          <p>
            Economic, political, and legal issues can affect values like other asset classes. In some cases, government
            restrictions on selling a property to foreign owners may restrict the range of potential buyers. Any future
            downturn in the real estate market could materially adversely affect the Property’s value, resulting in
            partial or complete loss and Income generated from property investment. Investors are to individually assess
            and establish their comfort level with this risk from the outset. If the operator ceases to carry on its
            business, investors may lose their capital money, incur costs, or experience delays in the investment being
            wound up. Metropoly intends to operate in full compliance with applicable laws and regulations and use its
            best endeavors to obtain the necessary licenses and approvals. Regulatory licenses and permissions are
            required in several relevant jurisdictions where relevant activities may occur. This means that the
            development and roll-out of all the initiatives described in this website are not guaranteed.
          </p>
          <p>
            It is impossible to guarantee, and no person makes any representations, warranties, or assurances that any
            such licenses or approvals will be obtained within a particular timeframe or at all. As such, the
            initiatives described in this website may not be available in certain jurisdictions or at all. This could
            require restructuring these initiatives and their unavailability in all or certain respects. In addition,
            the development of any initiatives is intended to be implemented in stages. During certain stages of
            development, the project may rely on relationships with certain licensed third-party entities. If these
            entities are no longer licensed correctly in the relevant jurisdiction, this will impact the ability of
            Metropoly to rely on the services of that party.
          </p>
          <p>
            It is impossible to guarantee, and no person makes any representations, warranties, or assurances that any
            such licenses or approvals will be obtained within a particular timeframe or at all. As such, the
            initiatives described in this website may not be available in certain jurisdictions or at all. This could
            require restructuring these initiatives and their unavailability in all or certain respects. In addition,
            the development of any initiatives is intended to be implemented in stages. During certain stages of
            development, the project may rely on relationships with certain licensed third-party entities. If these
            entities are no longer appropriately licensed in the relevant jurisdiction, this will impact the ability of
            Metropoly to rely on the services of that party.
          </p>
          <h2>No Representations</h2>
          <p>
            No representations or warranties have been made to the recipient of this website or its advisers as to the
            accuracy or completeness of the information, statements, opinions, or matters (express or implied) arising
            out of, contained in, or derived from this website or any omission from this document or of any other
            written or oral information or opinions provided now or in the future to any interested party or their
            advisers. The Metropoly NFTs, as envisaged in this website, are under development and constantly updated,
            including but not limited to critical governance and technical features. When the Metropoly NFTs are
            completed, they may differ significantly from the description set out in this website. No representation or
            warranty is given regarding the achievement or reasonableness of any plans, future projections, or
            prospects. Nothing in this document is or should be relied upon as a promise or representation of the
            future.
          </p>
          <h2>Not Financial Advice</h2>
          <p>
            This website does not constitute any investment advice, financial advice, trading advice, or recommendation
            by Metropoly Ltd., its affiliates, or its respective officers, directors, managers, employees, agents,
            advisers, or consultants on the merits of purchasing Metropoly NFTs, nor should it be relied upon in
            connection with any other contract or purchasing decision. Metropoly does not provide any investment advice,
            and no assessment is made to determine if an investment is suitable for investors. All information is
            provided to help you make your own informed decisions. It is important to rely on one’s own due diligence
            before investing. If in doubt, please seek the advice of an independent financial adviser.
          </p>
          <h2>Not Securities</h2>
          <p>
            This website does not constitute a prospectus or financial service offering document. It is not an offer to
            sell or solicitation of an offer to buy any security, investment products, regulated products, or financial
            instruments in any jurisdiction. Real Estate NFTs are not being structured or sold as securities in
            Metropoly.io. Owners of Real Estate NFTs are not entitled to any rights in Metropoly.io or any of its
            affiliates, including any equity, shares, units, royalties to capital, profit, returns, or income in
            Metropoly.io or any other company or intellectual property associated with Metropoly.io.
          </p>
          <h2>Third-party Data</h2>
          <p>
            This webpage contains data and references obtained from third-party sources. While the company believes that
            these data are accurate and reliable, they have not been subject to independent audit, verification, or
            analysis by any professional legal, accounting, engineering, or financial advisers. As a result, there is no
            assurance of the data’s accuracy, reliability, or completeness.
          </p>
          <h2>Views</h2>
          <p>
            The views and opinions expressed in this website do not reflect the official policy or position of any
            government, quasi-government, authority, or public body (including but not limited to any regulatory body)
            in any jurisdiction. Accordingly, this website has not been reviewed by any regulatory authority.
          </p>
          <h2>Forward-Looking Statements</h2>
          <p>
            This website contains certain forward-looking statements regarding the business we operate based on the
            belief of certain information available to as well as certain assumptions made by Metropoly. Accordingly,
            forward-looking statements, by their nature, are subject to significant risks and uncertainties.
            Forward-looking statements may involve estimates and assumptions and are subject to other factors beyond our
            control and prediction. Accordingly, these factors could cause results or outcomes that differ materially
            from those expressed in the forward-looking statements. Any forward-looking statement speaks only as of the
            date of which the statement was made. We undertake no obligation to update forward-looking statements
            to reflect events or circumstances after the date on which the statement was made or to reflect the
            occurrence of unanticipated events.
          </p>
          <h2>Demonstrations</h2>
          <p>
            All the images used are only for demonstrations and are taken from royalty-free platforms to illustrate
            Metropoly’s vision, and the properties shown are not associated with Metropoly.
          </p>
          <h2>Third-Party References</h2>
          <p>
            References in this website to specific companies, networks, and potential use cases are for illustrative
            purposes only. Using any company and platform names and trademarks does not imply any affiliation with, or
            recommendation or endorsement of/by, any of those parties. All references to “dollars,” “USD,” or “$” are
            references to the United States Dollars unless otherwise stated.
          </p>
          <h2> Risk Statements</h2>
          <p>
            Purchasing Real Estate NFT involves substantial risk and may lead to a loss of a significant or entire
            amount of the money involved. Before purchasing Metropoly NFTs, you should carefully assess and consider the
            risks, including those listed in any other documentation. A purchaser should not purchase Metropoly NFTs for
            speculative or investment purposes. Purchasers should only purchase Metropoly NFTs if they fully understand
            the nature of the Metropoly NFTs and accept the risks inherent to the Metropoly NFTs. Cryptographic NFTs may
            be subject to expropriation and theft; hackers or other malicious groups or organizations may attempt to
            interfere with our system/network in various ways, including malware attacks, denial of service attacks,
            consensus-based attacks, Sybil attacks, smurfing, and spoofing, which may result in the loss of your
            cryptographic NFTs or the loss of your ability to access or control your cryptographic NFTs. In such an
            event, there may be no remedy, and holders of cryptographic NFTs are not guaranteed any treatment, refund,
            or compensation. The regulatory status of cryptographic NFTs and digital assets is currently unsettled,
            varies among jurisdictions, and is subject to significant uncertainty. It is possible that in the future,
            specific laws, regulations, policies, or rules relating to cryptographic NFTs, digital assets, blockchain
            technology, or blockchain applications may be implemented, which may directly or indirectly affect or
            restrict cryptographic NFT holders’ right to acquire, own, hold, sell, convert, trade, or use cryptographic
            NFTs. In addition, the uncertainty in tax legislation relating to cryptographic NFTs and digital assets may
            expose cryptographic NFT holders to tax consequences associated with using or trading a cryptographic NFT.
            Potential purchasers should consider all the above, independently assess their appetite for such risks, and
            consult their advisers before making any decisions.
          </p>
        </div>
      </Container>
    </>
  );
};

export default KeyRisk;
