import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/Layout/Container';
import InputField from '../../components/Forms/InputField';
import ErrorAlert from '../../components/Widgets/ErrorAlert';
import { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import toast from 'react-hot-toast';
import FilledButton from 'components/Buttons/FilledButton';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 15,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
      paddingTop: 0,
      textAlign: 'center',
    },
  },

  section: {
    position: 'relative',
    paddingTop: theme.spacing(0),
  },
  topSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(7),
  },
  top: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(5),
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      marginBottom: theme.spacing(2),
    },
  },
  myForm: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(7),
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
    },
    '& .row': {
      marginTop: 20,
      width: 'calc(50% - 20px)',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        width: '100%',
        marginTop: 5,
      },
    },
    '& p': {
      marginTop: 20,
      width: '100%',
      textAlign: 'start',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        width: '100%',
      },
    },
  },
  video: {
    width: '100%',
  },
  wrapper: {
    height: '100%',
    minHeight: '16vw',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 70,
    padding: 70,
    backgroundImage: `url("assets/images/report_banner.png")`,
    backgroundSize: '100% 100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      paddingTop: 100,
      backgroundSize: 'cover',
      backgroundPosition: '90%',
    },
    '& h1': {
      color: '#fff',
      fontSize: 40,
      fontWeight: 700,
      fontFamily: "'Outfit', sans-serif",
      width: '100%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 30,
      },
    },
  },
  formWrapper: {
    marginTop: 20,
    width: '100%',
  },

  myInput: {
    color: '#555',
    borderBottom: '1px #ddd solid',
    borderRadius: 0,
  },
}));

const Report = () => {
  const classes = useStyles();
  const [formSubmit, setFormSubmit] = useState(false);
  const [name, setName] = useState(null);
  const [mail, setMail] = useState(null);
  const [subject, setSubject] = useState(null);
  const [telegram, setTelegram] = useState(null);
  const [message, setMessage] = useState(null);

  const { library, account } = useWeb3React();

  const onSubmit = async data => {
    setFormSubmit(true);
    if (!account || !library) {
      toast.error('Please connect your wallet correctly!');
      return;
    }

    if (!name || !mail || !message || !subject || !telegram) {
      return;
    }
  };

  return (
    <>
      <Container className={classes.root}>
        <div className={classes.topSection}>
          <div className={classes.wrapper}>
            <h1>Report</h1>
          </div>
        </div>
        <div className={classes.top}>
          <h5>Have you found any errors or bugs? </h5>
          <h2>Get in touch with us!</h2>
        </div>
        <form
          className={classes.myForm}
          style={{
            backgroundColor: '#fff',
            borderRadius: '20px',
            padding: '20px',
            boxShadow: '0px 4px 100px rgba(202, 202, 202, 0.2)',
            marginBottom: 20,
          }}
          noValidate
          onSubmit={onSubmit}
        >
          <span className="row">
            <InputField
              name="name"
              className={classes.myInput}
              error={formSubmit && !name}
              wrapperClass={classes.formWrapper}
              label={<p>Your name*</p>}
              placeholder="Julia William"
              onChangeData={val => {
                setName(val);
              }}
            />
            <ErrorAlert title="Your Name Field is required !" show={formSubmit && !name} />
          </span>
          <span className="row">
            <InputField
              name="mail"
              className={classes.myInput}
              error={formSubmit && !name}
              wrapperClass={classes.formWrapper}
              label={<p>Contact email *</p>}
              placeholder="you@example.com"
              onChangeData={val => {
                setMail(val);
              }}
            />
            <ErrorAlert title="Contact email Field is required !" show={formSubmit && !mail} />
          </span>
          <span className="row">
            <InputField
              name="subject"
              className={classes.myInput}
              error={formSubmit && !name}
              wrapperClass={classes.formWrapper}
              label={<p>SUBJECT</p>}
              placeholder="Your Subject"
              onChangeData={val => {
                setSubject(val);
              }}
            />
            <ErrorAlert title="Your Subject Field is required !" show={formSubmit && !subject} />
          </span>
          <span className="row">
            <InputField
              name="telegram"
              className={classes.myInput}
              error={formSubmit && !name}
              wrapperClass={classes.formWrapper}
              label={<p>SUBJECT</p>}
              placeholder="Your message"
              onChangeData={val => {
                setTelegram(val);
              }}
            />
            <ErrorAlert title="Your message Field is required !" show={formSubmit && !telegram} />
          </span>
          <InputField
            name="message"
            wrapperClass={classes.formWrapper}
            label={<p>Your message*</p>}
            isMulti
            placeholder="Type your message…."
            className={classes.myInput}
            onChangeData={val => {
              setMessage(val);
            }}
            error={formSubmit && !message}
          />
          <ErrorAlert title="Your message Field is required !" show={formSubmit && !message} />
          <p>We will get back to you as soon as possible.</p>
          <span className="row">
            <FilledButton label={'Submit'} />
          </span>
        </form>
      </Container>
    </>
  );
};

export default Report;
