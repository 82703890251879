import { makeStyles, useTheme } from '@material-ui/core/styles';
import FormatsortOptionLabel from './FormatsortOptionLabel';
import Select from 'react-select';
import { useEffect, useState } from 'react';
import PropertyTypeModal from './propertyTypeModal/PropertyTypeModal';
import CountryModal from './countryModal/CountryModal';
import BedModal from './bedModal/BedModal';
import PriceModal from './priceModal/PriceModal';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Menu from 'components/menu/Menu';
import ChooseCountry from './ChooseCountry/ChooseCountry';
import PropertyType from './PropertyType/PropertyType';
import Beds from './Beds/Beds';
import Price from './Price/Price';
import FilledButton from 'components/Buttons/FilledButton';
import OutlinedButton from 'components/Buttons/OutlinedButton';
import { filterAll } from 'utils/hooks/useFilter';

interface PropsType {
  data?: any;
  setData?: any;
  myData?: any;
}
const customStyles = {
  control: base => ({
    ...base,
    // fontSize: 'min(0.8vw, 13px)',
    fontSize: '0.8vw',
    fontWeight: 'bold',
    borderRadius: '50px',
    border: '1px solid #ddd !important',
    boxShadow: 'none',
    background: '#F7F9FA',
    // height: 40,
    minWidth: '10vw',
    '@media screen and (min-width: 1980px) ': {
      fontSize: 18,
    },
    '&:focus': {
      border: '0 !important',
    },
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      fontSize: 14,
    },
  }),

  menu: (provided, state) => ({
    ...provided,
    border: '1px solid #ffffff13',
    // color: '#fff',
    color: '#93989A',
    padding: 0,
    background: '#fff',
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #aaa',
    padding: 3,
    // color: '#93989A',
    cursor: 'pointer',
    fontSize: '0.8vw',
    // fontSize: 'min(0.8vw, 13px)',
    '@media screen and (min-width: 1980px) ': {
      fontSize: 18,
    },
    color: state.isSelected ? 'white' : '#93989A !important',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      fontSize: 14,
    },
    ':hover': {
      color: '#93989A !important',
    },
    ':active': {
      color: '#fff !important',
    },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};
const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 2),
    // padding: theme.spacing(0, 2),
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: '1px #ddd solid',
    width: 'calc(100% - 30px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      // padding: 20,
    },
    '& .row': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.only('md')]: {
        flexWrap: 'wrap',
      },
      [theme.breakpoints.only('sm')]: {
        flexWrap: 'wrap',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    '& p': {
      fontSize: 14,
      color: '#93989A',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 10,
      },
    },
    '& .search': {
      marginRight: 5,
      marginBottom: 10,
      marginTop: 10,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        width: '100%',
        marginRight: 0,
        marginBottom: 10,
      },
      '& span': {
        position: 'relative',
        border: '1px #ddd solid',
        padding: '8px 18px',
        borderRadius: 30,
        display: 'flex',
        alignItems: 'center',
        background: '#F7F9FA',
        color: '#93989A',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          justifyContent: 'center',
        },
        '& button': {
          position: 'absolute',
          right: 8,
          border: 'none',
          background: '#ffffff00',
          color: '#93989A',
        },
        '& input': {
          border: 'none',
          background: '#ffffff00',
          width: '15vw',
          fontSize: '0.8vw',
          [theme.breakpoints.up('xl')]: {
            fontSize: 18,
            width: '22vw',
          },
          [theme.breakpoints.only('xl')]: {
            fontSize: 16,
          },

          [theme.breakpoints.only('md')]: {
            width: '15vw',
          },
          '&::placeholder': {
            fontWeight: 600,
            color: '#93989A',
            // fontSize: 'min(0.8vw, 13px)',
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
              fontSize: 14,
              // textAlign: 'center',
            },
          },
          '&:focus': {
            outline: 'none',
          },
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            width: '100%',
            fontSize: 14,
          },
        },
      },
    },
    '& .select': {
      marginRight: 5,
      marginBottom: 10,
      marginTop: 10,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        width: '100%',
        marginRight: 0,
        marginBottom: 10,
      },
      '& button': {
        display: 'flex',
        alignItems: 'center',
        border: '1px #ddd solid',
        padding: '8px 1.5vw',
        // fontSize: 'min(0.8vw, 13px)',
        fontSize: '0.8vw',
        fontWeight: 600,
        background: '#F7F9FA',
        color: '#93989A',
        borderRadius: 30,
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        [theme.breakpoints.up('xl')]: {
          fontSize: 18,
        },
        [theme.breakpoints.only('xl')]: {
          fontSize: 16,
        },
        [theme.breakpoints.only('md')]: {
          padding: '8px 1vw',
        },
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          width: '100%',
          justifyContent: 'center',
          padding: '8px 30px',
          fontSize: 14,
        },
        '&:hover': {
          background: '#fff',
        },
        '& img': {
          marginLeft: 10,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            marginRight: 10,
          },
        },
      },
    },
    '& .reset': {
      border: 'none',
      background: '#ffffff00',
      color: '#6B7380',
      cursor: 'pointer',
      fontWeight: 600,
      marginBottom: 10,
      marginTop: 10,
      // fontSize: 'min(0.8vw, 13px)',
      fontSize: '0.8vw',
      [theme.breakpoints.up('xl')]: {
        fontSize: 18,
      },
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 14,
      },
    },
    '& .select-gray': {
      '& .css-1jwi8f4-singleValue': {
        '& div': {
          // minHeight: '2vw !important',
          color: '#93989A !important',
          [theme.breakpoints.down('md')]: {
            height: 'auto !important',
          },
        },
      },
    },
  },
  menuFilter: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 30,
    '& .header': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .back': {
        color: '#93989A',
        background: '#ffffff00',
        border: 'none',
      },
    },
  },
}));

const Filter = ({ data, setData, myData }: PropsType) => {
  const classes = useStyles();

  const [propertyTypeModal, setPropertyTypeModal] = useState(false);
  const [countryModal, setCountryModal] = useState(false);
  const [bedModal, setBedModal] = useState(false);
  const [priceModal, setPriceModal] = useState(false);
  const [reset, setReset] = useState(false);

  const keys = ["name", "type"]

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [bed, setBed] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [sortVal, setSortVal] = useState(0);
  const [homeType, setHomeType] = useState("");
  const [minSqft, setMinSqft] = useState(0);
  const [maxSqft, setMaxSqft] = useState(0);
  const [country, setCountry] = useState("");


  const handleFilter = () => {
    let filtered = filterAll(myData, name.toLowerCase(), type.toLowerCase(), minPrice, maxPrice, sortVal, bed, country, minSqft, maxSqft)
    setData(filtered)
  }

  const handleReset = () => {
    let filtered = filterAll(myData, "", "", 0, 0, sortVal, 0, "", 0, 0)
    setData(filtered)
    setReset(true)
    setName("")
    setType("")
    setMaxPrice(0)
    setMinPrice(0)
    setSortVal(0)
    setBed(0)
    setCountry("")
    setMinSqft(0)
    setMaxSqft(0)

  }
  useEffect(() => {
    setReset(false)
    handleFilter();
  }, [name, type, minPrice, maxPrice, sortVal, bed, country, minSqft, maxSqft])

  const handleSelected = e => {
    setSortVal(e.value)
  };

  const options = [
    { value: 0, label: 'Price: Low To High', customAbbreviation: '0' },
    { value: 1, label: 'Price: High To Low', customAbbreviation: '1' },
  ];

  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <>
      <div className={classes.root}>
        {isMobileOrTablet ? (
          <>
            <div className="row">
              <span className="select">
                <button onClick={() => setMenuOpen(true)}>
                  <img src="/assets/images/filter_icon.svg" alt="" /> Filter
                </button>
              </span>
              <div className="search">
                <span>
                  <input type="text" placeholder="Search by Name" value={name} onChange={(e) => setName(e.target.value)} />
                  <button>
                    <i className="fas fa-search"></i>
                  </button>
                </span>
              </div>

              <span className="select">
                <Select
                  defaultValue={options[0]}
                  formatOptionLabel={FormatsortOptionLabel}
                  options={options}
                  instanceId="chainSelect"
                  className={`select-gray `}
                  onChange={e => handleSelected(e)}
                  isSearchable={false}
                  isClearable={false}
                  styles={customStyles}
                />
              </span>
            </div>
          </>
        ) : (
          <div className="row">
            <span className="select">
              <button onClick={() => setPropertyTypeModal(true)}>
                Property Type <img src="/assets/images/arrow_02.svg" alt="" />
              </button>
            </span>
            <div className="search">
              <span>
                <input type="text" placeholder="Search by Name" value={name} onChange={(e) => { setName(e.target.value) }} />
                <button>
                  <i className="fas fa-search"></i>
                </button>
              </span>
            </div>

            <span className="select">
              <button onClick={() => setCountryModal(true)}>
                Country <img src="/assets/images/arrow_02.svg" alt="" />
              </button>
            </span>
            <span className="select">
              <button onClick={() => setBedModal(true)}>
                Beds & Sqft <img src="/assets/images/arrow_02.svg" alt="" />
              </button>
            </span>
            <span className="select">
              <button onClick={() => setPriceModal(true)}>
                Price Range <img src="/assets/images/arrow_02.svg" alt="" />
              </button>
            </span>
            <span className="select">
              <Select
                defaultValue={options[0]}
                formatOptionLabel={FormatsortOptionLabel}
                options={options}
                instanceId="chainSelect"
                className={`select-gray `}
                onChange={e => handleSelected(e)}
                isSearchable={false}
                isClearable={false}
                styles={customStyles}
              />
            </span>
            <span>
              <button className="reset" onClick={() => { handleReset() }}>Clear all filters</button>
            </span>
          </div>
        )}

        <div className="row"></div>
      </div>

      {isMobileOrTablet ? (
        <Menu
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          children={
            <div className={classes.menuFilter}>
              <div className="header">
                <button className="back" onClick={() => setMenuOpen(false)}>
                  <i className="fas fa-arrow-left"></i>
                </button>
                <OutlinedButton label="Reset" />
              </div>
              <ChooseCountry />
              <PropertyType />
              <Beds />
              <Price setMinPrice={setMinPrice} setMaxPrice={setMaxPrice} />
              <FilledButton label={'Apply Filter'} />
            </div>
          }
        />
      ) : (
        <>
          <PropertyTypeModal 
          showModal={propertyTypeModal} 
          setShowtModal={setPropertyTypeModal}
          setType={setType} 
          setHomeType={setHomeType} 
          reset={reset} 
          />
          <CountryModal
            myData={myData}
            showModal={countryModal}
            setShowtModal={setCountryModal}
            setCountry={setCountry}
          />
          <BedModal
            showModal={bedModal}
            setShowtModal={setBedModal}
            setBed={setBed}
            setMinSqft={setMinSqft}
            setMaxSqft={setMaxSqft}
          />
          <PriceModal
            showModal={priceModal}
            setShowtModal={setPriceModal}
            setMinPrice={setMinPrice}
            setMaxPrice={setMaxPrice}
            minPrice={minPrice}
            maxPrice={maxPrice}
            reset={reset} />
        </>
      )}
    </>
  );
};

export default Filter;
