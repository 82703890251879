import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '../../components/Layout/Container';
import InputField from '../../components/Forms/InputField';
import { useState } from 'react';
import { AreaChart, Area, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import FilledButton from 'components/Buttons/FilledButton';
const chartData = [
  {
    name: 'Today',
    uv: 0,
    pv: 0,
  },
  {
    name: 'Year1',
    uv: 100,
    pv: 110,
  },
  {
    name: 'Year2',
    uv: 200,
    pv: 220,
  },
  {
    name: 'Year3',
    uv: 300,
    pv: 330,
  },
  {
    name: 'Year4',
    uv: 400,
    pv: 440,
  },
  {
    name: 'Year5',
    uv: 500,
    pv: 550,
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    padding: 15,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      // padding: '0px !important',
      paddingTop: 0,
      textAlign: 'center',
    },
  },

  section: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      padding: 20,
    },
    '& ul': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      padding: 0,
      listStyle: 'none',
      '& li': {
        width: '100%',
      },
    },
    '& .btns': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
      paddingTop: 20,
      paddingBottom: 20,
      '& button': {
        margin: 20,
      },
    },
    '& .chart': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
      height: 500,
      padding: 20,
      position: 'relative',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        padding: 0,
        height: 400,
      },
      '& p': {
        color: '#6B7380',
      },
      '& .text1': {
        position: 'absolute',
        bottom: 80,
        left: 20,
        fontWeight: 600,
        zIndex: 1,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          left: 0,
          bottom: 50,
        },
        '& span': {
          color: '#292A36',
        },
      },
      '& .text2': {
        position: 'absolute',
        top: 30,
        right: 20,
        fontWeight: 600,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          top: 20,
          right: 0,
        },
        '& span': {
          color: '#292A36',
        },
      },
      '& .recharts-responsive-container': {
        overflow: 'visible',
      },
      '& .recharts-surface': {
        overflow: 'visible',
      },
      '& text': {
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 12,
        },
      },
    },
  },
  topSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(7),
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      marginBottom: theme.spacing(0),
    },
  },
  top: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      padding: 20,
      marginBottom: theme.spacing(0),
    },
    '& p': {
      color: '#000',
      fontSize: 16,
      width: '100%',
      textAlign: 'start',
      marginBottom: 20,

      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 14,
      },
    },
    '& h2': {
      color: '#000',
      fontSize: 20,
      width: '100%',
      marginBottom: 20,
      textAlign: 'start',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        // fontSize: 18,
      },
    },
  },

  banner: {
    height: '100%',
    minHeight: '16vw',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 70,
    padding: 70,
    backgroundImage: `url("assets/images/home_banner.png")`,
    backgroundSize: '100% 100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      paddingTop: 100,
      backgroundSize: 'cover',
      backgroundPosition: '90%',
    },
    '& h1': {
      color: '#fff',
      fontSize: 40,
      fontWeight: 700,
      fontFamily: "'Outfit', sans-serif",
      width: '100%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 30,
      },
    },
  },
  formWrapper: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    '& h3': {
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 14,
        fontWeight: 400,
      },
    },
  },

  myInput: {
    color: '#555',
    border: '1px #ddd solid',
    width: '70%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      width: '100%',
    },
    '& .fas': {
      color: '#000',
      fontSize: 14,
    },
  },
}));

const Calculator = () => {
  const classes = useStyles();
  const [initialAmount, setInitialAmount] = useState(0);
  const [annual, setAnnual] = useState(0);
  const [yeld, setYield] = useState(0);
  const [period, setPeriod] = useState(0);
  const [formSubmit, setFormSubmit] = useState(false);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);

  return (
    <>
      <Container className={classes.root}>
        <div className={classes.topSection}>
          <div className={classes.banner}>
            <h1>CALCULATOR</h1>
          </div>
        </div>
        <div className={classes.top}>
          <p>Calculate the returns on your real estate NFT with Metropoly.</p>
          <p>

            Let’s say you spend $20,000 in Real Estate NFTs and hold for 5 years to get an average annual return of up to 8.2%. This means you’ll get $8,200 more than simply leaving your funds in your wallet or bank. Even if the property price falls, you will still be earning rental income, which will help grow your wealth.


          </p>
        </div>
        <div className={classes.section}>
          <ul>
            <li>
              <InputField
                name="name"
                className={classes.myInput}
                error={formSubmit && !initialAmount}
                wrapperClass={classes.formWrapper}
                label={'Investment Amount:'}
                placeholder="$ 20,000 USD"
                onChangeData={val => {
                  setInitialAmount(parseFloat(val));
                  setFormSubmit(true);
                }}
              />
            </li>
            <li>
              <InputField
                name="name"
                className={classes.myInput}
                error={formSubmit && !period}
                wrapperClass={classes.formWrapper}
                label={'Investment Period (Years):'}
                placeholder="5 Years"
                onChangeData={val => {
                  setPeriod(parseFloat(val));
                }}
              />
            </li>

            <li>
              <InputField
                name="name"
                className={classes.myInput}
                error={formSubmit && !yeld}
                wrapperClass={classes.formWrapper}
                label={'Net Rental Yield:'}
                placeholder="7.79%"
                onChangeData={val => {
                  setYield(parseFloat(val));
                }}
              />
            </li>

            <li>
              <InputField
                name="name"
                className={classes.myInput}
                error={formSubmit && !annual}
                wrapperClass={classes.formWrapper}
                endIcon={<i className="fas fa-lock"></i>}
                label={'Total Rental Income:'}
                placeholder="$ 6,200 USD "
                onChangeData={val => {
                  setAnnual(parseFloat(val));
                }}
              />
            </li>

            <li>
              <InputField
                name="name"
                className={classes.myInput}
                error={formSubmit && !annual}
                wrapperClass={classes.formWrapper}
                label={'Expected Annual Appreciation:'}
                placeholder="2% "
                onChangeData={val => {
                  setAnnual(parseFloat(val));
                }}
              />
            </li>
            <li>
              <InputField
                name="name"
                className={classes.myInput}
                error={formSubmit && !annual}
                wrapperClass={classes.formWrapper}
                endIcon={<i className="fas fa-lock"></i>}
                label={'Expected Capital Appreciation:'}
                placeholder="$ 2,000 USD"
                onChangeData={val => {
                  setAnnual(parseFloat(val));
                }}
              />
            </li>

            <li>
              <InputField
                name="name"
                className={classes.myInput}
                error={formSubmit && !annual}
                wrapperClass={classes.formWrapper}
                endIcon={<i className="fas fa-lock"></i>}
                label={'Total Average Annualised Return:'}
                placeholder="8.2%"
                onChangeData={val => {
                  setAnnual(parseFloat(val));
                }}
              />
            </li>
            <li>
              <InputField
                name="name"
                className={classes.myInput}
                error={formSubmit && !annual}
                wrapperClass={classes.formWrapper}
                endIcon={<i className="fas fa-lock"></i>}
                label={'Total Return on Investment:'}
                placeholder="USD 8,200"
                onChangeData={val => {
                  setAnnual(parseFloat(val));
                }}
              />
            </li>
            <li>
              <InputField
                name="name"
                className={classes.myInput}
                error={formSubmit && !initialAmount}
                wrapperClass={classes.formWrapper}
                endIcon={<i className="fas fa-lock"></i>}
                label={'Total Expected Value:'}
                placeholder="USD 28,200"
                onChangeData={val => {
                  setAnnual(parseFloat(val));
                }}
              />
            </li>
          </ul>
          <div className="btns">
            <FilledButton label={'Reset'} color="grey" />
            <FilledButton label={'Calculate'} />
          </div>
          <div className="chart">
            <p className="text1"><span>Initial Amount</span> <br />
              USD 20,000</p>
            <p className="text2"><span>Total Expected Value</span><br />USD 28,200</p>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                width={500}
                height={400}
                data={chartData}
                margin={{
                  top: 10,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#2892F3" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#2892F3" stopOpacity={0} />
                  </linearGradient>
                </defs>
                {isMobileOrTablet ? (
                  <XAxis interval={0} dataKey="name" />
                ) : (
                  <XAxis interval={'preserveStartEnd'} dataKey="name" />
                )}
                {/* <YAxis /> */}
                {!isMobileOrTablet && <Tooltip />}
                <Area
                  type="monotone"
                  dot={{ stroke: '#D9D9D9', strokeWidth: 2 }}
                  dataKey="uv"
                  stroke="#D9D9D9"
                  fillOpacity={0.8}
                  fill="url(#colorUv)"
                  strokeWidth={2}
                />
                <Area
                  type="monotone"
                  dot={{ stroke: '#D9D9D9', strokeWidth: 2 }}
                  dataKey="pv"
                  stackId="1"
                  stroke="#D9D9D9"
                  fillOpacity={0.8}
                  fill="url(#colorUv)"
                  strokeWidth={2}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Calculator;
