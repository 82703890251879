import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useState } from 'react';
import InboxList from './InboxList';
import PropertyCard from 'components/Cards/PropertyCard';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DropDown from './DropDown';
import Menu from 'components/menu/Menu';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    '@media screen and (max-width: 576px) and (orientation: portrait)': {},
  },

  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingBottom: '30px',
    height: '100%',
    position: 'relative',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      padding: '0px 20px',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 360,
      height: '100%',
      borderRight: '1px #eee solid',
      '@media screen and (max-width: 576px) and (orientation: portrait)': {
        display: 'none',
      },
    },
  },
  left: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: 360,
    // borderRight: '1px #eee solid',
    '& a': {
      fontSize: 14,
      color: '#2892F3',
    },
    '@media screen and (max-width: 576px) and (orientation: portrait)': {
      flexDirection: 'column',
      width: '100%',
      paddingTop: 0,
      border: 'none',
    },
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: 'calc(100% - 360px)',
    padding: 20,
    paddingTop: 5,

    '@media screen and (max-width: 576px) and (orientation: portrait)': {
      flexDirection: 'column',
      width: '100%',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: 'calc(100%)',
    paddingBottom: 20,
    marginBottom: 10,
    borderBottom: '1px #eee solid',
    '@media screen and (max-width: 576px) and (orientation: portrait)': {},
    '& .avatar': {
      display: 'flex',
      alignItems: 'center',
      '& img': {
        marginRight: 10,
        width: 60,
        height: 60,
        borderRadius: 80,
        '@media screen and (max-width: 576px) and (orientation: portrait)': {
          width: 50,
          height: 50,
        },
      },
    },
    '& h4': {
      color: '#000',
      // width: '60%',
      fontSize: 18,
      '@media screen and (max-width: 576px) and (orientation: portrait)': {
        width: 'auto',
        textAlign: 'left',
      },
    },
  },
  messageBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',

    width: 'calc(90%)',
    background: '#F3F3F3',
    borderRadius: 10,
    padding: 20,
    paddingBottom: 10,
    border: '1px solid #DBDDDD',
    marginBottom: 20,
    '@media screen and (max-width: 576px) and (orientation: portrait)': {
      width: 'calc(100%)',
    },
    '& p': {
      fontSize: 14,
      color: '#000',
      marginBottom: 15,
      textAlign: 'left',
    },
    '& a': {
      color: '#2892F3',
      fontWeight: 600,
    },
  },
  subText: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingBottom: '30px',
    width: 'calc(90%)',
    borderRadius: 10,
    marginBottom: 20,
    '@media screen and (max-width: 576px) and (orientation: portrait)': {
      flexDirection: 'column',
    },
    '& a': {
      fontSize: 14,
      color: '#000',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 20,
      '& .startIcon': {
        marginRight: 7,
        height: 20,
      },
      '& .endIcon': {
        marginLeft: 7,
      },
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    flexDirection: 'column',
    width: 'calc(100%)',
    paddingBottm: 30,

    '@media screen and (max-width: 576px) and (orientation: portrait)': {},
    '& p': {
      fontSize: 14,
      color: '#93989A',
    },
  },
  expand: {
    display: 'none',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: 'calc(100%)',
    padding: 10,

    '@media screen and (max-width: 576px) and (orientation: portrait)': {
      display: 'flex',
    },
    '& p': {
      fontSize: 14,
      color: '#93989A',
    },
  },
}));

const InboxSection = () => {
  const classes = useStyles();
  const [chat, setChat] = useState<any>(null);

  const [menuOpen, setMenuOpen] = useState(false);

  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.left}>
            <InboxList setChat={setChat} setHide={setMenuOpen} />
            {/* {!isMobileOrTablet && <InboxList setChat={setChat} setHide={setMenuOpen} />} */}
          </div>
          {!isMobileOrTablet && (
            <div className={classes.right}>
              <div className={classes.header}>
                <div className="avatar">
                  <img src={chat?.img || '/assets/avatars/avatar_01.svg'} alt="" />
                  <h4>{chat?.title}</h4>
                </div>
                <DropDown />
              </div>
              {chat?.message && (
                <div className={classes.messageBox}>
                  {chat?.message.map((t, k) => (
                    <p key={k}>
                      {t.includes('###') ? (
                        <>
                          {t.split('###')[0]} <Link to={`/payouts`}>{t.split('###')[1]}</Link>
                        </>
                      ) : t.includes('$$$') ? (
                        <>
                          {t.split('$$$')[0]} <Link to={`/upcoming`}>{t.split('$$$')[1]}</Link> {t.split('$$$')[2]}
                        </>
                      ) : (
                        <>{t}</>
                      )}
                    </p>
                  ))}
                </div>
              )}
              {chat?.property && <PropertyCard product={chat?.property} />}
              {chat?.book && (
                <div className={classes.subText}>
                  <a href="/" target={'_blank'}>
                    <img src="/assets/images/pdf_icon.svg" alt="" className="startIcon" /> {chat?.book}{' '}
                    <img src="/assets/images/download_icon.svg" alt="" className="endIcon" />
                  </a>
                </div>
              )}

              <div className={classes.footer}>
                <p>{chat?.date}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InboxSection;
