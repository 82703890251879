import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/Layout/Container';
import FilledButton from 'components/Buttons/FilledButton';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 15,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
      paddingTop: 0,
      textAlign: 'center',
    },
  },

  section: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    widht: '100%',
    paddingBottom: theme.spacing(5),
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      padding: 20,
    },
    '& .left': {
      color: '#000',
      fontSize: 24,
      width: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: 20,
      margin: 'auto',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        width: '100%',
        marginBottom: 30,
      },
    },
    '& .right': {
      color: '#000',
      fontSize: 24,
      width: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        width: '100%',
        marginBottom: 30,
      },
      '& img': {
        width: '80%',
        margin: 'auto',
      },
    },
    '& .col': {
      color: '#000',
      fontSize: 24,
      width: '30%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: 20,
      background: 'linear-gradient(140.75deg, #FDFDFD 3.18%, rgba(253, 253, 253, 0.72) 98.85%)',
      boxShadow: '0px 2.66804px 38.0196px rgba(0, 0, 0, 0.1)',
      borderRadius: 12,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        width: '100%',
        marginBottom: 10,
      },

    },
    '& h2': {
      color: '#000',
      fontSize: 24,
      width: '100%',
      marginBottom: 10,
      // textTransform: "uppercase",
      [theme.breakpoints.only('sm')]: {
        fontSize: 20,
      },
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        width: '100%',
      },
    },
    '& p': {
      color: '#000',
      fontSize: 16,
      width: '100%',
      marginBottom: 10,
      [theme.breakpoints.only('sm')]: {
        fontSize: 14,
      },
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 12,
      },
    },


  },
  topSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: theme.spacing(5),
    '& h1': {
      color: '#000',
      fontSize: 28,
      fontWeight: 700,
      marginBottom: 20,
      // textTransform: "uppercase",

      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 12,
      },
    },
    '& p': {
      color: '#000',
      fontSize: 16,
      marginBottom: 20,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 12,
      },
    },
    '& .back': {
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: 0,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {},
    },

  },
  fotPara: {
    width: 600,
    textAlign: "center",
  },
  video: {
    width: '100%',
  },
  banner: {
    height: '100%',
    minHeight: '16vw',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 70,
    padding: 70,
    backgroundImage: `url("assets/images/home_banner.png")`,
    backgroundSize: '100% 100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      paddingTop: 100,
      backgroundSize: 'cover',
      backgroundPosition: '90%',
    },
    '& h1': {
      color: '#fff',
      fontSize: 40,
      width: '100%',
      fontWeight: 700,
      textTransform: "uppercase",

      fontFamily: "'Outfit', sans-serif",
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 30,
      },
    },
  },

}));

const AboutUs = () => {
  const classes = useStyles();
  return (
    <>
      <Container className={classes.root}>
        <div className={classes.topSection}>
          <div className={classes.banner}>
            <h1>About Us</h1>
          </div>
        </div>

        <div className={classes.topSection}>
          <h1>What is Metropoly?</h1>
          <p>Metropoly is the world’s first real estate NFT marketplace backed by real-world properties.</p>
        </div>

        <div className={classes.section}>
          <div className="col">
            <h2>Grow Your Wealth</h2>
            <p>Boost your wealth using the power of real estate without spending significant sums of capital.</p>
          </div>
          <div className="col">
            <h2>Truly Passive Income</h2>
            <p>
              We help you build truly passive monthly income without the hassles of traditional real estate investments.
            </p>
          </div>
          <div className="col">
            <h2>Blockchain-Powered</h2>
            <p>
              Enter the next era of real estate ownership through fractionalization using real estate NFTs on the
              Ethereum blockchain.
            </p>
          </div>
        </div>

        <div
          className={classes.section}
          style={{
            backgroundImage: 'linear-gradient(90deg, rgba(40, 146, 243, 0.12) 0%, rgba(225, 231, 255, 0) 105.92%)',
          }}
        >
          <div className="left">
            <h2>Our Vision</h2>
            <p>
              Our vision is to make the real estate market accessible to people worldwide—regardless of their origin,
              country, or credit score—through a global, decentralized real estate NFT marketplace backed by real-world
              properties.
            </p>
          </div>
          <div className="right">
            <img src="/assets/aboutus/img_01.svg" alt="" />
          </div>
        </div>
        <div
          className={classes.topSection}
          style={{
            backgroundImage: 'linear-gradient(90deg, rgba(40, 146, 243, 0.12) 0%, rgba(225, 231, 255, 0) 105.92%)',
            minHeight: 300,
          }}
        >
          <img src="/assets/aboutus/img_02.svg" alt="" className="back" />
          <h1>Get Started With Metropoly</h1>
          <p className={classes.fotPara}>
            Take on the journey of true wealth creation with Metropoly
            and earn massive returns without any downside.

          </p>
          <FilledButton label="Get Started" />
        </div>
      </Container>
    </>
  );
};

export default AboutUs;
