import { makeStyles } from '@material-ui/core/styles';

import Expand from 'react-expand-animated';
import BasicTable from 'components/Table';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    border: `1px solid #eee`,
    padding: 20,
    borderRadius: 20,
    [theme.breakpoints.down('xs')]: {
      // paddingBottom: 0,
    },
  },
  title: {
    // marginBottom: theme.spacing(2),
    color: '#000',
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      fontSize: 20,
      cursor: 'pointer',
      color: '#999',
      // marginBottom: theme.spacing(2),
    },
  },
}));

const OfferTable = ({ listings }) => {
  const classes = useStyles();

  const rows = [];

  for (let i = 0; i < listings?.length; i++) {
    const list = {
      price: `$ ${listings[i].price}`,
      ethereum: (
        <p style={{ display: 'flex', alignItems: 'center' }}>
          <img src="/assets/images/eth_icon_01.svg" alt="" style={{ marginRight: 7 }} />
          {`${listings[i].ethereum} ETH`}
        </p>
      ),
      different: `${listings[i].different} % below`,
      expiration: `about ${listings[i].expiration} hours`,
      from: <p style={{ color: '#2892F3' }}>{`${listings[i].from}`}</p>,
    };
    rows.push(list);
  }

  const columns = [
    { key: 'price', label: 'Price' },
    { key: 'ethereum', label: 'Ethereum' },
    { key: 'different', label: 'Floor Difference' },
    { key: 'expiration', label: 'Expiration' },
    { key: 'from', label: 'From' },
  ];

  const styles = {
    open: { width: '100%' },
    close: { width: '100%' },
  };
  const transitions = ['height', 'opcity', 'background'];
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <h3 className={classes.title}>
          <i className="fas fa-list"></i> Offers
        </h3>
        <span>
          {menuOpen ? (
            <i className="fas fa-angle-up" onClick={() => setMenuOpen(!menuOpen)}></i>
          ) : (
            <i className="fas fa-angle-down" onClick={() => setMenuOpen(!menuOpen)}></i>
          )}
        </span>
      </div>

      <Expand open={menuOpen} duration={800} styles={styles} transitions={transitions}>
        <BasicTable columns={columns} rows={rows} />
      </Expand>

      <div>{/* <BasicTable columns={columns} rows={rows} /> */}</div>
    </div>
  );
};

export default OfferTable;
