import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import { makeStyles } from '@material-ui/core/styles';
import ProductCard1 from 'components/Cards/ProductCard1';
import { useMoralisWeb3Api, useMoralis } from "react-moralis"
import { currentChain, metropolyContract } from "constants/config";

interface Properties {
  total?: number;
  page?: number;
  page_size?: number;
  cursor?: string;
  result?: {
    token_address: string;
    token_id: string;
    owner_of?: string;
    token_hash?: string;
    block_number?: string;
    block_number_minted?: string;
    symbol: string;
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 30,
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      paddingTop: 10,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 20,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 20,
    },
    '& .MuiOutlinedInput-root': {
      background: '#00D9AC00',
      border: 'none',
      boxShadow: '0px 0px 3px #00D9AC',
    },
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: theme.spacing(0, 2),
    paddingBottom: 30,
    width: '100%',

    '& a': {
      fontSize: 14,
      color: '#2892F3',
    },
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      padding: '5px 20px',
    },
  },

  header: {
    fontSize: 30,
    fontWeight: 600,
    color: '#000',
    textAlign: 'left',
    lineHeight: '50px',
    position: 'relative',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      fontSize: 20,
    },
  },
  masonry: {
    display: 'flex',
    width: '100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      width: '100%',
    },
  },
  gridColumn: {
    margin: theme.spacing(0, 2),
    // margin: 'auto',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100% !important',
    },
  },
}));

const myData = [
  {
    id: 0,
    img: '/assets/images/unsplash_IYfp2Ixe9nM.png',
    type: 'Rental Property',
    startPrice: 5200,
    estatePrice: 5200000,
    name: 'Beautiful 5-Bedroom Villa with Incredible view',
    location: 'Los Angeles, United States of America',
    country: 'United States of America',
    homeState: 'Rented',
    beds: 5,
    state: {
      expected: 49,
      yeld: 7.79,
      frequency: 'Monthly',
    },
  },
  {
    id: 1,
    img: '/assets/images/unsplash_IYfp2Ixe9nM (1).png',
    type: 'Rental Property',
    startPrice: 280,
    estatePrice: 280000,
    name: 'Cozy 1-Bedroom Apartment in the city center',
    location: 'London, United Kingdom',
    homeState: 'Rented',
    beds: 1,
    country: 'United Kingdom',
    state: {
      expected: 32,
      yeld: 4.48,
      frequency: 'Monthly',
    },
  },
  {
    id: 2,
    img: '/assets/images/unsplash_IYfp2Ixe9nM (2).png',
    type: 'Holiday Home',
    startPrice: 860,
    estatePrice: 860000,
    name: 'Authentic 3-Bedroom Penthouse with a private terrace',
    location: 'Berlin, Germany',
    country: 'Germany',
    homeState: 'Vacant',
    beds: 3,
    state: {
      expected: 37,
      yeld: 5.32,
      frequency: 'Monthly',
    },
  },
];
const breakpointColumnsObj = {
  // default: 3,
  3840: 7,
  3000: 6,
  2560: 5,
  2200: 4,
  1840: 3,
  1440: 3,
  1280: 2,
  768: 2,
  450: 1,
};
const DiscoverNewProperties = () => {
  const classes = useStyles();

  const Web3Api = useMoralisWeb3Api()
  const [propertyData, setPropertyData] = useState<any>(myData)
  const [isFetching, setIsFetching] = useState(false);

  const { isAuthenticated, user, authenticate, isWeb3Enabled, enableWeb3, isInitialized, isInitializing, isWeb3EnableLoading, initialize } = useMoralis();

  const [loading, setLoading] = useState(false);

  const getProperty = useCallback(async () => {
    let property: any = {};
    setLoading(true);
    const NFTs = await Web3Api.token.getAllTokenIds({
      chain: currentChain,
      address: metropolyContract
    }).then(async (data) => {
      console.log(data)
      for (let x = 0; x < data.result.length; x++) {
        let metadata = JSON.parse(data.result[x].metadata)

        property = {
          id: data.result[x].token_id,
          img: metadata.image,
          name: metadata.name,
          homeState: "Rented",
          state: {

          }
        }

        for (let i = 0; i < metadata.attributes.length; i++) {
          switch (metadata.attributes[i].trait_type) {
            case "Property Price": {
              property.estatePrice = metadata.attributes[i].value * 1000
              let price = parseInt(metadata.attributes[i].value.replaceAll(',', ''));
              property.startPrice = price
              break;
            }
            case "Property Type": {
              property.type = metadata.attributes[i].value
              break;
            }
            case "Bedroom": {
              property.bedroom = metadata.attributes[i].value
              break;
            }
            case "Dividend Yield": {
              property.state.yeld = metadata.attributes[i].value
              break;
            }
            case "Expected Annual Appreciaton": {
              property.state.expected = metadata.attributes[i].value
              break;
            }
            case "Dividends Frequency": {
              property.state.frequency = metadata.attributes[i].value
              break;
            }
            case "Location": {
              property.location = metadata.attributes[i].value
              break;
            }
            default: { }
          }
        }
        setPropertyData(myData)
        // setPropertyData(propertyData => [...propertyData, property])
      }
      // setIsFetching(true)
      return;
    }).then(async () => {
      setPropertyData(myData)
      setLoading(false);
    })
  }, [])
  function compare(a, b) {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }
    return 0;
  }
  // useEffect(() => {

  //   let filterProperty: [] = propertyData;
  //   filterProperty.sort(compare);
  //   console.log(filterProperty)
  //   setPropertyData(filterProperty)
  // }, [loading])

  useEffect(() => {
    (async () => {
      try {
        if (isInitializing || isWeb3EnableLoading)
        return () => { };
        if (!isInitialized) {
          initialize({
            appId: "F254vsO3ffYAne6sff288HKXOjBrUvqhzhDAjxpn",
            serverUrl: "https://bzgdtzqlihki.usemoralis.com:2053/server"
          });
        }
        if (isInitialized && !isWeb3Enabled) {
          try {
            const wc = window as WindowChain;
            const provider = wc.ethereum;
            if (window.localStorage.walletconnect) {
              console.log("walletconnect")
            } else
              if (provider) {
                await enableWeb3()
              }
          } catch (e) {
            console.log(e)
          }
        }
        if (isInitialized && isWeb3Enabled)
          await getProperty();

      } catch (error) {

        setPropertyData(myData)
        console.log("error", error);
      }
    }
    )()
  }, [isWeb3Enabled, isInitialized])

  return (
    <>


      <div className={classes.root}>
        <div className={classes.top}>
          <h1 className={classes.header}>Discover New Properties</h1>
          <p></p>
          <Link to="/marketplace">View All</Link>
        </div>
        <div className={classes.content}>
          {
            loading ?
              <>
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className={classes.masonry}
                  columnClassName={classes.gridColumn}
                >
                  <ProductCard1 loading={loading} />
                  <ProductCard1 loading={loading} />
                  <ProductCard1 loading={loading} />

                </Masonry>
              </>
              :

              <Masonry
                breakpointCols={breakpointColumnsObj}
                className={classes.masonry}
                columnClassName={classes.gridColumn}
              >{
                  // propertyData.sort(compare).map((d, k) => (
                  propertyData.map((d, k) => (
                    <ProductCard1 loading={loading} product={d} key={k} />
                  ))
                }
              </Masonry>
          }
        </div>
      </div>


    </>
  );
};

export default DiscoverNewProperties;
