import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/Layout/Container';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 15,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
      paddingTop: 0,
      textAlign: 'center',
    },
  },

  section: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginBottom: 20,
    },
  },

  rowReverse: {
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column !important',
    },
  },
  left: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 30,
    },
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 30,
    },
  },

  panel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '90%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& img': {
      width: '80%',
      margin: 'auto',
      maxWidth: 540,
    },
    '& h4': {
      width: '80%',
      margin: 'auto',
      color: '#2892F3',
      textAlign: 'start',
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    },
    '& h1': {
      width: '80%',
      margin: 'auto',
      fontSize: 30,
      textAlign: 'start',
      textTransform: "uppercase",
      fontFamily: "'Outfit', sans-serif",
      marginBottom: 20,
      [theme.breakpoints.down('xs')]: {
        fontSize: 24,
      },
    },
    '& p': {
      width: '80%',
      margin: 'auto',
      color: '#000',
      marginBottom: 20,
      fontFamily: "'Outfit', sans-serif",
      fontSize: 18,
      textAlign: 'start',
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
  topSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(7),
  },
  video: {
    width: '100%',
  },
  banner: {
    height: '100%',
    minHeight: '16vw',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 70,
    padding: 70,
    backgroundImage: `url("assets/images/home_banner.png")`,
    backgroundSize: '100% 100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      paddingTop: 100,
      backgroundSize: 'cover',
      backgroundPosition: '90%',
    },

    '& h1': {
      color: '#fff',
      fontSize: 40,
      fontWeight: 700,
      fontFamily: "'Outfit', sans-serif",
      width: '100%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 30,
      },
    },
  },
}));

const tmpData = [
  {
    img: '/assets/why/img_01.svg',
    title: 'BUY Real estate NFTS in seconds',
    text: 'Simply connect your wallet and create your Metropoly account within seconds. Metopoly currently supports MetaMask and WalletConnect.if you dont have a wallet, then you dont have a wallet, then you can easily creat one.'
  },
  {
    img: '/assets/why/img_02.svg',
    title: 'LOW barrier to enter',
    text: 'Start with as little as $100 and reap the benefits of real estate investing without spending huge amounts of money. By creating NFTs backed by physical properties and fractionalizing them, we have democratized the Real Estate industry and simplified the process of purchasing properties.'
  },
  {
    img: '/assets/why/img_03.svg',
    title: 'Truly Passive Income',
    text: 'Get truly passive income through monthly paid dividends All the Real Estate NFTs we offer are income-producing properties with expected growth in value.'
  },
  {
    img: '/assets/why/img_04.svg',
    title: 'Sell Anytime You Want',
    text: 'Metropoly offers high liquidity to its users at all times through decentralization and the use of NFTs, Real Estate NFTs holders can transfer ownership in just seconds without paperwork.',
  },
  {
    img: '/assets/why/img_05.svg',
    title: 'Protection Against Inflation',
    text: 'Real Estate assets are historically the best hedge against inflation and offer great returns as well. Metropoly gives you the opportunity to protect your savings against without any hassles.',
  },
  {
    img: '/assets/why/img_06.svg',
    title: 'Borrow (Coming Soon)',
    text: 'Leverage billions of locked-in real estate dollars with Metropoly. You can use your NFTs as collateral and borrow against them at competitive rates while retaining full ownership of your Real Estate NFTs.',
  },
  {
    img: '/assets/why/img_07.svg',
    title: 'Great Returns ',
    text: 'Enjoy the long term value appreciation in Real Estate and earn higher returns with Metropoly. Remember, though: Appreciation isn’t guaranteed. You’ll need to invest in the right property to see those significant returns.',
  },
];

const WhyMetropoly = () => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.root}>
        <div className={classes.topSection}>
          <div className={classes.banner}>
            <h1>Why Metropoly</h1>
          </div>
        </div>
        {tmpData.map((d, k) => (
          <>
            <div className={`${classes.section} ${k % 2 === 0 ? classes.rowReverse : ''}`} key={k}>
              <div className={classes.left}>
                <div className={classes.panel}>
                  <h1>{d.title}</h1>
                  <p>{d.text}</p>
                </div>
              </div>
              <div className={classes.right}>
                <div className={classes.panel}>
                  <img src={d.img} alt="" />
                </div>
              </div>
            </div>
          </>
        ))}
      </Container>
    </>
  );
};

export default WhyMetropoly;
