import { makeStyles } from '@material-ui/core/styles';

import Expand from 'react-expand-animated';
import BasicTable from 'components/Table';
import { useState } from 'react';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    border: `1px solid #eee`,
    padding: 20,
    borderRadius: 20,
    [theme.breakpoints.down('xs')]: {
      // paddingBottom: 0,
    },
  },
  title: {
    // marginBottom: theme.spacing(2),
    color: '#000',
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      fontSize: 20,
      cursor: 'pointer',
      color: '#999',
      // marginBottom: theme.spacing(2),
    },
  },
}));

const ActivityTable = ({ listings }) => {
  const classes = useStyles();

  const rows = [];

  for (let i = 0; i < listings?.length; i++) {
    const list = {
      event:
        listings[i].event === 'Sale' ? (
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <img src="/assets/images/cart_icon_01.svg" alt="" style={{ marginRight: 7 }} />
            {` ${listings[i].event}`}
          </p>
        ) : (
          <p>
            <img src="/assets/images/mint_icon_01.svg" alt="" />
            {` ${listings[i].event}`}
          </p>
        ),
      price: `$ ${listings[i].price}`,
      from: `${listings[i].from}`,
      to: `${listings[i].to}`,
      date: (
        <p style={{ color: '#2892F3', display: 'flex', alignItems: 'center' }}>
          {` ${listings[i].date} days ago`} <img src="/assets/images/exit_icon_01.svg" alt="" style={{ marginLeft: 7 }} />
        </p>
      ),
    };
    rows.push(list);
  }

  const columns = [
    { key: 'event', label: 'Event' },
    { key: 'price', label: 'Price' },
    { key: 'from', label: 'From' },
    { key: 'to', label: 'To' },
    { key: 'date', label: 'Date' },
  ];

  const styles = {
    open: { width: '100%' },
    close: { width: '100%' },
  };
  const transitions = ['height', 'opcity', 'background'];
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <h3 className={classes.title}>
          <i className="fas fa-sort"></i> Activity
        </h3>
        <span>
          {menuOpen ? (
            <i className="fas fa-angle-up" onClick={() => setMenuOpen(!menuOpen)}></i>
          ) : (
            <i className="fas fa-angle-down" onClick={() => setMenuOpen(!menuOpen)}></i>
          )}
        </span>
      </div>

      <Expand open={menuOpen} duration={800} styles={styles} transitions={transitions}>
        <BasicTable columns={columns} rows={rows} />
      </Expand>

      <div>{/* <BasicTable columns={columns} rows={rows} /> */}</div>
    </div>
  );
};

export default ActivityTable;
