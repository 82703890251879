import { makeStyles } from '@material-ui/core/styles';
import { Fragment } from 'react';
import Container from '../../components/Layout/Container';
const useStyles = makeStyles(theme => ({
  root: {
    padding: 15,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
      paddingTop: 0,
      textAlign: 'center',
    },
  },

  section: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginBottom: 20,
    },
  },
  line: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: -80,
    marginTop: -80,
    width: '100%',
    [theme.breakpoints.only('sm')]: {
      marginBottom: -20,
      marginTop: -20,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    '& img': {
      width: '40%',
      // maxHeight: 300,
      objectFit: 'contain',
    },
  },
  rowReverse: {
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column !important',
    },
  },
  left: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 30,
    },
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 30,
    },
  },

  panel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '90%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& img': {
      width: '80%',
      margin: 'auto',
      maxWidth: 540,
    },
    '& h4': {
      width: '80%',
      margin: 'auto',
      color: '#2892F3',
      textAlign: 'start',
      [theme.breakpoints.only('sm')]: {
        fontSize: 12,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    },
    '& h1': {
      width: '80%',
      margin: 'auto',
      fontSize: 30,
      textAlign: 'start',
      [theme.breakpoints.only('sm')]: {
        fontSize: 28,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 24,
      },
    },
    '& p': {
      width: '80%',
      margin: 'auto',
      color: '#000',
      marginBottom: 20,
      textAlign: 'start',
      fontSize: 18,
      [theme.breakpoints.only('sm')]: {
        fontSize: 14,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
  topSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(7),
  },
  video: {
    width: '100%',
  },
  banner: {
    height: '100%',
    minHeight: '16vw',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 70,
    padding: 70,
    backgroundImage: `url("assets/images/home_banner.png")`,
    backgroundSize: '100% 100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      paddingTop: 100,
      backgroundSize: 'cover',
      backgroundPosition: '90%',
    },

    '& h1': {
      color: '#fff',
      fontSize: 40,
      fontWeight: 700,
      fontFamily: "'Outfit', sans-serif",
      width: '100%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 30,
      },
    },
  },
}));

const tmpData = [
  {
    step: 'STEP 1',
    img: '/assets/how/step_01.svg',
    title: 'CONNECT YOUR WALLET',
    text: 'Simply connect your wallet and create your Metropoly account within seconds. Metropoly currently supports MetaMask and WalletConnect. If you don’t have a wallet, then you can easily create one.',
  },
  {
    step: 'STEP 2',
    img: '/assets/how/step_02.svg',
    title: 'ADD ETHEREUM',
    text: 'Connect your decentralized wallet to the Ethereum blockchain and have sufficient funds. In the future, we intend to offer credit card deposits to make it even easier for you to invest. As for now, we only support ETH.',
  },
  {
    step: 'STEP 3',
    img: '/assets/how/step_03.svg',
    title: 'FIND YOUR DREAM PROPERTY',
    text: 'It has never been so easy and affordable to buy real estate NFTs. Our experts hand-selected a wide range of properties worldwide using all of the relevant information at their disposal. Discover the right properties based on your preferences. ',
  },
  {
    step: 'STEP 4',
    img: '/assets/how/step_04.svg',
    title: 'BUY YOUR FIRST REAL ESTATE NFT',
    text: 'Chose the perfect property for you and purchase it within seconds—without banks, hidden fees, or geographic limits—in a completely decentralized manner.',
  },
  {
    step: 'STEP 5',
    img: '/assets/how/step_05.svg',
    title: 'ENJOY YOUR RETURNS',
    text: 'Enjoy truly passive income every month that is fully automated and backed by real-world properties without any paperwork. We take care of everything, including property management, insurance, and tenants, to make your experience as convenient as possible.',
  },
];
const HowItWorks = () => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.root}>
        <div className={classes.topSection}>
          <div className={classes.banner}>
            <h1>How Does It Work?</h1>
          </div>
        </div>
        {tmpData.map((d, k) => (
          <Fragment key={k}>
            <div className={`${classes.section} ${k % 2 === 1 ? classes.rowReverse : ''}`}>
              <div className={classes.left}>
                <div className={classes.panel}>
                  <h4>{d.step}</h4>
                  <h1>{d.title}</h1>
                  <p>{d.text}</p>
                </div>
              </div>
              <div className={classes.right}>
                <div className={classes.panel}>
                  <img src={d.img} alt="" />
                </div>
              </div>
            </div>

            {k < tmpData.length - 1 &&
              (k % 2 === 0 ? (
                <div className={classes.line}>
                  <img src="/assets/how/line_01.svg" alt="" />
                </div>
              ) : (
                <div className={classes.line}>
                  <img src="/assets/how/line_02.svg" alt="" />
                </div>
              ))}
          </Fragment>
        ))}
      </Container>
    </>
  );
};

export default HowItWorks;
