import Masonry from 'react-masonry-css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 50,
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      paddingTop: 0,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {},
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: '30px',
    width: '100%',
    '& a': {
      fontSize: 14,
      color: '#2892F3',
    },
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      padding: '5px 20px',
    },
  },

  header: {
    fontSize: 30,
    color: '#000',
    textAlign: 'left',
    lineHeight: '50px',
    position: 'relative',
    '@media (max-width: 576px)': {
      fontSize: 20,
    },
  },
  masonry: {
    display: 'flex',
    width: '100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      width: '100%',
    },
  },
  gridColumn: {
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      // display: 'flex',
      // justifyContent: 'center',
      // alignItems: 'center',
      // flexDirection: 'column',
      // width: '100% !important',
    },
  },
  productWrapper: {
    maxWidth: 360,
    minWidth: 250,
    margin: 'auto',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {},
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      maxWidth: 300,
      minWidth: '100%',
    },
    '& .content': {
      width: '90%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexDirection: 'column',
      boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.25)',
      padding: 20,
      borderRadius: 10,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        padding: 10,
      },
    },

    '& .image': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 45,
      height: 45,
      borderRadius: '50%',
      overflow: 'hidden',
      background: 'radial-gradient(94.26% 94.26% at 61.78% 22.88%, #FAFCFFdd 0%, #E5E8EC88 100%) ',

      // filter: 'blur(5.31505px)',
      '& img': {
        width: '50%',
      },
    },

    '& .state': {
      // padding: '5px 10px',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {},
      '& h5': {
        color: '#93989A',
        fontSize: 16,
        textAlign: 'left',
        fontWeight: 500,
        marginBottom: 10,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 10,
        },
      },
      '& h3': {
        color: '#000',
        fontSize: 25,
        textAlign: 'left',
        fontWeight: 700,
        marginBottom: 10,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 20,
        },
      },
      '& p': {
        color: '#93989A',
        fontSize: 14,
        textAlign: 'left',
        fontWeight: 700,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 10,
        },
        '& span': {
          color: '#42A846',
          fontSize: 14,
          marginRight: 10,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 10,
            marginRight: 5,
          },
          '& img': {
            marginRight: 10,
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
              width: 10,
              marginRight: 5,
            },
          },
        },
      },
    },
  },
}));
const breakpointColumnsObj = {
  // default: 3,
  3840: 6,
  3000: 6,
  2560: 6,
  2200: 3,
  1840: 3,
  1440: 3,
  1280: 2,
  768: 2,
  450: 1,
};
const State = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.content}>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className={classes.masonry}
            columnClassName={classes.gridColumn}
          >
            <div className={classes.productWrapper}>
              <div className="content">
                <div className="image">
                  <img src="/assets/images/overview_icon_1.svg" alt="" />
                </div>
                <div className="state">
                  <h5>Number of Properties </h5>
                  <h3>9</h3>
                  <p>
                    <span>
                      <img src="/assets/images/trendingUp_icon.svg" alt="" /> +2.4%
                    </span>{' '}
                    July 5, 2022
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.productWrapper}>
              <div className="content">
                <div className="image">
                  <img src="/assets/images/overview_icon_2.svg" alt="" />
                </div>

                <div className="state">
                  <h5>NFTs Owned</h5>
                  <h3>196</h3>
                  <p>
                    <span style={{ color: '#E23434' }}>
                      <img src="/assets/images/trendingDown_icon.svg" alt="" />
                      -2.32
                    </span>{' '}
                    July 5, 2022
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.productWrapper}>
              <div className="content">
                <div className="image">
                  <img src="/assets/images/overview_icon_3.svg" alt="" />
                </div>

                <div className="state">
                  <h5>Current NFTs Value</h5>
                  <h3>$146,090</h3>
                  <p>
                    <span>
                      <img src="/assets/images/trendingUp_icon.svg" alt="" />
                      +1.1%
                    </span>{' '}
                    July 5, 2022
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.productWrapper}>
              <div className="content">
                <div className="image">
                  <img src="/assets/images/overview_icon_4.svg" alt="" />
                </div>

                <div className="state">
                  <h5>Annual Rental Income</h5>
                  <h3>$13,050</h3>
                  <p>
                    <span>
                      <img src="/assets/images/trendingUp_icon.svg" alt="" />
                      +2.2%
                    </span>{' '}
                    July 5, 2022
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.productWrapper}>
              <div className="content">
                <div className="image">
                  <img src="/assets/images/overview_icon_5.svg" alt="" />
                </div>

                <div className="state">
                  <h5>Overall Performance</h5>
                  <h3>+ 7.64%</h3>
                  <p>
                    <span>
                      <img src="/assets/images/trendingUp_icon.svg" alt="" />
                      +1.9%
                    </span>{' '}
                    July 5, 2022
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.productWrapper}>
              <div className="content">
                <div className="image">
                  <img src="/assets/images/overview_icon_6.svg" alt="" />
                </div>

                <div className="state">
                  <h5>Profit/Loss</h5>
                  <h3 style={{ color: '#42A846' }}>+ $9,512</h3>
                  <p>
                    <span>
                      <img src="/assets/images/trendingUp_icon.svg" alt="" />
                      +1.5%
                    </span>{' '}
                    July 5, 2022
                  </p>
                </div>
              </div>
            </div>
          </Masonry>
        </div>
      </div>
    </>
  );
};

export default State;
