import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  table: {
    '& .MuiTableCell-root': {
      borderBottom: `1px solid #ddd`,
      color: '#000',
      cursor: 'pointer',
      fontWeight: 600,
      minWidth: 120,
      '@media screen and (max-width: 640px) and (orientation: portrait)': {
        minWidth: 'auto',
        // minWidth: 80,
        fontSize: 8,
        padding: '5px 2px',
      },
    },

    '& .MuiTableHead-root .MuiTableCell-root': {
      background: '#2C8DFF !important',
      fontSize: 18,
      color: '#fff',
      '@media screen and (max-width: 450px) and (orientation: portrait)': {
        fontSize: 8,
      },
    },
  },

  container: {
    borderRadius: '10px',
    padding: 20,
    boxShadow: '3px 3px 15px #eee',
    '@media screen and (max-width: 450px) and (orientation: portrait)': {
      padding: 0,
    },
  },
}));

const RecentProposalsTable = ({ columns, rows, type }) => {
  const classes = useStyles();
  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column, k) => (
              <TableCell style={{ color: '#fff' }} key={k}>
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, kk) => (
            <TableRow key={kk}>
              <TableCell style={{ color: '#000' }}>{row.date}</TableCell>
              <TableCell style={{ color: '#000' }}>{row.id}</TableCell>
              <TableCell style={{ color: '#000' }}>{row.name}</TableCell>
              <TableCell style={{ color: '#000' }}>{row.typeOfProperty}</TableCell>
              <TableCell style={{ color: '#000' }}>$ {row.yesNo}</TableCell>
              <TableCell style={{ color: '#000' }}>$ {row.owners}</TableCell>
              <TableCell style={{ color: '#000' }}>$ {row.proposal}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default RecentProposalsTable;
