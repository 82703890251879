import { makeStyles } from '@material-ui/core/styles';
import RecentTransactions from 'components/OverviewSection/RecentTransactions';
const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(5),
    },
  },

  section: {
    position: 'relative',
    paddingTop: theme.spacing(0),
  },
  topSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(7),
  },
  video: {
    width: '100%',
  },
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 20,
    padding: 20,
    border: '1px #ddd solid',
    borderRadius: 20,
    marginBottom: 20,
    '@media screen and (max-width: 450px) and (orientation: portrait)': {
      flexDirection: 'column',
      width: 'calc(100% - 20px)',
      margin: 10,
    },

    '& span': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      '@media screen and (max-width: 450px) and (orientation: portrait)': {
        fontSize: 30,
      },
    },
    '& p': {
      color: '#000',
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.8,
      '@media screen and (max-width: 450px) and (orientation: portrait)': {
        fontSize: 14,
      },
    },
  },
  loadMore: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 20,
    },
  },
  title: {
    fontSize: 32,
    color: '#000',
  },
}));

const Transactions = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.section}>
          <RecentTransactions />
        </div>
        {/* <div className={classes.loadMore}>
          <LoadMoreButton loadMore={undefined} />
        </div> */}
        <div className={classes.section}>
          <div className={classes.wrapper}>
            <span>
              <p>Portfolio</p>
              <p>USD 146,090</p>
            </span>

            <span>
              <p>Annual Rental Income:</p>
              <p>USD 13,050</p>
            </span>

            <span>
              <p>Total Profit/Loss: </p>
              <p>USD +9,512</p>
            </span>
          </div>
        </div>
        {/* <div className={classes.loadMore}>
          <LoadMoreButton loadMore={undefined} />
        </div> */}
      </div>
    </>
  );
};

export default Transactions;
