import { useStyles } from './style';
import Masonry from 'react-masonry-css';
import ProductCard1 from 'components/Cards/ProductCard1';
import Filter from 'components/Filter/Filter';
import { useState } from 'react';
const myData = [
  {
    id: 9,
    img: '/assets/images/image_08.png',
    type: 'Holiday Home',
    startPrice: 2400,
    estatePrice: 2400000,
    name: 'Modern 4-Bedroom House Near the Alps',
    location: 'Swiss Alps, Switzerland',
    homeState: 'Upcoming',
    beds: 4,
    minted: 300,
    country: "Switzerland",
    state: {
      expected: 54,
      yeld: 8.79,
      frequency: 'Monthly',
    },
  },
  {
    id: 10,
    img: '/assets/images/image_09.png',
    type: 'Holiday Home',
    startPrice: 800,
    estatePrice: 800000,
    name: 'Beautiful 3-Bedroom Holiday House with a Pool',
    location: 'Dubai Hills, Dubai',
    homeState: 'Upcoming',
    beds: 3,
    minted: 600,
    country: "Dubai",
    state: {
      expected: 48,
      yeld: 7.5,
      frequency: 'Monthly',
    },
  },
  {
    id: 11,
    img: '/assets/images/image_10.png',
    type: 'Rental Property',
    startPrice: 400,
    estatePrice: 400000,
    name: 'Chic 1-Bedroom Apartment with a Beautiful View',
    location: 'Paris, France',
    homeState: 'Upcoming',
    beds: 1,
    minted: 750,
    country: "France",
    state: {
      expected: 42,
      yeld: 6.32,
      frequency: 'Monthly',
    },
  },
];

const Upcoming = () => {
  const classes = useStyles();
  const breakpointColumnsObj = {
    // default: 4,
    3840: 7,
    3000: 6,
    2560: 5,
    2200: 4,
    1840: 3,
    1440: 3,
    1280: 2,
    768: 2,
    450: 1,
  };


  const [data, setData] = useState(myData);

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <span>
          <h1 className={classes.title}>Upcoming Properties</h1>
          <p>Mint exclusive Real Estate NFTs before they hit the secondary market</p>
        </span>
      </div>
      <Filter data={data} setData={setData} myData={myData}/>
      <div className={classes.content}>
        <Masonry breakpointCols={breakpointColumnsObj} className={classes.masonry} columnClassName={classes.gridColumn}>
          {data.map((d, i) => (
            <ProductCard1 key={i} product={d} isUpcoming />
          ))}
        </Masonry>
      </div>
    </div>
  );
};

export default Upcoming;
