import './Beds.scss';
import InputField from 'components/Forms/InputField';
import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  formWrapper: {
    width: '48%',
  },

  myInput: {
    color: '#333',
  },
}));

const Beds = () => {
  const classes = useStyles();
  return (
    <div className="Beds">
      <h2>Square Feet</h2>
      <div className="row">
        <InputField
          name="max"
          className={classes.myInput}
          wrapperClass={classes.formWrapper}
          type="number"
          endIcon={<img src="/assets/images/arrow_02.svg" alt="" />}
          placeholder="No Min"
          onChangeData={val => {}}
        />

        <InputField
          name="max"
          className={classes.myInput}
          wrapperClass={classes.formWrapper}
          type="number"
          endIcon={<img src="/assets/images/arrow_02.svg" alt="" />}
          placeholder="No Max"
          onChangeData={val => {}}
        />
      </div>
      <h2>Beds</h2>
      <div className="beds">
        <button>Any</button>
        <button>1+</button>
        <button>2+</button>
        <button>3+</button>
        <button>4+</button>
        <button>5+</button>
      </div>
    </div>
  );
};
export default Beds;
