import { useCallback } from 'react'
import { networkConnector } from "../utils/connectors"
import { useMoralis } from 'react-moralis';

let connector = null;
connector = networkConnector;

const useAuth = () => {
  // const { chainId, activate, deactivate } = useWeb3React()
  const { authenticate } = useMoralis();
  
  const login = useCallback(async (walletId = 0) => {
    await authenticate({ signingMessage: "Welcome to Metropoly!" })
    // if (walletId === 1) {
    //   connector = injectedConnector;
    // } else if (walletId === 2) {
    //   connector = walletConnector;
    // }


    // await activate(connector);
    // if (chainId !== parseInt(currentNetwork)) {
    //   if (connector !== networkConnector) {
    //     toast.error("Unsupported Network. This platform is working on Binance Smart Chain " + parseInt(currentNetwork));
    //     connector = networkConnector;
    //     await activate(connector);
    //   }
    // }
  }, [])

  const logout = useCallback(() => {
    // connector = networkConnector;
    // deactivate()
  }, [])

  return { login, logout }
}

export default useAuth
