import './listModal.scss';
import Bounce from 'react-reveal/Bounce';
import { useEffect, useState } from 'react';
import OutlinedButton from 'components/Buttons/OutlinedButton';
import InputField from 'components/Forms/InputField';

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  formWrapper: {
    width: '100%',
  },

  myInput: {
    color: '#333',
  },
}));
interface Props {
  showModal: boolean;
  setShowModal?: any;
}

const ListModal: React.FC<Props> = ({ showModal, setShowModal }) => {
  const classes = useStyles();
  const [isStart, setIsStart] = useState(false);
  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        setIsStart(true);
      }, 100);
    }
  }, [setIsStart, showModal]);
  const onClose = () => {
    setIsStart(false);
    setTimeout(() => {
      setShowModal(false);
    }, 800);
  };

  const [mintCount, setMintCount] = useState(1);
  const decreaseHandle = () => {
    if (mintCount > 0) {
      setMintCount(mintCount - 1);
    }
  };
  const increaseHandle = () => {
    if (mintCount < 10) {
      setMintCount(mintCount + 1);
    }
  };
  return (
    <div className={showModal === true ? 'listModal active' : 'listModal'}>
      <Bounce opposite when={isStart}>
        <div className="modelContent">
          <div className="connectWalletHeader">
            <h1 className="connectWalletTitle">List your Real Estate NFT for sale</h1>
            <button className="connectModalCloseButton" onClick={onClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="connectWalletWrapper">
            <div className="row">
              <h5>Quantity</h5>
              <p>Price</p>
            </div>
            <div className="row mb-20">
              <div className="count">
                <button onClick={decreaseHandle}>-</button>
                <input type="number" value={mintCount} onChange={e => setMintCount(parseFloat(e.target.value))} />
                <button onClick={increaseHandle}>+</button>
              </div>
              <div className="amount">
                <input type="number" placeholder="Amount" />
              </div>
            </div>
            <div className="row mb-20">
              <InputField
                name="name"
                className={classes.myInput}
                wrapperClass={classes.formWrapper}
                startIcon={<img src="/assets/images/calender_03.svg" alt="" />}
                label={<p>Duration</p>}
                placeholder="1 Month"
                onChangeData={val => {}}
              />
            </div>
            <div className="row mb-20">
              <InputField
                name="name"
                className={classes.myInput}
                wrapperClass={classes.formWrapper}
                endIcon={'%'}
                label={<p>Fees</p>}
                placeholder="Service Fee"
                onChangeData={val => {}}
              />
            </div>

            <div className="btns">
              <OutlinedButton
                iconPosition="start"
                icon={<img src="/assets/images/list_icon.svg" alt="" />}
                label="List for Sale"
              />
            </div>
          </div>
        </div>
      </Bounce>
    </div>
  );
};
export default ListModal;
