import { useState, useEffect } from 'react';
import Masonry from 'react-masonry-css';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';

import { NFTUserFullDetail, useGetNFTUserFullDetail } from 'hooks/useApi';
import FilledButton from '../../components/Buttons/FilledButton';

import ProductCard1 from 'components/Cards/ProductCard1';
import InputField from 'components/Forms/InputField';
import OptionModal from 'components/modal/optionModal/OptionModal';
import BuyModal from 'components/modal/buyModal/BuyModal';
import MakeModal from 'components/modal/makeModal/MakeModal';
import AuctionModal from 'components/modal/auctionModal/AuctionModal';
import ListModal from 'components/modal/listModal/ListModal';
import { truncateWalletString } from 'utils';
import { useMoralis, useMoralisFile } from "react-moralis";
import toast from 'react-hot-toast';
import copy from 'copy-to-clipboard';

const myData = [
  {
    id: 8,
    img: '/assets/images/image_07.png',
    type: 'Rental Property',
    startPrice: 2400,
    estatePrice: 2400000,
    name: 'Rare 3-Bedroom Penthouse with a Patio',
    location: 'Barcelona, Spain',
    homeState: 'Rented',
    beds: 3,
    state: {
      expected: 51,
      yeld: 8.12,
      frequency: 'Monthly',
      profit: '+8%',
      owned: '6/1000',
      value: '$14,400',
    },
    annualIncome: 1169.28,
  },
  {
    id: 6,
    img: '/assets/images/image_05.png',
    type: 'Rental Property',
    startPrice: 215,
    estatePrice: 215000,
    name: '1-Bedroom Studio Apartment Within Walking Distance of the City Center',
    location: 'Manchester, United Kingdom',
    homeState: 'Rented',
    beds: 1,
    state: {
      expected: 38,
      yeld: 5.66,
      frequency: 'Monthly',
      profit: '+5%',
      owned: '80/1000',
      value: '$17,200',
    },
    annualIncome: 973.25,
  },
];
const useStyles = makeStyles<Theme, NFTUserFullDetail>(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      paddingTop: 0,
    },
  },

  banner: {
    background: '#00D9AC33',
    borderRadius: '10px',
    backgroundImage: props => `url("/assets/profile_banner.png")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '300px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      height: '61vw',
    },
    '& .editProfile': {
      position: 'absolute',
      bottom: 20,
      right: 20,
      width: 40,
      height: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fff',
      borderRadius: 40,
      [theme.breakpoints.down('xs')]: {
        top: 20,
        bottom: 'auto',
      },
      '& .input': {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
  editBtn: {
    display: 'none',
    // width: '100%',
    // position: 'absolute',
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingBottom: 32,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },

  userInfo: {
    paddingRight: 24,
    margin: '1vw',
    marginTop: -120,
    width: '20vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
    background: '#fff',
    boxShadow: '0px 4px 100px rgba(202, 202, 202, 0.5)',
    borderRadius: 15,
    zIndex: 1,

    [theme.breakpoints.down('xs')]: {
      marginLeft: '5%',
      marginTop: -80,
      width: '90%',
    },
    '& .address': {
      display: 'flex',
      alignItems: 'center',
      '& p': {
        border: 'none',
        width: 'fit-content',
        color: '#93989A',
        fontWeight: 600,
      },
      '& button': {
        border: 'none',
        background: '#ffffff00',
        color: '#93989A',
        fontSize: 18,
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        '&:hover': {
          color: '#aaa',
        },
      },
    },
    '& img': {
      width: 80,
      height: 80,
      borderRadius: 80,
      objectFit: 'cover',
    },
    '& h4': {
      width: 'fit-content',
      color: '#93989A',
      textAlign: 'center',
      paddingTop: 30,
      paddingBottom: 30,
      fontWeight: 600,
      fontSize: '1vw',

      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    },
    '& h1': {
      fontSize: '1.7vw',
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
      },
    },
  },

  content: {
    width: 'calc(100% - 20vw)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '0 0px',
    },
  },
  top: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingTop: 30,
    paddingBottom: 20,
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0px',
      // flexWrap: 'wrap',
    },
    '& span': {
      color: '#93989A',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        marginBottom: 10,
        // width: '100%',
        justifyContent: 'space-between',
      },
    },
    '& a': {
      color: '#93989A',
      display: 'flex',
      alignItems: 'center',
      marginRight: 20,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
        fontSize: 10,
        marginRight: 7,
      },
      '& img': {
        marginRight: 10,
        [theme.breakpoints.down('xs')]: {
          width: 12,
          marginRight: 7,
        },
      },
    },
    '& .viewAll': {
      color: '#2892F3',
      marginLeft: '3vw',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  },
  formWrapper: {},
  header: {
    color: '#000',
    fontSize: 26,
    [theme.breakpoints.only('sm')]: {
      fontSize: 30,
    },
  },

  masonry: {
    display: 'flex',
    // margin: theme.spacing(0, -1.75),
  },

  gridColumn: {
    margin: theme.spacing(0, 2),
  },

  myInput: {
    color: '#999',
    [theme.breakpoints.down('xs')]: {
      height: 40,
      minHeight: 40,
      borderRadius: 10,
      width: 150,
      fontSize: 10,
    },
  },
}));

const Profile = () => {
  const location = useLocation();

  const { setUserData, user, isAuthenticated} = useMoralis();
  const {
    saveFile,
  } = useMoralisFile();
  const walletAddress = location.pathname.split('/').pop();

  const nftUserFullDetail = useGetNFTUserFullDetail(walletAddress) || {};

  const classes = useStyles({ ...nftUserFullDetail });
  const history = useHistory();
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // const [name, setName] = useState('');

  const theme = useTheme();
  const breakpointColumnsObj = {
    default: 2,
    [theme.breakpoints.values.md]: 1,
    700: 1,
    500: 1,
  };
  const [account, setAccount] = useState(null);
  const [username, setUsername] = useState(null);
  const [bio, setBio] = useState(null);
  const [joined, setJoined] = useState(null);
  const [showOption, setShowOption] = useState(false);
  const [showBuy, setShowBuy] = useState(false);
  const [showMake, setShowMake] = useState(false);
  const [showSellAuction, setShowSellAuction] = useState(false);
  const [showList, setShowList] = useState(false);
  const [avatarImage, setAvatar] = useState(null);
  const [profilePicture, setProfPic] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [loading, setLoading] = useState(false);


  const handleUploadAvatar = (file) => {
    saveFile(file.name, file).then((data) => {
      console.log("uploaded", data)
      setUserData({ avatarImage: data._url }).then((data) => {
        console.log(data)
        setAvatar(data.get("avatarImage"))
        toast.success("Updated Cover photo.")
      })
    });

  }
  useEffect(() => {
    if (isAuthenticated) {
      setAccount(user.get("ethAddress"))
      setUsername(user.get("username"))
      setBio(user.get("bio"))
      setAvatar(user.get("avatarImage"))
      setJoined(user.get("createdAt"))
      setProfPic(user.get("profilePicture"))
    }
  }, [isAuthenticated, avatarImage, user])

  return (
    <>
      <div className={classes.root}>
        <div
          className={classes.banner}
          style={{
            backgroundImage: `url("${avatarImage ? avatarImage : 'assets/profile_banner.png'}")`,
          }}
        >
          {nftUserFullDetail!.userProfile?.walletAddress?.toLowerCase() === account?.toLowerCase() && (
            <div className={classes.buttonGroup}>
              <FilledButton
                label="Edit profile"
                icon={<BorderColorOutlinedIcon className={classes.icon} />}
                handleClick={() => history.push('/profile/edit')}
              />
            </div>
          )}

          <label className="editProfile">
            <div className="input">
              <img src="/assets/images/pen_icon.svg" alt="" />
              <input
                className={classes.editBtn}
                type="file"
                id="avatar-file-input"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files && e.target.files[0]) {
                    setAvatar(e.target.files[0]);
                    handleUploadAvatar(e.target.files[0]);
                  }
                }}
              />
            </div>
          </label>
        </div>
        <div className={classes.container}>
          <div className={classes.userInfo}>
            <img src={profilePicture ? profilePicture : "/assets/avatars/avatar_02.png"} alt="" />
            <h1>{
              username ?
                username.length > 24 ?
                  "Demo Account" :
                  <> {username}
                  </>
                : "Not Connected"
            }
            </h1>
            <div className="address">
              <p>{truncateWalletString(account)}</p>
              <button onClick={() => {
                copy(account)
                toast.success("Copied!")
              }}>
                <i className="fas fa-copy"></i>

              </button>
            </div>
            <h4>{bio ? bio : "To add biography, please edit your profile."}</h4>
            <FilledButton label="Edit Profile" handleClick={() => history.push('/settings')} />

            <h4>Joined {monthNames[new Date(joined).getMonth()]} {new Date(joined).getFullYear().toString()}</h4>
          </div>
          <div className={classes.content}>
            <div className={classes.top}>
              <span>
                <Link to="/my-properties">
                  <img src="/assets/images/folder_icon_01.svg" alt="" />
                  My Properties
                </Link>
                <Link to="/transactions">
                  <img src="/assets/images/clock_icon_01.svg" alt="" />
                  Transaction History
                </Link>
              </span>

              <span>
                <InputField
                  name="name"
                  className={classes.myInput}
                  wrapperClass={classes.formWrapper}
                  startIcon={
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8.625 15.75C12.56 15.75 15.75 12.56 15.75 8.625C15.75 4.68997 12.56 1.5 8.625 1.5C4.68997 1.5 1.5 4.68997 1.5 8.625C1.5 12.56 4.68997 15.75 8.625 15.75Z"
                        stroke="#93989A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.5 16.5L15 15"
                        stroke="#93989A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                  value={searchName}
                  placeholder="Search by name"
                  onChangeData={val => {
                    setSearchName(val);
                  }}
                />
                <Link to="/marketplace" className="viewAll">
                  View All
                </Link>
              </span>
            </div>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className={classes.masonry}
              columnClassName={classes.gridColumn}
            >
              {myData.filter((element) => {

                return element.name.toLowerCase().includes(searchName.toLowerCase())
              }).map((d, i) => (
                <ProductCard1 key={i} product={d} isMyProperty showOption={setShowOption} />
              ))}
            </Masonry>
          </div>
        </div>
      </div>
      <OptionModal
        showModal={showOption}
        setShowModal={setShowOption}
        setShowBuy={setShowBuy}
        setShowMake={setShowMake}
        setShowSellAuction={setShowSellAuction}
        setShowList={setShowList}
      />
      <BuyModal showModal={showBuy} setShowModal={setShowBuy} />
      <MakeModal showModal={showMake} setShowModal={setShowMake} />
      <AuctionModal showModal={showSellAuction} setShowModal={setShowSellAuction} />
      <ListModal showModal={showList} setShowModal={setShowList} />
    </>
  );
};

Profile.propTypes = {};

Profile.defaultProps = {};

export default Profile;
