import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/Layout/Container';
import Expand from 'react-expand-animated';
import { useState } from 'react';
const faqData = [
  {
    quetion: 'What is Metropoly?',
    answer: [
      'Metropoly is the first real estate NFT ecosystem that allows anyone to own real estate-backed NFTs for as little as $100. Our vision is to make the real estate market accessible to people worldwide with a global decentralized real estate NFT marketplace, regardless of their country of origin or credit score.',
    ],
  },

  {
    quetion: 'What is a real estate NFT?',
    answer: [
      'In our quest to revolutionize the real estate industry, each real estate NFT that we mint is fractionalized and 100% backed by a physical income-generating property. All properties have been inspected beforehand and are owned by Metropoly or a trusted and vetted partner company.',
    ],
  },
  {
    quetion: 'How can I start?   ',
    answer: [
      '1. Connect your Metamask or use WalletConnect (ensure that you’re connected with the Ethereum blockchain).',
      '2. Discover and compare all available real estate NFTs.',
      '3. Buy your first real estate NFT without any paperwork.',
    ],
  },
  {
    quetion: 'What is net yield?',
    answer: [
      'The net yield of a property is the percentage of monthly rental income generated after deducting all expenses, such as management fees and maintenance costs. ',
      'For example, if a property is priced at $1,000,000 USD, rents for $100,000 USD per year, and costs $25,000 USD to maintain and cover all of the fees, then this property’s net income generates $75,000 USD, and the net yield is 7.5%. ',
    ],
  },
  {
    quetion: 'What is capital appreciation?',
    answer: [
      'The capital appreciation of a property is the growth in market value of the asset over time. For example, if a property is acquired for $1,000,000 USD and the acquisition costs are $100,000 USD, the total amount is $1,100,000 USD. Assuming that the property is sold after several years at a price of $1,500,000 USD (i.e., the net selling price), then the capital appreciation is $400,000 USD, or 36.4% (i.e., $400,000 USD / $1,100,000 USD).',
    ],
  },
  {
    quetion: 'Can I visit the properties?',
    answer: [
      'As part of our commitment to being fully transparent with our NFT buyers, all properties offered on the platform will include high-resolution photos and third-party inspection reports. However, visiting units is not part of our service because the property is generally already tenanted. ',
    ],
  },
  {
    quetion: 'What is the minimum starting price?',
    answer: [
      'Prices start from $100 for fractionalized ownership, depending on the properties offered in the Metropoly marketplace.',
    ],
  },
  {
    quetion: 'How much does it cost?',
    answer: [
      'Prices start from $100 but can cost up to several thousand dollars for fractionalized ownership, depending on the properties offered in the Metropoly marketplace.',
    ],
  },
  {
    quetion: 'Why should I buy real estate NFTs?',
    answer: [
      'Real estate is a tangible and stable asset class that has been a leading performer throughout history. As a result, it is a favorite among large institutional and high-net-worth individuals for many reasons, including:',
      '• Real estate appreciates in value over long periods and is less volatile than financial markets and cryptocurrencies, offering stability during turbulent times.',
      '• Real estate is a hedge against inflation and currency depreciation and an excellent long-term store of value.',
      '• Real estate is a productive asset that generates predictable income from rent.',
      '• When included in a diversified portfolio of different asset classes, real estate enhances the portfolio’s risk-return profile.',
    ],
  },
  {
    quetion: 'How much will real estate NFT holders receive monthly?',
    answer: [
      'The amount received will be determined by the amount spent and the net yield of the property owned. For example, if someone spends $100,000 USD on a property that generates a net yield of 7%, then they will receive $7,000 USD per year in rental income paid to their wallet every month.',
      'However, these numbers cannot be guaranteed, and many external factors can impact how a property performs. Therefore, every buyer of a real estate NFT is obligated to conduct independent research before purchasing one.',
    ],
  },
];
const useStyles = makeStyles(theme => ({
  root: {
    padding: 15,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
      paddingTop: 0,
    },
  },

  section: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: 20,
    '& .line': {
      padding: 20,
      border: '1px #bbb solid',
      borderRadius: 10,
      marginBottom: 20,
      width: '100%',
      '& .question': {
        padding: 20,
        marginBottom: 10,
        borderBottom: '1px #ddd solid',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        cursor: 'pointer',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          padding: 10,
        },
        '& p': {
          fontSize: 20,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 18,
          },
        },
      },
      '& .answer': {
        fontSize: 16,
        marginBottom: 10,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 14,
        },
      },
    },
  },
  topSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(7),
  },

  banner: {
    height: '100%',
    minHeight: '16vw',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 70,
    padding: 70,
    backgroundImage: `url("assets/images/home_banner.png")`,
    backgroundSize: '100% 100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      paddingTop: 100,
      backgroundSize: 'cover',
      backgroundPosition: '90%',
    },
    '& h1': {
      color: '#fff',
      fontSize: 40,
      fontWeight: 700,
      fontFamily: "'Outfit', sans-serif",
      width: '100%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 30,
      },
    },
  },
}));

const FAQ = () => {
  const classes = useStyles();
  const styles = {
    open: { width: '100%' },
    close: { width: '100%' },
  };
  const transitions = ['height', 'opacity', 'background'];
  const [faqId, setFaqId] = useState(-1);
  const handleClick = (id: number) => {
    if (faqId !== -1) {
      if (faqId === id) {
        setFaqId(-1);
      } else {
        setFaqId(id);
      }
    } else {
      setFaqId(id);
    }
  };
  return (
    <>
      <Container className={classes.root}>
        <div className={classes.topSection}>
          <div className={classes.banner}>
            <h1>FAQ'S</h1>
          </div>
        </div>
        <div className={classes.section}>
          {faqData.map((d, k) => (
            <div
              className="line"
              key={k}
              onClick={() => {
                handleClick(k);
              }}
            >
              <div className="question">
                <p
                  style={{
                    color: '#000',
                  }}
                >
                  {d.quetion}
                </p>
                <i
                  className="show fas fa-chevron-right"
                  style={{ transform: k === faqId ? 'rotate(90deg)' : 'rotate(0deg)' }}
                ></i>
              </div>
              <Expand open={faqId === k} duration={300} styles={styles} transitions={transitions}>
                {d.answer.map((e, i) => (
                  <p className="answer" key={`an${i}`}>
                    {e}
                  </p>
                ))}
              </Expand>
            </div>
          ))}
        </div>
      </Container>
    </>
  );
};

export default FAQ;
