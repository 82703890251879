import { makeStyles } from '@material-ui/core/styles';

import FilledButton from 'components/Buttons/FilledButton';
import { useState } from 'react';
import OutlinedButton from 'components/Buttons/OutlinedButton';
import InputField from 'components/Forms/InputField';
import { numberToString } from 'utils';

interface PropsType {
  product?: any;
  isBorrow?: boolean;
  isSupply?: boolean;
}

const useStyles = makeStyles(theme => ({
  productWrapper: {
    marginBottom: theme.spacing(4),
    maxWidth: 454,
    cursor: 'pointer',
    border: '1px solid #bababa',
    borderRadius: 18,
    overflow: 'hidden',
    paddingBottom: 30,
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      // maxWidth: 300,
    },
    '& .top': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: 20,
      position: 'relative',
      '& img': {
        width: '100%',
      },
      '& .topLeft': {
        position: 'absolute',
        top: 10,
        left: 10,
        '& span': {
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          background: '#fff',
          border: '2px solid #004AAD',
          padding: '5px 20px',
          borderRadius: 50,
          fontSize: 12,
          color: '#004AAD',
          fontWeight: 700,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 12,
          },
        },
      },
      '& .topRight': {
        position: 'absolute',
        top: 10,
        right: 10,
        '& span': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#fff',
          padding: 10,
          borderRadius: 50,

          '& img': {},
        },
      },
      '& .bottomRight': {
        position: 'absolute',
        bottom: 10,
        right: 10,
        '& span': {
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          background: '#fff',
          border: '2px solid #004AAD',
          padding: '5px 5px',
          borderRadius: 10,
          fontSize: 10,
          color: '#004AAD',
          fontWeight: 700,
          marginBottom: 8,
          minWidth: 70,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 10,
            minWidth: 60,
          },
          '& p': {
            color: '#004AAD',
            fontWeight: 700,
            fontSize: 10,
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
              fontSize: 10,
            },
          },
          '& img': {
            width: 35,
          },
        },
      },
    },
    '& .price': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 5,
      '& span': {
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        background: '#13CF8F',
        border: '2px solid #ffffff55',
        borderLeft: 'none',
        padding: '8px 20px',
        borderRadius: '0 50px 50px 0',
        '& .timer': {
          color: '#fff',
          fontSize: 18,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 16,
          },
        },
        '& h3': {
          color: '#fff',
          fontSize: 18,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 16,
          },
        },
        '& p': {
          color: '#fff',
          fontSize: 12,
          marginLeft: 7,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 10,
          },
        },
      },
    },
    '& .auction': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 5,
      '& span': {
        width: 'fit-content',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        background: '#13CF8F',
        border: '2px solid #ffffff55',
        borderLeft: 'none',
        padding: '8px 20px',
        borderRadius: '0 50px 50px 0',
        '& .timer': {
          color: '#fff',
          fontSize: 18,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 16,
          },
        },
        '& h3': {
          color: '#fff',
          fontSize: 18,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 16,
          },
        },
        '& p': {
          color: '#fff',
          fontSize: 12,
          marginLeft: 7,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 10,
          },
        },
      },
    },
    '& .title': {
      padding: '5px 20px',
      height: 'min(7vw, 100px)',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flexDirection: 'column',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        height: 'auto',
      },
      '& h1': {
        color: '#292A36',
        fontSize: 'min(1.8vw, 24px)',
        lineHeight: 1.1,
        fontWeight: 700,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 20,
          textAlign: 'left',
        },
      },
      '& p': {
        fontSize: 'min(1.2vw, 18px)',
        lineHeight: 1.7,
        color: '#6B7380',
        display: 'flex',
        alignItems: 'center',
        fontFamily: "'Antic Didone', serif",
        marginTop: 'auto',
        '& img': {
          marginRight: 7,
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: 14,
        },
      },
    },

    '& .state': {
      padding: '5px 20px',
      marginBottom: 10,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {},
      '& ul': {
        background: '#F5F6F8',
        padding: '8px 20px',
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'row',
        width: '100%',
        listStyle: 'none',

        '@media screen and (max-width: 768px) and (orientation: portrait)': {},
        '& li': {
          lineHeight: 1.7,
          color: '#6B7380',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          fontFamily: "'Antic Didone', serif",
          width: '100%',
          '& img': {
            marginRight: 7,
          },
          '& h4': {
            color: '#004AAD',
            fontSize: 14,
          },
          '& p': {
            color: '#000',
            fontSize: 10,
            textAlign: 'center',
            '@media screen and (max-width: 768px) and (orientation: portrait)': { fontSize: 10 },
          },
        },
      },
    },

    '& .state1': {
      padding: '5px 20px',
      marginBottom: 20,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {},
      '& ul': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'row',
        width: '100%',
        listStyle: 'none',
        padding: 0,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {},
        '& li': {
          lineHeight: 1.7,
          color: '#6B7380',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          fontFamily: "'Antic Didone', serif",
          width: '100%',

          '& h4': {
            color: '#000',
            fontSize: 14,
            background: '#fff',
            padding: '8px 20px',

            width: '100%',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            textAlign: 'center',
            border: '1px #BABABA solid',
          },
          '& p': {
            color: '#000',
            fontSize: 10,
            textAlign: 'center',
            '@media screen and (max-width: 768px) and (orientation: portrait)': { fontSize: 10 },
          },
        },
        '& .border-radius-left': {
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
          borderRight: '1px solid #ffffff00',
        },
        '& .border-radius-right': {
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
          borderLeft: '1px solid #ffffff00',
        },
      },
    },
    '& .state2': {
      padding: '5px 20px',
      marginBottom: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {},
      '& h3': {
        color: '#000',
        fontSize: 20,
      },
      '& p': {
        color: '#000',
        fontSize: 10,
        textAlign: 'center',
        '@media screen and (max-width: 768px) and (orientation: portrait)': { fontSize: 10 },
      },
      '& img': {
        marginRight: 10,
      },
      '& span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {},
      },
    },
    '& .state3': {
      margin: '5px 20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      width: 'calc(100% - 40px)',
      border: '1px #ddd solid',
      borderRadius: 7,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {},
      '& h4': {
        color: '#29999',
        fontSize: 20,
      },
      '& .count': {
        display: 'flex',
        alignItems: 'center',

        '& button': {
          fontSize: 24,
          border: 'none',
          width: 50,
          height: 50,
          borderRadius: 7,
          background: '#F7F9FA',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            width: 35,
            height: 35,
          },
          '&:hover': {
            background: '#ddd',
          },
        },
        '& input': {
          width: 100,
          height: 50,
          border: '2px #F7F9FA solid',
          fontSize: 24,
          textAlign: 'center',
          [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            width: 80,
            height: 33,
          },
        },
      },
    },
    '& .progress': {
      padding: '5px 10px',
      marginBottom: 20,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexDirection: 'column',

      '@media screen and (max-width: 768px) and (orientation: portrait)': {},
      '& h3': {
        color: '#000',
        fontSize: 14,
      },
      '& .progressBar': {
        background: '#BABABA',
        height: 15,
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        overflow: 'hidden',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {},
        '& .track': {
          background: '#004AAD',
          height: 15,
        },
      },
    },
    '& .footer': {
      padding: '5px 20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        width: '100% !important',
      },
      '& h4': {
        color: '#000',
        fontSize: 20,
        marginBottom: 10,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 16,
        },
      },
      '& p': {
        lineHeight: 1.7,
        fontSize: 14,
        color: '#6B7380',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 10,
        },
      },
    },
    '& .btns': {
      padding: '10px 20px',
      paddingBottom: 20,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',

      '& button': {
        padding: '5px 10px !important',
        width: '30%',
        // height : 50,
      },
    },
  },
  myBtn: {
    margin: '0px 20px',
    width: 'calc(100% - 40px)',
    height: 50,
    fontSize: 18,
  },
  label: {
    margin: '5px 20px',
    fontSize: 16,
    color: '#6B7380',
  },
  smallLabel: {
    margin: '5px 20px',
    fontSize: 12,
    width: 'calc(100% - 40px)',
    color: '#999',
    textAlign: 'end',
  },
  formWrapper: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    '& h3': {
      fontSize: 16,
      width: '30%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 14,
        width: '30%',
        marginBottom: 0,
      },
    },
  },

  myInput: {
    color: '#333',
    boxShadow: 'none !important',
    width: '68%',
    border: '1px #ddd solid !important',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      padding: 0,
      borderRadius: 10,
      height: 40,
    },
  },
}));

const ProductCard2 = ({ product, isBorrow, isSupply }: PropsType) => {
  const classes = useStyles();
  const onBorrow = () => { };
  const onSupply = () => { };
  const [mintCount, setMintCount] = useState(1);
  const decreaseHandle = () => {
    if (mintCount > 1) {
      setMintCount(mintCount - 1);
    }
  };
  const increaseHandle = () => {
    if (mintCount < 10) {
      setMintCount(mintCount + 1);
    }
  };
  const [isFavorite, setIsFavorite] = useState(false);
  return (
    <div className={classes.productWrapper}>
      <div className="top">
        <img src={product?.img || '/assets/images/unsplash_IYfp2Ixe9nM.png'} alt="" />
        {isBorrow && (
          <>
            <div className="topLeft">
              <span>Rental Property</span>
            </div>
            <div className="topRight" onClick={() => setIsFavorite(!isFavorite)}>
              <span>
                {isFavorite ? (
                  <img src="/assets/images/favorite_icon_03.svg" alt="" />
                ) : (
                  <img src="/assets/images/favorite_icon_01.svg" alt="" />
                )}
              </span>
            </div>
            <div className="bottomRight">
              <span>{product?.state}</span>
              <span>
                <img src="/assets/images/bed_icon.svg" alt="" />
                <p>3 Beds</p>
              </span>
            </div>
          </>
        )}
      </div>

      <div className="price">
        <span>
          <h3>{product?.price} USD*</h3>
          <p>starting price</p>
        </span>
      </div>

      <div className="title">
        <h1>{product?.name}</h1>
        <p>
          <img src={product?.location_icon} alt="" />
          {product?.location}
        </p>
      </div>

      {isBorrow && (
        <>
          <div className="state">
            <ul>
              <li>
                <h4>51%</h4>
                <p>Expected Return</p>
                <img src="/assets/images/coins.svg" alt="" />
              </li>
              <li>
                <h4>8.12%</h4>
                <p>Dividend Yield</p>
                <img src="/assets/images/arrow_01.svg" alt="" />
              </li>
              <li>
                <h4>Monthly</h4>
                <p>Dividend Frequency</p>
                <img src="/assets/images/calenter_01.svg" alt="" />
              </li>
            </ul>
          </div>
          <div className="state1">
            <ul>
              <li>
                <p>Profit/Loss</p>
                <h4 className="border-radius-left" style={{ color: '#13CF8F' }}>
                  +8%
                </h4>
              </li>
              <li>
                <p>NFTs Owned</p>
                <h4>6/1000</h4>
              </li>
              <li>
                <p>Current Value</p>
                <h4 className="border-radius-right">$14,400</h4>
              </li>
            </ul>
          </div>
          <div className="state2">
            <img src="/assets/images/back_icon_02.svg" alt="" />
            <span>
              <p>Annual Rental Income</p>
              <h3>1,169.40 USD </h3>
            </span>
          </div>
          <h4 className={classes.label}>No. of NFTs</h4>
          <div className="state3">
            <span className="count">
              <button onClick={decreaseHandle}>-</button>
              <input type="number" value={mintCount} onChange={e => setMintCount(parseFloat(e.target.value))} />
              <button onClick={increaseHandle}>+</button>
            </span>
            <h4 className={classes.label}>${numberToString(2400 * mintCount)}</h4>
          </div>
          <p className={classes.smallLabel}>Value of Collateral</p>
        </>
      )}

      {isSupply && (
        <>
          <div className="state">
            <ul>
              <li>
                <h4>16,235</h4>
                <p>Participants</p>
                <img src="/assets/images/users_icon_01.svg" alt="" />
              </li>
              <li>
                <h4>7.6%</h4>
                <p>Average Return</p>
                <img src="/assets/images/back_icon_01.svg" alt="" />
              </li>
              <li>
                <h4>3 months</h4>
                <p>Average Duration</p>
                <img src="/assets/images/clock_icon_02.svg" alt="" />
              </li>
            </ul>
          </div>
          <div className="state1">
            <ul>
              <li>
                <p>Current Liquidity Pool</p>
                <h4 style={{ borderRadius: 10 }}>$7,247,680</h4>
              </li>
            </ul>
          </div>
          <div className="state2">
            <img src="/assets/images/chart_icon_02.svg" alt="" />
            <span>
              <p>Up to </p>
              <h3 style={{ color: '#13CF8F' }}>15% APY</h3>
            </span>
          </div>
          <h4 className={classes.label}>Lending Pool Amount</h4>
          <OutlinedButton color="grey" className={classes.myBtn} label={'Enter Amount'} />

          <p className={classes.smallLabel} style={{ color: '#fff' }}>
            d fadfad
          </p>
        </>
      )}
      <h4 className={classes.label}>Maximum Duration</h4>
      <div className="btns">
        <OutlinedButton label={'1 WEEK'} />
        <OutlinedButton label={'1 MONTH'} />
        <OutlinedButton label={'1 YEAR'} />
      </div>

      <OutlinedButton className={classes.myBtn} label={'FLEXIBLE RATE'} />

      <div className="footer">
        {isBorrow ? (
          <>
            <InputField
              name="name"
              className={classes.myInput}
              wrapperClass={classes.formWrapper}
              label={'Amount to PAY BACK'}
              placeholder=""
              onChangeData={val => { }}
            />
            <p className={classes.smallLabel}>Current Borrow Rate: - %</p>
          </>
        ) : (
          <>
            <InputField
              name="name"
              className={classes.myInput}
              wrapperClass={classes.formWrapper}
              label={'The Amount YOU GET BACK'}
              placeholder=""
              onChangeData={val => { }}
            />
            <p className={classes.smallLabel}>Estimated APY: - %</p>
          </>
        )}
      </div>
      <FilledButton
        className={classes.myBtn}
        label={isBorrow ? 'BORROW NOW' : 'SUPPLY NOW'}
        handleClick={isBorrow ? onBorrow : onSupply}
      />
    </div>
  );
};

export default ProductCard2;
