import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import Expand from 'react-expand-animated';
import { numberToString } from 'utils';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 50,
    width: '100%',
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingBottom: 10,
    [theme.breakpoints.only('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  top: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    maxWidth: 1440,
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      justifyContent: 'center',
    },
    '& .search': {
      '& span': {
        position: 'relative',
        border: '1px #555 solid',
        padding: 8,
        borderRadius: 30,
        display: 'flex',
        alignItems: 'center',
        '& button': {
          position: 'absolute',
          right: 0,
          border: 'none',
          background: '#ffffff00',
        },
        '& input': {
          border: 'none',
          background: '#ffffff00',
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
    '& span': {
      marginRight: 10,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        marginRight: 0,
        marginBottom: 10,
      },
      '& .reset': {
        border: 'none',
        background: '#ffffff00',
        color: '#6864F9',
        cursor: 'pointer',
      },
    },
  },
  left: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '50%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      width: '100%',
      alignItems: 'center',
      marginBottom: 20,
    },
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      listStyle: 'none',
      flexWrap: 'wrap',
      padding: 20,
      '& li': {
        width: 'calc(50% - 40px)',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          width: '100%',
        },
      },
    },
    '& .chart': {
      width: '100%',
      height: 300,
      padding: 20,
    },
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '50%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      width: '100%',
      alignItems: 'center',
    },
  },

  header: {
    fontSize: 35,
    color: '#000',
    textAlign: 'center',
    lineHeight: '50px',
    position: 'relative',
    marginBottom: 10,
    fontWeight: 600,
    width: '100%',
    '@media (max-width: 768px)': {
      fontSize: '1.5em',
    },
  },

  panelTop: {
    display: 'flex',
    margin: theme.spacing(5, 0),
  },

  panel: {
    width: 'calc(100% - 20px)',
    padding: 20,
    border: '1px #ddd solid',
    borderRadius: 20,
    '& .head': {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: '1px #ddd solid',
      paddingBottom: 10,
      position: 'relative',
      '& img': {
        marginRight: 15,
      },
      '& span': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: 10,
        paddingBottom: 10,
        '& p': {
          color: '#2892F3',
          textAlign: 'left',
          fontSize: 14,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 12,
          },
        },
        '& h5': {
          color: '#343434',
          textAlign: 'left',
          fontSize: 18,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 14,
          },
        },
        '& h2': {
          color: '#2892F3',
          textAlign: 'left',
          fontSize: 30,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {},
        },
      },
      '& button': {
        position: 'absolute',
        right: '0',
        background: '#000',
        border: '1px #000 solid',
        borderRadius: 10,
        cursor: 'pointer',
      },
    },
    '& .row': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      marginTop: 20,
      '& p': {
        color: '#000',
        width: 'fit-content',
        fontSize: 14,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 10,
        },
      },
      '& h5': {
        color: '#2892F3',
        width: 'fit-content',
        fontSize: 16,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 12,
        },
      },
    },
  },

  smalTxt: {
    marginTop: 20,
    color: '#777',
    fontSize: 14,
    textAlign: 'center',
    width: '90%',
  },
}));
interface PropsType {
  data?: any;
}
const FinancialDetails = ({ data }: PropsType) => {
  const classes = useStyles();
  const styles = {
    open: { width: '100%' },
    close: { width: '100%' },
  };
  const transitions = ['height', 'opcity', 'background'];
  const [menuOpen1, setMenuOpen1] = useState(true);
  const [menuOpen2, setMenuOpen2] = useState(true);
  return (
    <>
      <div className={classes.root}>
        <div className={classes.top}>
          <h1 className={classes.header}>Financial Details</h1>
        </div>
        <div className={classes.content}>
          <div className={classes.left}>
            <div className={classes.panel}>
              <div className="head">
                <img src="/assets/images/back_icon_03.svg" alt="" />
                <span>
                  <p>Transaction</p>
                  <h5>Details</h5>
                </span>
                <button onClick={() => setMenuOpen1(!menuOpen1)}></button>
              </div>
              <Expand open={menuOpen1} duration={800} styles={styles} transitions={transitions}>
                <div className="row">
                  <p>Property Listing Price :</p>
                  <p>USD {numberToString(data?.financial.listPrice || 0)}</p>
                </div>
                <div className="row">
                  <p>
                    <strong>
                      Transaction Costs <i className="fas fa-caret-down"></i>
                    </strong>
                  </p>
                  <p>
                    <strong>USD {numberToString(data?.financial.transactionCosts || 0)}</strong>
                  </p>
                </div>
                <div className="row">
                  <p>Title Search & Insurance (0.5% - 1%)</p>
                  <p>USD {numberToString((data?.financial.transactionCosts * 0.1 || 0))}</p>
                </div>
                <div className="row">
                  <p>Recording Fees (0.2% - 0.5%)</p>
                  <p>USD {numberToString((data?.financial.transactionCosts * 0.05 || 0))}</p>
                </div>
                <div className="row">
                  <p>Legal Fees (0.5% - 1%)</p>
                  <p>USD {numberToString((data?.financial.transactionCosts * 0.1 || 0))}</p>
                </div>
                <div className="row">
                  <p>Taxes (1%)</p>
                  <p>USD {numberToString((data?.financial.transactionCosts * 0.1 || 0))}</p>
                </div>
                <div className="row">
                  <p>Broker Fee (3%)</p>
                  <p>USD {numberToString((data?.financial.transactionCosts * 0.3 || 0))}</p>
                </div>
                <div className="row">
                  <p>Renovation Costs (2%)</p>
                  <p>USD {numberToString((data?.financial.transactionCosts * 0.2 || 0))}</p>
                </div>
                <div className="row">
                  <p>Metropoly fee (1.5%)</p>
                  <p>USD {numberToString((data?.financial.transactionCosts * 0.15 || 0))}</p>
                </div>
                <div className="row">
                  <h5>Total Acquisition Cost :</h5>
                  <h5>USD {numberToString((data?.financial.acquisitionCost || 0))}</h5>
                </div>
                <div className="row">
                  <p>Total Number of NFTs :</p>
                  <p>1,000</p>
                </div>
                <div className="row">
                  <p>Value of One NFT :</p>
                  <p>1/1000</p>
                </div>
                <div className="row">
                  <h5>Mint Price Per NFT :</h5>
                  <h5>USD {numberToString((data?.financial.acquisitionCost / 1000|| 0))}</h5>
                </div>
              </Expand>
            </div>
          </div>
          <div className={classes.right}>
            <div className={classes.panel}>
              <div className="head">
                <img src="/assets/images/file_icon.svg" alt="" />
                <span>
                  <p>Rental</p>
                  <h5>Breakdown</h5>
                </span>
                <button onClick={() => setMenuOpen2(!menuOpen2)}></button>
              </div>
              <Expand open={menuOpen2} duration={800} styles={styles} transitions={transitions}>
                <div className="row">
                  <p>Gross Yield</p>
                  <p>{data?.financial.grossYield} %</p>
                </div>
                <div className="row">
                  <p>Gross Rent Per Year</p>
                  <p>USD {numberToString((data?.financial.grossRentPerYear || 0))}</p>
                </div>
                <div className="row">
                  <p>Service Charges (10%)</p>
                  <p>USD {numberToString((data?.financial.grossRentPerYear * 0.1 || 0))}</p>
                </div>
                <div className="row">
                  <p>Property Management (15%)</p>
                  <p>USD {numberToString((data?.financial.grossRentPerYear * 0.15 || 0))}</p>
                </div>
                <div className="row">
                  <p>Property Insurance</p>
                  <p>USD {numberToString((data?.financial.propertyInsureance || 0))}</p>
                </div>
                <div className="row">
                  <p>Maintenance Charges (10%) </p>
                  <p>USD {numberToString((data?.financial.grossRentPerYear * 0.1 || 0))}</p>
                </div>
                <div className="row">
                  <p>Net Dividend Yield</p>
                  <p>{data?.howmuch.netDividend} %</p>
                </div>
                <div className="row">
                  <h5>Annual Rental income</h5>
                  <h5>USD {numberToString(data?.financial.annualIncome || 0)}</h5>
                </div>
              </Expand>
            </div>
          </div>
        </div>
        <p className={classes.smalTxt}>
          This financial breakdown is entirely fictitious and serves only as an illustration. Prices and costs may vary
          and depend on the city, the country, the applicable laws, and other factors.
        </p>
      </div>
    </>
  );
};

export default FinancialDetails;
