import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    background: '#ffffff00',
    paddingTop: 50,
    width: '100%',
    paddingBottom: 127,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 80,
      paddingBottom: 50,
    },
    '&.MuiSelect-icon': {},
  },
  top: {
    background: '#ffffff00',
    padding: theme.spacing(0, 2),
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 20,
    },
    '& p': {
      fontSize: 14,
      color: '#93989A',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 10,
      },
    },
  },
  container: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    '& h3': {
      fontSize: 20,
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
    '& h2': {
      fontSize: 22,
      textTransform: 'uppercase',
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    '& p': {
      fontSize: 16,
      textTransform: 'uppercase',
      marginBottom: 20,
      textAlign: 'left',
      width: '100%',
      color: '#999',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
  },
  myForm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: 10,
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '48%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {},
    '& p': {
      fontSize: 14,
      color: '#93989A',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 10,
      },
    },
    '& button': {
      fontSize: 14,
      border: 'none',
      borderRadius: 50,
      padding: '10px 30px',
      transition: 'all 0.3s ease',
      cursor: 'pointer',
      color: '#fff',
      background: '#aaa',
      '&:hover': {
        opacity: 0.8,
      },

      [theme.breakpoints.down('sm')]: {},
    },
    '& .submit': {
      background: '#2892F3',
    },
  },

  uploadContainer: {},

  title: {
    fontSize: 32,
    color: '#000',
  },
  subTitle: {
    fontSize: 20,
    color: '#000',
    width: '100%',
    paddingBottom: 10,
    borderBottom: '1px #ddd solid',
  },
  label: {
    fontWeight: 500,
  },
  formWrapper: {
    marginTop: 20,
    width: '100%',
  },

  myInput: {
    color: '#000',
    borderRadius: 0,
    borderBottom: '1px solid #ddd',
  },
  formGroup: {
    paddingTop: 3,
  },
}));

export default useStyles;
