import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import UserChip from '../AvatarInfoItems/UserChip';
import PopoverMenu from '../PopoverMenu';
import TinyBold from '../Typography/TinyBold';

import FilledButton from 'components/Buttons/FilledButton';
import { truncateWalletString } from 'utils';
import { } from 'utils';
import { useMoralis } from "react-moralis";
import toast from 'react-hot-toast';

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(3.875),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    '& .MuiPopover-paper': {
      background: '#2892F3',
      boxShadow: '0 0 5px 7px #00000022',
      right: '30px !important',
      left: 'auto !important',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        right: '10px !important',
      },
    },
  },
  menuContent: {
    minWidth: 320,
    [theme.breakpoints.down('xs')]: {
      minWidth: '90vw',
    },
  },
  label: {
    fontWeight: 700,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    '& button': {
      border: 'none',
      background: '#fff',
      padding: '7px 20px',
      borderRadius: 50,
    },
    '& img': {
      width: 40,
      height: 40,
      border: '3px #fff solid',
      borderRadius: 50,
      marginRight: 10,
      cursor: 'pointer',
    },
  },
  state: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '#F7F9FA',
    padding: '20px 20px',
    borderTopRightRadius: 30,
    borderTopLeftRadius: 30,
    [theme.breakpoints.down('xs')]: {
      padding: '20px 30px',
    },
    '& button': {
      width: '100%',
      height: 50,
      fontSize: 18,
      marginTop: 10,
    },
    '& h2': {
      color: '#2892F3ee',
      marginBottom: 10,
      fontSize: 18,
    },
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    borderBottom: '1px #ddd solid',
    paddingBottom: 5,
    marginBottom: 5,
    '& img': {
      height: 40,
      marginRight: 10,
    },
    '& .text': {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'column',
      '& p': {
        fontSize: 12,
        color: '#292A3699',
        '& span': {
          marginLeft: 10,
          color: '#0AAA2D99',
          '& img': {
            height: 14,
            marginRight: 7,
          },
        },
      },
      '& h3': {
        fontSize: 26,
        color: '#6B7380',
        isplay: 'flex',
        alignItems: 'center',
      },
    },
    '& .right_border': {
      borderRight: '1px #aaa solid',
    },
  },
  header: {
    widht: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
  },
  balance: {
    widht: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '#ffffff34',
    padding: '20px 20px',
    borderRadius: 30,
    margin: 15,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.5)',
    '& p': {
      fontSize: 14,
      color: '#fff',
    },
    '& h3': {
      fontSize: 40,
      color: '#fff',
    },
  },
  icon: {
    color: '#fff !important',
    fontSize: 25 + 'px !important',
  },
  links: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingTop: 20,
    paddingBottom: 10,
    '& a': {
      fontSize: 14,
      color: '#54555E',
      display: 'flex',
      alignItems: 'center',
      '& img': {
        marginRight: 10,
      },
    },
    '&:hover': {
      cursor: 'pointer',
      color: '#fff',
    },
  },
}));

const UserDropDown = ({ avatarUrl, displayName, balance, walletAddress }) => {
  const classes = useStyles();
  const history = useHistory();
  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const today = month[new Date().getMonth()] + " " + new Date().getDate() + ", " + new Date().getFullYear();
  const [profilePicture, setProfPic] = useState(null);

  const { isAuthenticated, user, logout } = useMoralis();

  const handleLogout = () => {
    console.log("user logged out")
    logout();
    history.push('/');
    toast.success("Logout successful!");
  }
  const gotoProfile = () => {
    history.push('/profile');
  };


  useEffect(() => {
    if (isAuthenticated) {
      setProfPic(user.get("profilePicture"))
      console.log(user)
    }
  }, [isAuthenticated, user])

  return (
    <PopoverMenu
      className={classes.paper}
      anchor={<UserChip avatarUrl={avatarUrl} displayName={truncateWalletString(walletAddress)} balance={balance} />}
      anchorOrigin={{
        vertical: 60,
        horizontal: 30,
      }}
    >
      <div className={classes.menuContent}>
        <div className={classes.header}>
          <TinyBold className={classes.label}>
            <img src={profilePicture ? profilePicture : "/assets/avatars/avatar_02.png"} alt="" onClick={gotoProfile} />
            <button>My Wallet</button>
          </TinyBold>
          <img src="/assets/images/exit_icon_02.svg" alt="" className={classes.icon} onClick={() => handleLogout()} />
        </div>

        <div className={classes.balance}>
          <p>Total Balance</p>
          <h3>$ {balance}</h3>
        </div>

        <div className={classes.state}>
          <h2>{today}</h2>
          <div className={classes.row}>
            <img src="/assets/images/home_icon_01.svg" alt="" />
            <div className="text">
              <p>
                My Properties Total Value{' '}
                <span>
                  <img src="/assets/images/chart_icon_06.svg" alt="" />
                  +10.4%
                </span>
              </p>
              <h3>$ 146,096</h3>
            </div>
          </div>
          <div className={classes.row}>
            <img src="/assets/images/home_icon_02.svg" alt="" />
            <div className="text">
              <p>
                Annual Rental Income
                <span>
                  <img src="/assets/images/chart_icon_06.svg" alt="" />
                  +2.4%
                </span>
              </p>
              <h3>$ 13,050</h3>
            </div>
          </div>
          <div className={classes.row}>
            <img src="/assets/images/home_icon_03.svg" alt="" />
            <div className="text">
              <p>
                Numbers of Properties
                <span>
                  <img src="/assets/images/chart_icon_06.svg" alt="" />
                  +2.2%
                </span>
              </p>
              <h3>9</h3>
            </div>
          </div>
          <FilledButton label={'Overview'} handleClick={() => history.push('/overview')} />

          <div className={classes.links}>
            <span>
              <Link to="/my-properties">
                <img src="/assets/images/folder_icon.svg" alt="" /> My Properties
              </Link>
            </span>
            <span>
              <Link to="/payouts">
                <img src="/assets/images/clock_icon.svg" alt="" /> Payouts
              </Link>
            </span>
          </div>
        </div>
      </div>
    </PopoverMenu>
  );
};

export default UserDropDown;
