import ReactDOM from 'react-dom';


import App from './containers/App';
import reportWebVitals from './reportWebVitals';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-virtualized/styles.css';

import { MoralisProvider } from "react-moralis";

import './style.scss';
import { AudioProvider } from './utils/hooks/useAudio';


ReactDOM.render(
  <MoralisProvider serverUrl="https://bzgdtzqlihki.usemoralis.com:2053/server" appId="F254vsO3ffYAne6sff288HKXOjBrUvqhzhDAjxpn">
    <AudioProvider>
      <App />
    </AudioProvider>
  </MoralisProvider>,
  document.getElementById('root'),
);
reportWebVitals();
