
export function filterAll(arr, name, type, minPrice, maxPrice, sortVal, beds, country, minSqft, maxSqft) {
    let sorted;
    if (sortVal === 1) {
        sorted = arr.sort(function (a, b) {
            return parseFloat(b.startPrice) - parseFloat(a.startPrice);
        });

    } else

        sorted = arr.sort(function (a, b) {
            return parseFloat(a.startPrice) - parseFloat(b.startPrice);
        });

    if (!maxPrice)
        maxPrice = 100000000;


    if (!minPrice)
        minPrice = 0;

    if (!maxSqft)
        maxSqft = 100000000;


    if (!minSqft)
        minSqft = 0;



    return sorted.filter((element) => {
        let status = false;
        if (name === "" && type === ""
            && minPrice === 0 && maxPrice === 0
            && beds === 0 && country === ""
            && minSqft === 0 && maxSqft === 0)
            return true;
        if (element.name.toLowerCase().includes(name.toLowerCase())
            && element.type.toLowerCase().includes(type.toLowerCase())
            && element.country.toLowerCase().includes(country.toLowerCase())
            && element.startPrice >= minPrice && element.startPrice <= maxPrice
            && element.beds >= beds
            && element.startPrice >= minSqft && element.startPrice <= maxSqft
        ) {
            status = true;
        }
        return status;

    });
}