import './bedModal.scss';
import Bounce from 'react-reveal/Bounce';
import { useEffect, useState } from 'react';
import OutlinedButton from 'components/Buttons/OutlinedButton';
import FilledButton from 'components/Buttons/FilledButton';
import InputField from 'components/Forms/InputField';
import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  formWrapper: {
    width: '48%',
  },

  myInput: {
    color: '#333',
  },
}));
interface Props {
  showModal: boolean;
  setShowtModal?: any;
  setBed?: any;
  setMinSqft?: any;
  setMaxSqft?: any;
}
const BedModal: React.FC<Props> = ({ showModal, setShowtModal, setBed, setMinSqft, setMaxSqft }) => {
  const classes = useStyles();
  const [isStart, setIsStart] = useState(false);
  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        setIsStart(true);
      }, 100);
    }
  }, [setIsStart, showModal]);
  const onClose = () => {
    setIsStart(false);
    setTimeout(() => {
      setShowtModal(false);
    }, 800);
  };

  const handleResetFields = () => {

    setMinSqft(0)
    setMaxSqft(0)
  } 
  return (
    <div className={showModal === true ? 'bedModal active' : 'bedModal'}>
      <Bounce opposite when={isStart}>
        <div className="modelContent">
          <div className="connectWalletHeader">
            <h1 className="connectWalletTitle"> </h1>
            <button className="connectModalCloseButton" onClick={onClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="connectWalletWrapper">
            <h2>Beds</h2>
            <div className="beds">
              <button onClick={() => { setBed(0) }}>Any</button>
              <button onClick={() => { setBed(1) }}>1+</button>
              <button onClick={() => { setBed(2) }}>2+</button>
              <button onClick={() => { setBed(3) }}>3+</button>
              <button onClick={() => { setBed(4) }}>4+</button>
              <button onClick={() => { setBed(5) }}>5+</button>
            </div>

            <h2>Square Feet</h2>
            <div className="row">
              <InputField
                name="max"
                className={classes.myInput}
                wrapperClass={classes.formWrapper}
                type="number"
                endIcon={<img src="/assets/images/arrow_02.svg" alt="" />}
                placeholder="No Min"
                onChangeData={val => { setMinSqft(val) }}
              />

              <InputField
                name="max"
                className={classes.myInput}
                wrapperClass={classes.formWrapper}
                type="number"
                endIcon={<img src="/assets/images/arrow_02.svg" alt="" />}
                placeholder="No Max"
                onChangeData={val => { setMaxSqft(val) }}
              />
              {/* <Select
                formatOptionLabel={FormatsortOptionLabel}
                options={options}
                instanceId="chainSelect"
                className={`select-gray `}
                onChange={e => handleSelected(e)}
                isSearchable={false}
                isClearable={false}
                styles={customStyles}
                placeholder="No Min"
              /> */}
              {/* <Select
                formatOptionLabel={FormatsortOptionLabel}
                options={options}
                instanceId="chainSelect"
                className={`select-gray `}
                onChange={e => handleSelected(e)}
                isSearchable={false}
                isClearable={false}
                styles={customStyles}
                placeholder="No Max"
              /> */}
            </div>

            <div className="row">
              <OutlinedButton label="Reset" handleClick={() => handleResetFields()} />
              <FilledButton label={'Done'} handleClick={onClose} />
            </div>
          </div>
        </div>
      </Bounce>
    </div>
  );
};
export default BedModal;
