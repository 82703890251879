import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { MuiThemeProvider } from '@material-ui/core/styles';
import lightTheme from '../../theme/light';
import Routes from '../../routes';
import { Toaster } from 'react-hot-toast';
import { useEagerConnect } from 'hooks/useEagerConnect';
import { AudioPlayer } from 'components/AudioPlayer';
// import ScrollToTop from 'utils/scrollToTop';

function App() {
  return (
    <MuiThemeProvider theme={lightTheme}>
      <Toaster position="top-center" toastOptions={{ success: { duration: 3000 }, error: { duration: 3000 } }} />
      <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Router basename={process.env.PUBLIC_URL}>
          <Routes />
        </Router>
      </SnackbarProvider>
      <AudioPlayer />
    </MuiThemeProvider>
  );
}

export default App;
