import './buyModal.scss';
import Bounce from 'react-reveal/Bounce';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import FormatsortOptionLabel from 'components/Filter/FormatsortOptionLabel';
import FilledButton from 'components/Buttons/FilledButton';
import OutlinedButton from 'components/Buttons/OutlinedButton';
import { numberToString } from 'utils';
import toast from 'react-hot-toast';
interface Props {
  showModal: boolean;
  setShowModal?: any;
  item?: any;
  price?: number;
}

const customStyles = {
  control: base => ({
    ...base,
    fontSize: '0.8vw',
    fontWeight: 'bold',
    borderRadius: '50px',
    border: '1px solid #ddd !important',
    boxShadow: 'none',

    minWidth: '10vw',
    '&:focus': {
      border: '0 !important',
    },
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      fontSize: 14,
      minWidth: '30vw',
    },
  }),

  menu: (provided, state) => ({
    ...provided,
    border: '1px solid #ffffff13',
    // color: '#fff',
    color: '#93989A',
    padding: 0,
    background: '#fff',
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #aaa',
    padding: 3,
    // color: '#93989A',
    cursor: 'pointer',
    fontSize: '0.8vw',
    color: state.isSelected ? 'white' : '#93989A !important',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      fontSize: 14,
    },
    ':hover': {
      color: '#93989A !important',
    },
    ':active': {
      color: '#fff !important',
    },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

const BuyModal: React.FC<Props> = ({ showModal, setShowModal, item, price }) => {
  const [isStart, setIsStart] = useState(false);
  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        setIsStart(true);
      }, 100);
    }
  }, [setIsStart, showModal]);
  const onClose = () => {
    setIsStart(false);
    setTimeout(() => {
      setShowModal(false);
    }, 800);
  };
  const handleSelected = e => { };
  const options = [
    { value: 0, label: 'USD', customAbbreviation: '0' },
    { value: 1, label: 'ETH', customAbbreviation: '1' },
    { value: 2, label: 'WETH', customAbbreviation: '2' },
  ];

  const handleClick = () => {
    toast.error("Buy Feature not yet live.")
  }
  return (
    <div className={showModal === true ? 'buyModal active' : 'buyModal'}>
      <Bounce opposite when={isStart}>
        <div className="modelContent">
          <div className="connectWalletHeader">
            <h1 className="connectWalletTitle">Complete checkout</h1>
            <button className="connectModalCloseButton" onClick={onClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="connectWalletWrapper">
            <div className="row mb-20 border-bottom">
              <h5>Item</h5>
              <h5>Total</h5>
            </div>
            <div className="row mb-20  pb-10 border-bottom">
              <div className="item">
                <img src={item?.img} alt="" className="item-img" />
                <span>
                  <h5>{item?.name}</h5>
                  <p>
                    <i className="fas fa-map-marker-alt"></i>
                    {item?.location}
                  </p>
                </span>
              </div>
              <div className="price">
                <p>$ {numberToString(price)}</p>
              </div>
            </div>

            <div className="row mb-20">
              <h5>Payment Method</h5>
            </div>

            <div className="row mb-20">
              <div className="col">
                <span className="circle">
                  <input type="radio" id="crypto" name="paymentmothod" onChange={e => { }} defaultChecked={true} />
                  <label htmlFor="crypto">
                    Crypto <i className="fab fa-ethereum"></i>
                  </label>
                </span>

                <span className="circle">
                  <input type="radio" id="card" name="paymentmothod" onChange={e => { toast.error("Card Payment is not yet available.") }} />
                  <label htmlFor="card">
                    Card <i className="fas fa-credit-card"></i>
                  </label>
                </span>
              </div>
            </div>
            <div className="btns" >
              <FilledButton label="Complete Purchase" handleClick={handleClick} />
            </div>
          </div>
        </div>
      </Bounce>
    </div>
  );
};
export default BuyModal;
