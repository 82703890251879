import { makeStyles } from '@material-ui/core/styles';
import State from 'components/OverviewSection/State';
import RecentTransactions from 'components/OverviewSection/RecentTransactions';
const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
      textAlign: 'center',
    },
  },

  section: {
    position: 'relative',
    paddingTop: theme.spacing(0),
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      padding: 10,
    },
  },
  topSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(7),
  },

  wrapper: {
    height: '100%',
    width: 'calc(100% - 40px)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 20,
    padding: 15,
    margin: 20,
    border: '1px #ddd solid',
    borderRadius: 20,

    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      margin: 10,
      width: 'calc(100% - 20px)',
    },

    '& span': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 14,
      },
    },
    '& p': {
      color: '#000',
      fontSize: 16,
      fontWeight: 600,
      display: 'flex',
      lineHeight: 1.8,
      alignItems: 'center',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 14,
      },
    },
  },
  top: {
    background: '#ffffff00',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '0 15px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0 15px',
    },
  },
  title: {
    fontSize: 32,
    color: '#000',
    fontWeight: 600,
  },
}));

const Overview = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.top}>
          <span>
            <h1 className={classes.title}>Overview</h1>
          </span>
        </div>

        <div className={classes.section}>
          <State />
        </div>

        <div className={classes.section}>
          <RecentTransactions />
        </div>

        <div className={classes.section}>
          <div className={classes.wrapper}>
            <span>
              <p>Portfolio</p>
              <p style={{ fontWeight: 700 }}>USD 146,090</p>
            </span>

            <span>
              <p>Annual Rental Income:</p>
              <p style={{ fontWeight: 700 }}>USD 13,050</p>
            </span>

            <span>
              <p>Total Profit/Loss: </p>
              <p style={{ fontWeight: 700 }}>
                USD <span style={{ color: '#42A846' }}> +9,512</span>
              </p>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
