import Masonry from 'react-masonry-css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    paddingBottom: 20,
    paddingTop: 30,
    '@media screen and (max-width: 450px) and (orientation: portrait)': {
      paddingTop: 10,
      padding: theme.spacing(0, 0),
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',

    paddingBottom: 50,
    paddingTop: 50,
    background: '#F5F6F8',
    borderRadius: 20,
    '@media screen and (max-width: 450px) and (orientation: portrait)': {
      borderRadius: 0,
    },
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: '30px',
    width: '100%',
    '& a': {
      fontSize: 14,
      color: '#2892F3',
    },
    '@media screen and (max-width: 450px) and (orientation: portrait)': {
      padding: '5px 20px',
    },
  },

  header: {
    fontSize: 30,
    fontWeight: 600,
    color: '#000',
    textAlign: 'left',
    lineHeight: '50px',
    position: 'relative',
    '@media (max-width: 576px)': {
      fontSize: 20,
    },
  },
  masonry: {
    display: 'flex',
    width: '100%',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      width: '100%',
    },
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      width: '100%',
    },
  },
  gridColumn: {
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0),
      width: '100%',
    },
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100% !important',
    },
  },
  productWrapper: {
    maxWidth: 360,
    margin: 'auto',
    cursor: 'pointer',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      maxWidth: '60vw',
      marginBottom: 40,
    },
    '& .image': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 75,
      height: 75,
      borderRadius: '50%',
      overflow: 'hidden',
      background: 'radial-gradient(94.26% 94.26% at 61.78% 22.88%, #FAFCFF 0%, #E5E8EC 100%) ',
      boxShadow: '0px 0px 4px #fff, 0px 15px 30px rgba(0, 0, 0, 0.25)',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        marginBottom: 20,
      },
      '& img': {
        width: '50%',
      },
    },

    '& .state': {
      padding: '5px 10px',

      '@media screen and (max-width: 768px) and (orientation: portrait)': {},

      '& p': {
        color: '#000',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: 600,
        marginTop: "10px",

        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 14,
        },
        '& span': {
          color: '#004AAD',
          fontSize: 20,
          fontWeight: 700,
          marginRight: "5px",
        },
      },
    },
  },
}));
const breakpointColumnsObj = {
  default: 4,
  1440: 4,
  1024: 3,
  768: 2,
  450: 1,
};
const MetropolyThusFar = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.top}>
          <h1 className={classes.header}>Metropoly so far</h1>
        </div>
        <div className={classes.content}>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className={classes.masonry}
            columnClassName={classes.gridColumn}
          >
            <div className={classes.productWrapper}>
              <div className="image">
                <img src="/assets/images/hand_01.svg" alt="" />
              </div>

              <div className="state">
                <p>
                  <span>540</span>Real-Estate <br />
                  Listed on Metropoly
                </p>
              </div>
            </div>
            <div className={classes.productWrapper}>
              <div className="image">
                <img src="/assets/images/diamond_01.svg" alt="" />
              </div>

              <div className="state">
                <p>
                  <span>$270M</span>Value of <br />
                  Real Estate NFT’s
                </p>
              </div>
            </div>
            <div className={classes.productWrapper}>
              <div className="image">
                <img src="/assets/images/hand_02.svg" alt="" />
              </div>

              <div className="state">
                <p>
                  <span>$19M</span>Dividend’s <br />
                  paid out to users
                </p>
              </div>
            </div>
            <div className={classes.productWrapper}>
              <div className="image">
                <img src="/assets/images/home_01.svg" alt="" />
              </div>

              <div className="state">
                <p>
                  <span>$1.1M</span>Distributed to <br />
                  Homeless Organisations
                </p>
              </div>
            </div>
          </Masonry>
        </div>
      </div>
    </>
  );
};

export default MetropolyThusFar;
