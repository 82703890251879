import { Link, useLocation } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import RecentTransactionsTable from './RecentTransactionsTable';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const myData = [
  {
    date: '5-07-22',
    id: 5238,
    name: 'Villa Ibiza',
    typeOfProperty: 'Holiday Home',
    order: 'Dividend',
    deposit: '-',
    received: '-',
    totalProfitLoss: 277,
  },
  {
    date: '4-07-22',
    id: 4238,
    name: 'Apart. Berlin',
    typeOfProperty: 'Holiday Home',
    order: 'Buy',
    deposit: '800',
    received: '860',
    totalProfitLoss: 86,
  },
  {
    date: '2-07-22',
    id: 9238,
    name: 'House Stock..',
    typeOfProperty: 'Holiday Home',
    order: 'Dividend',
    deposit: '-',
    received: '-',
    totalProfitLoss: 250,
  },
  {
    date: '2-07-22',
    id: 2630,
    name: 'House Paris',
    typeOfProperty: 'Rental Property',
    order: 'Sell',
    deposit: '5,500',
    received: '5,450',
    totalProfitLoss: -50,
  },
  {
    date: '1-07-22',
    id: 2630,
    name: 'House Paris',
    typeOfProperty: 'Holiday Home',
    order: 'Dividend',
    deposit: '-',
    received: '-',
    totalProfitLoss: 102,
  },
];
const cols = ['Date', 'NFT ID', 'Name', 'Type of Property', 'Order', 'Deposit (USD)', 'Received (USD)', 'Profit/Loss'];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 50,
    '@media screen and (max-width: 450px) and (orientation: portrait)': {
      padding: 10,
      paddingBottom: 10,
      paddingTop: 0,
    },
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '0 15px',

    width: '100%',
    '& a': {
      fontSize: 14,
      color: '#2892F3',
    },
    '@media screen and (max-width: 450px) and (orientation: portrait)': {
      padding: '5px 20px',
    },
  },

  header: {
    fontSize: 30,
    color: '#000',
    textAlign: 'left',
    lineHeight: '50px',
    position: 'relative',
    '@media (max-width: 576px)': {
      fontSize: 20,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 20,
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      padding: 0,
    },
  },
  node: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 10,
    '& .num': {
      fontSize: 12,
      color: '#000',
      fontWeight: 600,
      paddingTop: 10,
      paddingRight: 10,
    },
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'flex-start',
    background: '#F7F9FA',
    flexWrap: 'wrap',
    padding: 5,
    borderRadius: 10,
    '& .nft': {
      width: '50%',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'center',
      '& p': {
        fontSize: 10,
        color: '#000',
        fontWeight: 600,
        textAlign: 'left',
      },
      '& h3': {
        fontSize: 12,
        color: '#000',
        textAlign: 'left',
      },
    },
    '& .profit': {
      width: '50%',
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
      justifyContent: 'center',
      '& p': {
        fontSize: 10,
        color: '#000',
        fontWeight: 600,
        textAlign: 'left',
      },
      '& h3': {
        fontSize: 12,
        color: '#000',
        fontWeight: 600,
        textAlign: 'end',
      },
    },
    '& ul': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: 0,
      listStyle: 'none',
      marginTop: 10,
      '& li': {
        marginRight: 5,
        width: 'fit-content',
        '& span': {
          color: '#13CF8F',
          fontWeight: 600,
          isplay: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'center',
          '& h5': {
            color: '#000',
            fontSize: 10,
            textAlign: 'left',
            width: '100%',
          },
          '& h6': {
            color: '#93989A',
            fontSize: 8,
            textAlign: 'left',
            width: '100%',
          },
        },
      },
    },
  },
}));

const RecentTransactions = () => {
  const classes = useStyles();
  const path = useLocation();
  console.log(path.pathname);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <h1 className={classes.header}>Recent Transactions</h1>
        {path.pathname !== '/transactions' && <Link to="/transactions">View All</Link>}
      </div>
      <div className={classes.content}>
        {isMobileOrTablet ? (
          <>
            {myData.map((d, k) => (
              <div className={classes.node} key={k}>
                <p className="num">{k + 1}</p>
                <div className={classes.row}>
                  <div className="nft">
                    <p>Type of Property</p>
                    <h3>{d.typeOfProperty}</h3>
                  </div>
                  <div className="profit">
                    <p>Profit/Loss</p>
                    <h3
                      style={{
                        color: d.totalProfitLoss < 0 ? '#E92C2C' : '#13CF8F',
                        fontWeight: 700,
                      }}
                    >
                      {`${d.totalProfitLoss > 0 ? '+ $' : '- $'} ${Math.abs(d.totalProfitLoss)}`}
                    </h3>
                  </div>
                  <ul>
                    <li>
                      <span>
                        <h5>{d.id}</h5>
                        <h6>NFT ID</h6>
                      </span>
                    </li>

                    <li>
                      <span>
                        <h5>{d.name}</h5>
                        <h6>Name</h6>
                      </span>
                    </li>

                    <li>
                      <span>
                        <h5>{d.date}</h5>
                        <h6>Date</h6>
                      </span>
                    </li>
                    <li>
                      <span>
                        <h5
                          style={{
                            color: d.order === 'Sell' ? '#E92C2C' : '#13CF8F',
                          }}
                        >
                          {d.order}
                        </h5>
                        <h6>Orders</h6>
                      </span>
                    </li>
                    <li>
                      <span>
                        <h5>$ {d.deposit}</h5>
                        <h6>Amount Invested (USD)</h6>
                      </span>
                    </li>

                    <li>
                      <span>
                        <h5>$ {d.received}</h5>
                        <h6>Current Value</h6>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </>
        ) : (
          <RecentTransactionsTable columns={cols} rows={myData} type={undefined} />
        )}
      </div>
    </div>
  );
};

export default RecentTransactions;
