import { useStyles } from './style';
import Masonry from 'react-masonry-css';
import ProductCard1 from 'components/Cards/ProductCard1';
import Filter from 'components/Filter/Filter';
import OptionModal from 'components/modal/optionModal/OptionModal';
import { useState } from 'react';
import BuyModal from 'components/modal/buyModal/BuyModal';
import MakeModal from 'components/modal/makeModal/MakeModal';
import AuctionModal from 'components/modal/auctionModal/AuctionModal';
import ListModal from 'components/modal/listModal/ListModal';
import MultiBuyModal from 'components/modal/multiBuyModall/MultiBuyModal';
const myData = [
  {
    id: 8,
    img: '/assets/images/image_14.png',
    type: 'Rental Property',
    startPrice: 5200,
    estatePrice: 5200000,
    name: 'Rare 3-Bedroom Penthouse with a patio',
    location: 'Barcelona, Spain',
    homeState: 'Rented',
    beds: 3,
    country: "Spain",
    state: {
      expected: 51,
      yeld: 8.12,
      frequency: 'Monthly',
      profit: '+8%',
      owned: '6/1000',
      value: '$14,400',
    },
    annualIncome: 1169.28,
  },
  {
    id: 6,
    img: '/assets/images/image_05.png',
    type: 'Rental Property',
    startPrice: 215,
    estatePrice: 215000,
    name: '1-Bedroom Studio Apartment Within Walking Distance of the City Center',
    location: 'Manchester, United Kingdom',
    homeState: 'Rented',
    beds: 1,
    country: "United Kingdom",
    state: {
      expected: 38,
      yeld: 5.66,
      frequency: 'Monthly',
      profit: '+5%',
      owned: '80/1000',
      value: '$17,200',
    },
    annualIncome: 973.52,
  },
  {
    id: 3,
    img: '/assets/images/image_02.png',
    type: 'Holiday Home',
    startPrice: 725,
    estatePrice: 725000,
    name: 'Modern 3-Bedroom Family Home',
    location: 'Stockholm, Sweden',
    homeState: 'Vacant',
    beds: 3,
    country: "Sweden",
    state: {
      expected: 51,
      yeld: 8.28,
      frequency: 'Monthly',
      profit: '+6%',
      owned: '50/1000',
      value: '$36,250',
    },
    annualIncome: 3001.5,
  },

  {
    id: 7,
    img: '/assets/images/image_06.png',
    type: 'Rental Property',
    startPrice: 725,
    estatePrice: 725000,
    name: 'Luxury 2-Bedroom Condo in the heart of the city',
    location: 'Rome, Italy',
    homeState: 'Vacant',
    beds: 2,
    country: "Italy",
    state: {
      expected: 30,
      yeld: 3.8,
      frequency: 'Monthly',
      profit: '+3%',
      owned: '5/1000',
      value: '$2,800',
    },
    annualIncome: 109.2,
  },
  {
    id: 4,
    img: '/assets/images/image_03.png',
    type: 'Rental Property',
    startPrice: 7000,
    estatePrice: 7000000,
    name: 'Luxury 5-Bedroom Villa with a Pool',
    location: 'San Francisco, USA',
    homeState: 'Rented',
    beds: 5,
    country: "United States of America",
    state: {
      expected: 37,
      yeld: 5.3,
      frequency: 'Monthly',
      profit: '+4%',
      owned: '1/1000',
      value: '$7,000',
    },
    annualIncome: 371,
  },
  {
    id: 18,
    img: '/assets/inbox/img_04.png',
    type: 'Holiday Home',
    startPrice: 1600,
    estatePrice: 3500000,
    name: 'Stunning 3-Bedroom Villa next to the beach',
    location: 'Dubai, United Arab Emirates',
    homeState: 'Vacant',
    beds: 3,
    country: "United Arab Emirates",
    state: {
      expected: 50,
      yeld: 7.2,
      frequency: 'Monthly',
      profit: '+9%',
      owned: '15/1000',
      value: '$24,000',
    },
    annualIncome: 2688,
  },

  {
    id: 19,
    img: '/assets/images/Paris, France.png',
    type: 'Holiday Home',
    startPrice: 600,
    estatePrice: 215000,
    name: '4-Bedroom Family House with a garden',
    location: 'Paris, France',
    homeState: 'Vacant',
    beds: 4,
    country: "France",
    state: {
      expected: 45,
      yeld: 8.2,
      frequency: 'Monthly',
      profit: '+5%',
      owned: '25/1000',
      value: '$15,000',
    },
    annualIncome: 1230,
  },

  {
    id: 8,
    img: '/assets/images/image_07.png',
    type: 'Rental Property',
    startPrice: 8600,
    estatePrice: 2400000,
    name: 'Authentic 3-Bedroom apartment in the city center',
    location: 'Berlin, Germany',
    homeState: 'Rented',
    beds: 3,
    country: "Germany",
    state: {
      expected: 5371,
      yeld: 5.32,
      frequency: 'Monthly',
      profit: '+4%',
      owned: '4/1000',
      value: '$3,440',
    },
    annualIncome: 183,
  },
  {
    id: 15,
    img: '/assets/inbox/img_01.png',
    type: 'Holiday Home',
    startPrice: 2600,
    estatePrice: 2400000,
    name: 'Stunning 5-Bedroom Villa next to the beach',
    location: 'Ibiza, Spain',
    homeState: 'Vacant',
    beds: 3,
    country: "Spain",
    state: {
      expected: 65,
      yeld: 12.79,
      frequency: 'Monthly',
      profit: '+7.5%',
      owned: '10/1000',
      value: '$26,000',
    },
    annualIncome: 3325.4,
  },
];
const MyProperties = () => {
  const classes = useStyles();
  const breakpointColumnsObj = {
    // default: 4,
    3840: 7,
    3000: 6,
    2560: 5,
    2200: 4,
    1840: 3,
    1440: 3,
    1280: 2,
    768: 2,
    450: 1,
  };
  const [showOption, setShowOption] = useState(false);
  const [showBuy, setShowBuy] = useState(false);
  const [showMake, setShowMake] = useState(false);
  const [showSellAuction, setShowSellAuction] = useState(false);
  const [showList, setShowList] = useState(false);
  const [showMultiBuy, setShowMultiBuy] = useState(false);

  const [data, setData] = useState(myData);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.top}>
          <span>
            <h1 className={classes.title}>My Properties</h1>
            <p>Find all of your properties in one place.</p>
          </span>
        </div>
        <Filter data={data} setData={setData}  myData={myData}/>

        <div className={classes.content}>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className={classes.masonry}
            columnClassName={classes.gridColumn}
          >
            {data.map((d, i) => (
              <ProductCard1 key={i} product={d} isMyProperty showOption={setShowOption} />
            ))}
          </Masonry>
        </div>
      </div>
      <OptionModal
        showModal={showOption}
        setShowModal={setShowOption}
        setShowBuy={setShowBuy}
        setShowMake={setShowMake}
        setShowSellAuction={setShowSellAuction}
        setShowList={setShowList}
      />
      <BuyModal showModal={showBuy} setShowModal={setShowBuy} />
      <MakeModal showModal={showMake} setShowModal={setShowMake} />
      <AuctionModal showModal={showSellAuction} setShowModal={setShowSellAuction} />
      <ListModal showModal={showList} setShowModal={setShowList} />
      <MultiBuyModal showModal={showMultiBuy} setShowModal={setShowMultiBuy} />
    </>
  );
};

export default MyProperties;
