import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './style.scss';
import FilledButton from 'components/Buttons/FilledButton';
import { useMoralis } from "react-moralis";
import { truncateWalletString } from '../../../utils';
import toast from 'react-hot-toast';

type MenuType = {
  menuOpen?: boolean;
  setMenuOpen?(flag: boolean): void;
};
export default function SideBar({ menuOpen, setMenuOpen }: MenuType) {
  const { isAuthenticated, user } = useMoralis();
  const [loginStatus, setLoginStatus] = useState(false);
  const [profilePicture, setProfPic] = useState(null);
  const [username, setUsername] = useState(null);
  // const { connector, library, chainId, account, active } = useWeb3React();

  const [navId, setNavId] = useState('');
  const search = useLocation();
  useEffect(() => {
    const path = search.pathname.replace('/', '');
    setNavId(path);
  }, [setNavId, search]);

  useEffect(() => {
    setLoginStatus(isAuthenticated);
    if (isAuthenticated) {
      setUsername(user.get("username"))
      setProfPic(user.get("profilePicture"))
    }
  }, [isAuthenticated, user, username, profilePicture]);

  return (
    <div className="sideBar">
      <div className="avater">
        {!loginStatus ? (
          <Link to="/" onClick={() => toast.error("Please connect your wallet.")}>
            <img src="/assets/avatars/avatar_01.svg" alt="" />
            <h1>Not Connected</h1>
          </Link>
        ) : (
          <Link to="/profile">

            <img className='profile' src={profilePicture ? profilePicture : "/assets/avatars/avatar_02.png"} alt="" onClick={() => setMenuOpen(false)} />
            <span>
              <h1>{
                username ?
                  username.length > 24 ?
                    "Demo Account" :
                    <> {username}
                    </>
                  : "Not Connected"
              }
              </h1>
              <p>Level: Broker</p>
              <div className="imgs">
                <img src="/assets/logo_icon.png" alt="" />
                <img src="/assets/logo_icon.png" alt="" />
              </div>
            </span>
          </Link>
        )}
      </div>
      <div className="navList" onClick={() => setMenuOpen(false)}>
        <h3>General</h3>
        <ul>
          <li className={navId === '' ? 'selected' : ''}>
            <Link to="/">
              <img src="/assets/side/sideBar_01.svg" alt="" />
              <p>Dashboard</p>
            </Link>
          </li>
          <li className={navId.indexOf('inbox') >= 0 ? 'selected' : ''}>

            {!loginStatus ?
              <Link to="/" onClick={() => toast.error("Please connect your wallet.")}>
                <img src="/assets/side/sideBar_02.svg" alt="" />
                <p>Inbox</p>
              </Link>
              :
              <Link to="/inbox/0">
                <img src="/assets/side/sideBar_02.svg" alt="" />
                <p>Inbox</p>
              </Link>
            }
          </li>
          <li className={navId.indexOf('settings') >= 0 ? 'selected' : ''}>
            {!loginStatus ?
              <Link to="/" onClick={() => toast.error("Please connect your wallet.")}>
                <img src="/assets/side/sideBar_03.svg" alt="" />
                <p>Settings</p>
              </Link>
              :

              <Link to="/settings">
                <img src="/assets/side/sideBar_03.svg" alt="" />
                <p>Settings</p>
              </Link>
            }
          </li>
        </ul>

        <h3>Market</h3>
        <ul>
          <li className={navId.indexOf('marketplace') >= 0 ? 'selected' : ''}>
            <Link to="/marketplace">
              <img src="/assets/side/sideBar_04.svg" alt="" />
              <p>Marketplace</p>
            </Link>
          </li>
          <li className={navId.indexOf('upcoming') >= 0 ? 'selected' : ''}>
            <Link to="/upcoming">
              <img src="/assets/side/sideBar_05.svg" alt="" />
              <p>Upcoming</p>
            </Link>
          </li>
          <li className={navId.indexOf('auctions') >= 0 ? 'selected' : ''}>
            <Link to="/auctions">
              <img src="/assets/side/sideBar_06.svg" alt="" />

              <p>Auctions</p>
            </Link>
          </li>
          <li className={navId.indexOf('mortgages') >= 0 ? 'selected' : ''}>
            <Link to="/mortgages">
              <img src="/assets/side/sideBar_07.svg" alt="" />

              <p>Mortgages</p>
            </Link>
          </li>
          <li className={navId.indexOf('favorites') >= 0 ? 'selected' : ''}>
            {!loginStatus ?
              <Link to="/" onClick={() => toast.error("Please connect your wallet.")}>
                <img src="/assets/side/sideBar_08.svg" alt="" />

                <p>Favorites</p>
              </Link>
              :
              <Link to="/favorites">
                <img src="/assets/side/sideBar_08.svg" alt="" />

                <p>Favorites</p>
              </Link>
            }
          </li>
        </ul>

        <h3>My Profile</h3>
        <ul>
          <li className={navId.indexOf('overview') >= 0 ? 'selected' : ''}>
            {/* <Link to={loginStatus ? '/overview' : ''}>
              <img src="/assets/side/sideBar_09.svg" alt="" />

              <p>Overview</p>
            </Link> */}
            {!loginStatus ?
              <Link to="/" onClick={() => toast.error("Please connect your wallet.")}>
                <img src="/assets/side/sideBar_09.svg" alt="" />
                <p>Overview</p>
              </Link>
              :
              <Link to={'/overview'}>
                <img src="/assets/side/sideBar_09.svg" alt="" />
                <p>Overview</p>
              </Link>
            }
          </li>
          <li className={navId.indexOf('my-properties') >= 0 ? 'selected' : ''}>
            {/* <Link to={loginStatus ? '/my-properties' : ''}>
              <img src="/assets/side/sideBar_10.svg" alt="" />
              <p>My Properties</p>
            </Link> */}
            {!loginStatus ?
              <Link to="/" onClick={() => toast.error("Please connect your wallet.")}>
                <img src="/assets/side/sideBar_10.svg" alt="" />
                <p>My Properties</p>
              </Link>
              :
              <Link to={'/my-properties'}>
                <img src="/assets/side/sideBar_10.svg" alt="" />
                <p>My Properties</p>
              </Link>
            }
          </li>
          <li className={navId.indexOf('payouts') >= 0 ? 'selected' : ''}>
            {/* <Link to={loginStatus ? '/payouts' : ''}>
              <img src="/assets/side/sideBar_11.svg" alt="" />
              <p>Payouts</p>
            </Link> */}
            {!loginStatus ?
              <Link to="/" onClick={() => toast.error("Please connect your wallet.")}>
                <img src="/assets/side/sideBar_11.svg" alt="" />
                <p>Payouts</p>
              </Link>
              :
              <Link to={'/payouts'}>
                <img src="/assets/side/sideBar_11.svg" alt="" />
                <p>Payouts</p>
              </Link>
            }
          </li>
        </ul>
      </div>
      <div className="helpCenter">
        <div className="helpIcon">
          <img src="/assets/side/sideBar_12.svg" alt="" />
        </div>
        <h2>Help Center</h2>
        <p>Do you have a question? Please use our Help Center for more info.</p>
        <a href="https://www.metropoly.io/support"><FilledButton color="white" label={'Visit The Help Center'} /></a>
      </div>
    </div>
  );
}
