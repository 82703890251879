import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/Layout/Container';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 15,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
      paddingTop: 0,
      textAlign: 'center',
    },
  },

  section: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 20,
    '& p': {
      color: '#000',
      fontSize: 14,
      width: '100%',
      marginBottom: 20,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 12,
        textAlign: 'left',
      },
    },
    '& h2': {
      color: '#000',
      fontSize: 20,
      width: '100%',
      marginBottom: 20,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 18,
        textAlign: 'left',
      },
    },
  },
  topSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(7),
  },

  banner: {
    height: '100%',
    minHeight: '16vw',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 70,
    padding: 70,
    backgroundImage: `url("assets/images/home_banner.png")`,
    backgroundSize: '100% 100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      paddingTop: 100,
      backgroundSize: 'cover',
      backgroundPosition: '90%',
    },
    '& h1': {
      color: '#fff',
      fontSize: 40,
      fontWeight: 700,
      fontFamily: "'Outfit', sans-serif",
      width: '100%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 30,
      },
    },
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();
  return (
    <>
      <Container className={classes.root}>
        <div className={classes.topSection}>
          <div className={classes.banner}>
            <h1>Privacy Policy</h1>
          </div>
        </div>
        <div className={classes.section}>
          <h2>Your Privacy Is Important to Metropoly</h2>
          <p>
            In the course of serving our clients, Metropoly may obtain personal information about such clients.
            Obtaining this information is important to our ability to deliver the highest level of service to you, but
            we also recognize that you expect us to treat this information appropriately.
          </p>
          <p>
            This policy describes the types of personal information we may collect about you, the purposes for which we
            use the information, the circumstances in which we may share the information and the steps that we take to
            safeguard the information to protect your privacy.{' '}
          </p>
          <h2>The Sources of Information</h2>
          <p>
            The personal information we collect about you comes primarily from the account applications or other forms
            and materials you submit to Metropoly during the course of your relationship with us. We may also collect
            information about your transactions and experiences with Metropoly relating to the services Metropoly
            provides. In addition, depending on the services you require, Metropoly may obtain additional information
            about you, such as your credit history, from consumer reporting agencies.
          </p>
          <p>
            Finally, in the provision of financial services to you and subject to strict compliance with all applicable
            laws and regulations, information may be collected about you indirectly from monitoring or other means (e.g.
            e-mail monitoring). In these circumstances, the information is not accessed on a continuous or routine
            basis, but it may be used for compliance or security purposes.
          </p>
          <h2>The Information We Have about You</h2>
          <p>
            If you deal with Metropoly in your individual capacity (e.g. as a private client), or as a
            settlor/trustee/beneficiary of a trust, or as an owner or principal of a company or other investment vehicle
            established to invest on your behalf or on behalf of your family, etc., the typical information we collect
            about you may include (in addition to any other information as may be determined on a case-by-case basis):
          </p>
          <p>
            Of course, you are not required to supply any of the personal information that we may request. However,
            failure to do so may result in our being unable to open or maintain your account or to provide services to
            you. While we make every effort to ensure that all information we hold about our clients is accurate,
            complete and we rely on our clients promptly notifying us if there are any changes to their
            personal information.
          </p>
          <h2>Our Use of Your Personal Information</h2>
          <p>We may use your personal information to:</p>
          <p>
            Administer, operate, facilitate, and manage your relationship and/or account with Metropoly. This may
            include sharing such information internally as well as disclosing it to third parties, as described in the
            following two sections, respectively.
          </p>
          <p>
            Contact you or, if applicable, your designated representative(s) by post, telephone, electronic mail,
            facsimile, etc., in connection with your relationship and/or account.
          </p>
          <p>
            Provide you with information (such as investment research), recommendations, or advice concerning products
            and services offered by Metropoly; and
          </p>
          <p>
            Facilitate our internal business operations, including assessing and managing risk and fulfilling our legal
            and regulatory requirements.
          </p>
          <p>
            If your relationship with Metropoly ends, Metropoly will continue to treat your personal information, to the
            extent we retain it, as described in this policy.
          </p>
          <h2>Disclosures of Your Personal Information within Metropoly</h2>
          <p>
            In order to provide efficient and reliable services and to improve product and service options available to
            you, more than one entity within Metropoly may be given, or given access to, your personal information. For
            example, one Metropoly entity might share your information with another in order to facilitate settlement of
            your transactions or the maintenance of your accounts, or as part of its arranging for the performance of
            specialized services such as US and international brokerage, asset management and advisory services. When
            sharing your personal information, we adhere to applicable legal and industry standards regarding the
            protection of personal information. Additional information on how your personal information is protected
            while within Metropoly is provided below, under Information Security: How We Protect Your Privacy.
          </p>
          <h2>Disclosures of Your Personal Information to Third Parties</h2>
          <p>
            Metropoly does not disclose your personal information to third parties, except as described in this policy.
            Third party disclosures may include sharing such information with investment partners (e.g. deal sponsors),
            other financial institutions (e.g. lenders requiring such information pursuant to regulatory requirements)
            and/or nonaffiliated companies that perform support services for your account or facilitate your
            transactions with Metropoly, including those that provide professional, legal or accounting advice to
            Metropoly. Each such third party is required to maintain the confidentiality of such information to the
            extent received and to use your personal information only while providing the services, and only for the
            purposes dictated by Metropoly.
          </p>
          <p>
            We may also disclose your personal information to fulfill your instructions, to protect our rights and
            interests and those of our business partners or pursuant to your express consent. Finally, under limited
            circumstances, your personal information may be disclosed to third parties as permitted by, or to comply
            with, applicable laws and regulations; for instance, when responding to a subpoena or similar legal process,
            to protect against fraud and to otherwise cooperate with law enforcement or regulatory authorities or with
            organizations such as exchanges and clearinghouses. For clients who link or verify bank accounts over our
            platform, we may use services provided by Plaid Inc. (“Plaid”) to gather your data from financial
            institutions. By using those features of our service, you grant Client and Plaid the right, power, and
            authority to act on your behalf to access and transmit your personal and financial information from the
            relevant financial institution. You agree to your personal and financial information being transferred,
            stored, and processed by Plaid in accordance with the Plaid Privacy Policy.
          </p>
          <p>You should know that Metropoly will not sell your personal information.</p>
          <h2>Information Security: How We Protect Your Privacy</h2>
          <p>
            Metropoly is committed to protecting the privacy and confidentiality of your personal information. We limit
            access to your personal information to authorized Metropoly employees or agents and, as described above in
            Disclosures of Your Personal Information to Third Parties, third parties are held to stringent standards of
            privacy. We also maintain physical, electronic and procedural safeguards to protect the information against
            loss, misuse, damage or modification and unauthorized access or disclosure. Some of the other central
            features of our information security program are:
          </p>
          <p>
            A dedicated group within Metropoly that designs, implements, and provides oversight to our information
            security program.
          </p>
          <h2>The use of specialized technology such as firewalls.</h2>
          <p>
            Testing of the security and operability of products and services before they are introduced to the Internet,
            as well as ongoing scanning for publicly known vulnerabilities in the technology.
          </p>
          <p>Internal and external reviews of our Internet sites and services.</p>
          <p>Monitoring of our systems infrastructure to detect weaknesses and potential intrusions.</p>
          <p>Implementing controls to identify, authenticate and authorize access to various systems or sites.</p>
          <p>
            Protecting information during transmission through various means including, where appropriate, encryption;
            and
          </p>
          <p>
            Providing Metropoly personnel with relevant training and continually updating our security practices in
            light of new risks and developments in technology.
          </p>
          <h2>Privacy and the Internet</h2>
          <p>The following additional information will be of interest to you as a visitor to this site:</p>
          <p>
            Users of Metropoly.io are required to identify and authenticate themselves prior to accessing our services.
            Generally, identification and authentication take place through the use of your user name and a password.
          </p>
          <p>
            Metropoly Marketplace is built upon a secure infrastructure with multiple layers of protection. Metropoly
            uses industry standard encryption technologies to protect your information from external compromise.
          </p>
          <p>
            Security is a cooperative effort between Metropoly and the users of Metropoly.io. Please remember that your
            login and password are personal to you and should not be made available to any other person. Also, you
            should discontinue their use and notify us if you have any reason to suspect that someone else may be using
            them.
          </p>
          <p>
            We may collect certain aggregate and non-personal information when you visit the Metropoly.io. We may
            collect this information through “cookie” technology. Cookies are bits of text that can be placed on your
            computer’s hard drive when you visit certain websites. Cookies may enhance your online experience by saving
            your preferences while you are visiting a particular website. The “help” portion of the toolbar on most
            browsers will tell you how to stop accepting new cookies, how to be notified when you receive a new cookie,
            and how to disable existing cookies. Remember, though, without cookies, you may not be able to take full
            advantage of all of the Metropoly.io features.
          </p>
          <p>
            “Clickstream” data (e.g., information regarding which of our Web pages you access, the frequency of such
            access, and your product and service preferences) may be collected by Metropoly itself, or by our service
            providers, using cookies, Web beacons, page tags, or similar tools that are set when you visit our Website
            or when you view an advertisement we have placed on another Website. Clickstream data and similar
            information may be shared internally within Metropoly and used: for administrative purposes; to assess the
            usage, value and performance of our online products and services; to improve your experience with our websites; and as otherwise permitted by applicable law or regulation. If you are a Metropoly client, this
            information helps us suggest products or service offerings that may be of interest to you. This information
            may be processed by us for the purposes described above, or on our behalf by third parties, solely in
            accordance with our instructions.
          </p>
          <p>
            Metropoly may make available on this Website third party applications such as content linking or sharing
            facilities. Information collected by providers of such applications is governed by their privacy policies.
          </p>
          <p>Our websites are not currently configured to respond to “do not track” signals or similar mechanisms.</p>
          <p>Information For EEA Users (EU plus Iceland, Norway and Liechtenstein)</p>
          <h2>What Rights Do I Have?</h2>
          <p>
            Where Metropoly is a Controller of data under the EU’s new General Data Protection Regulation (GDPR),
            individuals located in the European Economic Area (EEA) will have additional rights with respect to their
            non-public personal information (NPI), including the right of access, rectification, restriction,
            opposition, erasure and data portability (each, as defined by the GDPR). Where possible, we obtain and rely
            on user consent as a lawful basis for processing personal data. In some cases, Metropoly may process and
            retain NPI pursuant to a legal obligation or another legitimate interest under the GDPR to protect your
            vital interests, the interests of Metropoly, or those of another person.
          </p>
          <h2> How May I Exercise My Individual Rights?</h2>
          <p>
            Users located within the EEA may contact our Data Protection Officer with questions or requests regarding
            their NPI using the contact information below. Please note that Metropoly may request additional information
            from you to verify your identity before we disclose any personal or account information. You may opt out of
            receiving promotional communications at any time by following the “unsubscribe” instructions found within
            Metropoly email updates or changing your contact preferences. Please note, you will continue to receive
            essential account-related information, even if you unsubscribe from promotional emails.
          </p>
          <h2>Who Can I Contact at Metropoly Regarding Data Protection Issues?</h2>
          <p>
            Metropoly has designated a Data Protection Officer to assist with data privacy and data protection issues.
            You may contact him/her by emailing support@metropoly.io and addressing your questions or concerns to the
            Data Protection Officer.
          </p>
          <h2>Other Privacy Policies or Statements; Changes to Policy; Contact</h2>
          <p>
            This policy provides a general statement of the ways in which Metropoly protects your personal information.
            You may, however, in connection with specific products or services offered by Metropoly, be provided with
            privacy policies or statements that supplement this policy. This policy may be changed from time to time to
            reflect changes in our practices concerning the collection and use of personal information. The revised
            policy will be effective immediately upon posting to our website. This version of the Policy is effective
            May 24, 2018. If you require further information regarding our privacy policies and practices please contact
            legal@metropoly.io.
          </p>
          <h2>Statement on Cookies</h2>
          <p>
            By using our Website with your browser settings adjusted to accept cookies, you consent to Metropoly’s use
            of cookies and other tools to provide the products and services available on our site. If you would like to
            modify your browser to notify you when you receive a new cookie or to disable cookies altogether, please
            refer to Managing Cookies below.
          </p>
          <h2> Cookies at Metropoly and How We Use Them</h2>
          <p>
            Cookies are small text files that may be placed on your Web browser when you visit our website or when you
            view advertisements we have placed on other websites. Cookies allow your browser to remember some specific
            information which the web server can later retrieve and use. Metropoly does not use cookies to store any
            personal information that could be read or understood by others. When you quit your browser, some cookies
            are stored in your computer’s memory, while some expire or disappear.
          </p>
          <p>
            Cookies are used primarily for administrative purposes, to improve your experience with our website.
            Examples of this include the use of cookies:
          </p>
          <p>
            To improve site security by “authenticating” you (i.e., verify that you are who you say you are) when you
            sign-in to our site.
          </p>
          <p>
            To keep track of your specified preferences such as language, time zone and timeouts after periods of
            inactivity.
          </p>
          <p>
            To allow you to navigate our sites more easily by “remembering” your identity so that you do not have to
            input your password multiple times as you move between pages or services.
          </p>
          <p>
            In addition to administrative uses, Metropoly may also use cookies in one or more of the following ways:
          </p>
          <p>
            To conduct research and analytics to improve our website and our products and services. This includes
            compiling statistical information concerning, among other things, the frequency of your use of our website,
            the pages visited and the length of each visit.
          </p>
          <p>
            To display Metropoly advertisements when you visit websites of third parties with whom we have marketing
            relationships and to help us gauge the effectiveness of our advertising efforts. These parties may gather
            information concerning your use of other websites and provide us with de-identified information about you
            (such as demographic information or the names of sites where you have been shown ads) which we may use to
            provide you with more relevant and useful advertising.
          </p>
          <h2> </h2>
          <p>
            The Help menu on the menu bar of most browsers will tell you how to prevent your browser from accepting new
            cookies, how to have the browser notify you when you receive a new cookie and how to disable cookies
            altogether. Additionally, you can disable or delete similar data used by browser add-ons, such as Flash
            cookies, by changing the add-on’s settings or visiting the website of its manufacturer. Some of our sites
            employ “functionality cookies” that help the site remember your settings and preferences for use when you
            visit the site in the future. This provides a more tailored and pleasant user experience. These cookies
            store information in multiple places. On those sites using functionality cookies, you will find a “Delete
            Cookies” feature on every page that will allow you to delete these cookies completely from your device.
            Please note that, if you decline to use cookies, you may experience reduced functionality and, for sites
            using authentication-related cookies, declining cookies will prevent you from using the website altogether.
            To learn more about what cookies are set on your computer as you browse the Web and how to manage or delete
            them, visit allaboutcookies.org.
          </p>
        </div>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
