import './countryModal.scss';
import Bounce from 'react-reveal/Bounce';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import FormatsortOptionLabel from '../FormatsortOptionLabel';
const customStyles = {
  control: base => ({
    ...base,
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '50px',
    border: '1px solid #ddd !important',
    boxShadow: 'none',
    width: '100%',
    minWidth: 300,
    '&:focus': {
      border: '0 !important',
    },
  }),

  menu: (provided, state) => ({
    ...provided,
    border: '1px solid #ffffff13',
    color: '#fff',
    padding: 0,
    background: '#fff',
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #aaa',
    padding: 3,
    color: '#fff',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#ddd',
    },
    ':active': {
      backgroundColor: '#555',
    },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

interface Props {
  showModal: boolean;
  setShowtModal?: any;
  myData?: any;
  setCountry?: any;
}
const CountryModal: React.FC<Props> = ({ showModal, setShowtModal, myData, setCountry }) => {
  const [isStart, setIsStart] = useState(false);
  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        setIsStart(true);
      }, 100);
    }
  }, [setIsStart, showModal]);
  const onClose = () => {
    setIsStart(false);
    setTimeout(() => {
      setShowtModal(false);
    }, 800);
  };

  const [options, setOptions] = useState([]);
  useEffect(() => {
    const uniqueIds = new Set();
    const unique = myData.filter(element => {
      const isDuplicate = uniqueIds.has(element.country);
      uniqueIds.add(element.country);

      if (!isDuplicate) {
        return true;
      }
      return false;
    });

    let opt = [];
    unique.map((index) => {

      let data = {
        value: index.id,
        label: index.country,
        customAbbrevation: index.id
      }
      opt.push(data)
    })
    setOptions([...opt])
  }, [])
  // const options = [
  //   { value: 0, label: 'United States', customAbbreviation: '0' },
  //   { value: 1, label: 'United Kingdom', customAbbreviation: '1' },
  //   { value: 2, label: 'Germany', customAbbreviation: '2' },
  //   { value: 3, label: 'Spain', customAbbreviation: '3' },
  // ];
  const handleSelected = e => {
    setCountry(e.label)
  };
  return (
    <div className={showModal === true ? 'countryModal active' : 'countryModal'}>
      <Bounce opposite when={isStart}>
        <div className="modelContent">
          <div className="connectWalletHeader">
            <h1 className="connectWalletTitle">Choose Country</h1>
            <button className="connectModalCloseButton" onClick={onClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="connectWalletWrapper">
            <Select
              defaultValue={options[0]}
              formatOptionLabel={FormatsortOptionLabel}
              options={options}
              instanceId="chainSelect"
              className={`select-gray `}
              onChange={e => handleSelected(e)}
              isSearchable={true}
              isClearable={false}
              styles={customStyles}
            />
          </div>
        </div>
      </Bounce>
    </div>
  );
};
export default CountryModal;
