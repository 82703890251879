import './PropertyType.scss';
import { useEffect, useState } from 'react';

const PropertyType = () => {
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const properties = ['House', 'Apartment', 'Villa', 'Penthouse'];
  const handleChange = e => {
    const idx = selectedList.indexOf(e.target.value);
    if (idx > -1) selectedList.splice(idx, 1); //isExist
    else selectedList.push(e.target.value);
    setSelectedList([...selectedList]);
  };

  const [tabId, setTabId] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  useEffect(() => {
    console.log(selectAll);
    if (selectAll) {
      setSelectedList(['House', 'Apartment', 'Villa', 'Penthouse']);
    } else {
      setSelectedList([]);
    }
  }, [selectAll, setSelectedList]);
  return (
    <div className="PropertyType">
      <h3 className="">Property Type</h3>
      <div className="myTab">
        <div className="tabList">
          <div className={`tab ${tabId === 0 ? 'activeTab' : ''}`} onClick={() => setTabId(0)}>
            All
          </div>
          <div className={`tab ${tabId === 1 ? 'activeTab' : ''}`} onClick={() => setTabId(1)}>
            <img src="/assets/images/rental_icon.svg" alt="" /> Rental Property
          </div>
          <div className={`tab ${tabId === 2 ? 'activeTab' : ''}`} onClick={() => setTabId(2)}>
            <img src="/assets/images/holiday_icon.svg" alt="" /> Holiday Home
          </div>
        </div>
        {tabId === 0 && (
          <div className="tabContent1">
            <h2>Home Type</h2>

            <span className="checkAll">
              <input type="checkbox" id="all" name="all" onChange={e => setSelectAll(e.target.checked)} />
              <label htmlFor="all">{selectAll ? 'Deselect All' : 'Select All'}</label>
            </span>

            {properties?.map((d, key) => (
              <span key={key} className="checkGroup">
                <input
                  type="checkbox"
                  id={`one_${key}`}
                  name={d}
                  value={d}
                  onChange={e => handleChange(e)}
                  checked={selectedList.indexOf(d) > -1}
                />
                <label htmlFor={`one_${key}`}>{d}</label>
              </span>
            ))}
          </div>
        )}
        {tabId === 1 && (
          <div className="tabContent1">
            <h2>Home Type</h2>

            <span className="checkAll">
              <input type="checkbox" id="all" name="all" onChange={e => setSelectAll(e.target.checked)} />
              <label htmlFor="all">{selectAll ? 'Deselect All' : 'Select All'}</label>
            </span>

            {properties?.map((d, key) => (
              <span key={key} className="checkGroup">
                <input
                  type="checkbox"
                  id={`one1_${key}`}
                  name={d}
                  value={d}
                  onChange={e => handleChange(e)}
                  checked={selectedList.indexOf(d) > -1}
                />
                <label htmlFor={`one_${key}`}>{d}</label>
              </span>
            ))}
          </div>
        )}
        {tabId === 2 && (
          <div className="tabContent1">
            <h2>Home Type</h2>

            <span className="checkAll">
              <input type="checkbox" id="all" name="all" onChange={e => setSelectAll(e.target.checked)} />
              <label htmlFor="all">{selectAll ? 'Deselect All' : 'Select All'}</label>
            </span>

            {properties?.map((d, key) => (
              <span key={key} className="checkGroup">
                <input
                  type="checkbox"
                  id={`one2_${key}`}
                  name={d}
                  value={d}
                  onChange={e => handleChange(e)}
                  checked={selectedList.indexOf(d) > -1}
                />
                <label htmlFor={`one_${key}`}>{d}</label>
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default PropertyType;
