import './multiBuyModal.scss';
import Bounce from 'react-reveal/Bounce';
import { useEffect, useState } from 'react';

interface Props {
  showModal: boolean;
  setShowModal?: any;
}

const MultiBuyModal: React.FC<Props> = ({ showModal, setShowModal }) => {
  const [isStart, setIsStart] = useState(false);
  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        setIsStart(true);
      }, 100);
    }
  }, [setIsStart, showModal]);
  const onClose = () => {
    setIsStart(false);
    setTimeout(() => {
      setShowModal(false);
    }, 800);
  };
  const [price, setPrice] = useState(0);
  return (
    <div className={showModal === true ? 'multiBuyModal active' : 'multiBuyModal'}>
      <Bounce opposite when={isStart}>
        <div className="modelContent">
          <div className="connectWalletHeader">
            <h1> </h1>
            <button className="connectModalCloseButton" onClick={onClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="connectWalletWrapper">
            <div className="row">
              <h1>Multi Buy</h1>
              <div className="btns">
                <button className="grey">Items</button>
                <button>USD</button>
              </div>
            </div>

            <div className="row mb-10">
              <p>Use the Multi Buy Option To Diversify Your Real Estate porfolio</p>
            </div>

            <div className="row">
              <h5>Total Order Value</h5>
            </div>
            <div className="row">
              <input
                type="range"
                min="0"
                max="100"
                value={price}
                className="slider"
                onChange={e => {
                  setPrice(parseFloat(e.target.value));
                }}
              />
            </div>

            <div className="row mb-10 borderBottom">
              <span>
                <input type="number" placeholder={`${price === 0 ? '7,000' : ''}`} value={price} /> <p>USD</p>
              </span>
            </div>
            <div className="cart">
              <div className="header">
                <span>
                  <h4>My Cart </h4>
                  <h5>4</h5>
                </span>
                <button className="clear">Clear</button>
              </div>
              <div className="item">
                <div className="nft">
                  <img src="/assets/images/image_02.png" alt="" />
                  <i className="fas fa-times"></i>
                </div>
                <div className="text">
                  <h4>3-Bedroom Fam...</h4>
                  <p>Stockholm, Sweden</p>
                </div>
                <p className="price">$725</p>
              </div>

              <div className="item">
                <div className="nft">
                  <img src="/assets/images/image_05.png" alt="" />
                  <i className="fas fa-times"></i>
                </div>
                <div className="text">
                  <h4>1-Bedroom Studio..</h4>
                  <p>manchester, UK</p>
                </div>
                <p className="price">$215</p>
              </div>

              <div className="item">
                <div className="nft">
                  <img src="/assets/images/image_03.png" alt="" />
                  <i className="fas fa-times"></i>
                </div>
                <div className="text">
                  <h4>Luxury 5-Bedroom...</h4>
                  <p>San Francisco, USA</p>
                </div>
                <p className="price">$700</p>
              </div>

              <div className="item">
                <div className="nft">
                  <img src="/assets/images/unsplash_IYfp2Ixe9nM.png" alt="" />
                  <i className="fas fa-times"></i>
                </div>
                <div className="text">
                  <h4>Beautiful 5-Bedroom.</h4>
                  <p>Los Angeles, USA</p>
                </div>
                <p className="price">$5,200</p>
              </div>
              <div className="footer">
                <h4>Total Amount </h4>
                <p>$ 6,840</p>
              </div>
            </div>
            <button className="Insufficient">Insufficient Balance</button>
          </div>
        </div>
      </Bounce>
    </div>
  );
};
export default MultiBuyModal;
