import { useStyles } from './style';
import Masonry from 'react-masonry-css';
import ProductCard2 from 'components/Cards/ProductCard2';
import { useState } from 'react';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const myData = [
  {
    title: 'Borrow',
    desc: 'Select your Real Estate NFT as Collateral',
    img: '/assets/images/image_14.png',
    price: 2400,
    name: 'Rare 3-Bedroom Penthouse with a Patio',
    location_icon: '/assets/images/tag.svg',
    location: 'Barcelona, Spain',
    state: 'Rented',
    beds: 3,
  },
  {
    title: 'Supply',
    desc: 'Earn interest with Our Liquidity Protocol',
    img: '/assets/images/image_15.png',
    price: 100,
    name: 'Metropoly Real Estate NFT Liquidity Protocol',
    location_icon: '/assets/images/bedge_icon.svg',
    location: 'Results so far',
    state: 'Rented',
  },
];

const Mortgages = () => {
  const classes = useStyles();
  const breakpointColumnsObj = {
    default: 2,
    1440: 2,
    1024: 2,
    768: 2,
    450: 1,
  };
  const [tabId, setTabId] = useState(0);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <span>
          <h1 className={classes.title}>Mortgages</h1>
          <p>Borrow and supply funds at competitive rates while retaining full real estate NFT ownership.</p>
        </span>
      </div>

      <div className={classes.content}>
        {isMobileOrTablet ? (
          <div className="myTab">
            <div className="tabList">
              <div className={`tab ${tabId === 0 ? 'activeTab' : ''}`} onClick={() => setTabId(0)}>
                BORROW
              </div>
              <div className={`tab ${tabId === 1 ? 'activeTab' : ''}`} onClick={() => setTabId(1)}>
                SUPPLY
              </div>
            </div>
            {tabId === 0 && (
              <div className="tabContent">
                <h5>{myData[0].desc}</h5>
                <ProductCard2 product={myData[0]} isBorrow />
              </div>
            )}
            {tabId === 1 && (
              <div className="tabContent">
                <h5>{myData[1].desc}</h5>
                <ProductCard2 product={myData[1]} isSupply />
              </div>
            )}
          </div>
        ) : (
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className={classes.masonry}
            columnClassName={classes.gridColumn}
          >
            <div className={classes.productDiv}>
              <h3>{myData[0].title}</h3>
              <h5>{myData[0].desc}</h5>
              <ProductCard2 product={myData[0]} isBorrow />
            </div>

            <div className={classes.productDiv}>
              <h3>{myData[1].title}</h3>
              <h5>{myData[1].desc}</h5>
              <ProductCard2 product={myData[1]} isSupply />
            </div>
          </Masonry>
        )}
      </div>
    </div>
  );
};

export default Mortgages;
