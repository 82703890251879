import { makeStyles } from '@material-ui/core/styles';
import useAuth from 'hooks/useAuth';
import UserChip from '../AvatarInfoItems/UserChip';
import PopoverMenu from '../PopoverMenu';
import TinyBold from '../Typography/TinyBold';

import FilledButton from 'components/Buttons/FilledButton';

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(3.875),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    '& .MuiPopover-paper': {
      boxShadow: '0 0 5px 7px #00000022',
      right: '30px !important',
      left: 'auto !important',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        right: '10px !important',
      },
    },
  },
  menuContent: {
    minWidth: 350,
    width: 350,
    padding: 15,
    [theme.breakpoints.down('xs')]: {
      minWidth: '90vw',
    },
    '& p': {
      fontSize: 14,
      color: '#000',
      paddingTop: 10,
    },
  },
  label: {
    fontWeight: 700,
    fontSize: 20,
    color: '#000',
    display: 'flex',
    alignItems: 'center',
  },
  state: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '#F7F9FA',
    padding: '20px 20px',
    borderTopRightRadius: 30,
    borderTopLeftRadius: 30,
    '& .connectBtn': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px #ddd solid',
      paddingBottom: 10,
      paddingTop: 10,
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      '& .left': {
        display: 'flex',
        alignItems: 'center',
        '& img': {
          width: 35,
          marginRight: 10,
        },
        '& h2': {
          fontSize: 14,
          color: '#000',
        },
      },
      '&:hover': {
        opacity: 0.8,
      },

      '& .middle': {
        display: 'flex',
        alignItems: 'center',
        '& a': {
          border: 'none',
          padding: 10,
          background: '#ffffff00',
          fontSize: 16,
          fontWeight: 600,
          cursor: 'pointer',
          color: '#000',
        },
      },
    },
  },

  header: {
    widht: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 20,
    borderBottom: '1px #ddd solid',
  },

  icon: {
    color: '#fff !important',
    fontSize: 25 + 'px !important',
  },
  links: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingTop: 20,
    paddingBottom: 10,
    '& a': {
      fontSize: 14,
      color: '#54555E',
      display: 'flex',
      alignItems: 'center',
      '& img': {
        marginRight: 10,
      },
    },
    '&:hover': {
      cursor: 'pointer',
      color: '#fff',
    },
  },
}));

const WalletDropDown = ({ avatarUrl, displayName, balance, walletAddress }) => {
  const classes = useStyles();
  const { login } = useAuth();
  const connectMetamask = () => {
    login(1);
  };
  const connectWalletConnector = () => {
    login(2);
  };

  return (
    <PopoverMenu
      className={classes.paper}
      anchor={<UserChip avatarUrl={avatarUrl} displayName={'Connect Wallet'} balance={balance} />}
      anchorOrigin={{
        vertical: 60,
        horizontal: 30,
      }}
      // disableCloseAsSelfClick
    >
      <div className={classes.menuContent}>
        <div className={classes.header}>
          <TinyBold className={classes.label}>My Wallet</TinyBold>
        </div>
        <p>If you do not have a wallet, you can select a provider and create a new one.</p>

        <div className={classes.state}>
          <div className="connectBtn metaMask" onClick={connectMetamask}>
            <div className="left">
              <img src="/assets/images/metamask.png" alt="" />
              <h2>Metamask</h2>
            </div>
            <div className="right">
              <FilledButton label={'Popular'} />
            </div>
          </div>

          <div className="connectBtn wallet" onClick={connectWalletConnector}>
            <div className="left">
              <img src="/assets/images/wallet-connect.png" alt="" />
              <h2>Wallet Connect</h2>
            </div>
            <div className="middle"></div>
          </div>

          <div className="connectBtn wallet" onClick={connectWalletConnector}>
            <div className="left"></div>
            <div className="middle">
              <a
                href="https://metamask.zendesk.com/hc/en-us/articles/360015289452-How-to-create-an-additional-account-in-your-wallet"
                target={'_blank'}
                rel="noreferrer"
              >
                Create a new Wallet
              </a>
            </div>
            <div className="right"></div>
          </div>
        </div>
      </div>
    </PopoverMenu>
  );
};

export default WalletDropDown;
