import { Switch } from '@material-ui/core';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    background: '#ffffff00',
    paddingTop: 50,
    paddingBottom: 127,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: 112,
    },
    '&.MuiSelect-icon': {},
  },
  top: {
    background: '#ffffff00',
    padding: theme.spacing(0, 2),
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 20,
    },
    '& p': {
      fontSize: 14,
      color: '#93989A',
      [theme.breakpoints.down('xs')]: {
        marginBottom: 10,
      },
    },
  },
  container: {
    padding: theme.spacing(0, 2),
  },
  btns: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {},
    '& button': {
      marginLeft: 20,
      minWidth: 100,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        marginRight: 10,
      },
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: '1px #eee solid',
    [theme.breakpoints.down('xs')]: {},
    '& p': {
      fontSize: 14,
      color: '#93989A',
      [theme.breakpoints.down('xs')]: {
        marginBottom: 10,
      },
    },
    '& h4': {
      fontWeight: 600,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 16,
      },
    },
  },

  uploadContainer: {},

  title: {
    fontSize: 32,
    color: '#000',
    fontWeight: 600,
  },
  subTitle: {
    fontSize: 20,
    color: '#000',
    width: '100%',
    fontWeight: 600,
    paddingBottom: 15,
    paddingTop: 10,
    borderBottom: '1px #ddd solid',
  },
  label: {
    fontWeight: 500,
  },
  formWrapper: {
    marginTop: 20,
    width: '100%',
    '& h3': {
      fontWeight: 600,
      fontSize: 16,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },

  myInput: {
    color: '#333',
  },
  formGroup: {
    paddingTop: 3,
  },
  preview: {
    background: '#fff',
    borderRadius: theme.shape.cardBorderRadius,
    padding: 20,
    boxShadow: '0px 4px 100px rgba(202, 202, 202, 0.2)',
    [theme.breakpoints.down('xs')]: {
      padding: 21,
    },
    '& .avatar': {
      width: '100%',
      display: 'flex',
      alignItem: 'center',
      paddingTop: 10,
      paddingBottom: 10,
      '& img': {
        width: 56,
        height: 56,
        objectFit: 'cover',
        marginRight: 10,
      },
      '& h4': {
        color: '#010101',
        fontWeight: 600,
      },
      '& .btns': {
        '& button': {
          border: 'none',
          color: '#93989A',
          background: '#ffffff00',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          padding: 0,
          marginRight: 10,

          '&:hover': {
            color: '#000',
          },
        },
        '& .updateBtn': {
          border: 'none',
          color: '#E23434',
        },
      },
    },
    '& .MuiSwitch-switchBase': {
      '& .MuiIconButton-label': {
        color: '#00D9AC',
      },
    },
    '& .Mui-checked': {
      '& .MuiIconButton-label': {
        color: '#fff !important',
      },
    },
  },

  upload: {
    marginBottom: 40,
  },

  b2: { marginTop: 10 },
  settingBtn: { marginTop: 20, width: '100%' },

  note: {
    marginTop: 8,
  },
}));

export const AntSwitch = withStyles((theme: Theme) => ({
  root: {
    width: (props: { kind: string }) => (props.kind === 'small' ? 40 : 48),
    height: (props: { kind: string }) => (props.kind === 'small' ? 20 : 24),
    borderRadius: 20,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 3,
    color: '#2892F3',
    '&$checked': {
      transform: 'translateX(calc(100% + 2px))',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#2892F3',
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: (props: { kind: string }) => ({
    width: props.kind === 'small' ? 13 : 17,
    height: props.kind === 'small' ? 13 : 17,
    boxShadow: 'none',
  }),
  track: {
    borderColor: theme.palette.surface[2],
    borderRadius: 20,
    opacity: 1,
    backgroundColor: theme.palette.surface[2],
  },
  checked: {},
}))(Switch);

export default useStyles;
