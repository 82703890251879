import React, { useEffect } from "react"
import PropTypes from 'prop-types';
import { useState } from 'react';
import Topbar from './topbar/Topbar';
import Footer from './Footer';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SideBar from './SideBar/SideBar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Menu from 'components/menu/Menu';
import { useMoralis } from "react-moralis";

const useStyles = makeStyles(theme => ({
  layout: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingTop: 80,
    },
  },
  layoutContent: {
    flex: 1,
    display: 'flex',
    marginzTop: '5rem',
    zIndex: 1,
    background: '#F3F3F3',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  sideBar: {
    // width: 300,
    width: '16vw',
    minWidth: 230,
    maxWidth: 300,
    padding: '13px 0.8vw',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '0px 20px',
      marginTop: 80,
    },
  },
  mainContent: {
    width: 'calc(100% - 16vw)',
    background: '#fff',
    // padding: '0px 20px ',
    [theme.breakpoints.up('xl')]: {
      width: 'calc(100% - 300px)',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
      padding: 0,
    },
  },
}));
const Layout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);
  const { isAuthenticated, user, authenticate, isWeb3Enabled, enableWeb3, isInitialized, isInitializing, isWeb3EnableLoading, initialize } = useMoralis();

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       if (isInitializing || isWeb3EnableLoading)
  //         return () => { }
  //       if (!isInitialized) {
  //         initialize({
  //           appId: "F254vsO3ffYAne6sff288HKXOjBrUvqhzhDAjxpn",
  //           serverUrl: "https://bzgdtzqlihki.usemoralis.com:2053/server"
  //         });
  //       }
  //       if (isInitialized && !isWeb3Enabled) {
  //         try {

  //           const wc = window as WindowChain;
  //           const provider = wc.ethereum;
  //           if (window.localStorage.walletconnect) {
  //             console.log("walletconnect")
  //           } else
  //             if (provider) {
  //               await enableWeb3()
  //             }
  //         } catch (e) {
  //           console.log(e)
  //         }
  //       }

  //     } catch (error) {
  //       console.log("error", error);
  //     }
  //   }
  //   )()
  // }, [isWeb3Enabled, isInitialized, isAuthenticated, user])

  return (
    <>
      <div className={classes.layout}>
        <div className={classes.layoutContent}>
          {!isMobileOrTablet && (
            <div className={classes.sideBar}>
              <SideBar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            </div>
          )}

          <div className={classes.mainContent}>
            <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            {children}
          </div>
        </div>
        <Footer />
      </div>
      <Menu
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        children={<SideBar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />}
      />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
