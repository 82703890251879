import { useStyles } from './style';
import Masonry from 'react-masonry-css';
import ProductCard1 from 'components/Cards/ProductCard1';
import Filter from 'components/Filter/Filter';
import { useState } from 'react';
const myData = [
  {
    id: 12,
    img: '/assets/images/image_11.png',
    type: 'Rental Property',
    startPrice: 1000,
    estatePrice: 1000000,
    name: 'Beautiful 3-Bedroom House in a Quiet Neighbourhood',
    location: 'Miami, United States of America',
    homeState: 'Vacant',
    country: 'United States of America',
    beds: 3,
    currntBid: 20,
    state: {
      expected: 57,
      yeld: 9.44,
      frequency: 'Monthly',
    },
  },
  {
    id: 13,
    img: '/assets/images/image_12.png',
    type: 'Rental Property',
    startPrice: 5500,
    estatePrice: 5500000,
    name: 'Comfortable 2-Bedroom Ensuite Apartment',
    location: 'Dublin, Ireland',
    homeState: 'Vacant',
    beds: 2,
    currntBid: 12.7,
    country: 'Ireland',
    state: {
      expected: 40,
      yeld: 6,
      frequency: 'Monthly',
    },
  },
  {
    id: 14,
    img: '/assets/images/image_13.png',
    type: 'Rental Property',
    startPrice: 159,
    estatePrice: 150000,
    name: '1-Bedroom Apartment in the Well-Appointed City Center',
    location: 'Athens, Greece',
    homeState: 'Vacant',
    beds: 1,
    currntBid: 10,
    country: 'Greece',
    state: {
      expected: 34,
      yeld: 4.8,
      frequency: 'Monthly',
    },
  },
];

const Auctions = () => {
  const classes = useStyles();
  const breakpointColumnsObj = {
    // default: 4,
    3840: 7,
    3000: 6,
    2560: 5,
    2200: 4,
    1840: 3,
    1440: 3,
    1280: 2,
    768: 2,
    450: 1,
  };

  const [data, setData] = useState(myData);

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <span>
          <h1 className={classes.title}>Auctions</h1>
          <p>Get real estate NFTs at the lowest price.</p>
        </span>
      </div>
      <Filter data={data} setData={setData} myData={myData}/>

      <div className={classes.content}>
        <Masonry breakpointCols={breakpointColumnsObj} className={classes.masonry} columnClassName={classes.gridColumn}>
          {data.map((d, i) => (
            <ProductCard1 key={i} product={d} isAuction />
          ))}
        </Masonry>
      </div>
    </div>
  );
};

export default Auctions;
