import './propertyTypeModal.scss';
import Bounce from 'react-reveal/Bounce';
import { useEffect, useState } from 'react';
interface Props {
  showModal: boolean;
  setShowtModal?: any;
  setType?: any;
  setHomeType?: any;
  reset?: any;
  minPrice?: number;
  maxPrice?: number;
}
const PropertyTypeModal: React.FC<Props> = ({ showModal, setShowtModal, setType, setHomeType, reset }) => {
  const [isStart, setIsStart] = useState(false);
  
  useEffect(() => {
    setTabId(0)
    setSelectedList([]);
  }, [reset]);

  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        setIsStart(true);
      }, 100);
    }
  }, [setIsStart, showModal]);
  
  const onClose = () => {
    setIsStart(false);
    setTimeout(() => {
      setShowtModal(false);
    }, 800);
  };
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const properties = ['House', 'Apartment', 'Villa', 'Penthouse'];
  const handleChange = e => {
    const idx = selectedList.indexOf(e.target.value);
    if (idx > -1) selectedList.splice(idx, 1); //isExist
    else selectedList.push(e.target.value);
    setSelectedList([...selectedList]);
    setHomeType(selectedList)
  };

  const [tabId, setTabId] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  useEffect(() => {
    if (selectAll) {
      setSelectedList(['House', 'Apartment', 'Villa', 'Penthouse']);
    } else {
      setSelectedList([]);
    }
  }, [selectAll]);
  return (
    <div className={showModal === true ? 'propertyTypeModal active' : 'propertyTypeModal'}>
      <Bounce opposite when={isStart}>
        <div className="modelContent">
          <div className="connectWalletHeader">
            <h1 className="connectWalletTitle">Property Type</h1>
            <button className="connectModalCloseButton" onClick={onClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="connectWalletWrapper">
            <div className="myTab">
              <div className="tabList">
                <div className={`tab ${tabId === 0 ? 'activeTab' : ''}`} onClick={() => {
                  setTabId(0)
                  setType("")
                }}>
                  All
                </div>
                <div className={`tab ${tabId === 1 ? 'activeTab' : ''}`} onClick={() => {
                  setTabId(1)
                  setType("Rental Property")
                }}>
                  <img src="/assets/images/rental_icon.svg" alt="" /> Rental Property
                </div>
                <div className={`tab ${tabId === 2 ? 'activeTab' : ''}`} onClick={() => {
                  setTabId(2)
                  setType("Holiday Home")
                }}>
                  <img src="/assets/images/holiday_icon.svg" alt="" /> Holiday Home
                </div>
              </div>
              {tabId === 0 && (
                <div className="tabContent">
                  <h2>Home Type</h2>

                  <span className="checkAll">
                    <input type="checkbox" id="all" name="all" onChange={e => setSelectAll(e.target.checked)} />
                    <label htmlFor="all">{selectAll ? 'Deselect All' : 'Select All'}</label>
                  </span>

                  {properties?.map((d, key) => (
                    <span key={key} className="checkGroup">
                      <input
                        type="checkbox"
                        id={`one_${key}`}
                        name={d}
                        value={d}
                        onChange={e => handleChange(e)}
                        checked={selectedList.indexOf(d) > -1}
                      />
                      <label htmlFor={`one_${key}`}>{d}</label>
                    </span>
                  ))}
                </div>
              )}
              {tabId === 1 && (
                <div className="tabContent">
                  <h2>Home Type</h2>

                  <span className="checkAll">
                    <input type="checkbox" id="all" name="all" onChange={e => setSelectAll(e.target.checked)} />
                    <label htmlFor="all">{selectAll ? 'Deselect All' : 'Select All'}</label>
                  </span>

                  {properties?.map((d, key) => (
                    <span key={key} className="checkGroup">
                      <input
                        type="checkbox"
                        id={`one_${key}`}
                        name={d}
                        value={d}
                        onChange={e => handleChange(e)}
                        checked={selectedList.indexOf(d) > -1}
                      />
                      <label htmlFor={`one_${key}`}>{d}</label>
                    </span>
                  ))}
                </div>
              )}
              {tabId === 2 && (
                <div className="tabContent">
                  <h2>Home Type</h2>

                  <span className="checkAll">
                    <input type="checkbox" id="all" name="all" onChange={e => setSelectAll(e.target.checked)} />
                    <label htmlFor="all">{selectAll ? 'Deselect All' : 'Select All'}</label>
                  </span>

                  {properties?.map((d, key) => (
                    <span key={key} className="checkGroup">
                      <input
                        type="checkbox"
                        id={`one_${key}`}
                        name={d}
                        value={d}
                        onChange={e => handleChange(e)}
                        checked={selectedList.indexOf(d) > -1}
                      />
                      <label htmlFor={`one_${key}`}>{d}</label>
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Bounce>
    </div>
  );
};
export default PropertyTypeModal;
