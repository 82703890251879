import './auctionModal.scss';
import Bounce from 'react-reveal/Bounce';
import { useEffect, useState } from 'react';
import FilledButton from 'components/Buttons/FilledButton';
import Select from 'react-select';
import FormatOptionLabelToken from '../FormatOptionLabelToken';
import FormatsortOptionLabel from 'components/Filter/FormatsortOptionLabel';
const customStyles = {
  control: base => ({
    ...base,
    fontSize: '0.8vw',
    fontWeight: 'bold',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    border: '1px solid #ddd !important',
    boxShadow: 'none',
    color: '#93989A',
    // minWidth: '10vw',
    // width: '5vw',
    '&:focus': {
      border: '0 !important',
    },
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      fontSize: 14,
      minWidth: 110,
    },
  }),

  menu: (provided, state) => ({
    ...provided,
    border: '1px solid #ffffff13',
    // color: '#fff',
    color: '#93989A',
    padding: 0,
    background: '#fff',
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #aaa',
    padding: 3,
    // color: '#93989A',
    cursor: 'pointer',
    fontSize: '0.8vw',
    color: state.isSelected ? 'white' : '#93989A !important',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      fontSize: 14,
    },
    ':hover': {
      color: '#93989A !important',
    },
    ':active': {
      color: '#fff !important',
    },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

interface Props {
  showModal: boolean;
  setShowModal?: any;
}

const AuctionModal: React.FC<Props> = ({ showModal, setShowModal }) => {
  const [isStart, setIsStart] = useState(false);
  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        setIsStart(true);
      }, 100);
    }
  }, [setIsStart, showModal]);
  const onClose = () => {
    setIsStart(false);
    setTimeout(() => {
      setShowModal(false);
    }, 800);
  };

  const [mintCount, setMintCount] = useState(1);
  const decreaseHandle = () => {
    if (mintCount > 0) {
      setMintCount(mintCount - 1);
    }
  };
  const increaseHandle = () => {
    if (mintCount < 10) {
      setMintCount(mintCount + 1);
    }
  };

  const options = [
    { value: 'usd', label: 'USD', customAbbreviation: '0' },
    { value: 'eth', label: 'ETH', customAbbreviation: '1' },
    { value: 'weth', label: 'WETH', customAbbreviation: '2' },
  ];

  const options1 = [
    { value: '0', label: '1 Days', customAbbreviation: '0' },
    { value: '1', label: '5 Days', customAbbreviation: '1' },
    { value: '2', label: '15 Days', customAbbreviation: '1' },
    { value: '3', label: '30 Days', customAbbreviation: '1' },
  ];
  const handleSelected = e => {};
  return (
    <div className={showModal === true ? 'auctionModal active' : 'auctionModal'}>
      <Bounce opposite when={isStart}>
        <div className="modelContent">
          <div className="connectWalletHeader">
            <h1 className="connectWalletTitle">Sell on Auction </h1>
            <button className="connectModalCloseButton" onClick={onClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="connectWalletWrapper">
            <div className="row mb-10 borderBottom">
              <span>
                <h5>Number of NFTs</h5>
                <p>Select the number of NFTs that you want to sell in this auction</p>
              </span>
              <div className="count">
                <button onClick={decreaseHandle}>-</button>
                <input type="number" value={mintCount} onChange={e => setMintCount(parseFloat(e.target.value))} />
                <button onClick={increaseHandle}>+</button>
              </div>
            </div>

            <div className="row mb-10 borderBottom">
              <span>
                <h5>Current Market Value</h5>
                <p>This price signals the current market value</p>
              </span>
              <div className="input-div">
                <Select
                  defaultValue={options[0]}
                  formatOptionLabel={FormatOptionLabelToken}
                  options={options}
                  instanceId="chainSelect1"
                  className={`select-gray `}
                  onChange={e => handleSelected(e)}
                  isSearchable={false}
                  isClearable={false}
                  styles={customStyles}
                />
                <input type="number" placeholder="2,400" />
              </div>
            </div>

            <div className="row mb-10 borderBottom">
              <span>
                <h5>Starting Price</h5>
                <p>Set your starting bid price </p>
              </span>
              <div className="input-div">
                <Select
                  defaultValue={options[0]}
                  formatOptionLabel={FormatOptionLabelToken}
                  options={options}
                  instanceId="chainSelect2"
                  className={`select-gray `}
                  onChange={e => handleSelected(e)}
                  isSearchable={false}
                  isClearable={false}
                  styles={customStyles}
                />
                <input type="number" placeholder="Amount" />
              </div>
            </div>
            <div className="row mb-10 borderBottom">
              <span>
                <h5>Reserve Price</h5>
                <p>Create a hidden minimum. selling price by setting a reserve price </p>
              </span>
              <div className="input-div">
                <Select
                  defaultValue={options[0]}
                  formatOptionLabel={FormatOptionLabelToken}
                  options={options}
                  instanceId="chainSelect3"
                  className={`select-gray `}
                  onChange={e => handleSelected(e)}
                  isSearchable={false}
                  isClearable={false}
                  styles={customStyles}
                />
                <input type="number" placeholder="Amount" />
              </div>
            </div>
            <div className="row mb-10 borderBottom">
              <span>
                <h5>Expiration Date</h5>
                <p>You can schedule this listing to buy able at due date</p>
              </span>
              <div className="input-div">
                <Select
                  defaultValue={options1[0]}
                  formatOptionLabel={FormatsortOptionLabel}
                  options={options1}
                  instanceId="chainSelect4"
                  className={`select-gray `}
                  onChange={e => handleSelected(e)}
                  isSearchable={false}
                  isClearable={false}
                  styles={customStyles}
                />
                <input type="time" placeholder="09:06 PM" />
              </div>
            </div>
            <FilledButton
              label={'Start Your Auction'}
              icon={<i className="fas fa-angle-right"></i>}
              iconPosition="end"
            />
          </div>
        </div>
      </Bounce>
    </div>
  );
};
export default AuctionModal;
