import { Link, useHistory } from 'react-router-dom';
import FilledButton from 'components/Buttons/FilledButton';
import { makeStyles } from '@material-ui/core/styles';
import BuyModal from 'components/modal/buyModal/BuyModal';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    paddingTop: 30,
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      paddingTop: 30,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingTop: 0,
    },
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: '30px',
    width: '100%',
    '& a': {
      fontSize: 14,
      color: '#2892F3',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 10,
      },
    },
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      padding: '5px 0px',
    },
  },

  header: {
    fontSize: 30,
    color: '#000',
    textAlign: 'left',
    lineHeight: '50px',
    position: 'relative',
    fontWeight: 600,
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      fontSize: 20,
    },
  },

  productWrapper: {
    marginBottom: theme.spacing(4),
    width: '100%',
    margin: 'auto',
    cursor: 'pointer',
    // boxShadow: '3px 3px 5px #777',
    // borderRadius: 18,
    overflow: 'hidden',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {},
    '& .top': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: 20,
      position: 'relative',
      borderRadius: 10,
      overflow: 'hidden',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        marginBottom: 5,
      },
      '& img': {
        width: '100%',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          width: '130%',
        },
      },
      '& .topLeft': {
        position: 'absolute',
        top: 20,
        left: 0,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          top: 0,
        },
        '& span': {
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          fontSize: 12,
          color: '#004AAD',
          background: '#13CF8F',
          border: '2px solid #ffffff55',
          borderLeft: 'none',
          padding: '8px 20px',
          borderRadius: '0 50px 50px 0',
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            padding: '5px 10px',
          },
          '& p': {
            color: '#fff',
            fontSize: 12,
            marginLeft: 7,
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
              fontSize: 10,
            },
          },
          '& h3': {
            color: '#fff',
            fontSize: 20,
            marginLeft: 7,
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
              fontSize: 14,
            },
          },
        },
      },
    },
    '& .row': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0px 0px',
      },
    },

    '& .title': {
      padding: '5px 0px',
      width: '28%',
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        width: '100%',
      },
      '& h1': {
        color: '#292A36',
        fontSize: 20,
        marginBottom: 10,
        lineHeight: 1,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 20,
          textAlign: 'left',
        },
      },
      '& p': {
        lineHeight: 1.7,
        color: '#6B7380',
        display: 'flex',
        alignItems: 'center',
        fontFamily: "'Antic Didone', serif",
        marginBottom: 10,
        '& img': {
          marginRight: 7,
        },
      },
    },

    '& .state': {
      padding: '5px 10px',
      width: '40%',
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        width: '100%',
        padding: '5px 0px',
      },
      '& ul': {
        background: '#F5F6F8',
        padding: '8px 20px',
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'row',
        width: '100%',
        listStyle: 'none',
        boxShadow: 'inset 0px 4.67924px 4.67924px rgba(0, 0, 0, 0.05)',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {},
        '& li': {
          lineHeight: 1.7,
          color: '#6B7380',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          fontFamily: "'Antic Didone', serif",
          width: '33%',
          '& img': {
            marginRight: 7,
          },
          '& h4': {
            color: '#004AAD',
            fontSize: 14,
          },
          '& p': {
            color: '#000',
            fontSize: 12,
            textAlign: 'center',
            '@media screen and (max-width: 768px) and (orientation: portrait)': { fontSize: 10 },
          },
        },
      },
    },
    '& .footer': {
      padding: '5px 0px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      width: '30%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        width: '100% !important',
      },
      '& span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
      },
      '& h4': {
        color: '#000',
        fontSize: '24px',
        marginBottom: 10,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 22,
        },
      },
      '& p': {
        lineHeight: 1.7,
        fontSize: 18,
        color: '#6B7380',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 10,
        },
      },
    },
  },
}));

const PropertyDay = () => {
  const classes = useStyles();
  const [showBuyModal, setShowBuyModal] = useState(false);
  const history = useHistory();
  return (
    <>
      <div className={classes.root}>
        <div className={classes.top}>
          <h1 className={classes.header}>Property of the Day</h1>
          <Link to="/detail/21">More Info</Link>
        </div>
        <div className={classes.content}>
          <div className={classes.productWrapper}>
            <div className="top">
              <img src="/assets/images/image_01.png" alt="" onClick={() => history.push('/detail/21')} />
              <div className="topLeft">
                <span>
                  <h3>$5,700 USD*</h3>
                  <p>starting price</p>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="title">
                <p>
                  <img src="assets/images/tag.svg" alt="" />
                  Cannes, France
                </p>
                <h1>Beautiful 5-Bedroom Villa
                  near the Beach</h1>
              </div>
              <div className="state">
                <ul>
                  <li>
                    <h4>36%</h4>
                    <p>Expected Return</p>
                    <img src="/assets/images/coins.svg" alt="" />
                  </li>
                  <li>
                    <h4>5.20%</h4>
                    <p>Dividend Yield</p>
                    <img src="/assets/images/arrow_01.svg" alt="" />
                  </li>
                  <li>
                    <h4>Monthly</h4>
                    <p>Dividend Frequency</p>
                    <img src="/assets/images/calenter_01.svg" alt="" />
                  </li>
                </ul>
              </div>
              <div className="footer">
                <span>
                  <p>Price of Real Estate* </p>
                  <h4>5,700,000 USD</h4>
                </span>
                <FilledButton label={'BUY NOW'} handleClick={() => setShowBuyModal(true)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <BuyModal showModal={showBuyModal} setShowModal={setShowBuyModal} />
    </>
  );
};

export default PropertyDay;
