import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 50,
    background: '#fff',
    width: '100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      paddingTop: 40,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingBottom: 10,
    [theme.breakpoints.only('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& img': {
      width: '90%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        height: '80vw',
        objectFit: 'cover',
      },
    },
  },

  header: {
    fontSize: 35,
    color: '#000',
    textAlign: 'center',
    lineHeight: '50px',
    position: 'relative',
    marginBottom: 10,
    width: '100%',
    fontWeight: 600,
    '@media (max-width: 768px)': {
      fontSize: '1.5em',
    },
  },
}));

interface PropsType {
  map?: string;
}

const Location = ({ map }: PropsType) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <h1 className={classes.header}>Location</h1>
        <div className={classes.content}>
          <img src={map} alt="" />
        </div>
      </div>
    </>
  );
};

export default Location;
