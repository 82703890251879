import './Price.scss';
import InputField from 'components/Forms/InputField';
import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  formWrapper: {
    width: '48%',
  },

  myInput: {
    color: '#333',
  },
}));

interface PropsType {
  setMinPrice?: any;
  setMaxPrice?: any;
}
const Price = ({ setMinPrice, setMaxPrice}) => {
  const classes = useStyles();

  return (
    <div className="Price">
      <h2>Real Estate NFT price</h2>
      <div className="row">
        <InputField
          name="max"
          className={classes.myInput}
          wrapperClass={classes.formWrapper}
          type="number"
          endIcon={<img src="/assets/images/arrow_02.svg" alt="" />}
          placeholder="No Min"
          onChangeData={val => {setMinPrice(val)}}
        />

        <InputField
          name="max"
          className={classes.myInput}
          wrapperClass={classes.formWrapper}
          type="number"
          endIcon={<img src="/assets/images/arrow_02.svg" alt="" />}
          placeholder="No Max"
          onChangeData={val => {setMaxPrice(val)}}
        />
      </div>

      <h2>Expected Dividend Yield</h2>
      <div className="row">
        <InputField
          name="max"
          className={classes.myInput}
          wrapperClass={classes.formWrapper}
          type="number"
          endIcon={<img src="/assets/images/arrow_02.svg" alt="" />}
          placeholder="No Min %"
          onChangeData={val => {}}
        />

        <InputField
          name="max"
          className={classes.myInput}
          wrapperClass={classes.formWrapper}
          type="number"
          endIcon={<img src="/assets/images/arrow_02.svg" alt="" />}
          placeholder="No Max %"
          onChangeData={val => {}}
        />
      </div>
      <h2>Expected Return after 5 years</h2>
      <div className="row">
        <InputField
          name="max"
          className={classes.myInput}
          wrapperClass={classes.formWrapper}
          type="number"
          endIcon={<img src="/assets/images/arrow_02.svg" alt="" />}
          placeholder="No Min %"
          onChangeData={val => {}}
        />

        <InputField
          name="max"
          className={classes.myInput}
          wrapperClass={classes.formWrapper}
          type="number"
          endIcon={<img src="/assets/images/arrow_02.svg" alt="" />}
          placeholder="No Max %"
          onChangeData={val => {}}
        />
      </div>
    </div>
  );
};
export default Price;
