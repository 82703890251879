import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 50,
    width: '100%',
    background: '#F8F9FB',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      paddingBottom: 30,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingBottom: 10,
    [theme.breakpoints.only('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },

  left: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '50%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      width: '100%',
    },
    // '& p': {
    //   color: '#000',
    //   fontSize: 14,
    //   width: '90%',
    //   '@media screen and (max-width: 768px) and (orientation: portrait)': {
    //     fontSize: 12,
    //   },
    // },
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '50%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      width: '100%',
    },
  },
  wrapper: {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    width: '100%',
    '& img': {
      marginBottom: 20,
      height: 50,
    },
    '& h3': {
      marginBottom: 20,
      fontSize: 16,
      fontWeight: 600,

    },
    '& p': {
      marginBottom: 20,
      color: '#BABABA',
      textAlign: 'center',
      lineHeight: 2,
      width: '90%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 12,
      },
    },
  },
  header: {
    fontSize: 35,
    color: '#000',
    textAlign: 'center',
    lineHeight: '50px',
    position: 'relative',
    marginBottom: 40,
    width: '100%',
    fontWeight: 600,
    '@media (max-width: 768px)': {
      fontSize: '1.5em',
    },
  },
}));

const DeveloperManager = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <h1 className={classes.header}>Developer & Property Manager</h1>
        <div className={classes.content}>
          <div className={classes.left}>
            <div className={classes.wrapper}>
              <img src="/assets/images/titan.svg" alt="" />
              <h3>Developer: {data.developer}</h3>
              <p>
                This company is fictitious and for illustrative purposes only. Titan is a multinational real estate development company based in London, UK. The company operates internationally and provides real estate development and management services.
              </p>
            </div>
          </div>
          <div className={classes.right}>
            <div className={classes.wrapper}>
              <img src="/assets/images/chill.svg" alt="" />
              <h3>Property Manager: {data.propertyManager}</h3>
              <p>
                This company is fictitious and for illustrative purposes only. Founded in 2012, Bed & Chill specializes in full-service property management services. The company’s mission is to help landlords save time and turn their house or apartment into a profitable revenue stream.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeveloperManager;
