import { makeStyles } from '@material-ui/core/styles';
import RecentProposalsTable from './RecentProposalsTable';

const myData = [
  {
    date: '20-07-22',
    id: 5238,
    name: 'Villa Ibiza',
    typeOfProperty: 'Holiday Home',
    yesNo: '80/20',
    owners: '100 of 300',
    proposal: 'Increase rent by 12.5%',
  },
  {
    date: '1-07-22',
    id: 2630,
    name: 'Apart. Berlin',
    typeOfProperty: 'Holiday Home',
    yesNo: '200/50',
    owners: '250 of 600',
    proposal: 'Sell the property for $875,000',
  },
];
const cols = ['Date', 'NFT ID', 'Name', 'Property Type', 'YES/NO', 'No. of Owners', 'Proposal'];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    '@media screen and (max-width: 450px) and (orientation: portrait)': {
      paddingBottom: 10,
    },
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',

    width: '100%',
    '& a': {
      fontSize: 14,
      color: '#2892F3',
    },
    '@media screen and (max-width: 450px) and (orientation: portrait)': {
      padding: '5px 0px',
    },
  },

  header: {
    fontSize: 30,
    color: '#000',
    textAlign: 'left',
    lineHeight: '50px',
    position: 'relative',
    '@media (max-width: 576px)': {
      fontSize: 20,
      lineHeight: 1.1,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
    },
  },
}));

const RecentProposals = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <h1 className={classes.header}>Recent Proposals</h1>
      </div>
      <div className={classes.content}>
        <RecentProposalsTable columns={cols} rows={myData} type={undefined} />
      </div>
    </div>
  );
};

export default RecentProposals;
