import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
import LeaderBoardTable from './LeaderBoardTable';
import Expand from 'react-expand-animated';
import { useState } from 'react';
import { Link } from 'react-router-dom';
const myData = [
  {
    img: '/assets/avatars/user_01.png',
    name: 'Gohard',
    fractionsOwned: '2.4K',
    currentValue: '$1.2M',
    performance: '+20%',
    dividendsReceived: '+ $100,000',
    tradingVolume: '$2.5M',
    totalProfitLoss: '+ $300,000',
  },
  {
    img: '/assets/avatars/user_02.png',
    name: 'June27x',
    fractionsOwned: '1.6K',
    currentValue: '$780K',
    performance: '+30%',
    dividendsReceived: '+ $80,000',
    tradingVolume: '$1.8M',
    totalProfitLoss: '+ $260,000',
  },
  {
    img: '/assets/avatars/user_03.png',
    name: 'MoneyMaster_',
    fractionsOwned: '2.2K',
    currentValue: '$920K',
    performance: '+15%',
    dividendsReceived: '+ $110,000',
    tradingVolume: '$2.3M',
    totalProfitLoss: '+ $230,000',
  },
  {
    img: '/assets/avatars/user_04.png',
    name: 'SuperNova',
    fractionsOwned: '1.3K',
    currentValue: '$875K',
    performance: '+25%',
    dividendsReceived: '+ $40,000',
    tradingVolume: '$1.5M',
    totalProfitLoss: '+ $215,000',
  },
  {
    img: '/assets/avatars/user_05.png',
    name: 'MikelovesNFT',
    fractionsOwned: '1K',
    currentValue: '$720K',
    performance: '+20%',
    dividendsReceived: '+ $70,000',
    tradingVolume: '$1.2M',
    totalProfitLoss: '+ $190,000',
  },
];
const cols = [
  'Username',
  'Fractions Owned',
  'Current Value',
  'Performance',
  'Dividends Received',
  'Trading Volume',
  'Total Profit/Loss',
];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    paddingTop: 30,
    paddingBottom: 20,
    '@media screen and (max-width: 450px) and (orientation: portrait)': {},
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    '& a': {
      fontSize: 14,
      color: '#2892F3',
    },
    '@media screen and (max-width: 450px) and (orientation: portrait)': {
      padding: '5px 10px',
    },
  },

  header: {
    fontSize: 30,
    color: '#000',
    fontWeight: 600,
    textAlign: 'left',
    lineHeight: '50px',
    position: 'relative',
    marginBottom: 10,
    '@media (max-width: 576px)': {
      fontSize: 20,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      padding: 0,
    },
  },
  node: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 10,

    '& .num': {
      fontSize: 12,
      color: '#000',
      fontWeight: 600,
      paddingTop: 10,
      paddingRight: 10,
    },
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'flex-start',
    background: '#F7F9FA',
    flexWrap: 'wrap',
    padding: 5,
    borderRadius: 10,
    paddingTop: 10,
    paddingBottom: 10,
    '& .nft': {
      display: 'flex',
      alignItems: 'center',
      '& img': {
        height: 40,
        width: 40,
      },
      '& h3': {
        fontSize: 14,
        color: '#000',
        marginLeft: 10,
      },
    },
    '& .profit': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'flex-end',

      '& p': {
        fontSize: 12,
        color: '#000',
        fontWeight: 600,
        '& span': {
          color: '#13CF8F',
        },
      },
    },
    '& ul': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: 0,
      listStyle: 'none',
      marginTop: 10,
      '& li': {
        marginRight: 5,
        '& span': {
          color: '#13CF8F',
          fontWeight: 600,
          isplay: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'center',
          '& h5': {
            color: '#000',
            fontSize: 12,
            textAlign: 'left',
          },
          '& h6': {
            color: '#93989A',
            fontSize: 8,
            textAlign: 'left',
          },
        },
      },
    },
  },
}));

const Leaderboards = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);

  const styles = {
    open: { width: '100%' },
    close: { width: '100%' },
  };
  const transitions = ['height', 'opcity', 'background'];

  const [faqId, setFaqId] = useState(-1);
  const handleClick = (id: number) => {
    if (faqId !== -1) {
      if (faqId === id) {
        setFaqId(-1);
      } else {
        setFaqId(id);
      }
    } else {
      setFaqId(id);
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <h1 className={classes.header}>All-Time Leaderboards</h1>
        <Link to="">View All</Link>
      </div>
      <div className={classes.content}>
        {isMobileOrTablet ? (
          <>
            {myData.map((d, k) => (
              <div
                className={classes.node}
                key={k}
                onClick={() => {
                  handleClick(k);
                }}
              >
                <p className="num">{k + 1}</p>
                <div className={classes.row}>
                  <div className="nft">
                    <img src={d.img} alt="" onClick={() => history.push('/profile')} />
                    <h3>{d.name}</h3>
                  </div>
                  <div className="profit">
                    <p>Profit/Loss</p>
                    <p>
                      <span>{d.totalProfitLoss}</span>
                    </p>
                  </div>
                  <Expand open={faqId === k} duration={300} styles={styles} transitions={transitions}>
                    <ul>
                      <li>
                        <span>
                          <h5>{d.fractionsOwned}</h5>
                          <h6>NFT Owned</h6>
                        </span>
                      </li>

                      <li>
                        <span>
                          <h5>{d.currentValue}</h5>
                          <h6>Current Value</h6>
                        </span>
                      </li>

                      <li>
                        <span>
                          <h5>{d.performance}</h5>
                          <h6>Performance</h6>
                        </span>
                      </li>

                      <li>
                        <span>
                          <h5>{d.dividendsReceived}</h5>
                          <h6>Dividends Received</h6>
                        </span>
                      </li>

                      <li>
                        <span>
                          <h5>{d.tradingVolume}</h5>
                          <h6>Trading Volume</h6>
                        </span>
                      </li>
                    </ul>
                  </Expand>
                </div>
              </div>
            ))}
          </>
        ) : (
          <LeaderBoardTable columns={cols} rows={myData} type={undefined} />
        )}
      </div>
    </div>
  );
};

export default Leaderboards;
