import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Container from '../../components/Layout/Container';
import InputField from '../../components/Forms/InputField';
import ErrorAlert from '../../components/Widgets/ErrorAlert';
import toast from 'react-hot-toast';
import { useStyles } from './style';
import {useMoralis} from "react-moralis";

const NewProposal = () => {
  const classes = useStyles();

  const { isAuthenticated } = useMoralis();
  const [formSubmit, setFormSubmit] = useState(false);
  const [name, setName] = useState(null);
  const [location, setLocation] = useState(null);
  const [id, setId] = useState(null);
  const [description, setNFTDescription] = useState(null);
  const [subject, setSubject] = useState(null);

  const onSubmit = async data => {
    setFormSubmit(true);
    if (!isAuthenticated) {
      toast.error('Please connect your wallet correctly!');
      return;
    }

    if (!name || !location || !description || !id || !subject) {
      return;
    }
  };

  const [loginStatus, setLoginStatus] = useState(false);
  useEffect(() => {
      setLoginStatus(isAuthenticated);
  }, [isAuthenticated]);

  function updatePreview(item) {}
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <h1 className={classes.title}>New Proposal Submission</h1>
      </div>
      <Container className={classes.container}>
        <h3>Do you have any suggestions?</h3>
        <h2>Use the form below to submit.</h2>
        <form className={classes.myForm} noValidate onSubmit={onSubmit}>
          <Box className={classes.box}>
            <InputField
              name="name"
              disabled={!isAuthenticated}
              className={classes.myInput}
              error={formSubmit && !name}
              wrapperClass={classes.formWrapper}
              label="PROPERTY NAME"
              placeholder="Villa Ibiza"
              onChangeData={val => {
                setName(val);
                updatePreview({ name: val });
              }}
            />
            <ErrorAlert title="User Name Field is required !" show={formSubmit && !name} />
          </Box>
          <Box className={classes.box}>
            <InputField
              name="location"
              disabled={!isAuthenticated}
              className={classes.myInput}
              error={formSubmit && !location}
              wrapperClass={classes.formWrapper}
              label="LOCATION"
              placeholder="Ibiza"
              onChangeData={val => {
                setLocation(val);
              }}
            />
            <ErrorAlert title="Location Field is required !" show={formSubmit && !location} />
          </Box>
          <Box className={classes.box}>
            <InputField
              name="subject"
              disabled={!isAuthenticated}
              className={classes.myInput}
              error={formSubmit && !subject}
              wrapperClass={classes.formWrapper}
              label="TITLE"
              placeholder="Your Subject"
              onChangeData={val => {
                setSubject(val);
              }}
            />
            <ErrorAlert title="Location Field is required !" show={formSubmit && !subject} />
          </Box>
          <Box className={classes.box}>
            <InputField
              name="id"
              disabled={!isAuthenticated}
              className={classes.myInput}
              error={formSubmit && !id}
              wrapperClass={classes.formWrapper}
              label={'NFT ID'}
              placeholder="5238"
              onChangeData={val => {
                setId(val);
              }}
            />
            <ErrorAlert title="Email Address Field is required !" show={formSubmit && !id} />
          </Box>
          <Box marginTop={0} style={{ width: '100%' }}>
            <InputField
              name="description"
              disabled={!isAuthenticated}
              wrapperClass={classes.formWrapper}
              label="DESCRIPTION"
              isMulti
              placeholder="Type your message…."
              className={classes.myInput}
              onChangeData={val => {
                setNFTDescription(val);
                updatePreview({ description: val });
              }}
              error={formSubmit && !description}
            />
            <ErrorAlert title="NFT Description Field is required !" show={formSubmit && !description} />
          </Box>
        </form>
        <p>We will review your proposal within 3-5 business days.</p>
        <div className={classes.row}>
          <button className="submit">Submit</button>
          <button className="back">Back</button>
        </div>
      </Container>
    </div>
  );
};

export default NewProposal;
