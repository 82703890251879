
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import InputField from 'components/Forms/InputField';
import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import FilledButton from 'components/Buttons/FilledButton';
import { numberToString } from 'utils';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const chartData = [
  {
    name: 'Today',
    uv: 0,
    pv: 0,
  },
  {
    name: 'Year 1',
    uv: 100,
    pv: 1,
  },
  {
    name: 'Year 2',
    uv: 200,
    pv: 2,
  },
  {
    name: 'Year 3',
    uv: 300,
    pv: 3,
  },
  {
    name: 'Year 4',
    uv: 400,
    pv: 4,
  },
  {
    name: 'Year 5',
    uv: 500,
    pv: 5,
  },
];
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#F8F9FB',
    paddingTop: 50,
    paddingBottom: 50,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: 10,
      paddingTop: 50,
      paddingBottom: 50,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    // maxWidth: 1440,
    paddingBottom: 0,

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      paddingBottom: 10,
    },
  },
  top: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    maxWidth: 1440,
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      justifyContent: 'center',
      padding: 10,
    },
    '& .search': {
      '& span': {
        position: 'relative',
        border: '1px #555 solid',
        padding: 8,
        borderRadius: 30,
        display: 'flex',
        alignItems: 'center',
        '& button': {
          position: 'absolute',
          right: 0,
          border: 'none',
          background: '#ffffff00',
        },
        '& input': {
          border: 'none',
          background: '#ffffff00',
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
    '& span': {
      marginRight: 10,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        marginRight: 0,
        marginBottom: 10,
      },
      '& .reset': {
        border: 'none',
        background: '#ffffff00',
        color: '#6864F9',
        cursor: 'pointer',
      },
    },
  },
  left: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',

    // paddingBottom: '30px',
    paddingRight: 20,
    width: '70%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      width: '100%',
      paddingRight: 0,
    },
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      listStyle: 'none',
      flexWrap: 'wrap',
      padding: 20,
      width: '100%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        padding: 10,
      },
      '& li': {
        width: 'calc(50% - 40px)',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          width: 'calc(50% - 10px)',
        },
      },
    },
    '& .chart': {
      width: '100%',
      height: 400,
      padding: 20,
      position: 'relative',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        padding: 30,
      },
      '& p': {
        color: '#6B7380',
      },
      '& .text1': {
        position: 'absolute',
        bottom: 80,
        fontWeight: 600,
        zIndex: 1,
      },
      '& .text2': {
        position: 'absolute',
        top: 20,
        right: 30,
        fontWeight: 600,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          top: 25,
          right: 30,
        },
      },
      '& .yAxis': {
        display: 'none',
      },
      '& .recharts-responsive-container': {
        overflow: 'visible',
      },
      '& .recharts-surface': {
        overflow: 'visible',
      },
      '& text': {
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 12,
        },
      },
    },
  },
  right: {
    // display: 'inline-block',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: 0,
    // top: 0,
    // bottom: 0,
    width: '30%',
    height: 'auto',
    // height: 620,
    // marginTop: 'auto',
    // marginBottom: 'auto',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      width: '100%',
    },
  },

  header: {
    fontSize: '2vw',
    color: '#000',
    textAlign: 'center',
    lineHeight: '50px',
    position: 'relative',
    marginBottom: 10,
    fontWeight: 600,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      fontSize: 35,
    },
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      fontSize: '1.5em',
      width: 300,
      lineHeight: 1.5,
    },
  },

  masonry: {
    display: 'flex',
    margin: theme.spacing(5, 0),
  },
  gridColumn: {
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0),
    },
  },

  formWrapper: {
    marginBottom: 20,
    width: '100%',
    '& h3': {
      color: '#292A36',
      fontSize: 14,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 10,
      },
    },
  },
  myInput: {
    color: '#6B7380',
    boxShadow: 'none !important',
    background: '#fff!important',
    borderRadius: 7,
    minHeight: 40,
    padding: 0,
    '& input': {
      padding: 5,
    },
    '& .fas': {
      color: '#000',
      fontSize: 14,
      paddingRight: 7,
    },
  },
  panel: {
    width: '100%',
    padding: '20px 20px',
    paddingLeft: 40,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderLeft: '1px #ddd solid',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      paddingTop: 0,
      paddingBottom: 0,
      border: 'none',
      padding: '20px 10px',
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        paddingTop: 5,
        paddingBottom: 10,
        // width: 'calc(50% - 10px)',
      },
      '& p': {
        color: '#292A36',
        textAlign: 'left',
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 10,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 12,
          marginBottom: 0,
        },
      },
      '& h5': {
        color: '#292A36',
        textAlign: 'left',
        fontSize: 14,
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 16,
        },
        '& p': {
          fontSize: 12,
          marginLeft: 7,
          marginBottom: 0,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 12,
          },
        },
      },
      '& h2': {
        color: '#2892F3',
        textAlign: 'left',
        fontSize: 30,
        fontWeight: 700,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {},
      },
    },
    '& .mob1': {
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        width: '60%',
      },
    },
    '& .mob2': {
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        width: '40%',
      },
    },
  },
  buyBtn: {
    fontSize: 20,
    width: 200,
    height: 50,
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      width: '100%',
    },
  },
  smalTxt: {
    color: '#777',
    fontSize: 14,
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      textAlign: 'center',
    },
  },
}));

interface PropsType {
  data?: any;
  setShowBuy?: any;
}
const HowMuch = ({ data, setShowBuy }: PropsType) => {
  const classes = useStyles();
  const [initialAmount, setInitialAmount] = useState(20000);
  const [annual, setAnnual] = useState(2);
  const [yeld, setYield] = useState(data?.howmuch.netDividend);
  const [period, setPeriod] = useState(5);
  const [formSubmit, setFormSubmit] = useState(false);
  const [chartsData, setChartsData] = useState([
    {
      name: 'Today',
      uv: 0,
      pv: 0,
    }]);
  const [TAAR, setTAAR] = useState(0);
  const [TRI, setTRI] = useState(0);
  const [ECA, setECA] = useState(0);
  const [TR, setTR] = useState(0);
  const [TEVY, setTEVY] = useState(0);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);

  const handleCalculate = async () => {

    setTAAR(yeld + annual)
    setTRI((initialAmount * period) * (yeld / 100))
    setECA((initialAmount * period) * (annual / 100))
    setTR(((initialAmount * period) * (yeld / 100)) + ((initialAmount * period) * (annual / 100)))
    setTEVY((((initialAmount * period) * (yeld / 100)) + ((initialAmount * period) * (annual / 100))) + initialAmount)
  }
  
  const handleCalculateChartData = () => {
    setChartsData([
      {
        name: 'Today',
        uv: 0,
        pv: 0,
      }])
    let tev = (((initialAmount * period) * (yeld / 100)) + ((initialAmount * period) * (annual / 100))) + initialAmount;
    for (let x = 0; x < period; x++) {
      let chart =
      {
        name: 'Year ' + (x + 1),
        uv: ((tev / period) * (x + 1)),
        pv: x + 1,
      }
      setChartsData(chartsData => [...chartsData, chart])
    }
  }
  useEffect(() => {
    handleCalculateChartData()
  }, [TEVY])

  useEffect(() => {
    handleCalculate()
  }, [annual, period, yeld, initialAmount, TAAR])

  return (
    <>
      <div className={classes.root}>
        <div className={classes.top}>
          <h1 className={classes.header}>How much can you earn with this Property?*</h1>
        </div>
        <div className={classes.content}>
          <div className={classes.left}>
            <ul>
              <li>
                <InputField
                  name="name"
                  type="number"
                  className={classes.myInput}
                  error={formSubmit && !initialAmount}
                  wrapperClass={classes.formWrapper}
                  label={'Initial Amount'}
                  placeholder="USD 20,000"
                  onChangeData={val => {
                    setInitialAmount(parseFloat(val));
                    setFormSubmit(false);
                  }}
                />
              </li>

              <li>
                <InputField
                  name="name"
                  disabled
                  className={classes.myInput}
                  error={formSubmit && !annual}
                  wrapperClass={classes.formWrapper}
                  endIcon={<i className="fas fa-lock"></i>}
                  label={'Expected Annual Appreciaton'}
                  placeholder="2%"
                  onChangeData={val => {
                    setAnnual(parseFloat(val));
                  }}
                />
              </li>

              <li>
                <InputField
                  name="name"
                  disabled
                  className={classes.myInput}
                  error={formSubmit && !yeld}
                  wrapperClass={classes.formWrapper}
                  endIcon={<i className="fas fa-lock"></i>}
                  label={<p>Net Dividend Yield</p>}
                  // value={data?.howmuch.netDividend}
                  placeholder={`${data?.howmuch.netDividend} %`}
                  onChangeData={val => {
                    setYield(parseFloat(val));
                  }}
                />
              </li>

              <li>
                <InputField
                  name="name"
                  disabled
                  className={classes.myInput}
                  error={formSubmit && !period}
                  wrapperClass={classes.formWrapper}
                  endIcon={<i className="fas fa-lock"></i>}
                  label={'Holding Period'}
                  placeholder="5 Years"
                  onChangeData={val => {
                    setPeriod(parseFloat(val));
                  }}
                />
              </li>
            </ul>
            <div className="chart">
              <p className="text1">USD {numberToString(initialAmount)}</p>
              <p className="text2">
                USD{' '}
                {TEVY !== 0
                  ? numberToString(
                    TEVY || 0,
                  )
                  : numberToString(data?.howmuch.totalExpect || 0)}
              </p>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={300}
                  height={400}
                  data={chartsData ? chartsData : chartData}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  {/* <CartesianGrid strokeDasharray="3 3" /> */}
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#2892F3" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#2892F3" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  {isMobileOrTablet ? (
                    <XAxis interval={0} dataKey="name" />
                  ) : (
                    <XAxis interval={'preserveStartEnd'} dataKey="name" />
                  )}
                  {/* <YAxis /> */}
                  {!isMobileOrTablet && <Tooltip />}
                  <Area
                    type="monotone"
                    dot={{ stroke: '#D9D9D9', strokeWidth: 3 }}
                    dataKey="uv"
                    stroke="#D9D9D9"
                    fillOpacity={0.8}
                    fill="url(#colorUv)"
                    baseLine={8}
                    strokeWidth={2}
                  />
                  <Area
                    type="monotone"
                    dataKey="pv"
                    stackId="1"
                    stroke="#D9D9D9"
                    fillOpacity={0.8}
                    fill="url(#colorUv)"
                    dot={{ stroke: '#D9D9D9', strokeWidth: 2 }}
                    strokeWidth={2}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className={classes.right}>
            <div className={classes.panel}>
              <span className="mob1">
                <p>Total Average Annualized Return</p>
                <h5>{TAAR !== 0 ? (TAAR).toFixed(2) : "Calculating..."}%</h5>
              </span>
              <span className="mob2">
                <p>Total Rental Income</p>
                <h5>
                  USD{' '}
                  {TRI !== 0
                    ? numberToString(TRI || 0)
                    : "Calculating..."}
                </h5>
              </span>
              <span className="mob1">
                <p>Expected Capital Appreciation</p>
                <h5>
                  USD{' '}
                  {ECA !== 0
                    ? numberToString(ECA || 0)
                    : "Calculating..."}
                </h5>
              </span>
              <span className="mob2">
                <p>Total Return </p>
                <h5>
                  USD{' '}
                  {TR !== 0
                    ? numberToString(TR || 0)
                    : "Calculating..."}{' '}
                  <p> ({(((((initialAmount * annual * period) + (initialAmount * yeld * period)) - initialAmount) / initialAmount)).toFixed(2)}%)</p>
                </h5>
              </span>
              <span
                style={{
                  marginTop: isMobileOrTablet ? 20 : 0,
                  width: isMobileOrTablet ? '100%' : 'auto',
                  alignItems: isMobileOrTablet ? 'center' : 'flex-start',
                }}
              >
                <p style={{ color: '#6B7380', fontWeight: 600 }}>Total Expected Value After 5 Years</p>
                <h2>
                  USD{' '}
                  {TEVY !== 0
                    ? numberToString(
                      TEVY
                    )
                    : "Calculating..."}
                </h2>
              </span>
            </div>
          </div>
        </div>
        <div className={classes.top}>
          <FilledButton
            className={classes.buyBtn}
            size="large"
            label={'BUY NOW'}
            handleClick={() => setShowBuy(true)}
          />
        </div>
        <p className={classes.smalTxt}>
          This calculator is for illustrative purposes only. Buying a real-estate NFT carries risk and you may not
          receive the anticipated returns.
        </p>
      </div>
    </>
  );
};

export default HowMuch;
