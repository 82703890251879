import { makeStyles, useTheme } from '@material-ui/core/styles';
import PayoutTable from './PayoutTable';
import { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';

const myData = [
  {
    id: 3,
    img: '/assets/images/image_02.png',
    name: 'Modern 3-Bedroom Family Home',
    date: '5-07-22',
    price: '$36,250',
    owned: '50/1000',
    payout: '$250.12',
    status: 'Pending',
  },
  {
    id: 4,
    img: '/assets/images/image_03.png',
    name: 'Luxury 2-Bedroom Condo',
    date: '4-07-22',
    price: '$2,800',
    owned: '5/1000',
    payout: '$9.10',
    status: 'Pending',
  },
  {
    id: 5,
    img: '/assets/images/image_04.png',
    name: 'Rare 3-Bedroom Penthouse',
    date: '2-07-22',
    price: '$14,400',
    owned: '6/1000',
    payout: '$97.45',
    status: 'Paid',
  },
  {
    id: 6,
    img: '/assets/images/image_05.png',
    name: 'Luxury 5-Bedroom Villa',
    date: '2-07-22',
    price: '$7,000',
    owned: '1/1000',
    payout: '$30.91',
    status: 'Paid',
  },
  {
    id: 7,
    img: '/assets/images/image_06.png',
    name: '1-Bedroom Studio Apartment',
    date: '1-07-22',
    price: '$17,200',
    owned: '80/1000',
    payout: '$81.12',
    status: 'Paid',
  },
];
const cols = ['Name', 'Date', 'Price', 'NFTs Owned', 'Payout', 'Status', ''];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 50,
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      paddingBottom: 10,
      paddingTop: 0,
    },
  },
  top: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingBottom: '30px',
    width: '100%',
    '& a': {
      fontSize: 14,
      color: '#2892F3',
    },
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      padding: '5px 20px',
    },
    '& button': {
      fontSize: 14,
      border: '1px #ddd solid',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 50,
      cursor: 'pointer',
      background: '#ffffff00',
      padding: '10px 30px',
      fontWeight: 600,
      '@media (max-width: 576px)': {},
      '& img': {
        marginRight: 20,
      },
    },
  },

  header: {
    fontSize: 32,
    color: '#000',
    textAlign: 'left',
    lineHeight: '50px',
    fontWeight: 600,
    position: 'relative',
    fontFamily: "'Outfit', sans-serif",
    '@media (max-width: 576px)': {
      fontSize: 25,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
    },
  },
  pagenation: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    '@media (max-width: 576px)': {
      display: 'none',
    },

    '& button': {
      fontSize: 14,
      border: '1px #aaa solid',
      width: 35,
      height: 35,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#2892F3',
      borderRadius: 50,
      cursor: 'pointer',
      '@media (max-width: 576px)': {
        width: 25,
        height: 25,
      },
    },
    '& .page': {
      width: 35,
      height: 35,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 50,
      color: '#fff',
      margin: 10,
      transition: 'all 0.3s ease',
      cursor: 'pointer',
      '@media (max-width: 576px)': {
        width: 25,
        height: 25,
        margin: 5,
      },
    },
  },

  node: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 10,
    '& .num': {
      fontSize: 12,
      color: '#000',
      fontWeight: 600,
      paddingTop: 10,
      paddingRight: 10,
    },
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'flex-start',
    background: '#F7F9FA',
    flexWrap: 'wrap',
    padding: 5,
    borderRadius: 10,
    '& .nft': {
      display: 'flex',
      alignItems: 'center',
      width: '70%',
      '& img': {
        height: 40,
        width: 40,
        borderRadius: 40,
      },
      '& h3': {
        fontSize: 12,
        color: '#000',
        marginLeft: 10,
        textAlign: 'left',
      },
    },
    '& .profit': {
      width: '30%',
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
      justifyContent: 'center',

      '& p': {
        fontSize: 10,
        color: '#000',
        fontWeight: 600,
        textAlign: 'end',
      },
      '& h3': {
        fontSize: 12,
        color: '#000',
        marginLeft: 10,
        textAlign: 'end',
      },
    },
    '& ul': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: 0,
      listStyle: 'none',
      marginTop: 10,
      paddingLeft: 50,
      '& li': {
        marginRight: 5,
        width: 'fit-content',
        '& span': {
          color: '#13CF8F',
          fontWeight: 600,
          isplay: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'center',
          '& h5': {
            color: '#000',
            fontSize: 12,
            textAlign: 'left',
            width: '100%',
            '& span': {
              fontSize: 14,
              marginRight: 5,
            },
          },
          '& h6': {
            color: '#93989A',
            fontSize: 8,
            textAlign: 'left',
            width: '100%',
          },
        },
        '& button': {
          color: '#000',
          border: 'none',
          background: '#ffffff00',
          '& img': {
            height: 20,
          },
        },
      },
    },
  },
}));

const PayoutSection = () => {
  const classes = useStyles();

  const [page, setPage] = useState(0);

  const OnClick = (val: string) => {
    if (val === 'next') {
      if (page < 4) {
        setPage(page + 1);
      }
    }
    if (val === 'prev') {
      if (page > 0) {
        setPage(page - 1);
      }
    }
  };

  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);
  const history = useHistory();
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <h1 className={classes.header}>Payouts</h1>
      </div>
      <div className={classes.content}>
        {isMobileOrTablet ? (
          <>
            {myData.map((d, k) => (
              <div className={classes.node} key={k}>
                <p className="num">{k + 1}</p>
                <div className={classes.row}>
                  <div className="nft" onClick={() => history.push(`/detail/${d.id}`)}>
                    <img src={d.img} alt="" />
                    <h3>{d.name}</h3>
                  </div>
                  <div className="profit">
                    <p>Payout</p>
                    <h3>{d.payout}</h3>
                  </div>
                  <ul>
                    <li>
                      <span>
                        <h5>{d.owned}</h5>
                        <h6>NFTs Owned</h6>
                      </span>
                    </li>

                    <li>
                      <span>
                        <h5>{d.price}</h5>
                        <h6>Price</h6>
                      </span>
                    </li>

                    <li>
                      <span>
                        <h5>
                          {' '}
                          <span style={{ color: d.status === 'Paid' ? '#13CF8F' : '#F9CA5D' }}>●</span>
                          {d.status}
                        </h5>
                        <h6>Status</h6>
                      </span>
                    </li>
                    <li>
                      <button>
                        <img src="/assets/images/more_icon_01.svg" alt="" />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </>
        ) : (
          <PayoutTable columns={cols} rows={myData} type={undefined} />
        )}
      </div>

      <div className={classes.pagenation}>
        <button className="left" onClick={() => OnClick('prev')}>
          <i className="fas fa-arrow-left"></i>
        </button>
        {[0].map(d => (
          <div
            className="page"
            key={d}
            style={{
              background: page === d ? '#004AAD' : '#ffffff00',
              color: page === d ? '#fff' : '#000',
            }}
            onClick={() => setPage(d)}
          >
            {d + 1}
          </div>
        ))}
        <button className="left" onClick={() => OnClick('next')}>
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>
  );
};

export default PayoutSection;
