import { Switch } from '@material-ui/core';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    background: '#ffffff00',
    paddingTop: 50,
    paddingBottom: 127,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: 50,
    },
    '&.MuiSelect-icon': {},
  },
  top: {
    background: '#ffffff00',
    margin: theme.spacing(0, 2),
    width: 'calc(100% - 30px)',

    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: '1px #ddd solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    '& p': {
      fontSize: 14,
      color: '#93989A',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 10,
      },
    },
    '& .search': {
      '& span': {
        position: 'relative',
        border: '1px #555 solid',
        padding: 8,
        borderRadius: 30,
        display: 'flex',
        alignItems: 'center',

        '& button': {
          position: 'absolute',
          right: 0,
          border: 'none',
          background: '#ffffff00',
        },
        '& input': {
          border: 'none',
          background: '#ffffff00',
          width: 250,
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
    '& .select': {
      marginRight: 10,
      marginBottom: 20,
      '@media screen and (max-width: 678px) and (orientation: portrait)': {
        marginRight: 0,
        marginBottom: 10,
      },
      '& .reset': {
        border: 'none',
        background: '#ffffff00',
        color: '#6864F9',
        cursor: 'pointer',
      },
    },
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: '1px #eee solid',
    [theme.breakpoints.down('sm')]: {},
    '& p': {
      fontSize: 14,
      color: '#93989A',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 10,
      },
    },
  },

  title: {
    fontSize: 32,
    color: '#000',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 50,

    [theme.breakpoints.down('xs')]: {
      padding: 20,
    },
    '& .myTab': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      '& .tabList': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        '& .tab': {
          width: 'calc(50% - 5px)',
          fontSize: 16,
          fontWeight: 600,
          color: '#fff',
          padding: 10,
          transition: 'all 0.3s ease',
          cursor: 'pointer',
          background: '#BABABA',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 10,
        },
        '& .activeTab': {
          background: '#2892F3',
        },
      },
    },

    '& .tabContent': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      '& h5': {
        fontSize: 14,
        color: '#888',
        marginBottom: 20,
        marginTop: 20,
        fontWeight: 400,
        width: 200,
        textAlign: 'center',
      },
    },
  },
  masonry: {
    display: 'flex',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  gridColumn: {
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
    },
  },
  productDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: 454,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90vw',
    },
    '& h3': {
      fontSize: 30,
      color: '#292A36',
      fontWeight: 600,
    },
    '& h5': {
      fontSize: 12,
      color: '#888',
      marginBottom: 20,
      fontWeight: 400,
    },
  },
}));

export const AntSwitch = withStyles((theme: Theme) => ({
  root: {
    width: (props: { kind: string }) => (props.kind === 'small' ? 40 : 48),
    height: (props: { kind: string }) => (props.kind === 'small' ? 20 : 24),
    borderRadius: 20,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 3,
    color: theme.palette.primary.main,
    '&$checked': {
      transform: 'translateX(calc(100% + 2px))',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: (props: { kind: string }) => ({
    width: props.kind === 'small' ? 13 : 17,
    height: props.kind === 'small' ? 13 : 17,
    boxShadow: 'none',
  }),
  track: {
    borderColor: theme.palette.surface[2],
    borderRadius: 20,
    opacity: 1,
    backgroundColor: theme.palette.surface[2],
  },
  checked: {},
}))(Switch);

export default useStyles;
