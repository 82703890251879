import './priceModal.scss';
import Bounce from 'react-reveal/Bounce';
import { useEffect, useState } from 'react';
import OutlinedButton from 'components/Buttons/OutlinedButton';
import FilledButton from 'components/Buttons/FilledButton';
import InputField from 'components/Forms/InputField';
import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  formWrapper: {
    width: '48%',
  },

  myInput: {
    color: '#333',
  },
}));

interface Props {
  showModal: boolean;
  setShowtModal?: any;
  setMinPrice?: any;
  setMaxPrice?: any;
  reset: boolean;
  minPrice?: number;
  maxPrice?: number;
}
const PriceModal: React.FC<Props> = ({ showModal, setShowtModal, setMinPrice, setMaxPrice, reset, minPrice, maxPrice }) => {
  const classes = useStyles();
  const [isStart, setIsStart] = useState(false);
  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        setIsStart(true);
      }, 100);
    }
  }, [setIsStart, showModal]);
  const onClose = () => {
    setIsStart(false);
    setTimeout(() => {
      setShowtModal(false);
    }, 800);
  };

  const handleResetFields = () => {

    setMinPrice(0)
    setMaxPrice(0)
  }
  useEffect(() => {
    if (reset) {
      console.log(minPrice, "minPrice")

    }

  }, [reset])

  return (
    <div className={showModal === true ? 'priceModal active' : 'priceModal'}>
      <Bounce opposite when={isStart}>
        <div className="modelContent">
          <div className="connectWalletHeader">
            <h1 className="connectWalletTitle"> </h1>
            <button className="connectModalCloseButton" onClick={onClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="connectWalletWrapper">
            <h2>Real Estate NFT price</h2>
            <div className="row">
              <InputField
                name="max"
                className={classes.myInput}
                wrapperClass={classes.formWrapper}
                type="number"
                endIcon={<img src="/assets/images/arrow_02.svg" alt="" />}
                placeholder="No Min"
                value={minPrice !== 0 ? minPrice : ""}
                onChangeData={val => { setMinPrice(val) }}
              />

              <InputField
                name="max"
                className={classes.myInput}
                wrapperClass={classes.formWrapper}
                type="number"
                endIcon={<img src="/assets/images/arrow_02.svg" alt="" />}
                value={maxPrice !== 0 ? maxPrice : ""}
                placeholder="No Max"
                onChangeData={val => { setMaxPrice(val) }}
              />
            </div>

            <h2>Expected Dividend Yield</h2>
            <div className="row">
              <InputField
                name="max"
                className={classes.myInput}
                wrapperClass={classes.formWrapper}
                type="number"
                endIcon={<img src="/assets/images/arrow_02.svg" alt="" />}
                placeholder="No Min %"
                onChangeData={val => { }}
              />

              <InputField
                name="max"
                className={classes.myInput}
                wrapperClass={classes.formWrapper}
                type="number"
                endIcon={<img src="/assets/images/arrow_02.svg" alt="" />}
                placeholder="No Max %"
                onChangeData={val => { }}
              />
            </div>
            <h2>Expected Return after 5 years</h2>
            <div className="row">
              <InputField
                name="min"
                className={classes.myInput}
                wrapperClass={classes.formWrapper}
                type="number"
                endIcon={<img src="/assets/images/arrow_02.svg" alt="" />}
                placeholder="No Min %"
                onChangeData={val => { setMinPrice(val) }}
              />

              <InputField
                name="max"
                className={classes.myInput}
                wrapperClass={classes.formWrapper}
                type="number"
                endIcon={<img src="/assets/images/arrow_02.svg" alt="" />}
                placeholder="No Max %"
                onChangeData={val => { setMaxPrice(val) }}
              />
            </div>
            <div className="row">
              <OutlinedButton label="Reset" handleClick={() => handleResetFields()} />
              <FilledButton label={'Done'} handleClick={onClose} />
            </div>
          </div>
        </div>
      </Bounce>
    </div>
  );
};
export default PriceModal;
