import './makeModal.scss';
import Bounce from 'react-reveal/Bounce';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import FormatsortOptionLabel from 'components/Filter/FormatsortOptionLabel';
import OutlinedButton from 'components/Buttons/OutlinedButton';
import DateTimePickerField from 'components/DateTimePicker';
import FormatOptionLabelToken from '../FormatOptionLabelToken';
import FilledButton from 'components/Buttons/FilledButton';
interface Props {
  showModal: boolean;
  setShowModal?: any;
}
const handleSelected = e => {};
const customStyles = {
  control: base => ({
    ...base,
    fontSize: '0.8vw',
    fontWeight: 'bold',
    borderRadius: '50px',
    border: '1px solid #ddd !important',
    boxShadow: 'none',

    minWidth: '10vw',
    '&:focus': {
      border: '0 !important',
    },
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      fontSize: 14,
      minWidth: '30vw',
    },
  }),

  menu: (provided, state) => ({
    ...provided,
    border: '1px solid #ffffff13',
    // color: '#fff',
    color: '#93989A',
    padding: 0,
    background: '#fff',
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #aaa',
    padding: 3,
    // color: '#93989A',
    cursor: 'pointer',
    fontSize: '0.8vw',
    color: state.isSelected ? 'white' : '#93989A !important',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      fontSize: 14,
    },
    ':hover': {
      color: '#93989A !important',
    },
    ':active': {
      color: '#fff !important',
    },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

const MakeModal: React.FC<Props> = ({ showModal, setShowModal }) => {
  const [isStart, setIsStart] = useState(false);
  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        setIsStart(true);
      }, 100);
    }
  }, [setIsStart, showModal]);
  const onClose = () => {
    setIsStart(false);
    setTimeout(() => {
      setShowModal(false);
    }, 800);
  };
  const options = [
    { value: 0, label: '3 days', customAbbreviation: '0' },
    { value: 1, label: '15 days', customAbbreviation: '1' },
    { value: 2, label: '30 days', customAbbreviation: '2' },
  ];
  const [amount, setAmount] = useState(1);

  // const [mintCount, setMintCount] = useState(1);
  // const decreaseHandle = () => {
  //   if (mintCount > 0) {
  //     setMintCount(mintCount - 1);
  //   }
  // };
  // const increaseHandle = () => {
  //   if (mintCount < 10) {
  //     setMintCount(mintCount + 1);
  //   }
  // };
  const options1 = [
    { value: 'usd', label: 'USD', customAbbreviation: '0' },
    { value: 'eth', label: 'ETH', customAbbreviation: '1' },
    { value: 'weth', label: 'WETH', customAbbreviation: '2' },
  ];

  return (
    <div className={showModal === true ? 'makeModal active' : 'makeModal'}>
      <Bounce opposite when={isStart}>
        <div className="modelContent">
          <div className="connectWalletHeader">
            <h1 className="connectWalletTitle">Make an offer</h1>
            <button className="connectModalCloseButton" onClick={onClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="connectWalletWrapper">
            <div className="row">
              <h5>Offer amount</h5>
              <p>Balance 25,500 USD</p>
            </div>
            <div className="row">
              <Select
                defaultValue={options1[0]}
                formatOptionLabel={FormatOptionLabelToken}
                options={options1}
                instanceId="chainSelect1"
                className={`select-gray `}
                onChange={e => handleSelected(e)}
                isSearchable={false}
                isClearable={false}
                styles={customStyles}
              />
              <div className="amount">
                <input type="number" placeholder="Amount" onChange={e => setAmount(parseFloat(e.target.value))} />
              </div>
            </div>
            <div className="row mb-20">
              <h5> </h5>
              <p>Total offer amount: - {(amount / 1750).toFixed(2)}ETH</p>
            </div>

            <div className="row">
              <h5>Offer expiration</h5>
            </div>
            <div className="row mb-20">
              <Select
                defaultValue={options[0]}
                formatOptionLabel={FormatsortOptionLabel}
                options={options}
                instanceId="chainSelect"
                className={`select-gray `}
                onChange={e => handleSelected(e)}
                isSearchable={false}
                isClearable={false}
                styles={customStyles}
              />
              <div className="date">
                <DateTimePickerField label={undefined} value={undefined} onChange={undefined} />
              </div>
            </div>
            <div className="btns">
              <FilledButton label="Make Offer" />
            </div>
          </div>
        </div>
      </Bounce>
    </div>
  );
};
export default MakeModal;
