import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '../Container';
import TinyBold from '../../Typography/TinyBold';
import { Link } from 'react-router-dom';
import FilledButton from 'components/Buttons/FilledButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#0E1720',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),

    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },

  center: {
    padding: theme.spacing(1, 0),
    display: 'flex',
    justifyContent: 'space-between',
  },

  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  rowMob: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },

  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 1440,
  },
  logo: {
    display: 'flex',
    height: 60,
    '& img': {
      [theme.breakpoints.down('xs')]: {
        height: 40,
      },
    },
  },

  mail: {
    fontWeight: 300,
    marginBottom: 0,
    fontSize: 16,
    color: `#fff`,
    '& span': {
      fontWeight: 500,
      color: '#E92C2C',
    },
    '& a': {
      fontWeight: 500,
      color: '#fff',
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      marginTop: 5,
      marginBottom: 5,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  mail1: {
    fontWeight: 300,
    marginTop: 20,
    fontSize: 16,
    color: `#fff`,
    '& span': {
      fontWeight: 500,
      color: '#E92C2C',
    },
    '& a': {
      fontWeight: 400,
      color: '#fff',
      // textDecoration: 'underline',
    },
  },
  footText: {
    fontWeight: 300,
    marginBottom: 30,
    marginTop: 30,
    fontSize: 16,
    color: `#fff`,
    width: '100%',
    textAlign: 'end',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      textAlign: 'center',
    },
  },
  myForm: {
    marginTop: 40,
    marginBottom: 50,

    '& .mail': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#fff',
      width: 'fit-content',
      padding: '10px 20px',
      borderRadius: 10,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        justifyContent: 'space-between',
      },
      '& input': {
        fontSize: 14,
        border: 'none',
        width: '20vw',
        [theme.breakpoints.down('xs')]: {
          fontSize: 12,
          width: '60%',
        },
        '&:focus': {
          outline: 'none',
        },
      },
      '& button': {
        [theme.breakpoints.down('xs')]: {
          fontSize: 12,
          height: 40,
          borderRadius: 10,
        },
      },
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      marginBottom: 10,
      width: 'calc(100vw - 40px)',
    },
  },
  link: {
    fontWeight: 300,
    marginBottom: 25,
    fontSize: 16,
    color: `#fff !important`,
    fontFamily: "'Space Grotesk', sans-serif",
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      marginBottom: 15,
      width: '100%',
    },
  },
  icon: {
    fontWeight: 300,
    margin: 12,
    fontSize: 30,
    color: `#fff !important`,
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
  column: {
    '& .MuiSvgIcon-root': { color: theme.palette.primary.contrastText },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0 !important',
      paddingTop: 10,
      // width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },

    '& h3': {
      fontSize: 26,
      color: '#fff',
      marginTop: 50,
      marginBottom: 50,
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        marginTop: 5,
        marginBottom: 5,
        textAlign: 'center',
        width: 330,
      },
    },
  },
  socials: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 5,
    marginBottom: 5,
  },
  title: {
    color: '#fff',
    fontSize: 26,
    fontWeight: 700,
    position: 'relative',
    paddingBottom: 15,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      width: '100%',
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);

  return (
    <footer className={classes.root}>
      <Container className={classes.container}>
        <Grid container className={classes.center}>
          {isMobileOrTablet ? (
            <div className={classes.row}>
              <div className={classes.column}>
                <h3>Subscribe to get exciting updates and offers! Enter your email address to subscribe:</h3>
              </div>
              <div className={classes.column}>
                <form action="" className={classes.myForm}>
                  <span className="mail">
                    <input type="text" placeholder="Enter email address" /> <FilledButton label="Subscribe" />
                  </span>
                </form>
              </div>
              <div className={classes.rowMob}>
                <div className={classes.column}>
                  <h1 className={classes.title}>Product</h1>
                  <Link to="/how_works" className={classes.link}>
                    How Does It Work?
                  </Link>
                  <Link to="/what-is-estate" className={classes.link}>
                    What Is a Real Estate NFT?
                  </Link>
                  <Link to="/calculator" className={classes.link}>
                    Real Estate NFT Calculator
                  </Link>
                  <Link to="/key-risk" className={classes.link}>
                    Key Risks
                  </Link>
                  <Link to="/faq" className={classes.link}>
                    FAQ
                  </Link>
                </div>
                <div className={classes.column}>
                  <h1 className={classes.title}>Company</h1>
                  <Link to="/about-us" className={classes.link}>
                    About Us
                  </Link>
                  <Link to="/contact-us" className={classes.link}>
                    Contact Us
                  </Link>
                  <Link to="/why-metropoly" className={classes.link}>
                    Why Metropoly?
                  </Link>
                </div>
                <div className={classes.column}>
                  <h1 className={classes.title}>Legal</h1>
                  <Link to="/cookie_policy" className={classes.link}>
                    Cookie Policy
                  </Link>
                  <Link to="/privacy_policy" className={classes.link}>
                    Privacy Policy
                  </Link>
                  <Link to="/terms_of_service" className={classes.link}>
                    Terms of Service
                  </Link>
                </div>
              </div>
              <div className={classes.column}>
                <Link to="/" className={classes.logo}>
                  <img src="/assets/Complete logo.png" alt="" />
                </Link>

                <div className={classes.socials}>
                  <a href="https://t.me/metropoly_official" target="_blank" rel="noreferrer">
                    <TinyBold className={classes.icon}>
                      <i className="fab fa-telegram"></i>
                    </TinyBold>
                  </a>
                  <a href="https://twitter.com/metropoly_io" target="_blank" rel="noreferrer">
                    <TinyBold className={classes.icon}>
                      <i className="fab fa-twitter"></i>
                    </TinyBold>
                  </a>
                  <a href="https://www.instagram.com/metropoly_io" target="_blank" rel="noreferrer">
                    <TinyBold className={classes.icon}>
                      <i className="fab fa-instagram"></i>
                    </TinyBold>
                  </a>
                  <a href="https://discord.gg/Metropoly" target="_blank" rel="noreferrer">
                    <TinyBold className={classes.icon}>
                      <i className="fab fa-discord"></i>
                    </TinyBold>
                  </a>
                  {/* <a href="https://medium.com" target="_blank" rel="noreferrer">
                    <TinyBold className={classes.icon}>
                      <i className="fab fa-medium-m"></i>
                    </TinyBold>
                  </a> */}
                </div>
                <p className={classes.mail}>Metropoly.io LTD</p>
                <p className={classes.mail}>Intershore Chambers</p>
                <p className={classes.mail}>Road Town, Tortola</p>
                <p className={classes.mail}>British Virgin Islands</p>
                <p className={classes.mail1}>
                  <i className="far fa-envelope"></i> <a href="mailto: info@metropoly.io">info@metropoly.io</a>
                </p>
              </div>
              <div className={classes.column}>
                <p className={classes.mail}>
                  <span>RISK WARNING : </span> Metropoly LTD (“Metropoly”) operates a website at metropoly.io (the
                  “Platform”). By using the Platform, you accept our Terms of Service, Privacy Policy, and Cookie
                  Policy. Buying NFTs involves risk and may result in partial or total loss. Past performance is no
                  guarantee of future results. Any historical returns, expected returns, or probability projections may
                  not reflect actual future performance. Although the data we use from third parties is believed to be
                  reliable, we cannot ensure the accuracy or completeness of data provided by third parties. This is not
                  financial advice, and you should conduct your own due diligence before purchasing NFTs.
                </p>
                <p className={classes.footText}>© 2022 Metropoly Ltd. | All Rights Reserved</p>
              </div>
            </div>
          ) : (
            <>
              <div className={classes.row}>
                <div className={classes.column}>
                  <Link to="/" className={classes.logo}>
                    <img src="/assets/Complete logo.png" alt="" />
                  </Link>

                  <div className={classes.socials}>
                  <a href="https://t.me/metropoly_official" target="_blank" rel="noreferrer">
                    <TinyBold className={classes.icon}>
                      <i className="fab fa-telegram"></i>
                    </TinyBold>
                  </a>
                  <a href="https://twitter.com/metropoly_io" target="_blank" rel="noreferrer">
                    <TinyBold className={classes.icon}>
                      <i className="fab fa-twitter"></i>
                    </TinyBold>
                  </a>
                  <a href="https://www.instagram.com/metropoly_io" target="_blank" rel="noreferrer">
                    <TinyBold className={classes.icon}>
                      <i className="fab fa-instagram"></i>
                    </TinyBold>
                  </a>
                  <a href="https://discord.gg/Metropoly" target="_blank" rel="noreferrer">
                    <TinyBold className={classes.icon}>
                      <i className="fab fa-discord"></i>
                    </TinyBold>
                  </a>
                    {/* <a href="https://medium.com" target="_blank" rel="noreferrer">
                      <TinyBold className={classes.icon}>
                        <i className="fab fa-medium-m"></i>
                      </TinyBold>
                    </a> */}
                  </div>
                  <p className={classes.mail}>Metropoly.io LTD</p>
                  <p className={classes.mail}>Intershore Chambers</p>
                  <p className={classes.mail}>Road Town, Tortola</p>
                  <p className={classes.mail}>British Virgin Islands</p>
                  <p className={classes.mail1}>
                    <i className="far fa-envelope"></i> <a href="mailto:info@metropoly.io">info@metropoly.io</a>
                  </p>
                </div>

                <div className={classes.column}>
                  <h1 className={classes.title}>Product</h1>
                  <Link to="/how_works">
                    <TinyBold className={classes.link}>How Does It Work?</TinyBold>
                  </Link>
                  <Link to="/what-is-estate">
                    <TinyBold className={classes.link}>What Is a Real Estate NFT?</TinyBold>
                  </Link>
                  <Link to="/calculator">
                    <TinyBold className={classes.link}>Real Estate NFT Calculator</TinyBold>
                  </Link>
                  <Link to="/key-risk">
                    <TinyBold className={classes.link}>Key Risks</TinyBold>
                  </Link>
                  <Link to="/faq">
                    <TinyBold className={classes.link}>FAQ</TinyBold>
                  </Link>
                </div>

                <div className={classes.column}>
                  <h1 className={classes.title}>Company</h1>
                  <Link to="/about-us">
                    <TinyBold className={classes.link}>About Us</TinyBold>
                  </Link>
                  <Link to="/contact-us">
                    <TinyBold className={classes.link}>Contact Us</TinyBold>
                  </Link>
                  <Link to="/why-metropoly">
                    <TinyBold className={classes.link}>Why Metropoly?</TinyBold>
                  </Link>
                </div>

                <div className={classes.column}>
                  <h1 className={classes.title}>Legal</h1>
                  <Link to="/cookie_policy">
                    <TinyBold className={classes.link}>Cookie Policy</TinyBold>
                  </Link>
                  <Link to="/privacy_policy">
                    <TinyBold className={classes.link}>Privacy Policy</TinyBold>
                  </Link>
                  <Link to="/terms_of_service">
                    <TinyBold className={classes.link}>Terms of Service</TinyBold>
                  </Link>
                </div>
              </div>

              <div className={classes.row}>
                <div className={classes.column}>
                  <h3>Subscribe to get exciting updates and offers! </h3>
                </div>
                <div className={classes.column}>
                  <form action="" className={classes.myForm}>
                    <span className="mail">
                      <input type="text" placeholder="Enter email address" /> <FilledButton label="Subscribe" />
                    </span>
                  </form>
                </div>
              </div>
              <Grid item md={12} container spacing={3}>
                <Grid item md={12} sm={12} xs={12}>
                  <div className={classes.column}>
                    <p className={classes.mail}>
                      <span>RISK WARNING : </span> Metropoly.io LTD (“Metropoly”) operates a website at metropoly.io (the
                      “Platform”). By using the Platform, you accept our Terms of Service, Privacy Policy, and Cookie
                      Policy. Buying NFTs involves risk and may result in partial or total loss. Past performance is no
                      guarantee of future results. Any historical returns, expected returns, or probability projections
                      may not reflect actual future performance. Although the data we use from third parties is believed
                      to be reliable, we cannot ensure the accuracy or completeness of data provided by third parties.
                      This is not financial advice, and you should conduct your own due diligence before purchasing
                      NFTs.
                    </p>
                    <p className={classes.footText}>© 2022 Metropoly.io LTD | All Rights Reserved</p>
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
