import './optionModal.scss';
import Bounce from 'react-reveal/Bounce';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
interface Props {
  showModal: boolean;
  setShowModal?: any;
  setShowBuy?: any;
  setShowMake?: any;
  setShowList?: any;
  setShowSellAuction?: any;
}
const OptionModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  setShowBuy,
  setShowMake,
  setShowList,
  setShowSellAuction,
}) => {
  const [isStart, setIsStart] = useState(false);
  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        setIsStart(true);
      }, 100);
    }
  }, [setIsStart, showModal]);
  const onClose = () => {
    setIsStart(false);
    setTimeout(() => {
      setShowModal(false);
    }, 800);
  };

  const onBuy = () => {
    setShowBuy(true);
    onClose();
  };
  const onList = () => {
    setShowList(true);
    onClose();
  };
  const onMake = () => {
    setShowMake(true);
    onClose();
  };
  const onSellAuction = () => {
    setShowSellAuction(true);
    onClose();
  };

  return (
    <div className={showModal === true ? 'optionModal active' : 'optionModal'}>
      <Bounce opposite when={isStart}>
        <div className="modelContent">
          <div className="connectWalletHeader">
            <h1 className="connectWalletTitle">Selected Property Options</h1>
            <button className="connectModalCloseButton" onClick={onClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="connectWalletWrapper">
            <div className="row mb-20">
              <div className="state">
                <div className="col">
                  <div className="date">
                    <p>6 Days</p>
                    <img src="/assets/images/calender_02.svg" alt="" />
                  </div>
                  <p className="mb-10">Next Payout</p>
                </div>
                <div className="row mb-10">
                  <img src="/assets/images/clock_icon_03.svg" alt="" />
                  <span>
                    <h5>1st Sep 2022</h5>
                    <p>Payout Date</p>
                  </span>
                </div>
                <div className="row">
                  <img src="/assets/images/dollar.svg" alt="" />
                  <span>
                    <h5>97.44 USD</h5>
                    <p>Monthly Rental Income </p>
                  </span>
                </div>
              </div>
              <div className="btns">
                <button className="filled" onClick={onBuy}>
                  Buy More
                </button>
                <button className="filled" onClick={onList}>
                  Sell
                </button>
                <button className="outLined" onClick={onMake}>
                  Make an Offer
                </button>
                <button className="outLined" onClick={onSellAuction}>
                  Sell on Auction
                </button>
              </div>
            </div>
            <div className="row">
              <Link to="/voting">
                <img src="/assets/images/users_icon_03.svg" alt="" />
                Vote (DAO Rights)
              </Link>

              <Link to="/my-properties">
                <img src="/assets/images/folder_icon.svg" alt="" />
                All My Properties
              </Link>

              <Link to="/transactions">
                <img src="/assets/images/clock_icon.svg" alt="" />
                Transaction History
              </Link>
            </div>
          </div>
        </div>
      </Bounce>
    </div>
  );
};
export default OptionModal;
