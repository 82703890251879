import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/Layout/Container';

import VotingPolls from 'components/VotingPolls';
const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
      paddingTop: 76,
      textAlign: 'center',
    },
  },

  section: {
    position: 'relative',
    paddingTop: theme.spacing(0),
  },
  topSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
  },
  video: {
    width: '100%',
  },
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 70,
    padding: 70,
    backgroundImage: `url("assets/images/home_banner.png")`,
    backgroundSize: '100% 100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      paddingTop: 100,
      backgroundSize: 'cover',
      backgroundPosition: '90%',
    },
    '& .left': {
      flex: 0.6,
    },
    '& .right': {
      flex: 0.4,
      '& img': {
        width: '100%',
      },
    },
    '& h1': {
      color: '#fff',
      fontSize: 40,
      fontWeight: 900,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 30,
      },
    },
    '& p': {
      color: '#fff',
      fontSize: 18,
      lineHeight: 1.8,
      opacity: 0.6,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 14,
      },
    },
    '& .btns': {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 30,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        flexDirection: 'column',
        marginTop: 10,
      },
      '& button': {
        marginRight: 20,
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 14,
          padding: '8px 10px',
          margin: 10,
        },
      },
    },
  },
}));

const Voting = () => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.root}>
        <div className={classes.section}>
          <VotingPolls />
        </div>
      </Container>
    </>
  );
};

export default Voting;
