import { Link, useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Expand from 'react-expand-animated';
import { useState } from 'react';
import TrendingTable from './TrendingTable';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const myData = [
  {
    id: 3,
    img: '/assets/images/nft_01.png',
    name: '3-Bedroom Family Home',
    location: 'Stockholm, Sweden',
    volume: '$850,230',
    startingPrice: '$725',
    priceEstate: '$725,000',
    dividendYield: '8.28%',
    teturnYear: '+ 51.4%',
    onwners: 650,
  },
  {
    id: 6,
    img: '/assets/images/nft_02.png',
    name: '1-Bedroom Studio Apartment',
    location: 'Manchester, UK',
    volume: '$680,750',
    startingPrice: '$215',
    priceEstate: '$215,000',
    dividendYield: '5.66%',
    teturnYear: '+ 38.3%',
    onwners: 600,
  },
  {
    id: 5,
    img: '/assets/images/nft_03.png',
    name: '4-Bedroom House ',
    location: 'Munich, Germany',
    volume: '$510,320',
    startingPrice: '$3,500',
    priceEstate: '$3,500,000',
    dividendYield: '7.20%',
    teturnYear: '+ 46%',
    onwners: 410,
  },
  {
    id: 1,
    img: '/assets/images/nft_04.png',
    name: 'Cozy 1-Bedroom Ensuite ',
    location: 'London, UK',
    volume: '$470,510',
    startingPrice: '$280',
    priceEstate: '$280,000',
    dividendYield: '5.48%',
    teturnYear: '+ 37.4%',
    onwners: 590,
  },
  {
    id: 4,
    img: '/assets/images/nft_05.png',
    name: 'Luxury 5-Bedroom Villa',
    location: 'Berlin, Germany',
    volume: '$425,230',
    startingPrice: '$7,000',
    priceEstate: '$7,000,000',
    dividendYield: '5.3%',
    teturnYear: '+ 36.5%',
    onwners: 400,
  },
];
const cols = [
  'Property Name',
  'Volume',
  'Starting Price',
  'Price of Real Estate*',
  'Dividend Yield',
  'Return in 5 Years*',
];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    paddingTop: 30,
    paddingBottom: 50,
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    width: '100%',
    '& a': {
      fontSize: 14,
      color: '#2892F3',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 10,
      },
    },
    '& h1': {
      '@media screen and (max-width: 576px) and (orientation: portrait)': {
        // width: '100%',
      },
    },
    '@media screen and (max-width: 576px) and (orientation: portrait)': {
      padding: '5px 0px',
    },
  },

  header: {
    fontSize: 30,
    color: '#000',
    textAlign: 'left',
    lineHeight: '50px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    '@media screen and (max-width: 576px) and (orientation: portrait)': {
      fontSize: 18,
    },
    '& .select-gray': {
      marginLeft: 10,
      '@media screen and (max-width: 576px) and (orientation: portrait)': {
        marginLeft: 0,
        marginBottom: 10,
      },
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      padding: '0px 0px',
    },
  },
  node: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 10,
    '& .num': {
      fontSize: 12,
      color: '#000',
      fontWeight: 600,
      paddingTop: 10,
      paddingRight: 10,
    },
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'flex-start',
    background: '#F7F9FA',
    flexWrap: 'wrap',
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 10,
    '& .nft': {
      display: 'flex',
      alignItems: 'center',
      width: '50%',
      '& img': {
        height: 40,
        width: 40,
      },
      '& h3': {
        fontSize: 12,
        color: '#000',
        marginLeft: 10,
        textAlign: 'left',
      },
    },
    '& .profit': {
      width: '50%',
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
      justifyContent: 'center',

      '& p': {
        fontSize: 10,
        color: '#000',
        fontWeight: 600,
        textAlign: 'end',
        '& span': {
          color: '#13CF8F',
        },
      },
    },
    '& ul': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: 0,
      listStyle: 'none',
      marginTop: 10,
      '& li': {
        marginRight: 5,
        width: 'fit-content',
        '& span': {
          color: '#13CF8F',
          fontWeight: 600,
          isplay: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'center',
          '& h5': {
            color: '#000',
            fontSize: 12,
            textAlign: 'left',
            width: '100%',
          },
          '& h6': {
            color: '#93989A',
            fontSize: 8,
            textAlign: 'left',
            width: '100%',
          },
        },
      },
    },
  },
}));

const TrendingNFTs = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);
  const history = useHistory();
  const styles = {
    open: { width: '100%' },
    close: { width: '100%' },
  };
  const transitions = ['height', 'opcity', 'background'];

  const [faqId, setFaqId] = useState(-1);
  const handleClick = (id: number) => {
    if (faqId !== -1) {
      if (faqId === id) {
        setFaqId(-1);
      } else {
        setFaqId(id);
      }
    } else {
      setFaqId(id);
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <h1 className={classes.header}>Trending Real Estate NFTs</h1>
        <Link to="/marketplace">View All</Link>
      </div>
      <div className={classes.content}>
        {isMobileOrTablet ? (
          <>
            {myData.map((d, k) => (
              <div className={classes.node} key={k} onClick={() => handleClick(k)}>
                <p className="num">{k + 1}</p>
                <div className={classes.row}>
                  <div className="nft">
                    <img src={d.img} alt="" onClick={() => history.push(`/detail/${d.id}`)} />
                    <h3>{d.name}</h3>
                  </div>
                  <div className="profit">
                    <p>Expected Return (5Y)</p>
                    <p>
                      <span>{d.teturnYear}</span>
                    </p>
                  </div>
                  <Expand open={faqId === k} duration={300} styles={styles} transitions={transitions}>
                    <ul>
                      <li>
                        <span>
                          <h5>{d.dividendYield}</h5>
                          <h6>Dividend Yield</h6>
                        </span>
                      </li>

                      <li>
                        <span>
                          <h5>{d.priceEstate}</h5>
                          <h6>Floor Price</h6>
                        </span>
                      </li>

                      <li>
                        <span>
                          <h5>{d.onwners}</h5>
                          <h6>Owners</h6>
                        </span>
                      </li>
                      <li>
                        <span>
                          <h5>{1000}</h5>
                          <h6>Supply</h6>
                        </span>
                      </li>
                      <li>
                        <span>
                          <h5>{d.startingPrice}</h5>
                          <h6>Price</h6>
                        </span>
                      </li>

                      <li>
                        <span>
                          <h5>{d.volume}</h5>
                          <h6>Volume</h6>
                        </span>
                      </li>
                    </ul>
                  </Expand>
                </div>
              </div>
            ))}
          </>
        ) : (
          <TrendingTable columns={cols} rows={myData} type={undefined} />
        )}
      </div>
    </div>
  );
};

export default TrendingNFTs;
