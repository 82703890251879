import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  table: {
    '& .MuiTableCell-root': {
      borderBottom: `1px solid #F3F3F3`,
      color: '#000',
      cursor: 'pointer',
      fontWeight: 600,
      textAlign: 'center',
      width: 530,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,

        paddingRight: 0,
      },

      [theme.breakpoints.down('xs')]: {
        minWidth: 160,
        fontSize: 14,
      },
    },
    '& .MuiTableHead-root .MuiTableCell-root': {
      fontSize: 14,
      color: '#000',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
      // textShadow: '1px 1px 0px #000, -1px -1px 0px #000, -1px 1px 0px #000, 1px -1px 0px #000, 3px 3px 3px #000'
    },
  },
  cell: {
    display: 'flex !important',
    alignItems: 'center',
    fontWeight: 600,
    // justifyContent: 'center',
    // flexDirection: 'row',
    color: '#000',
    '& img': {
      width: 60,
      height: 60,
      borderRadius: 80,
      marginLeft: 35,
      marginRight: 10,
    },
    '& h5': {
      marginLeft: 10,
      fontSize: 16,
      color: '#000',
      textAlign: 'left',
    },
    '& p': {
      marginLeft: 10,
      fontSize: 12,
      color: '#ABABAB',
      textAlign: 'left',
    },
  },
  container: {
    borderRadius: '5px',
    // padding: 20,
    // boxShadow: '3px 3px 15px #ddd',
  },
}));

const TrendingTable = ({ columns, rows, type }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead style={{ paddingLeft: '3rem', }}>
          <TableRow >

            {columns.map((column, k) => (
              <TableCell
                style={{ color: '#93989A', textAlign: k === 0 ? 'center' : 'center', width: k === 0 ? 200 : 0 }}
                key={k}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, kk) => (
            <TableRow key={kk}>
              <TableCell style={{ color: '#000' }}>
                <div className={classes.cell} onClick={() => history.push(`/detail/${row.id}`)}>
                  {kk + 1}
                  <img src={row.img} alt="" />
                  <span>
                    <h5> {row.name}</h5>
                    <p>{row.location}</p>
                  </span>
                </div>
              </TableCell>
              <TableCell style={{ color: '#000' }}>{row.volume}</TableCell>
              <TableCell style={{ color: '#000' }}>{row.startingPrice}</TableCell>
              <TableCell style={{ color: '#000' }}>{row.priceEstate}</TableCell>
              <TableCell style={{ color: '#000' }}>{row.dividendYield}</TableCell>
              <TableCell
                style={{
                  fontWeight: 700,
                  color: '#13CF8F',
                }}
              >
                {row.teturnYear}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TrendingTable;
