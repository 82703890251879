import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PopoverMenu from '../PopoverMenu';
const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(3.875),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    '& .MuiPopover-paper': {
      background: '#fff',
      padding: 20,
      boxShadow: '0 0 5px 7px #00000022',
      '@media screen and (max-width: 450px) and (orientation: portrait)': {
        right: '10px !important',
        width: 200,
      },
    },
  },
  menuContent: {
    minWidth: 200,
    [theme.breakpoints.down('xs')]: {
      minWidth: 150,
      width: 150,
    },
  },

  link: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingTop: 20,
    paddingBottom: 10,
    borderBottom: '1px #ddd solid',
    '& a': {
      fontSize: 14,
      color: '#000',
      display: 'flex',
      alignItems: 'center',
      '& .fas': {
        marginRight: 10,
        color: '#999',
      },
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const DropDown = () => {
  const classes = useStyles();

  return (
    <PopoverMenu
      className={classes.paper}
      anchor={<img src="/assets/images/more_icon.svg" alt="" />}
      anchorOrigin={{
        vertical: 60,
        horizontal: 30,
      }}
    >
      <div className={classes.menuContent}>
        <a className={classes.link} href="https://www.metropoly.io/support">
          <p>Help Center</p> <i className="fas fa-angle-right"></i>
        </a>
        <Link className={classes.link} to="/contact-us">
          <p>Contact Us</p> <i className="fas fa-angle-right"></i>
        </Link>
        <Link className={classes.link} to="/report">
          <p>Report</p> <i className="fas fa-angle-right"></i>
        </Link>
      </div>
    </PopoverMenu>
  );
};

export default DropDown;
