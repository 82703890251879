import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import InputField from 'components/Forms/InputField';
import { useLocation, useHistory, Link } from 'react-router-dom';
import Expand from 'react-expand-animated';
import PropertyCard from 'components/Cards/PropertyCard';
import DropDown from '../DropDown';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const tempData = [
  {
    img: '/assets/inbox/user_01.png',
    title: 'Jenny, Property Manager in Ibiza, NFT ID 5238',
    name: 'Jenny, Property Manager',
    netid: '5238',
    text: 'Monthly Statement Villa Ibiza',
    time: '16:00',
    message: [
      'Hey @KevinCranel,',
      ' I hope you are doing well 😊',
      'Here is your monthly statement for the villa in Ibiza, Spain. I have good news for you.',
      'Currently rates are going up in the summer season ',
      'We have experienced more demand than expected. You will find all the related information in the attached statement . Here you can find the latest ###payouts.',
      'Best,',
      'Jenny',
    ],

    property: {
      id: 15,
      img: '/assets/inbox/img_01.png',
      type: 'Holiday Home',
      startPrice: 2600,
      name: 'Stunning 5-Bedroom Villa next to the beach',
      location: 'Ibiza, Spain',
      homeState: 'Vacant',
      beds: 5,
      state: {
        expected: 74,
        yeld: 12.79,
        frequency: 'Monthly',
      },
    },
    book: 'MonthlyStatementJune2022.pdf',
    date: '5th July 2022 4:00 PM',
  },
  {
    img: '/assets/logo_icon.png',
    title: 'Metropoly, New Real Estate in Dubai',
    name: 'Metropoly',
    text: 'New Real Estate NFTs in Dubai...',
    time: '18:00',

    message: [
      'Hey @KevinCranel,',
      'Great news! We just listed a new apartment in Dubai.',
      'As you might have heard, Dubai is one of the top 10 tourist destinations in the world. Bringing endless opportunities for Real Estate NFT owners. You don’t want to miss out. Check it out now! ',
      ' All the best,',
      'Metropoly',
    ],

    property: {
      id: 16,
      img: '/assets/inbox/img_02.png',
      type: 'Rental Property',
      startPrice: 840,
      estatePrice: 840000,
      name: 'Rare 2-Bedroom Apartment in Downtown',
      location: 'Dubai, United Arab Emirates',
      homeState: 'Vacant',
      beds: 2,
      state: {
        expected: 57,
        yeld: 9.42,
        frequency: 'Monthly',
      },
    },
    date: '4th July 2022 6:00 PM',
  },
  {
    img: '/assets/logo_icon.png',
    title: 'Metropoly, Level Update',
    name: 'Metropoly',
    text: 'Congratulations! You’re Now a...',
    time: '08:00',
    message: [
      'Hey @KevinCranel,',
      ' Congratulations! You have reached the Broker status. Experience now a world of exciting bonuses and rewards. Visit our website to learn more about Broker membership perks and how to take advantage of them.',
      "Do you have higher ambitions? Learn how to achieve the Manager level status and join Metropoly's exclusive circle, to receive unparalleled benefits.",
      'Head on over to our website at Metropoly.io to learn more!',
      'All the best,',
      'Metropoly',
    ],
    date: '3rd July 2022 8:00 AM',
  },
  {
    img: '/assets/inbox/user_02.png',
    title: 'Patrick, Marketplace Manager in San Francisco, NFT ID 7690',
    name: 'Patrick, Marketplace Manager',
    text: '23% Price Increase. The Floor ...',
    time: '11:00',
    message: [
      'Hey @KevinCranel,',
      ' I hope you are doing well 😊',
      'Good News! Your Villa in San Francisco now has a floor price of 7,500 USD after a 23% increase.',
      'The floor price is anticipated to increase by 10% in the coming months because there is an high demand for villas in San Francisco due to the predicted price hike for villas towards the end of September.',
      "I've also created a list for you with interesting Villas that are expected to go up in value. You can find them in the PDF attached below.",
      'Best,',
      'Patrick',
    ],

    property: {
      id: 17,
      img: '/assets/inbox/img_03.png',
      type: 'Rental Property',
      startPrice: 7000,
      estatePrice: 7000000,
      name: 'Luxury 5-Bedroom Villa with a Pool',
      location: 'San Francisco, United States of America',
      homeState: 'Rented',
      beds: 5,
      state: {
        expected: 37,
        yeld: 5.30,
        frequency: 'Monthly',
      },
    },
    book: 'VillasJuly2022.pdf',
    date: '2nd July 2022 11:00 AM',
  },
  {
    img: '/assets/inbox/user_03.png',
    title: 'Darrell, Property Manager in Dubai, NFT ID 4921',
    name: 'Darrell, Property Manager',
    text: 'Monthly Statement House Dubai',
    time: '08:30',
    message: [
      'Hey @KevinCranel,',
      'I hope you are well.',
      " I've attached your monthly statement for the Dubai villa below.",
      'I have great news for you! The demand exceeded expectations, and the rates are increasing as a result. The statement that is included has all the necessary information. Have a look at it, here you can find the latest payouts.',
      'All the best',
      'Darrell',
    ],
    property: {
      id: 18,
      img: '/assets/inbox/img_04.png',
      type: 'Holiday Home',
      homeState: 'Vacant',
      startPrice: 1600,
      // estatePrice: 7000000,
      name: 'Stunning 3-Bedroom Villa next to the beach',
      location: 'Dubai, United Arab Emirates',
      beds: 5,
      state: {
        expected: 66,
        yeld: 11.20,
        frequency: 'Monthly',
      },
    },
    book: 'MonthlyStatementJune2022.pdf',
    date: '2nd July 2022 8:30 AM',
  },
  {
    img: '/assets/inbox/user_04.png',
    title: 'Nadia, Property Manager in Paris,  NFT ID 2630',
    name: 'Nadia, Property Manager',
    text: 'Monthly Statement Villa Paris',
    time: '13:00',
    message: [
      'Hey @KevinCranel,',
      'I hope you are doing great 😊',
      'Here is your monthly statement for the villa in Paris, France.',
      'I am pleased to share that I have some good news for you! Demand was higher than we anticipated therefore we increased the rental. The statement below contains all the necessary details and payouts.',
      'Best',
      'Nadia',
    ],
    property: {
      id: 19,
      img: '/assets/inbox/img_05.png',
      type: 'Holiday Home',
      homeState: 'Vacant',
      startPrice: 600,
      // estatePrice: 7000000,
      name: '4-Bedroom Family House with a garden',
      location: 'Paris, France',
      beds: 5,
      state: {
        expected: 51,
        yeld: 8.2,
        frequency: 'Monthly',
      },
    },
    book: 'MonthlyStatementJune2022.pdf',
    date: '1st July 2022 1:00 PM',
  },
  {
    img: '/assets/logo_icon.png',
    title: 'Metropoly, Marketplace Update',
    name: 'Metropoly',
    text: 'New Marketplace Updates!',
    time: '09:30',
    message: [
      'Hey @KevinCranel,',
      " We updated the market while taking our community's concerns into consideration!",
      ' Our website now has a number of additional features. One of those recent features is the new auction page, where users can bid to obtain Real Estate NFTs at the lowest possible price. This is just one of the amazing changes we have incorporated.',
      'Head on to our website and browse through all the new markeplace updates!',
      ' Check it out now!',
      'All the best,',
      'Metropoly',
    ],
    date: '1st July 2022 9:30 AM',
  },
  {
    img: '/assets/inbox/user_05.png',
    title: 'Eleanor, property Manager in San Francisco,  NFT ID 6298',
    name: 'Eleanor, property Manager',
    text: 'Rent Update in San Francisco',
    time: '09:90',
    message: [
      'Hey @KevinCranel,',
      'I hope all is well with you.',
      'The rent in this area dropped 1.25% in the previous quarter due to construction work in the neighborhood. Please feel free to reach out to us if you have any questions.',
      'All the best,',
      'Eleanor',
    ],
    property: {
      id: 20,
      img: '/assets/inbox/img_06.png',
      type: 'Rental Property',
      homeState: 'Rented',
      startPrice: 35000,
      // estatePrice: 7000000,
      name: 'Luxury 5-Bedroom Villa with a pool',
      location: 'San Francisco, United States of America',
      beds: 5,
      state: {
        expected: 37,
        yeld: 5.3,
        frequency: 'Monthly',
      },
    },
    date: '1st July 2022 9:00 AM',
  },
  {
    img: '/assets/logo_icon.png',
    title: 'Metropoly, General Updates',
    name: 'Metropoly',
    text: 'Upcoming Real Estate Projects.. ',
    time: '12:00',
    message: [
      'Hey @KevinCranel,',
      'We have some exciting news for you!',
      "We have a variety of new real estate projects coming in the next few months. We are sure you don't want to miss out on them.",
      "Don't worry, we have got you covered! We've included a link with details on all the $$$upcoming projects.$$$ Have a look at them.",
      'You can also visit our website and browse through our new projects.',
      'All the best,',
      'Metropoly',
    ],
  },
  {
    img: '/assets/inbox/user_06.png',
    title: 'Sophie, Customer Support NFT ID 72193',
    name: 'Sophie, Customer Support',
    text: 'Request No. 2165 has been pr..',
    time: '12:30',
    message: [
      'Hey @KevinCranel,',
      'I hope that you are well.',
      'Your request no.2165 has been received and will be processed by the Metropoly support team.',
      'You should receive a message from our team within 3 business day. If you have any additional information to add to the request, please feel free to reach out using the contact form and include your request no.',
      'All the best,',
      'Sophie',
    ],
    date: '30th June 2022 12:00 PM',
  },
  {
    img: '/assets/logo_icon.png',
    title: 'Metropoly, General Updates',
    name: 'Metropoly',
    text: 'New Marketplace Features...',
    time: '14:00',
    message: [
      'Hey @KevinCranel,',
      'We have some exciting news for you!',
      "On request from our community, we've added a number of new features to the Metropoly website, including credit card payments. Browse through these features by heading on over to our website.",
      "Don't forget to fill out the website's feedback form to help improve our platform.",
      'All the best,',
      'Metropoly',
    ],
    date: '29th June 2022 12:30 PM',
  },

  {
    img: '/assets/inbox/user_07.png',
    title: 'Alfeen, Marketplace Manager in Stockholm, NFT ID 9235',
    name: 'Alfeen, Marketplace Manager',
    text: '16% price increase in Stockholm',
    time: '17:00',
    message: [
      'Hi @KevinCranel,',
      'I hope you are doing well! We have some exciting news for you!',
      'The price of your home in Stockholm, Sweden, has now increased by 16%.', 'Furthermore, the increasing demand for real estate is expected to cause the floor price to rise by another 20% in the coming months. By the end of the year, it will be an increase in demand for real estate in Sweden.',
      'Here is a list of Swedish properties that should increase in value to increase by 30% in the coming months.',
      'All the best to you',
      'Alfeen',
    ],

    property: {
      id: 3,
      img: '/assets/inbox/img_07.png',
      type: 'Rental Property',
      startPrice: 725,
      name: 'Modern 3-Bedroom Family Home',
      location: 'Stockholm, Sweden',
      homeState: 'Vacant',
      beds: 5,
      state: {
        expected: 51,
        yeld: 8.28,
        frequency: 'Monthly',
      },
    },
    book: 'SwedishHomeReport.pdf',
    date: '28th June 2022 5:00 PM',
  },
  {
    img: '/assets/logo_icon.png',
    title: 'Metropoly, General Updates',
    name: 'Metropoly',
    text: 'New Marketplace Updates',
    time: '13:20',
    message: [
      'Hey @KevinCranel,',
      'We updated the marketplace while taking our communities concerns into consideration!',
      'Our website now has a number of additional features. One of those recent features is the new Lending/Borrow. Now you can Borrow or Lend Money with the NFTs you own. Isn’t it amaizing?',
      ' Head on our website and browse through all the new updates!',
      'Check it out now!',
      'All the best,',
      'Metropoly',
    ],
    date: '25th June 2022 01:20 PM',
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    '@media screen and (max-width: 576px) and (orientation: portrait)': {
      justifyContent: 'flex-start',
      paddingBottom: 30,
      paddingTop: 10,
    },
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 20,
    width: '100%',
    position: 'relative',
    '& .fas': {
      left: 25,
      top: 'auto',
      bottom: 'auto',
      zIndex: 1,
      fontSize: 20,
      position: 'absolute',
      color: '#aaa',
      '@media screen and (max-width: 576px) and (orientation: portrait)': {
        flexDirection: 'column',
      },
    },
    '@media screen and (max-width: 576px) and (orientation: portrait)': {
      flexDirection: 'column',
    },
  },

  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
    },
  },
  item: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: '1px #eee solid',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    borderRadius: 10,
    padding: 10,
    '&:hover': {
      background: '#eee',
    },
    '@media (max-width: 576px)': {
      alignItems: 'center',
    },
    '& .avatar': {
      width: 60,
      height: 60,
      borderRadius: 50,
      overflow: 'hidden',
      marginRight: 10,
      '& img': {
        width: '100%',
        height: '100%',
      },
      '@media (max-width: 576px)': {
        width: 50,
        height: 50,
      },
    },
    '& .avatar-content': {
      width: 'calc(100% - 70px)',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      position: 'relative',
      '@media (max-width: 576px)': {
        width: 'calc(100% - 60px)',
        alignItems: 'center',
      },
      '& .text': {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        position: 'relative',
        flexDirection: 'column',
        '@media (max-width: 576px)': {
          width: 'calc(100% - 40px)',
        },
        '& h3': {
          width: '60%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          boxSizing: 'border-box',
          color: '#000',
          fontSize: 18,
          textAlign: 'left',
          marginBottom: 5,
          fontWeight: 600,
          '@media (max-width: 576px)': {
            width: 'calc(100%)',
          },
        },
        '& p': {
          width: '70%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          boxSizing: 'border-box',
          color: '#93989A',
          fontSize: 14,
          textAlign: 'left',
          '@media (max-width: 576px)': {
            width: 'calc(100%)',
          },
        },
      },
      '& .time': {
        position: 'absolute',
        top: 0,
        right: 0,
        color: '#93989A',
        fontWeight: 600,
        fontSize: 14,
      },
      '& .moreButton': {
        position: 'absolute',
        top: 15,
        right: 0,
        color: '#93989A',
        fontWeight: 600,
        fontSize: 14,
      },
    },
  },
  selected: {
    background: '#2081E223',
    '&:hover': {
      background: '#2081E240',
    },
  },
  formWrapper: {
    width: '100%',
  },
  myInput: {
    background: '#F7F9FA',
    border: 'none',
    color: '#aaa',
    borderRadius: 10,
    paddingLeft: 50,
    // boxShadow: '0px 0px 3px #f',
  },
  expand: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: 'calc(100% - 360px)',
    padding: 0,
    paddingTop: 5,

    '@media screen and (max-width: 576px) and (orientation: portrait)': {
      flexDirection: 'column',
      width: '100%',
    },
  },

  messageBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',

    width: 'calc(90%)',
    background: '#F3F3F3',
    borderRadius: 10,
    padding: 20,
    paddingBottom: 10,
    border: '1px solid #DBDDDD',
    marginBottom: 20,
    '@media screen and (max-width: 576px) and (orientation: portrait)': {
      width: 'calc(100%)',
    },
    '& p': {
      fontSize: 14,
      color: '#000',
      marginBottom: 15,
      textAlign: 'left',
    },
    '& a': {
      color: '#2892F3',
      fontWeight: 600,
    },
  },
  subText: {
    display: 'flex',
    paddingBottom: 20,
    width: 'calc(90%)',
    borderRadius: 10,
    '& a': {
      fontSize: 14,
      color: '#000',
      display: 'flex',
      alignItems: 'center',
      // marginBottom: 20,
    },
  },
  footer: {
    textAlign: 'left',
    width: '100%',
    paddingBottom: 30,

    '@media screen and (max-width: 576px) and (orientation: portrait)': {},
    '& p': {
      fontSize: 14,
      color: '#93989A',
    },
  },
}));

interface PropsType {
  setChat?: any;
  setHide?: any;
}

const InboxList = ({ setChat, setHide }: PropsType) => {
  const classes = useStyles();

  const [search, setSearch] = useState('');
  const [charId, setChatId] = useState(0);
  const [inboxList, setInboxList] = useState<any[]>([]);

  useEffect(() => {
    if (search !== '') {
      var filtered = tempData.filter(d => d.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
      setInboxList(filtered);
    } else {
      setInboxList(tempData);
    }
  }, [search, setInboxList]);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const index = location.pathname.split('/').pop();
    setChatId(parseInt(index));
    setChat(inboxList[index]);
    console.log(index);
  }, [location, inboxList, setChat, setChatId]);

  const handleClickChat = (chat: any, id: number) => {
    setChat(chat);
    setHide(false);
    setChatId(id);
    history.push(`/inbox/${id}`);
  };
  const styles = {
    open: { width: '100%' },
    close: { width: '100%' },
  };
  const transitions = ['height', 'opcity', 'background'];

  const [faqId, setFaqId] = useState(-1);
  const handleClick = (id: number) => {
    if (faqId !== -1) {
      if (faqId === id) {
        setFaqId(-1);
      } else {
        setFaqId(id);
      }
    } else {
      setFaqId(id);
    }
  };

  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <InputField
          name="name"
          className={classes.myInput}
          wrapperClass={classes.formWrapper}
          startIcon={<i className="fas fa-search"></i>}
          label=""
          placeholder="Search Chat"
          onChangeData={val => {
            setSearch(val);
          }}
        />
      </div>
      <div className={classes.content}>
        {inboxList.length === 0 ? (
          <p>No Messages</p>
        ) : (
          inboxList.map((d, k) => (
            <>
              {isMobileOrTablet ? (
                <div className={`${classes.item} `} key={k}>
                  <div className="avatar" onClick={() => handleClick(k)}>
                    <img src={d.img} alt="" />
                  </div>
                  <div className="avatar-content">
                    <div className="text" onClick={() => handleClick(k)}>
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                    {faqId === k ? (
                      <div className="moreButton">
                        {' '}
                        <DropDown />{' '}
                      </div>
                    ) : (
                      <div className="time"> {d.time}</div>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className={`${classes.item} ${charId === k ? classes.selected : ''}`}
                  key={k}
                  onClick={() => handleClickChat(d, k)}
                >
                  <div className="avatar">
                    <img src={d.img} alt="" />
                  </div>
                  <div className="avatar-content">
                    <div className="text">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                    <div className="time">{d.time}</div>
                  </div>
                </div>
              )}
              {isMobileOrTablet && (
                <Expand open={faqId === k} duration={300} styles={styles} transitions={transitions}>
                  <div className={classes.expand}>
                    {d?.message && (
                      <div className={classes.messageBox}>
                        {d?.message.map((t, k) => (
                          <p key={k}>
                            {t.includes('###') ? (
                              <>
                                {t.split('###')[0]} <Link to={`/payouts`}>{t.split('###')[1]}</Link>
                              </>
                            ) : t.includes('$$$') ? (
                              <>
                                {t.split('$$$')[0]} <Link to={`/upcoming`}>{t.split('$$$')[1]}</Link>{' '}
                                {t.split('$$$')[2]}
                              </>
                            ) : (
                              <>{t}</>
                            )}
                          </p>
                        ))}
                      </div>
                    )}
                    {d?.property && <PropertyCard product={d?.property} />}
                    {d?.book && (
                      <div className={classes.subText}>
                        <a href="/" target={'_blank'}>
                          <img src="/assets/images/pdf_icon.svg" alt="" /> {d?.book}{' '}
                          <img src="/assets/images/download_icon.svg" alt="" />
                        </a>
                      </div>
                    )}

                    <div className={classes.footer}>
                      <p>{d?.date}</p>
                    </div>
                  </div>
                </Expand>
              )}
            </>
          ))
        )}
      </div>
    </div>
  );
};

export default InboxList;
