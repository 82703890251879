import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/Layout/Container';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 15,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
      paddingTop: 0,
      textAlign: 'center',
    },
  },

  section: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 20,
    '& span': {
      marginBottom: 20,
    }, '& .bold': {
      color: '#000',
      fontSize: 14,
      width: '100%',
      fontWeight: 600,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 12,
        textAlign: 'left',
      },
    },
    '& p': {
      color: '#000',
      fontSize: 14,
      width: '100%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 12,
        textAlign: 'left',
      },
    },
    '& h2': {
      color: '#000',
      fontSize: 20,
      width: '100%',
      marginBottom: 20,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 18,
      },
    },
  },
  topSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(7),
  },
  video: {
    width: '100%',
  },
  wrapper: {
    height: '100%',
    minHeight: '16vw',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 70,
    padding: 70,
    backgroundImage: `url("assets/images/home_banner.png")`,
    backgroundSize: '100% 100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      flexDirection: 'column',
      paddingTop: 100,
      backgroundSize: 'cover',
      backgroundPosition: '90%',
    },
    '& h1': {
      color: '#fff',
      fontSize: 40,
      fontWeight: 700,
      fontFamily: "'Outfit', sans-serif",
      width: '100%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 30,
      },
    },
  },
  heading: {
    fontWeight: 'bold'
  }
}));

const CookiePolicy = () => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.root}>
        <div className={classes.topSection}>
          <div className={classes.wrapper}>
            <h1>COOKIE POLICY</h1>
          </div>
        </div>
        <div className={classes.section}>
          <span>
            This security policy has been compiled to better serve those concerned with how their Personally
            Identifiable Information (PII) is being used online. As described in privacy law and information security,
            PII is information that can be used on its own or with other information to identify, contact, or locate a
            single person or identify an individual in context. Please read our privacy policy carefully to understand
            how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with
            our website.
          </span>

          <p className='bold'>What personal information do we collect from the people that visit our blog, website or app?</p>
          <p>
            When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address
            or other details to help you with your experience.
          </p>
          <br />
          <p className='bold'>When do we collect information?</p>
          <p>We collect information from you when you register or enter information on our site.</p>
          <br />
          <p className='bold'>How do we use your information?</p>

          <p>
            We may use the information we collect from you when you register, sign up for our newsletter, respond to a
            survey or marketing communication, surf the website, or use certain other site features.
          </p>
        </div>
      </Container>
    </>
  );
};

export default CookiePolicy;
