import { makeStyles } from '@material-ui/core/styles';
import { numberToString } from 'utils';
const series1 = [
  {
    img: '/assets/images/hand_02.svg',
    title: 'Minimum. amount',
    value: ' $5,200',
  },
  {
    img: '/assets/images/users_icon_02.svg',
    title: 'Number of NFT holders',
    value: '800',
  },
  {
    img: '/assets/images/hand_01.svg',
    title: 'Suggested holding period',
    value: '5 Years',
  },
  {
    img: '/assets/images/hand_03.svg',
    title: 'Annual Rent',
    value: '$405,080',
  },
];
const series2 = [
  {
    img: '/assets/images/chart_icon_04.svg',
    title: 'Expected Dividend Yield',
    value: '7.79%',
  },
  {
    img: '/assets/images/chart_icon_03.svg',
    title: 'Gross yield',
    value: '12.79%',
  },
  {
    img: '/assets/images/poker_icon_01.svg',
    title: '5 YR Expected Return',
    value: '7.79%',
  },
  {
    img: '/assets/images/chart_icon_05.svg',
    title: 'Dividend Frequency',
    value: '7.79%',
  },
];
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 50,
    background: '#F8F9FB',
    width: '100%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      paddingBottom: 40,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingBottom: 10,
    [theme.breakpoints.only('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },

  left: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '50%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      width: '100%',
      marginBottom: 50,
    },
    '& p': {
      color: '#000',
      fontSize: 14,
      width: '90%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 12,
      },
    },
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '50%',
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      width: '100%',
    },
  },
  wrapper: {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    width: '100%',
    '& li': {
      width: '50%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 20,
      '& h5': {
        color: '#000',
        fontSize: 18,
        display: 'flex',
        alignItems: 'flex-start',
        '& span': {
          color: '#999',
          fontSize: 12,
          marginLeft: 7,
          '@media screen and (max-width: 768px) and (orientation: portrait)': {
            fontSize: 10,
          },
        },
      },
      '& p': {
        color: '#000',
        fontSize: 12,
        width: '90%',
        textAlign: 'center',
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
          fontSize: 10,
        },
      },
    },
  },
  header: {
    fontSize: 35,
    color: '#000',
    textAlign: 'center',
    lineHeight: '50px',
    position: 'relative',
    marginBottom: 30,
    fontWeight: 600,
    width: '100%',
    '@media (max-width: 768px)': {
      fontSize: '1.5em',
    },
  },

  img: {
    width: 56,
    height: 56,
    padding: 15,
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
    alignItems: 'center',
    background: 'radial-gradient(94.26% 94.26% at 61.78% 22.88%, #FAFCFF 0%, #E5E8EC 100%, #fff 1%)',
    boxShadow: '0px 1.21423px 4.8569px rgba(0, 0, 0, 0.25), 0px 15px 35px rgba(0, 0, 0, 0.15)',
  },

  smalTxt: {
    marginTop: 20,
    color: '#777',
    fontSize: 14,
  },
}));
interface PropsType {
  data?: any;
}
const PropertyInfo = ({ data }: PropsType) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.left}>
            <h1 className={classes.header}>Property Info</h1>
            <ul className={classes.wrapper}>
              <li>
                <div className={classes.img}>
                  <img src={series1[0].img} alt="" />
                </div>
                <p>{series1[0].title}</p>
                <h5>${numberToString(data?.startPrice || 0)}</h5>
              </li>

              <li>
                <div className={classes.img}>
                  <img src={series1[1].img} alt="" />
                </div>
                <p>{series1[1].title}</p>
                <h5>800</h5>
                {/* <h5>{data?.state1.owners === -1 ? '-' : numberToString(data?.state1.owners || 0)}</h5> */}
              </li>
    
              <li>
                <div className={classes.img}>
                  <img src={series1[2].img} alt="" />
                </div>
                <p>{series1[2].title}</p>
                <h5>{series1[2].value}</h5>
              </li>

              <li>
                <div className={classes.img}>
                  <img src={series1[3].img} alt="" />
                </div>
                <p>{series1[3].title}</p>
                <h5>${numberToString(data?.financial.annualIncome || 0)}</h5>
              </li>
            </ul>
          </div>
          <div className={classes.right}>
            <h1 className={classes.header}>Rental Yields</h1>
            <ul className={classes.wrapper}>
              <li>
                <div className={classes.img}>
                  <img src={series2[0].img} alt="" />
                </div>
                <p>{series2[0].title}</p>
                <h5>
                  {data?.howmuch.netDividend} <span>%</span>
                </h5>
              </li>
              <li>
                <div className={classes.img}>
                  <img src={series2[1].img} alt="" />
                </div>
                <p>{series2[1].title}</p>
                <h5>
                  {data?.financial.grossYield} <span>%</span>
                </h5>
              </li>
              <li>
                <div className={classes.img}>
                  <img src={series2[2].img} alt="" />
                </div>
                <p>{series2[2].title}</p>
                <h5>
                  {((data?.howmuch.totalAvg / 2) * 10).toFixed(2)} <span>%</span>
                </h5>
              </li>
              <li>
                <div className={classes.img}>
                  <img src={series2[3].img} alt="" />
                </div>
                <p>{series2[3].title}</p>
                <h5>Monthly</h5>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyInfo;
