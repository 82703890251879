import { makeStyles } from '@material-ui/core/styles';
import PayoutSection from 'components/PayoutSection';
const useStyles = makeStyles(theme => ({
  root: {
    padding: 20,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
      textAlign: 'center',
    },
  },

  section: {
    position: 'relative',
    paddingTop: theme.spacing(0),
    [theme.breakpoints.down('xs')]: {
      // padding: 20,
    },
  },
  topSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(7),
  },
  video: {
    width: '100%',
  },
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 20,
    padding: 20,
    border: '1px #ddd solid',
    borderRadius: 20,
    '@media screen and (max-width: 768px) and (orientation: portrait)': {
      margin: 10,
      width: 'calc(100% - 20px)',
    },

    '& span': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 30,
      },
    },
    '& p': {
      color: '#000',
      fontSize: 18,
      lineHeight: 1.8,
      '@media screen and (max-width: 768px) and (orientation: portrait)': {
        fontSize: 14,
      },
    },
  },
  top: {
    background: '#ffffff00',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: '1px #ddd solid',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 20,
    },
  },
  title: {
    fontSize: 32,
    color: '#000',
  },
}));

const Payout = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.section}>
          <PayoutSection />
        </div>
      </div>
    </>
  );
};

export default Payout;
