import { makeStyles } from '@material-ui/core/styles';
// import { useState } from 'react';
import { Link } from 'react-router-dom';
import TextTimer from 'components/Timer/TextTimer';
import RecentProposals from './RecentProposals';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#fdfdfd',
    padding: theme.spacing(0, 2),
    paddingTop: 50,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
    maxWidth: 1440,
    paddingBottom: 30,

    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingTop: 10,
      paddingBottom: 10,
      flexDirection: 'column',
    },
  },
  right_row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 1440,
    paddingBottom: 30,
    [theme.breakpoints.only('sm')]: {},
    [theme.breakpoints.down('xs')]: {},
    '& a': {
      background: '#00D9AC00',
      border: '1px #2892F3 solid',
      fontSize: 30,
      color: '#2892F3',
      padding: '10px 30px',
      borderRadius: 10,
      transition: 'all 0.3s ease',
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        padding: '8px 20px',
      },
      '&:hover': {
        opacity: 0.7,
      },
    },
  },
  top: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    maxWidth: 1440,
    '@media screen and (max-width: 640px) and (orientation: portrait)': {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  left: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingBottom: '30px',
    paddingRight: 30,
    width: '70%',
    '@media screen and (max-width: 640px) and (orientation: portrait)': {
      width: '100%',
      paddingRight: 0,
    },
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingBottom: '30px',
    width: '30%',
    '@media screen and (max-width: 640px) and (orientation: portrait)': {
      width: '100%',
    },
  },

  header: {
    fontSize: 24,
    color: '#000',
    textAlign: 'left',
    position: 'relative',
    width: '100%',
    '@media screen and (max-width: 640px) and (orientation: portrait)': {
      fontSize: '1.5em',
      // lineHeight: 1,
    },
  },
  text: {
    fontSize: 14,
    color: '#888',
    textAlign: 'left',
    position: 'relative',
    marginBottom: 20,
    width: '100%',
    '@media screen and (max-width: 640px) and (orientation: portrait)': {
      fontSize: 12,
    },
  },
  btns: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    '@media screen and (max-width: 640px) and (orientation: portrait)': {
      alignItems: 'center',
    },
    '& button': {
      border: 'none',
      padding: '8px 20px',
      borderRadius: 7,
      margin: 10,
      width: '40%',
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.8,
      },
    },
  },
  button1: {
    background: '#13CF8F',
  },
  button2: {
    background: '#F17A7A',
  },

  productWrapper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    cursor: 'pointer',
    boxShadow: '3px 3px 5px #777',
    borderRadius: 7,
    padding: 20,
    border: '1px solid #2C8DFF',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginBottom: 20,
    width: '100%',
    '@media screen and (max-width: 640px) and (orientation: portrait)': {
      alignItems: 'center',
    },
    '& .property': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column',
      '& .nft': {
        display: 'flex',
        alignItems: 'center',
        '& img': {
          width: 56,
          marginRight: 10,
          '@media screen and (max-width: 640px) and (orientation: portrait)': {
            width: 30,
          },
        },
        '& span': {
          display: 'flex',
          alignItems: 'center',
          '@media screen and (max-width: 640px) and (orientation: portrait)': {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
          },
        },
      },
      '& p': {
        fontSize: 14,
        color: '#999',
        '@media screen and (max-width: 640px) and (orientation: portrait)': {
          fontSize: 12,
        },
      },
      '& h4': {
        fontSize: 18,
        color: '#000',
        marginRight: 10,
        textAlign: 'left',
        '@media screen and (max-width: 640px) and (orientation: portrait)': {
          fontSize: 12,
        },
      },
    },
    '& .user': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',

      '& img': {
        width: 56,
        marginRight: 10,
        '@media screen and (max-width: 640px) and (orientation: portrait)': {
          width: 30,
        },
      },
      '& span': {
        display: 'flex',
        alignItems: 'center',
      },
      '& p': {
        fontSize: 14,
        color: '#999',
        '@media screen and (max-width: 640px) and (orientation: portrait)': {
          fontSize: 12,
        },
      },
      '& h4': {
        fontSize: 18,
        color: '#000',
        marginRight: 10,
        fontWeight: 300,
        '@media screen and (max-width: 640px) and (orientation: portrait)': {
          fontSize: 12,
        },
      },
    },
  },

  cardState: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginBottom: 20,
    '@media screen and (max-width: 640px) and (orientation: portrait)': {
      justifyContent: 'center',
    },
    '& p': {
      fontSize: 16,
      color: '#999',
    },
    '& .values': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .dot1': {
      display: 'flex',
      alignItems: 'center',
      marginRight: 30,
      '& h6': {
        fontSize: 16,
        fontWeight: 400,
        color: '#999',
        marginRight: 10,
        '@media screen and (max-width: 640px) and (orientation: portrait)': {
          fontSize: 12,
        },
      },
      '& h5': {
        fontSize: 16,
        fontWeight: 400,
        color: '#000',
        '@media screen and (max-width: 640px) and (orientation: portrait)': {
          fontSize: 12,
        },
      },
      '&:before': {
        content: '""',
        width: 10,
        height: 10,
        borderRadius: 10,
        marginRight: 10,
        background: '#13CF8F',
      },
    },
    '& .dot2': {
      display: 'flex',
      alignItems: 'center',
      marginRight: 10,
      '& h6': {
        fontSize: 16,
        fontWeight: 400,
        color: '#999',
        marginRight: 10,
        '@media screen and (max-width: 640px) and (orientation: portrait)': {
          fontSize: 12,
        },
      },
      '& h5': {
        fontSize: 16,
        fontWeight: 400,
        color: '#000',
        '@media screen and (max-width: 640px) and (orientation: portrait)': {
          fontSize: 12,
        },
      },
      '&:before': {
        content: '""',
        width: 10,
        height: 10,
        borderRadius: 10,
        marginRight: 10,
        background: '#F17A7A',
      },
    },
  },

  cardDetail: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginBottom: 20,

    '@media screen and (max-width: 640px) and (orientation: portrait)': {
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& p': {
      fontSize: 16,
      color: '#999',
      textAlign: 'left',
      '@media screen and (max-width: 640px) and (orientation: portrait)': {
        fontSize: 12,
      },
    },
    '& h4': {
      fontSize: 16,
      color: '#000',
      marginRight: 10,
      fontWeight: 300,
      textAlign: 'left',
      '@media screen and (max-width: 640px) and (orientation: portrait)': {
        fontSize: 12,
      },
    },
  },
  progressState: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    background: '#F17A7A',
    height: 30,
    borderRadius: 30,
    overflow: 'hidden',
    width: '100%',
    '@media screen and (max-width: 640px) and (orientation: portrait)': {
      marginTop: 20,
      height: 20,
    },

    '& .progress': {
      display: 'flex',
      height: 30,
      borderRadius: 30,
      background: '#13CF8F',
      '@media screen and (max-width: 640px) and (orientation: portrait)': {
        height: 20,
      },
    },
  },

  cardFooter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 10,
    '& p': {
      fontSize: 14,
      color: '#999',
    },
  },
  panel: {
    width: '100%',
    border: '1px solid #2C8DFF',
    padding: 30,
    boxShadow: '3px 3px 5px #777',
    borderRadius: 7,
    '& h4': {
      color: '#999',
      textAlign: 'left',
      '@media screen and (max-width: 640px) and (orientation: portrait)': {
        fontSize: 12,
      },
    },
    '& .timer-div': {
      paddingBottom: 10,
      marginBottom: 10,
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px #ddd solid',
      '@media screen and (max-width: 640px) and (orientation: portrait)': {},
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 10,
      paddingBottom: 10,
      '& p': {
        color: '#000',
        textAlign: 'left',
        '@media screen and (max-width: 640px) and (orientation: portrait)': {
          fontSize: 12,
        },
      },
      '& img': {
        marginRight: 7,
        '@media screen and (max-width: 640px) and (orientation: portrait)': {},
      },
    },
  },
}));

const VotingPolls = () => {
  const classes = useStyles();
  // const [timeEnd, setTimeEnd] = useState(false);
  console.log();
  return (
    <>
      <div className={classes.root}>
        <h1 className={classes.header}>Voting</h1>
        <p className={classes.text}>
          Each real estate NFT owner has a voting right proportional to the number of fractions held.{' '}
        </p>
        <div className={classes.content}>
          <div className={classes.left}>
            <div className={classes.productWrapper}>
              <div className={classes.row}>
                <div className="property">
                  <p>Property Name & location</p>
                  <div className="nft">
                    <img src="/assets/images/nft_01.png" alt="" />
                    <span>
                      <h4>3-Bedroom Family home </h4>
                      <p>Stockholm, Sweden</p>
                    </span>
                  </div>
                </div>
                <div className="user">
                  <p>Created By</p>
                  <img src="/assets/avatars/user_01.png" alt="" />
                  <h4>Gohard</h4>
                </div>
              </div>
              <div className={classes.cardDetail}>
                <p>Description</p>
                <h4>Should we sell the property at a future market valuation of $1,000,000?</h4>
              </div>
              <div className={classes.cardState}>
                <p>Current Votes</p>
                <div className="values">
                  <div className="dot1">
                    <h6>Yes</h6> <h5>50%</h5>
                  </div>
                  <div className="dot2">
                    <h6>No</h6> <h5>50%</h5>
                  </div>
                </div>
              </div>

              <div className={classes.progressState}>
                <div className="progress" style={{ width: '50%' }}></div>
              </div>

              <div className={classes.cardFooter}>
                <p>120 of 600 owners voted</p>
              </div>
            </div>
            <div className={classes.btns}>
              <button className={classes.button1}>
                <img src="/assets/images/up_icon.svg" alt="" />
              </button>
              <button className={classes.button2}>
                <img src="/assets/images/down_icon.svg" alt="" />
              </button>
            </div>
          </div>
          <div className={classes.right}>
            <div className={classes.panel}>
              <h4>Time Remaining</h4>
              <div className="timer-div">
                <img src="/assets/images/clock_icon.svg" alt="" />
                <TextTimer deadLine={1663829200} setTimeEnd={() => {}} />
              </div>

              <h4>Minimum. Requirements</h4>
              <span>
                <img src="/assets/images/defender_icon.svg" alt="" />
                <p>At least 50% of all owners must vote</p>
              </span>
              <span>
                <img src="/assets/images/defender_icon.svg" alt="" />
                <p>Overall, 75% must accept the proposal</p>
              </span>
              <span>
                <img src="/assets/images/defender_icon.svg" alt="" />
                <p>A vote is valid for 7 days</p>
              </span>
            </div>
          </div>
        </div>

        <div className={classes.content}>
          <RecentProposals />
        </div>
        <div className={classes.right_row}>
          <Link to={'/new-proposal'}>New Proposal</Link>
        </div>
      </div>
    </>
  );
};

export default VotingPolls;
